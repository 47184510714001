import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { DataService } from 'src/app/services/data-service/data.service';
import { Auth } from 'src/app/models';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'cls-single-sign-on',
	templateUrl: './single-sign-on.component.html',
	styleUrls: ['./single-sign-on.component.scss'],
})
export class SingleSignOnComponent implements OnInit, OnDestroy {
	subscriptions: Array<Subscription> = [];
	state: any;
	params = { key: null, user: null, hash: null, email: null };
	isMobile = false;
	isAndroid = false;
	ua: any;
	token: any;

	constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private global: GlobalService,
    private translate: TranslateService
	) {
		this.ua = navigator.userAgent;
	}
	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}

	ngOnInit() {
		this.getUrlParameters();
		this.checkMobileOrNavigate();
	}

	checkMobileOrNavigate() {
		if (
			/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
				this.ua
			)
		) {
			this.isMobile = true;
			if (/android/i.test(this.ua)) this.isAndroid = true;
		} else this.goToMobileWeb();
	}

	goToMobileWeb() {
		const subscription = this.dataService
			.doSingleSignOnLogin(this.params)
			.pipe(
				map((data: any) => (data.auth ? data.auth : data)),
				tap((data: Auth) => this.dataService.afterLoginRoutine(data)),
				switchMap(() =>
					this.dataService.getUsuarioByIdentificador(
						this.params.email || this.params.user
					)
				),
				tap((data: any) => {
					this.dataService.initialRoutine(data.usuario);
					this.dataService.checkEmailAndSetEmpresa(data.usuario.email);
					this.dataService.sendUltimoAcesso('login', null);
				}),
				catchError((error) => {
					this.dataService.logout();
					this.global.toast(
						this.translate.instant('GENERAL.ERROR'),
						error.error.message
					);
					return error;
				})
			)
			.subscribe();
		return this.subscriptions.push(subscription);
	}

	downloadApp() {
		if (this.isAndroid)
			window.open(
				'https://play.google.com/store/apps/details?id=br.com.ligaeducacional.ligaonline'
			);
		else window.open('https://itunes.apple.com/app/liga-online/id1502314858');
	}

	goToApp() {
		// Create url using single sign on legacy
		let url = this.isAndroid
			? 'ligaonlinee://androidapp/token?tkn=' +
        this.params.key +
        '&user=' +
        this.params.user
			: 'ligaonline://inparans?token=' +
        this.params.key +
        '&user=' +
        this.params.user;
		// if has a hash in this.params use new single sign on
		if ('hash' in this.params && this.params.hash !== null)
			url = this.isAndroid
				? 'ligaonlinee://androidapp/token?hash=' +
          this.params.hash +
          '&email=' +
          this.params.email
				: 'ligaonline://inparans?hash=' +
          this.params.hash +
          '&email=' +
          this.params.email;

		// Simule a link click
		const link = document.createElement('a');
		link.href = url;
		document.body.appendChild(link);
		link.click();
	}

	getUrlParameters() {
		this.route.paramMap.forEach((param) => {
			this.params.hash = param.get('hash');
			this.params.email = param.get('email');
			this.params.key = param.get('key');
			this.params.user = param.get('user');
		});
	}
}
