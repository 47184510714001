<div class="margin-ranking">
    <div class="width-100">
        <p class="enunciado">{{pergunta.enunciado}}</p>
        <div class="ranking-row">
            <button class="ranking-button" *ngFor="let number of counter(pergunta.nota_maxima); let i = index; let first = first; let last = last" [ngClass]="first ? ['first', getClass(selectedNumber, number)]: last ? ['last', getClass(selectedNumber, number)]: getClass(selectedNumber, number)" (click)="handleClick(number)">
                <img class="ranking-check" *ngIf="selectedNumber === number" src="assets/icons/check-pesquisa-white.svg">
                <span *ngIf="!(selectedNumber === number)">{{number}}</span>
            </button>
        </div>
        <div class="space-between">
            <span class="ranking-label">{{ 'NPS_SURVEY.TOO_BAD' | translate }}</span>
            <span class="ranking-label alerta" *ngIf="naoRespondida">{{ 'NPS_SURVEY.ANWSER_QUESTION' | translate }}</span>
            <span class="ranking-label">{{ 'NPS_SURVEY.VERY_GOOD' | translate }}</span>
        </div>
    </div>
</div>

