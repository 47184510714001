import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
	imports: [CommonModule, MatSliderModule, MatTooltipModule, MatCheckboxModule],
	declarations: [],
	exports: [MatSliderModule, MatTooltipModule, MatCheckboxModule],
	providers: [],
	bootstrap: [],
})
export class MaterialModule {}
