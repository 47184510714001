import { areAllEquivalent } from '@angular/compiler/src/output/output_ast';
import { newArray } from '@angular/compiler/src/util';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PerguntaPesquisaNps } from 'src/app/models/pesquisa-nps.model';

@Component({
  selector: 'cls-pergunta-ranking',
  templateUrl: './pergunta-ranking.component.html',
  styleUrls: ['./pergunta-ranking.component.scss']
})
export class PerguntaRankingComponent implements OnInit {
@Input() pergunta: PerguntaPesquisaNps
@Input() naoRespondida: boolean
@Output() selectedNumberEvent = new EventEmitter<number> ();

selectedNumber: number = null;

  constructor() { }

  ngOnInit(): void {
  }

  counter(number) {
    return Array.from({length: number}, (_, i) => i + 1)
  }

  getClass(selectedNumber, number) {
    if (selectedNumber === number) return 'selected'
    return `bg-${number}`
  }
  
  handleClick(value) {
    this.selectedNumber = value;
    this.selectedNumberEvent.emit(value);
  }

}
