import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertTime'
})
export class ConvertTimePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let min = value % 60;
    let hour = Math.floor(value / 60);
    let result: any;

    if (hour == 0 && min == 1)
      result = min + " minuto";
    else if (hour == 0 && min > 1)
      result = min + " minutos";
    else if (hour == 1 && min == 0)
      result = "1 hora";
    else if (hour == 1 && min == 1)
      result = "1 hora e 1 minuto";
    else if(hour == 1 && min > 1)
      result = "1 hora e " + min + " minutos";
    else if(hour > 1 && min == 0)
      result = hour + " horas";
    else if(hour > 1 && min == 1)
      result = hour + " horas e 1 minuto";
    else if(hour > 1 && min > 1)
      result = hour + " horas e " + min + " minutos";

      return result;
  }

}
