<div
  class="box-event-container"
  [ngClass]="layout"
  [class.grow]="growBox"
  (mouseout)="changeSize($event)"
  (mouseover)="changeSize($event)"
>
  <div
    class="capa"
    [style.background-image]="global.generateImageURL(data?.capa_evento)"
  >
    <div class="capa__side">
      <div class="capa__side__data">
        <span class="capa__side__data__dia">{{ dateFormated.dia }}</span>
        <span class="capa__side__data__mes">{{ dateFormated.mes }}</span>
      </div>
      <ng-container *ngIf="layout == 'vertical'">
        <div
          class="capa__side__tag capa__side__tag__online"
          *ngIf="data.canal_tipo != 1"
        >
          {{ "EVENT.ONLINE" | translate }}
        </div>
        <div
          class="capa__side__tag capa__side__tag__person"
          *ngIf="data.canal_tipo == 1"
        >
          {{ "EVENT.IN_PERSON" | translate }}
        </div>
        <div class="capa__side__tag" *ngIf="data.agenda">
          {{ "EVENT.SUBSCRIBED" | translate }}
        </div>
        <div
          class="capa__side__tag capa__side__tag__atv"
          *ngIf="data.atividade_complementar_hora"
        >
          <span
            >{{ "EVENT.ACTIVITY" | translate }} |
            {{ data.atividade_complementar_hora }}h</span
          >
        </div>
      </ng-container>
    </div>
    <div class="overlay"></div>
  </div>
  <div class="description">
    <div class="content">
      <span *ngIf="data.nome.length <= 100" class="content__nome">{{
        data.nome
      }}</span>
      <span *ngIf="data.nome.length > 100" class="content__nome"
        >{{ data.nome | slice : 0 : 100 }}...</span
      >
      <div class="divisor-row">
        <div class="divisor"></div>
        <div class="divisor"></div>
        <div class="divisor"></div>
      </div>
      <span class="content__time">{{ eventoTime }}</span>
    </div>
    <div class="footer">
      <div class="tags">
        <div
          class="capa__side__tag capa__side__tag__online"
          *ngIf="data.canal_tipo != 1"
        >
          {{ "EVENT.ONLINE" | translate }}
        </div>
        <div
          class="capa__side__tag capa__side__tag__person"
          *ngIf="data.canal_tipo == 1"
        >
          {{ "EVENT.IN_PERSON" | translate }}
        </div>
        <div class="capa__side__tag" *ngIf="data.agenda">
          {{ "EVENT.SUBSCRIBED" | translate }}
        </div>
        <div
          class="capa__side__tag capa__side__tag__atv"
          *ngIf="data.atividade_complementar_hora"
        >
          <span
            >{{ "EVENT.ACTIVITY" | translate }} |
            {{ data.atividade_complementar_hora }}h</span
          >
        </div>
      </div>
      <div class="saiba">
        <button class="btn" (click)="openModalEvent()">
          {{ btnString | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<cls-modal-evento *ngIf="openModal" [data]="data" (close)="closeModalEvent()">
</cls-modal-evento>
