import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GabaritoModel } from 'src/app/models/gabarito.model';

@Component({
	selector: 'app-revisao-quiz-novo',
	templateUrl: './revisao-quiz-novo.component.html',
	styleUrls: ['./revisao-quiz-novo.component.scss'],
})
export class RevisaoQuizNovoComponent implements OnInit {
  @Input() idAvaliacaoEstudante: number;
  @Input() idAvaliacaoEstudanteTentativa: number;
  @Output() exit = new EventEmitter();
  resultado: GabaritoModel = null;
  loading: boolean;

  ngOnInit(): void {
  	this.loading = true;
  }

  emitExit() {
  	this.exit.emit(true);
  }

  receiveResultado($event) {
  	this.resultado = $event;
  	this.loading = false;
  }
}
