import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cls-acordion-revisao',
  templateUrl: './acordion-revisao.component.html',
  styleUrls: ['./acordion-revisao.component.scss']
})
export class AcordionRevisaoComponent implements OnInit {

  @Input() resposta;
  @Input() isEditable = false;
  @Input() index;

  isOpen = false;

  constructor() { }

  ngOnInit(): void {}

}
