<div
  class="home-box-component pointer"
  [class.not-allowed]="
    !data.aberta && state.estudante.id_projeto_turma_estudante_status !== 8
  "
  [class.selected]="state?.faseAtual?.id_fase_turma == data.id_fase_turma"
  (click)="selectBox()"
>
  <div class="header" [style.background-color]="color">
    <div
      class="d-flex flex-direction-row justify-content-between align-items-center text-uppercase"
    >
      {{ data.nome }}
      <span class="icon-container float-right cadeado-container">
        <img
          *ngIf="
            (!data.aberta &&
              state.estudante.id_projeto_turma_estudante_status !== 8) ||
            state?.estudanteStatus === 'trial'
          "
          src="../../../assets/icons/icone_cadeado_branco.svg"
          alt=""
          srcset=""
        />
        <i
          *ngIf="data.concluida && data.aberta"
          [style.-webkit-text-stroke]="'1px ' + color"
          class="fas fa-check"
        ></i>
      </span>
    </div>
  </div>
  <div class="legend">
    <span class="title">
      {{ "PROJECT.DELIVERY_PERIOD" | translate }}
    </span>
    <span *ngIf="startDate"
      >{{ "PROJECT.FROM" | translate }}: {{ startDate }}h</span
    >
    <span *ngIf="endDate">{{ "PROJECT.TO" | translate }}: {{ endDate }}h</span>
  </div>
  <div *ngIf="!data.feedbackEntrega" class="text-center loader">
    <div class="spinner-border spinner-border-md text-secondary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <!-- <i *ngIf="state?.faseAtual?.id_fase_turma == data.id_fase_turma" class="fas fa-chevron-down indicator"></i> -->
  <!-- <img
    *ngIf="state?.faseAtual?.id_fase_turma == data.id_fase_turma"
    src="assets/icons/fas-fa-chevron-down-indicator.svg"
    alt=""
    class="indicator"
  /> -->
</div>
