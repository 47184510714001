import {
	AfterViewInit,
	Component,
	ElementRef,
	Input,
	ViewChild,
} from '@angular/core';

@Component({
	selector: 'cls-carousel',
	templateUrl: './carousel.component.html',
	styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements AfterViewInit {
  @Input() size = 'default';
  @ViewChild('carousel') carousel: ElementRef;
  scrolls = 0;
  scrooling = false;
  countScrolls = 0;
  maxScrollLeft = 0;
  showRightArrow = false;

  ngAfterViewInit(): void {
  	setTimeout(() => {
  		this.checkForScroll();
  	}, 500);
  }

  checkForScroll() {
  	if (
  		this.carousel.nativeElement.scrollWidth -
        this.carousel.nativeElement.clientWidth >
      0
  	)
  		this.showRightArrow = true;
  }

  scrollProjetosSmoothTemp(direction) {
  	const TIME_SCROLL = this.size == 'small' ? 5 : 10;
  	const SCROLL_DISTANCE =
      this.size == 'small'
      	? this.carousel.nativeElement.clientWidth - TIME_SCROLL
      	: 700;
  	this.scrooling = true;
  	setTimeout(() => {
  		this.scrooling = false;
  	}, SCROLL_DISTANCE);
  	const element = this.carousel.nativeElement;
  	const maxScrolledLeft = element.scrollWidth - element.clientWidth - 2;
  	const distance = direction === 'right' ? TIME_SCROLL : -TIME_SCROLL;

  	const widthRight =
      direction === 'right' &&
      element.scrollLeft + SCROLL_DISTANCE < maxScrolledLeft
      	? SCROLL_DISTANCE
      	: maxScrolledLeft - element.scrollLeft;

  	const widthLeft =
      direction === 'left' && element.scrollLeft - SCROLL_DISTANCE > 0
      	? SCROLL_DISTANCE
      	: element.scrollLeft;

  	const targetScroll =
      direction === 'right'
      	? element.scrollLeft + widthRight
      	: element.scrollLeft - widthLeft;

  	const animateScroll = () => {
  		element.scrollLeft += distance;
  		if (
  			(direction === 'right' && element.scrollLeft >= targetScroll) ||
        (direction === 'left' && element.scrollLeft <= targetScroll)
  		) {
  			this.maxScrollLeft = element.scrollWidth - element.clientWidth - 2;
  			if (direction === 'right') {
  				this.countScrolls++;
  			} else if (direction === 'left' && this.countScrolls > 0) {
  				this.countScrolls--;
  			}
  			this.showRightArrow = element.scrollLeft < this.maxScrollLeft;

  			return;
  		}

  		requestAnimationFrame(animateScroll);
  	};

  	animateScroll();
  }
}
