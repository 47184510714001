import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { AppState } from 'src/app/state';
import { fillModalVideo } from 'src/app/state/state.actions';

@Component({
	selector: 'cls-soft-skill-box',
	templateUrl: './soft-skill-box.component.html',
	styleUrls: ['./soft-skill-box.component.scss'],
})
export class SoftSkillBoxComponent {
  @Input() data;
  growBox = false;

  constructor(public global: GlobalService, private store: Store<AppState>) {}

  openVideoModal() {
  	this.store.dispatch(fillModalVideo({ payload: this.data.video }));
  }

  changeSize(event) {
  	if (event.type == 'mouseover') this.growBox = true;
  	else this.growBox = false;
  }
}
