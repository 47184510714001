<div class="main-view-container">
  <div class="panel">
    <ng-content></ng-content>
  </div>
  <div class="img" [style.background-image]="settedBackground">
    <div class="shadow-left"></div>
    <div class="carousel-container" *ngIf="background === 'carousel'">
      <cls-carousel-login></cls-carousel-login>
    </div>
    <img class="img-container" *ngIf="image" [src]="image">
  </div>
</div>
