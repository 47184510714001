import {
	notificacoes,
	showMenu,
	showNotificacao,
} from '../../../../../../src/app/state/state.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../src/app/state';
import { NotificacaoService } from '../../../../../../src/app/services/notificacao/notificacao.service';
import { NotificacaoMensagemTratadaModel } from '../../../../../../src/app/models/notificacao-mensagem.model';
import { mergeMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Directive, OnInit } from '@angular/core';
import { Usuario } from '../../../../../../src/app/models';

@Directive()
export abstract class HeaderBase implements OnInit {
	usuario: Usuario;
	subscriptions: Subscription[] = [];
	notificacoes: NotificacaoMensagemTratadaModel;
	showNotificacao: boolean;

	protected constructor(
    protected store: Store<AppState>,
    protected notificacaoService: NotificacaoService
	) {}

	ngOnInit(): void {
		this.getState();
		this.getNotificacoes();
	}

  // getState é obrigatorio ser implementado para receber notificacoes, usuario e showNotificacao
  abstract getState();

  toggleMenu() {
  	this.store.dispatch(showMenu({ payload: true }));
  	document.querySelector('body').setAttribute('class', 'no-scrollbar');
  }

  abrirNotificacao() {
  	setTimeout(() => {
  		this.store.dispatch(showNotificacao({ payload: true }));
  	});
  }

  closeSidebar() {
  	if (this.showNotificacao) {
  		this.store.dispatch(showNotificacao({ payload: false }));
  	}
  }

  getNotificacoes() {
  	if (!this.notificacoes) {
  		const subscription = this.notificacaoService
  			.getNotificacao(this.usuario.id_usuario)
  			.subscribe((data: NotificacaoMensagemTratadaModel) => {
  				this.store.dispatch(notificacoes({ payload: data }));
  			});
  		this.subscriptions.push(subscription);
  	}
  }

  marcarNotificacaoComoLida() {
  	if (this.notificacoes) {
  		if (this.notificacoes.mensagensNaoLidas.length > 0) {
  			const subscription = this.notificacaoService
  				.marcarNotificacaoComoLida(
  					this.usuario.id_usuario,
  					this.notificacoes.mensagensNaoLidas
  				)
  				.pipe(
  					mergeMap((data) =>
  						this.notificacaoService.getNotificacao(this.usuario.id_usuario)
  					)
  				)
  				.subscribe((data: NotificacaoMensagemTratadaModel) => {
  					this.store.dispatch(notificacoes({ payload: data }));
  				});
  			this.subscriptions.push(subscription);
  		}
  	}
  }
}
