import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/state';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  state: any;

  constructor(private router: Router, private store: Store<AppState>) {}

  getState() {
    this.store.select((state: any) => {
      this.state = state.newAppState;
    }).subscribe();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    this.getState();  
    if(this.state.usuario)
      return true;
    else
      this.router.navigate(['login']);
  }
}