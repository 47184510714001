import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { DatatableService } from "src/app/services/datatable-service/datatable.service";
import { GlobalService } from "src/app/services/global-service/global.service";
import { AppState } from "src/app/state";

@Component({
  selector: "cls-datatable",
  templateUrl: "./datatable.component.html",
  styleUrls: ["./datatable.component.scss"],
})
export class DatatableComponent implements OnInit, OnDestroy {
  @Input() data;
  @Input() method;
  @Input() label;
  @Output() editUser = new EventEmitter();
  @Output() selectUser = new EventEmitter();

  headers = [];
  dataArray = [];
  selection = [];
  filters = [];
  actions: any;
  pagination: any;
  before = [];
  stateSubscription$: any;
  state: any;

  constructor(
    private dataTableService: DatatableService,
    private global: GlobalService,
    private translate: TranslateService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    if (this.method) {
      if (this.method == "store.mensagensSecretaria") {
        this.getState();
        this.setComponentVariables();
      } else this.filter();
    } else this.setComponentVariables();
  }

  ngOnDestroy(): void {
    this.stateSubscription$.unsubscribe();
  }

  setComponentVariables() {
    this.headers = this.data.datalist.header;
    this.dataArray = this.data.datalist.data;
    this.actions = this.data.datalist.actions;
    this.filters = this.data.filters;
    this.pagination = this.data.pagination;
    this.dealWithDecimals();
  }

  getState() {
    this.stateSubscription$ = this.store
      .select((state: any) => {
        const { mensagensSecretaria } = state.newAppState;
        this.state = { mensagensSecretaria };
        this.data.datalist.data = this.state.mensagensSecretaria;
        this.dataArray = this.state.mensagensSecretaria;
      })
      .subscribe();
  }

  dealWithDecimals() {
    this.dataArray.forEach((item) => {
      this.headers.forEach((head) => {
        if (
          typeof item[head.field] == "number" &&
          item[head.field].toString().indexOf(".") != -1
        )
          item[head.field] = item[head.field].toFixed(2);
      });
    });
  }

  mark(item = null) {
    if (item) {
      if (!this.isChecked(item)) {
        this.selection.push(item);
        this.selectUser.emit({ item: item });
      } else this.deleteFromSelection(item);
    } else {
      if (this.selection.length == this.dataArray.length) this.selection = [];
      else {
        this.selection = [];
        this.dataArray.forEach((element) => this.selection.push(element));
      }
    }
  }

  deleteFromSelection(item) {
    this.selection.forEach((sel, index) => {
      if (sel == item) this.selection.splice(index, 1);
    });
  }

  isChecked(item) {
    let ret = false;
    this.selection.forEach((sel) => {
      if (sel == item) ret = true;
    });
    return ret;
  }

  emitAction(item) {
    this.editUser.emit({ item: item });
  }

  filter() {
    this.filters.forEach((filter) => {
      if (filter.type == "int" && filter.value)
        filter.value = parseInt(filter.value);
    });
    let filterObj = { filters: this.filters };
    this.dataTableService[this.method](filterObj).then(
      (data: any) => {
        this.data = data;
        this.setComponentVariables();
        this.before = [];
      },
      (error) => {
        console.log(error);
        this.global.toast(
          this.translate.instant("GENERAL.ERROR"),
          error.error.message
        );
      }
    );
  }

  isDefaultOrText(type, value) {
    if (type != "datetime" && type != "actions" && type != "checkbox")
      return true;
    else return false;
  }

  paginate(forward, page = null) {
    let next: any;

    if (forward) {
      if (this.pagination.page < this.pagination.pages) {
        next = this.pagination.page + 1;
        if (this.before.length <= 1) this.before.push(this.pagination.page);
      } else return;
    } else if (this.pagination.page > 1 && !page) {
      if (this.pagination.page <= 3) this.before.pop();
      next = this.pagination.page - 1;
    } else if (page) {
      this.before.forEach((bef, index) => {
        if (bef >= page) this.before.splice(index, 1);
      });
      next = page;
    }

    let filterObj = {
      filters: this.filters,
      pagination: {
        page: next,
      },
    };
    this.dataTableService[this.method](filterObj).then(
      (data: any) => {
        this.data = data;
        this.setComponentVariables();
      },
      (error) => {
        this.global.toast(
          this.translate.instant("GENERAL.ERROR"),
          error.error.message
        );
      }
    );
  }

  getSrcByActionType(type) {
    //console.log(type);
  }

  changeSelect(value, field) {
    this.filters.forEach((filter) => {
      if (filter.type == "select" && field == filter.field) {
        if (value == "null") filter.value = null;
        else filter.value = value;
      }
    });
  }
}
