import { AfterContentInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import {
	clearState,
	fillCentralAjuda,
	showAgenda,
	showMenu,
	showModalConfiguracoes,
} from 'src/app/state/state.actions';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data-service/data.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
	animations: [
		trigger('Menu', [
			state(
				'open',
				style({
					right: '0px',
				})
			),
			state(
				'closed',
				style({
					right: '-500px',
				})
			),
			transition('open => closed', [animate('.5s 0ms ease-in-out')]),
			transition('closed => open', [animate('.5s 0ms ease-in-out')]),
		]),
	],
})
export class MenuComponent implements OnInit, AfterContentInit, OnDestroy {
	state: { showingMenu: boolean };
	showMenu: boolean;
	fullState: any;
	portalURL: string;
	uploadPhoto: FormData;
	photoName: string;
	changePass = { oldpassword: '', newpassword: '', againpassword: '' };
	transition = 'closed';
	stateSubscription$: any;
	linkCentralAjuda = '';

	constructor(
    private store: Store<AppState>,
    private router: Router,
    private dataService: DataService,
    private translate: TranslateService
	) {}

	ngOnDestroy(): void {
		this.stateSubscription$.unsubscribe();
	}

	goToCentralAjuda() {
		window.open(this.fullState?.centralAjuda);
	}

	ngOnInit() {
		this.getState();
	}

	getLinkAjuda() {
		this.dataService
			.getDocument('url_central_ajuda')
			.toPromise()
			.then((data: any) => {
				this.linkCentralAjuda = data.valor_texto;
				this.store.dispatch(fillCentralAjuda({ payload: data.valor_texto }));
			});
	}

	getState() {
		this.stateSubscription$ = this.store
			.select((state: any) => {
				this.fullState = state.newAppState;
				const { showingMenu, centralAjuda, showingAgenda } = state.newAppState;
				if (!centralAjuda && !!Object.keys(this.fullState).length)
					this.getLinkAjuda();
				setTimeout(() => {
					this.state = { showingMenu };
					this.showMenu = showingMenu;
				}, 100);
			})
			.subscribe();
	}

	goToSolicitacoes() {
		this.store.dispatch(showMenu({ payload: false }));
		this.router.navigate(['/solicitacoes']);
		document.querySelector('body').removeAttribute('class');
	}

	goToPortal() {
		if (this.fullState?.projetoAtivo?.empresa?.login_sia)
			window.open(this.fullState.projetoAtivo.empresa.login_sia);
	}

	closeMenu() {
		this.transition = 'closed';
		setTimeout(() => {
			this.store.dispatch(showMenu({ payload: false }));
			document.querySelector('body').removeAttribute('class');
		}, 500);
	}

	handleClickOutside() {
		if (this.showMenu) this.closeMenu();
	}

	ngAfterContentInit() {
		setTimeout(() => {
			if (this.fullState.showingMenu) this.transition = 'open';
		}, 300);
	}

	showModalConfiguracoes() {
		this.store.dispatch(showModalConfiguracoes({ payload: true }));
	}

	logout() {
		sessionStorage.clear();
		this.router.navigate(['login']);
		this.store.dispatch(clearState());
		this.translate.setDefaultLang(environment.defaultLanguage);
		this.translate.currentLang = environment.defaultLanguage;
	}

	goToAgenda() {
		this.store.dispatch(showAgenda({ payload: 'open' }));
		document.querySelector('body').setAttribute('class', 'no-scrollbar');
	}
}
