<div class="header" [style.background-image]="backgroundImg">
  <div class="container-buttons">
    <div class="buttons">
      <button
        id="notificacao-button"
        class="notificacao-button"
        (click)="abrirNotificacao()"
        data-cy="HeaderHomeDivNotificacao"
      >
        <img
          src="assets/icons/icone_notificacoes_branco.svg"
          class="notificacao-img"
        />
      </button>
      <label
        class="notificacao-numero"
        for="notificacao-button"
        *ngIf="notificacoes?.mensagensNaoLidas.length > 0"
      >
        {{ notificacoes?.mensagensNaoLidas.length }}
      </label>
    </div>
    <img
      class="buttons hamburguer"
      (click)="toggleMenu()"
      src="assets/icons/menu.svg"
      data-cy="HeaderHomeImgMenu"
    />
  </div>
  <div
    [class]="
      voltarParaPerfil ? 'container-img-perfil pointer' : 'container-img-perfil'
    "
    [class.smaller]="isCurriculo"
    (click)="irParaPerfil()"
  >
    <div
      class="profile-image"
      [class.smaller]="isCurriculo"
      *ngIf="usuario?.foto"
      [style.background-image]="global.generateImageURL(usuario?.foto)"
    ></div>
    <div
      class="profile-image trial"
      *ngIf="!usuario?.foto && estudanteStatus === 'trial' && !isCurriculo"
      (click)="insertData()"
      [style.background-image]="
        global.generateImageURL('assets/imgs/profile-img.svg')
      "
    ></div>
    <div
      class="profile-image curriculo smaller"
      *ngIf="!usuario?.foto && estudanteStatus === 'trial' && isCurriculo"
      (click)="callInput(); $event.stopPropagation()"
    >
      <img
        src="assets/imgs/camera-curriculo.png"
        data-cy="HeaderHomeImgCameraCurriculo"
      />
      INSIRA SUA FOTO
    </div>
    <div
      class="profile-image"
      *ngIf="!usuario?.foto && estudanteStatus !== 'trial'"
      [style.background-image]="
        global.generateImageURL('assets/imgs/profile-img.svg')
      "
    ></div>
    <img
      class="profile-camera"
      (click)="callInput(); $event.stopPropagation()"
      *ngIf="isCurriculo && usuario?.foto"
      src="assets/icons/icone-camera.svg"
    />
  </div>
  <input
    type="file"
    class="d-none upload-picture"
    (change)="prepareUpload($event)"
  />
  <div
    class="medalha"
    *ngIf="quantidadeDeCompetencias && showQuantidadeCompetencia"
  >
    <!-- <img src="assets/old/medalha-grande.png" /> -->
    <img src="assets/imgs/medalha-grande.svg" />
    <!-- <div class="div-old">
      <span>{{ quantidadeDeCompetencias }}</span>
    </div> -->
    <div class="div-new">
      <span>{{ quantidadeDeCompetencias }}</span>
    </div>
  </div>
</div>

<!--NOTIFICAÇÕES-->
<ui-sidebar-content
  titulo="{{ 'NOTIFICATIONS.TITLE' | translate }}"
  [showSidebar]="showNotificacao"
  [notificacoesNaoLidas]="notificacoesNaoLidas"
  (marcarTodasNotificacoes)="marcarNotificacaoComoLida()"
  (closeSidebar)="closeSidebar()"
>
  <ui-card-data
    *ngFor="
      let mensagens of notificacoes?.mensagens | homeSortArrayDateNullFirst
    "
    [data]="mensagens[0].notificacao_usuario_data_envio"
  >
    <ui-mensagem-list
      *ngFor="
        let mensagem of mensagens | homeSortDateNullFirst : 'data_leitura';
        let i = index
      "
      [mensagem]="mensagem"
      [ultimaMensagem]="mensagens.length - 1 === i"
      [mensagemLida]="!!mensagem.data_leitura"
      (marcarLido)="marcarComoLido($event)"
    ></ui-mensagem-list>
  </ui-card-data>
  <p class="empty" *ngIf="notificacoes?.mensagens.length === 0">
    {{ "NOTIFICATIONS.NONE" | translate }}
  </p>
</ui-sidebar-content>
<!--NOTIFICAÇÕES-->
