<div class="chat-container">
  <div class="forum">
    <div class="label" *ngIf="label">{{ label.toLowerCase() }}</div>
    <div class="sub-title" *ngIf="subtitle">{{ subtitle }}</div>
    <div
      class="description"
      *ngIf="description"
      [innerHtml]="description"
    ></div>
    <div class="divisor"></div>
    <div
      class="more"
      *ngIf="messages?.length >= 30 && !hideMoreMessages"
      (click)="tryGetMoreMessages()"
    >
      <img src="assets/icons/plus-circle.svg" />
      <span class="capitalize">{{ "CHAT.MORE_MESSAGES" | translate }}</span>
    </div>
    <div
      class="msg"
      [class.aluno]="msg?.usuario?.tipo == 'aluno'"
      [class.tutor]="msg?.usuario?.tipo == 'tutor'"
      *ngFor="let msg of messages | orderBy : 'id_forum_mensagem'"
    >
      <div class="prof upper" *ngIf="msg.usuario.tipo == 'tutor'">
        {{ "CHAT.TEACHER" | translate }}
      </div>
      <img *ngIf="!msg?.usuario?.foto" src="assets/imgs/profile-img.svg" />
      <img *ngIf="msg?.usuario?.foto" [src]="msg.usuario.foto" />
      <div class="name">{{ msg?.usuario?.nome_social }}</div>
      <div class="text" [innerHTML]="msg?.mensagem"></div>
      <div class="link-block" *ngIf="verifyExt(msg)">
        <div class="message-file">
          <img
            class="img-preview pointer"
            [src]="msg.media_url"
            (click)="viewImage(msg)"
          />
          <img
            (click)="downloadFile(msg)"
            class="img-icon pointer"
            src="assets/icons/download.svg"
          />
        </div>
      </div>
      <div class="link-block" *ngIf="!verifyExt(msg) && msg?.media_name">
        <div class="message-file">
          <div class="row align-items-center ml-0">
            <img
              class="img-icon pointer"
              src="assets/icons/file-light.svg"
              (click)="viewFile(msg)"
            />
            <span *ngIf="msg?.media_name?.length > 50"
              >{{ msg?.media_name | slice : 0 : 50 }}...</span
            >
            <span *ngIf="msg?.media_name?.length <= 50">{{
              msg?.media_name
            }}</span>
          </div>
          <img
            (click)="downloadFile(msg)"
            class="img-icon pointer"
            src="assets/icons/download.svg"
          />
        </div>
      </div>
      <div class="date">{{ msg?.quando | date : "dd/MM/yyyy - HH:mm" }}</div>
    </div>
    <div
      class="cover-answer"
      *ngIf="state?.delayAnswerOpen && status_aluno"
    ></div>
    <div class="answer" *ngIf="state?.delayAnswerOpen && status_aluno">
      <textarea
        placeholder="{{ 'CHAT.TYPE_MSG' | translate }}"
        class="upper"
        [(ngModel)]="newMessage"
      ></textarea>
      <div class="attachments">
        <div class="pointer" (click)="attachFileToMsg()">
          <input
            type="file"
            #attachFileMsg
            class="d-none file-msg"
            (change)="sendFileToDOM($event)"
          />
          <img src="assets/icons/paperclip.svg" />
          <span *ngIf="!fileName" class="upper">{{
            "CHAT.ATTACH" | translate
          }}</span>
          <span *ngIf="fileName && fileName.length >= 50"
            >{{ fileName | slice : 0 : 50 }}...</span
          >
          <span *ngIf="fileName && fileName.length < 50">{{ fileName }}</span>
        </div>
        <!-- <img src="assets/icons/mic.svg">
                        <span>ÁUDIO</span>-->
        <button
          class="btn btn-send"
          (click)="sendMessage(); $event.stopPropagation()"
        >
          <span *ngIf="!state?.interacoesLoading" class="upper">{{
            "CHAT.SEND" | translate
          }}</span>
          <div *ngIf="state?.interacoesLoading" class="text-center">
            <div class="spinner-border spinner-border-md" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
