<div class="resposta-avaliacao-quiz-container">
  <div class="main">
    <div class="main-card">
      <div class="back-fase" *ngIf="hasBack" (click)="emitExit()">
        <i class="fas fa-chevron-left"></i>
        <div class="capitalize">{{ "AVALIACAO.BACK" | translate }}</div>
      </div>
      <div class="progresso">{{ index }}/{{ total }}</div>
      <div class="bars">
        <div class="bar" [class.marked]="bar" *ngFor="let bar of bars"></div>
      </div>
      <div class="timer" *ngIf="timer.minutes > 0 || timer.seconds > 0">
        <i class="far fa-clock"></i>
        {{ timer.minutes | number : "2.0-0" }}:{{
          timer.seconds | number : "2.0-0"
        }}
      </div>
      <div class="title">Pergunta {{ index }}</div>
      <div class="label" *ngIf="questao?.id_quiz_entrega_turma_tipo === 2">
        {{ "QUESTIONARIO_AVALIACAO.MULTI_SELECT" | translate }}
      </div>
      <div class="label" *ngIf="questao?.id_quiz_entrega_turma_tipo === 3">
        (Arraste os cards e coloque na ordem correta)
      </div>
      <div
        class="question"
        [innerHtml]="questao?.pergunta | sanitizeHtml"
      ></div>
      <div
        *ngIf="
          questao?.id_quiz_entrega_turma_tipo === 1 ||
          questao?.id_quiz_entrega_turma_tipo === 2
        "
      >
        <div
          class="answer"
          *ngFor="let option of questao?.respostas"
          (click)="markAnswer(option)"
        >
          <div class="check" [class.checked]="option.marcada">
            <div class="blue-check"></div>
          </div>
          <div class="divider"></div>
          {{ option.alternativa.texto }}
        </div>
      </div>
      <div *ngIf="questao?.id_quiz_entrega_turma_tipo === 3">
        <div
          cdkDropList
          [cdkDropListData]="questao?.respostas"
          (cdkDropListDropped)="
            dropAlternativaRanking($event, questao?.respostas)
          "
        >
          <div
            *ngFor="
              let option of questao?.respostas | orderBy : 'ordenacao';
              let i = index
            "
            class="flex-row"
            cdkDrag
            id="dragq-{{ i }}"
          >
            <div class="letter-ranking" id="letter-{{ i }}">
              <span>{{ option.letra_alternativa }}</span>
            </div>
            <div class="answer-ranking" id="answer-{{ i }}">
              <span>{{ option.alternativaRanking.texto }}</span>
              <div class="drag-ranking" id="drag-ranking-{{ i }}">
                <div class="divider"></div>
                <img
                  class="drag-img"
                  cdkDragHanlde
                  src="assets/icons/bars-light.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btns">
        <button class="btn" (click)="emitBefore()" *ngIf="index > 1">
          {{ "QUESTIONARIO_AVALIACAO.QUESTION_PREVIOUS" | translate }}
        </button>
        <button class="btn" *ngIf="index !== total" (click)="openModal()">
          {{ "QUESTIONARIO_AVALIACAO.QUESTION_NEXT" | translate }}
        </button>
        <button class="btn" *ngIf="index === total" (click)="openModal()">
          {{ "QUESTIONARIO_AVALIACAO.FINISH" | translate }}
        </button>
      </div>

      <div class="overlay" *ngIf="isOpenModal" (click)="closeModal()">
        <div class="confirm-modal">
          <p class="confirm-modal__text">
            {{ "QUESTIONARIO_AVALIACAO.MODAL_QUESTION_TITLE" | translate }}
          </p>
          <p class="confirm-modal__text">
            {{ "QUESTIONARIO_AVALIACAO.MODAL_QUESTION_QUIZ" | translate }}
          </p>
          <div class="confirm-modal__divisor"></div>
          <div class="confirm-modal__btns">
            <button class="btn upper" (click)="closeModal()">
              {{ "BUTTON.NO" | translate }}
            </button>
            <button class="btn upper" (click)="confirmModal()">
              {{ "BUTTON.YES" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="detail-1"></div>
    <div class="detail-2"></div>
  </div>
</div>
