<div class="agenda-container" [@Agenda]="transition">
  <div class="top-label">
    <span class="capitalize center">{{ "HEADER.CALENDAR" | translate }} </span>
    <div class="close-pattern" (click)="closeAgenda()">
      <!-- <i class="fas fa-times pointer"></i> -->
      <img src="assets/icons/fas-fa-times-pointer.svg" />
    </div>
  </div>
  <cls-calendar
    class="calendario"
    (changedMonth)="setMonthData($event)"
    (clickedDay)="clickedDay($event)"
    [rangesArray]="rangesArray"
    [scheduled]="encontrosCalendar"
  >
  </cls-calendar>
  <div class="list-container">
    <div class="day-header">
      <span>Dia {{ currentDay }}</span>
    </div>
    <div class="activities-container">
      <span *ngIf="currentActivities.length == 0">{{
        noActivity | translate
      }}</span>
      <div
        class="activity-container"
        *ngFor="let activity of currentActivities; let i = index"
        (click)="clickActivity(activity)"
      >
        <div class="activity">
          <div class="activity-time">
            <span>{{ activity.hora }}h</span>
          </div>
          <div class="activity-text">
            <span class="activity-name">{{ activity.nome }}</span>
            <span class="activity-description">{{ activity.descricao }}</span>
          </div>
        </div>
        <div
          *ngIf="i < currentActivities.length - 1"
          class="activity-divider"
        ></div>
        <div
          *ngIf="i == currentActivities.length - 1"
          class="activity-footer"
        ></div>
      </div>
    </div>
  </div>
</div>
<cls-modal-evento
  *ngIf="openModal"
  [data]="dataEvent"
  (close)="closeModalEvent()"
>
</cls-modal-evento>
