<div class="details-component-container">
  <div class="details-header" [style.background-image]="backgroundImg">
    <button class="back-to" (click)="backToHome()">
      <img src="assets/icons/chevron-right-white.svg" alt="Voltar" />
    </button>
    <div class="title">
      <h1>{{ "PERFIL." + objectType.title | translate }}</h1>
    </div>
    <div class="size-adjust"></div>
    <div class="overlay"></div>
  </div>
  <div class="details-body">
    <div class="filter">
      <app-carrossel-filters
        [objectData]="objectType"
        (clearFilters)="applyDefaultObjects()"
        (applyFilters)="applyFilters($event)"
      ></app-carrossel-filters>
    </div>
    <div class="cards" [ngSwitch]="objectType.type">
      <!-- CARROSSEL DE PROJETOS -->
      <div *ngSwitchCase="1" class="container-wrap">
        <cls-projeto-card
          [data]="projeto"
          [polos]="state?.polos"
          *ngFor="let projeto of projetosBox; let i = index"
          [class.first]="i == 0"
          [class.last]="i == projetosBox.length - 1"
        ></cls-projeto-card>
      </div>

      <!-- CARROSSEL DE EVENTOS -->
      <div *ngSwitchCase="2" class="container-wrap">
        <cls-eventos-box
          [data]="evento"
          [layout]="'horizontal'"
          *ngFor="
            let evento of eventosBox | orderBy : 'data_inicio';
            let i = index
          "
          [class.first]="i == 0"
          [class.last]="i == eventosBox.length - 1"
        ></cls-eventos-box>
      </div>

      <!-- CARROSSEL DE CONTEÚDOS -->
      <div *ngSwitchCase="3" class="container-wrap">
        <cls-contents-box
          [data]="content"
          *ngFor="let content of contentsBox | orderBy : 'ordem'; let i = index"
          [class.first]="i == 0"
          [class.last]="i == contentsBox?.length - 1"
        ></cls-contents-box>
      </div>
    </div>
    <div class="sidebar-content">
      <app-side-menu></app-side-menu>
    </div>
  </div>
</div>
