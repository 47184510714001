import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'homeSortDateNullFirst',
})
export class HomeSortPipeNullFirst implements PipeTransform {
	transform(array: any[], field: string): any[] {
		if (!Array.isArray(array)) {
			return array;
		}

		return array.slice().sort((a: any, b: any) => {
			if (a[field] === null && b[field] === null) {
				return 0;
			} else if (a[field] === null) {
				return -1;
			} else if (b[field] === null) {
				return 1;
			} else {
				return a[field].localeCompare(b[field]);
			}
		});
	}
}
