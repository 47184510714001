<div class="main">
    <div class="main-card">
        <div class="back-fase capitalize" *ngIf="hasBack" (click)="emitExit()">
            <i class="fas fa-chevron-left"></i>
            <div class="capitalize">{{'AVALIACAO.BACK' | translate}}</div>
        </div>
        <img *ngIf="!imageURL" src="assets/imgs/popup2.jpg">
        <img *ngIf="imageURL" [src]="imageURL">
        <ng-content></ng-content>
    </div>
    <div class="detail-1"></div>
    <div class="detail-2"></div>
</div>
