import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DataUtil } from '../../../../../../src/app/util/dataUtil';

@Component({
  selector: 'ui-card-data',
  templateUrl: './card-data.component.html',
  styleUrls: ['./card-data.component.css']
})
export class CardDataComponent implements OnInit {

  @Input() data: string;
  dataAjustada: string;
  dataUtil = new DataUtil();

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.AjustarData();
  }

  AjustarData() {
    const data = moment(this.data).format('YYYY-MM-DD');
    const hoje = moment().format('YYYY-MM-DD');
    if (this.translate.currentLang === 'pt-br') {
      if (data === hoje) {
        this.dataAjustada = 'Hoje';
        return;
      }
      const ontem = moment().add(-1, 'day').format('YYYY-MM-DD');
      if (data === ontem) {
        this.dataAjustada = 'Ontem';
        return;
      }
      const dia = moment(this.data).format('DD');
      const mesAbreviado = this.dataUtil.pegarMesEmPt(moment(this.data).month()).substr(0, 3);
      this.dataAjustada = `${dia} ${mesAbreviado}`;
    } else if(this.translate.currentLang === 'en-us'){
      if (data === hoje) {
        this.dataAjustada = 'Today';
        return;
      }
      const ontem = moment().add(-1, 'day').format('YYYY-MM-DD');
      if (data === ontem) {
        this.dataAjustada = 'Yesterday';
        return;
      }
      const dia = moment(this.data).format('DD');
      const mesAbreviado = this.dataUtil.pegarMesEmEn(moment(this.data).month()).substr(0, 3);
      this.dataAjustada = `${dia} ${mesAbreviado}`;
    }
  }
}
