import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../services/data-service/data.service';
import { GabaritoModel } from '../../models/gabarito.model';

@Component({
  selector: 'app-arquivo-avaliacao-aluno',
  templateUrl: './arquivo-avaliacao-aluno.component.html',
  styleUrls: ['./arquivo-avaliacao-aluno.component.scss']
})
export class ArquivoAvaliacaoAlunoComponent implements OnInit {

  @Input() avaliacaoEstudante: number;
  @Input() avaliacaoEstudanteObj: any;
  @Input() idAvaliacaoEstudanteTentativa: number;
  @Input() avaliacaoFinalizada: boolean;
  @Input() showNota: boolean;
  tentativaRespondida: GabaritoModel;
  isLoading = true;
  stateSubscription: any;
  loading = false;

  constructor(
    protected dataService: DataService,
  ) { }

  ngOnInit() {
    if (!!this.avaliacaoEstudanteObj.data_finalizacao) {
      this.dataService.getTentativaRespondida(this.avaliacaoEstudante, this.idAvaliacaoEstudanteTentativa).subscribe(tentativaRespondida => {
        this.isLoading = false;
        this.tentativaRespondida = tentativaRespondida;
      });
    }
  }
}
