<div class="overlay" (click)="close(); $event.stopPropagation()">
  <div class="box" (click)="$event.stopPropagation()">
    <div class="close-pattern" (click)="close(); $event.stopPropagation()">
      <!-- <i class="fas fa-times pointer"></i> -->
      <img src="assets/icons/fas-fa-times-pointer.svg" />
    </div>
    <img [src]="randomImg" />
    <div class="title">{{ data.title }}</div>
    <div class="body">{{ data.body }}</div>
  </div>
</div>
