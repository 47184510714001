<div class="carousel-login-container">
    <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide>
            <img src="assets/imgs/trial1.png">
            <div class="title capitalize">{{ 'LOGIN_CAROUSEL.WELCOME' | translate}}</div>
            <div class="divisor"></div>
            <div class="sub-title capitalize">{{ 'LOGIN_CAROUSEL.WELCOME_TEXT' | translate}}
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <img src="assets/imgs/trial2.png">
            <div class="title capitalize">{{ 'LOGIN_CAROUSEL.PROJECTS' | translate}}</div>
            <div class="divisor"></div>
            <div class="sub-title capitalize">{{ 'LOGIN_CAROUSEL.PROJECTS_TEXT' | translate}}
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <img src="assets/imgs/trial3.png">
            <div class="title capitalize">{{ 'LOGIN_CAROUSEL.SKILLS' | translate}}</div>
            <div class="divisor"></div>
            <div class="sub-title capitalize">{{ 'LOGIN_CAROUSEL.SKILLS_TEXT' | translate}}
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <img src="assets/imgs/trial4.png">
            <div class="title capitalize">{{ 'LOGIN_CAROUSEL.RESUME' | translate}}</div>
            <div class="divisor"></div>
            <div class="sub-title capitalize">{{ 'LOGIN_CAROUSEL.RESUME_TEXT' | translate}}
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <img src="assets/imgs/trial5.png">
            <div class="title capitalize">{{ 'LOGIN_CAROUSEL.EXCLUSIVE_CONTENT' | translate}}</div>
            <div class="divisor"></div>
            <div class="sub-title capitalize">{{ 'LOGIN_CAROUSEL.EXCLUSIVE_CONTENT_TEXT' | translate}}
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <img src="assets/imgs/trial6.png">
            <div class="title capitalize">{{ 'LOGIN_CAROUSEL.UNIQUE_LEARNING' | translate}}</div>
            <div class="divisor"></div>
            <div class="sub-title capitalize">{{ 'LOGIN_CAROUSEL.UNIQUE_LEARNING_TEXT' | translate}}
            </div>
        </ng-template>
    </owl-carousel-o>
</div>