import { Component, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { DataService } from 'src/app/services/data-service/data.service';
import {
	fillConfirmModal,
	fillDadosEmpresa,
	fillDadosPolo,
	fillModalVideo,
	fillPolos,
	loginLoading,
	toggleLoginAlert,
} from 'src/app/state/state.actions';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { Router } from '@angular/router';
import { Credentials } from 'src/app/models/credentials.model';
import { Trial } from 'src/app/models/trial.model';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Auth } from 'src/app/models';

declare global {
  interface Window {
    dotq: any;
  }
}

@Component({
	selector: 'cls-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
	state: any;
	stateSubscription: any;
	credentials: Credentials;
	_background = 'assets/imgs/foto-login-liga.png';
	// viewMode = "initial";
	viewMode = 'login';
	alert = null;
	textareaPlaceholder = '';
	selectedCupom = null;
	selectedIDOfertaCupom = null;
	createdUser = null;
	trialAccount: Trial;
	arrayOfertas = [];
	dadosEmpresaMatricula: any;
	dadosUsuario: any;
	watchedVideo = false;
	image = null;
	setPadding = false;
	showLogin = false;
	alertPassword = { text: '', color: '#B3B3B3' };
	acceptedTerms = false;
	passwordType = 'password';
	confirmPasswordType = 'password';
	loginPasswordType = 'password';
	documents = { termos_de_uso_global: '', politica_privacidade: '' };
	stepperFirstStep = true;

	param = { value: 'worlda' };

  @ViewChildren('password') inputPassword;

  constructor(
    private dataService: DataService,
    private store: Store<AppState>,
    public global: GlobalService,
    private router: Router,
    private translate: TranslateService
  ) {
  	this.textareaPlaceholder = this.translate.instant(
  		'NOTIFICATION_OFERTA.INSERT_COUPON'
  	);
  	this.trialAccount = new Trial();
  	this.credentials = new Credentials();
  }

  ngOnDestroy(): void {
  	this.stateSubscription.unsubscribe();
  }

  ngOnInit() {
  	this.verifyMobile();
  	this.getState();
  	this.tryAutomaticLogin();
  	this.checkCadastroRoute();
  }

  checkCadastroRoute() {
  	if (this.router.url.indexOf('cadastro') !== -1) this.goToCadastro();
  }

  verifyMobile() {
  	if (
  		/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
  			navigator.userAgent
  		)
  	)
  		this.router.navigate(['single-sign-on']);
  }

  getState() {
  	this.stateSubscription = this.store
  		.select((state: any) => {
  			const {
  				dadosEmpresa,
  				dadosPolo,
  				loginLoading,
  				loginAlert,
  				userEmail,
  				usuario,
  				modalVideo,
  				confirmModal,
  			} = state.newAppState;
  			this.state = {
  				dadosEmpresa,
  				dadosPolo,
  				loginLoading,
  				loginAlert,
  				userEmail,
  				usuario,
  				modalVideo,
  				confirmModal,
  			};
  		})
  		.subscribe();
  }

  tryAutomaticLogin() {
  	const token = sessionStorage.getItem('token');
  	const id_usuario = sessionStorage.getItem('id_usuario');
  	if (token && id_usuario) this.dataService.automaticLogin(token, id_usuario);
  	else this.showLogin = true;
  }

  returnLoginStep(): void {
  	this.alert = '';
  	this.store.dispatch(fillDadosEmpresa({ payload: null }));
  	this.store.dispatch(fillDadosPolo({ payload: null }));
  	this.store.dispatch(fillPolos({ payload: null }));
  }

  checkEmail() {
  	this.alert = '';
  	if (this.state.dadosEmpresa) {
  		this.tryLogin();
  		return;
  	}
  	if (!this.credentials.email) {
  		this.alert = this.translate.instant('LOGIN.TYPE_CREDENTIALS');
  		return;
  	}
  	this.store.dispatch(toggleLoginAlert(null));
  	this.store.dispatch(loginLoading({ payload: true }));
  	this.dataService.checkEmailAndSetEmpresa(this.credentials.email);
  }

  validateAccount() {
  	let ret = true;

  	for (const [field, value] of Object.entries(this.trialAccount)) {
  		if (!value || value === '') ret = false;
  	}
  	if (Object.keys(this.trialAccount).length < 6 || !ret) {
  		this.alert = this.translate.instant(
  			'CONFIGURATIONS_EDIT.FILL_ALL_FIELDS'
  		);
  		ret = false;
  	} else if (!this.global.validateEmail(this.trialAccount.email)) {
  		this.alert = this.translate.instant('CONFIGURATIONS_EDIT.VALID_EMAIL');
  		ret = false;
  	} else if (!this.global.validateCPF(this.trialAccount.cpf)) {
  		this.alert = this.translate.instant('CONFIGURATIONS_EDIT.VALID_CPF');
  		ret = false;
  	} else if (!this.global.validateTelefone(this.trialAccount.telefone)) {
  		this.alert = this.translate.instant(
  			'CONFIGURATIONS_EDIT.VALID_CELLPHONE_TELEPHONE'
  		);
  		ret = false;
  	} else if (
  		this.trialAccount.password !== this.trialAccount.confirm_password
  	) {
  		this.alert = this.translate.instant('CONFIGURATIONS_EDTI.SAME_PASSWORD');
  		ret = false;
  	} else if (!this.acceptedTerms) {
  		this.alert = this.translate.instant('CONFIGURATIONS_EDIT.TERMS_OF_USE');
  		ret = false;
  	} else this.alert = '';

  	return ret;
  }

  validatePasswordWithRegex() {
  	let ret = true;
  	if (!this.trialAccount.password || this.trialAccount.password == '') {
  		this.alertPassword.text = '';
  		ret = false;
  	} else if (
  		this.trialAccount.password &&
      this.trialAccount.password.length < 6
  	) {
  		this.alertPassword.text = this.translate.instant(
  			'SIGN_UP_FORM.RULE_PASSWORD'
  		);
  		ret = false;
  	} else this.alertPassword.text = '';
  	return ret;
  }

  togglePasswordType(variable) {
  	if (this[variable] === 'text') this[variable] = 'password';
  	else this[variable] = 'text';
  }

  checkTermosDeUso() {
  	this.acceptedTerms = !this.acceptedTerms;
  	if (this.acceptedTerms) this.validateAccount();
  }

  enterPress(event, method) {
  	if (event.keyCode === 13) {
  		if (method === 'login') this.checkEmail();
  		else if (method === 'cadastro') this.createAccount();
  		else if (method === 'matricular') {
  			this.validarCupomOuOferta();
  			event.preventDefault();
  		}
  	}
  }

  sendEmail() {
  	this.dataService
  		.resetPassword(this.credentials.email)
  		.toPromise()
  		.then(
  			(response) => {
  				this.changeView('reset-confirm');
  			},
  			(error) => {
  				console.log(error);
  				this.alert = error.error.message;
  			}
  		);
  }

  cadastroConcluido() {
  	this.background = 'assets/imgs/foto-login-liga.png';
  	this.changeView('bem-vindo');
  }

  forgotPassword() {
  	if (this.credentials.email) {
  		this.changeView('reset');
  		this.store.dispatch(toggleLoginAlert(null));
  		this.alert = '';
  	} else this.alert = this.translate.instant('LOGIN.TYPE_EMAIL');
  }

  goToCadastro() {
  	this.changeView('cadastro');
  	//this.background = 'experimente';
  	//this.image = 'assets/imgs/experimente-trial.png';
  	this.getTermosAndPolitica('termos_de_uso_global');
  	this.getTermosAndPolitica('politica_privacidade');
  }

  getTermosAndPolitica(doc) {
  	this.dataService
  		.getDocument(doc)
  		.toPromise()
  		.then((data: any) => {
  			this.documents[doc] = data.valor_texto;
  		});
  }

  download(doc) {
  	if (doc) window.open(this.documents[doc]);
  }

  runTagScript() {
  	window.dotq = window.dotq || [];
  	window.dotq.push({
  		projectId: '10000',
  		properties: {
  			pixelId: '10152598',
  			userEmail: '<email_address>',
  			qstrings: {
  				et: 'custom',
  				ea: 'inscricao',
  			},
  		},
  	});
  }

  createAccount() {
  	if (!this.validateAccount() || !this.validatePasswordWithRegex()) {
  		this.store.dispatch(loginLoading({ payload: false }));
  		return false;
  	} else this.store.dispatch(loginLoading({ payload: true }));

  	this.alert = null;
  	this.trialAccount.data_nascimento = null;
  	this.trialAccount.nome_completo = this.global.setToPascalCase(
  		this.trialAccount.nome_completo
  	);

  	this.dataService
  		.criarContaTrial(this.trialAccount)
  		.toPromise()
  		.then(
  			(data) => {
  				this.createdUser = data;
  				this.changeView('cupom');
  				this.runTagScript();
  				this.store.dispatch(loginLoading({ payload: false }));
  			},
  			(error) => {
  				console.log(error);
  				delete this.trialAccount.data_nascimento;
  				this.store.dispatch(loginLoading({ payload: false }));
  				this.alert = error.error.message;
  			}
  		);
  }

  validarCupom(cupom = null, hide = false) {
  	let localCupom = this.selectedCupom;

  	if (hide) this.selectedCupom = null;
  	else if (cupom) this.setPadding = true;

  	this.store.dispatch(loginLoading({ payload: true }));
  	if (cupom) localCupom = cupom;

  	this.dataService
  		.validarCupom(localCupom, this.createdUser)
  		.toPromise()
  		.then(
  			(data: any) => {
  				this.store.dispatch(fillConfirmModal({ payload: null }));
  				this.arrayOfertas = data;
  				this.store.dispatch(loginLoading({ payload: false }));
  				this.alert = '';
  			},
  			(error) => {
  				console.log(error);
  				this.store.dispatch(loginLoading({ payload: false }));
  				this.store.dispatch(fillConfirmModal({ payload: null }));
  				this.alert = error.error.message;
  			}
  		);
  }

  matricularAluno() {
  	this.store.dispatch(loginLoading({ payload: true }));
  	this.dataService
  		.matricularAlunoOferta(
  			this.createdUser.id_usuario,
  			this.selectedIDOfertaCupom
  		)
  		.toPromise()
  		.then(
  			(data: any) => {
  				this.dadosEmpresaMatricula = data.empresa;
  				this.changeView('final');
  				this.background = 'oferta-blue-background.png';
  				this.image = 'assets/imgs/thumbs-trial.png';
  				this.store.dispatch(loginLoading({ payload: false }));
  			},
  			(error) => {
  				console.log(error);
  				this.store.dispatch(loginLoading({ payload: false }));
  				this.alert = error.error.message;
  			}
  		);
  }

  changedTextarea(event) {
  	if (this.arrayOfertas.length > 0) this.arrayOfertas = [];
  	this.enterPress(event, 'matricular');
  }

  validarCupomOuOferta() {
  	if (this.arrayOfertas.length === 0) {
  		if (this.selectedCupom) this.validarCupom();
  		else
  			this.store.dispatch(
  				fillConfirmModal({
  					payload: this.translate.instant(
  						'NOTIFICATION_OFERTA.CONFIRM_OFFER'
  					),
  				})
  			);
  	} else if (this.selectedIDOfertaCupom) {
  		this.matricularAluno();
  	} else if (!this.selectedIDOfertaCupom)
  		this.alert = this.translate.instant('TRIAL.SELECT_COURSE');
  }

  launchVideo() {
  	this.store.dispatch(
  		fillModalVideo({
  			payload: this.dadosEmpresaMatricula.url_video_primeiro_acesso,
  		})
  	);
  	this.watchedVideo = true;
  }

  checkClearAlert() {
  	if (this.selectedIDOfertaCupom) this.alert = '';
  }

  checkCloseModal(event) {
  	if (event.toElement.nodeName == 'CLS-MODAL-VIDEO') {
  		this.store.dispatch(fillModalVideo({ payload: null }));
  		this.afterVideoLogin();
  	} else if (event.toElement.nodeName == 'CLS-CONFIRM-MODAL')
  		this.store.dispatch(fillConfirmModal({ payload: null }));
  }

  afterVideoLogin() {
  	if (this.trialAccount.email) {
  		this.credentials = this.trialAccount;
  		this.tryLogin();
  	} else {
  		this.dataService.initialRoutine(this.dadosUsuario);
  	}
  }

  videoOrLogin() {
  	if (
  		this.dadosEmpresaMatricula.url_video_primeiro_acesso &&
      !this.watchedVideo
  	)
  		this.launchVideo();
  	else this.afterVideoLogin();
  }

  openStore(isAndroid) {
  	if (isAndroid)
  		window.open(
  			'https://play.google.com/store/apps/details?id=br.com.ligaeducacional.ligaonline'
  		);
  	else window.open('https://itunes.apple.com/app/liga-online/id1502314858');
  }

  tryLogin() {
  	if (!this.credentials.email || !this.credentials.password) {
  		this.alert = this.translate.instant('LOGIN.TYPE_CREDENTIALS');
  		return false;
  	}
  	this.store.dispatch(loginLoading({ payload: true }));
  	this.dataService
  		.getToken(this.credentials)
  		.pipe(
  			tap((data: Auth) => this.dataService.afterLoginRoutine(data)),
  			switchMap(() =>
  				this.dataService.getUsuarioByIdentificador(this.credentials.email)
  			),
  			tap((data: any) => {
  				if (data.usuario.data_primeiro_acesso) {
  					this.dataService.initialRoutine(data.usuario);
  				} else {
  					this.dadosEmpresaMatricula = this.state.dadosEmpresa;
  					this.dadosUsuario = data.usuario;
  					this.cadastroConcluido();
  				}
  				this.dataService.sendUltimoAcesso('login', null);
  			}),
  			catchError((error) => {
  				console.log(error.error);
  				this.alert = error.error.message;
  				return error;
  			}),
  			finalize(() => {
  				this.store.dispatch(loginLoading({ payload: false }));
  			})
  		)
  		.subscribe();
  }

  changeView(view) {
  	this.viewMode = view;
  }

  set background(url: string) {
  	this._background = url;
  }

  get background(): string {
  	if (this.state?.dadosPolo?.capa_login)
  		this._background = this.state?.dadosPolo?.capa_login;
  	else
  		this._background = this.state?.dadosEmpresa?.capa_login
  			? this.state?.dadosEmpresa?.capa_login
  			: this._background;

  	return this._background;
  }

  goToAjuda() {
  	window.open(
  		'https://ligaeducacional.movidesk.com/kb/article/193963',
  		'_blank'
  	);
  }
}
