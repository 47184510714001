<div class="percent small" *ngIf="size == 'small'">
  <svg>
    <circle cx="11" cy="11" r="11"></circle>
    <circle
      [style.stroke-dashoffset]="calculateStroke(69)"
      cx="11"
      cy="11"
      r="11"
    ></circle>
  </svg>
  <div class="number">
    {{ value }}
  </div>
</div>
<div
  class="percent medium"
  [class.full]="value === '100'"
  *ngIf="size == 'medium'"
>
  <ng-container *ngIf="value !== '100'">
    <svg>
      <circle [style.stroke]="strokeFill" cx="14" cy="14" r="14"></circle>
      <circle
        [style.stroke-dashoffset]="calculateStroke(88)"
        cx="14"
        cy="14"
        r="14"
      ></circle>
    </svg>
  </ng-container>
  <div class="number" *ngIf="value !== '100'" [style.color]="textColor">
    {{ value }}<span>%</span>
  </div>
  <div class="number" *ngIf="value === '100'" [style.color]="textColor">
    <!-- <i class="fas fa-check"></i> -->
    <img class="perc-100" src="assets/icons/check-verde.svg" />
  </div>
</div>
<div
  class="percent medium-big"
  [class.full]="value === '100'"
  *ngIf="size == 'medium-big'"
>
  <ng-container *ngIf="value !== '100'">
    <svg>
      <circle [style.stroke]="strokeFill" cx="18" cy="18" r="18"></circle>
      <circle
        [style.stroke-dashoffset]="calculateStroke(113)"
        cx="18"
        cy="18"
        r="18"
      ></circle>
    </svg>
  </ng-container>
  <div class="number" *ngIf="value !== '100'" [style.color]="textColor">
    {{ value }}<span>%</span>
  </div>

  <div class="number" *ngIf="value === '100'" [style.color]="textColor">
    <!-- <i class="fas fa-check"></i>-->
    <img class="perc-100" src="assets/icons/check-verde.svg" />
  </div>
</div>
<div class="percent big" *ngIf="size == 'big'">
  <svg>
    <circle [style.stroke]="strokeFill" cx="70" cy="70" r="70"></circle>
    <circle
      [style.stroke-dashoffset]="calculateStroke(439)"
      cx="70"
      cy="70"
      r="70"
    ></circle>
  </svg>
  <div class="number" [style.color]="textColor">
    {{ value }}
  </div>
</div>
