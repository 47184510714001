import { Injectable } from "@angular/core";
@Injectable()
export class AtendimentoModel {
    assunto: string;
    titulo: string; 
    data_abertura: Date;
    id_projeto_turma_estudante: number; 
    id_suporte_categoria: number;
    id_suporte_status: number;
    mensagem: string;
    arquivo: File
}