import {
	Component,
	ElementRef,
	HostListener,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data-service/data.service';
import {
	catchError,
	debounceTime,
	finalize,
	map,
	switchMap,
	tap,
} from 'rxjs/operators';
import {
	AvaliacaoEstudateModel,
	EntregaTurmaModel,
} from '../../models/avaliacao-estudante.model';
import { HttpErrorResponse } from '@angular/common/http';
import { GabaritoModel } from '../../models/gabarito.model';
import {
	fillAtividadeAtual,
	fillAvaliacaoStage,
	fillDocumentViewerUrl,
	fillImageViewerUrl,
	fillMensagens,
	fillSelectedQuizDiscursivo,
	selectedFaseForum,
	selectedForumAtividade,
	showEntregaArquivo,
	showForumTurma,
	showInteracoes,
	showModalPesquisa,
	turmaInteracoes,
} from 'src/app/state/state.actions';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Subscription } from 'rxjs';
import { PesquisaNps } from 'src/app/models/pesquisa-nps.model';
import { Md5 } from 'ts-md5';
import { DataUtil } from 'src/app/util/dataUtil';
import * as moment from 'moment';

@Component({
	selector: 'cls-content-fase',
	templateUrl: './content-fase.component.html',
	styleUrls: ['./content-fase.component.scss'],
})
export class ContentFaseComponent implements OnInit, OnDestroy {
	dataUtil: DataUtil = new DataUtil();
	subscriptions: Subscription[] = [];
	pesquisaNps: PesquisaNps;
	pesquisaRespondida = false;
	state: any;
	objetivoAprendizagemAtual: any;
	fileName = null;
	file: FileList;
	currentFaseID = null;
	avaliacoes = [];
	avaliacaoEstudante: AvaliacaoEstudateModel;
	showNota = false;
	formArquivos: FormGroup;
	loading = false;
	loadingPesquisa = false;
	avaliacaoBotao = true;
	tusUrls = [];
	clearFiles = false;
	tentativasDiscursiva = [];
	configForaPrazo: {
    valor_inteiro: number;
    valor_texto: moment.DurationInputArg2;
    valor_booleano: boolean;
  } = { valor_inteiro: 15, valor_texto: 'days', valor_booleano: false };
	fileUrl = '';
	imageTypeList = [
		'jpg',
		'jpeg',
		'png',
		'bmp',
		'ico',
		'svg',
		'gif',
		'webp',
		'image/png',
		'image/jpeg',
		'image/gif',
		'image/bmp',
		'image/svg+xml',
		'image/vnd.microsoft.icon',
		'image/webp',
	];
  @ViewChild('upload') upload: ElementRef;
  @HostListener('click', ['$event']) onClick(event) {
  	const url =
      event.path?.find((element) => !!element.href)?.href ||
      event.srcElement?.href ||
      event.srcElement?.childNodes[0]?.parentElement?.href ||
      event.srcElement?.childNodes[0]?.parentElement?.parentElement?.href;
  	if (url && url.includes('bvirtual'))
  		this.openBVirtualIntegration(event, url);
  	let isDocPreview = false;
  	this.fileUrl = event.target.getAttribute('data-preview');
  	if (!this.fileUrl) return;
  	const fileSubscription = this.dataService
  		.getArquivo(this.fileUrl)
  		.pipe(
  			map((response: Blob) => response.type),
  			tap((type: string) => {
  				if (!this.imageTypeList.includes(type)) isDocPreview = true;
  			}),
  			tap(() => {
  				if (isDocPreview) {
  					this.store.dispatch(
  						fillDocumentViewerUrl({ payload: this.fileUrl })
  					);
  					event.preventDefault();
  					return;
  				}
  				this.store.dispatch(fillImageViewerUrl({ payload: this.fileUrl }));
  				event.preventDefault();
  				this.subscriptions.push(fileSubscription);
  			})
  		)
  		.subscribe();
  }

  constructor(
    private store: Store<AppState>,
    private global: GlobalService,
    private dataService: DataService,
    private fb: FormBuilder,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit() {
  	this.getState();
  	this.checkForNewFeedbacks();
  	this.construirFormulario();
  	this.getSystemaConfigProrrogarEntrega();
  }

  ngOnDestroy(): void {
  	this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  getSystemaConfigProrrogarEntrega() {
  	const subscription = this.dataService
  		.getSistemaConfigs(14)
  		.pipe(tap((res) => (this.configForaPrazo = res)))
  		.subscribe();
  	this.subscriptions.push(subscription);
  }

  construirFormulario() {
  	this.formArquivos = this.fb.group({
  		upload: ['', Validators.required],
  	});
  }

  existeNota(entregaTurma: EntregaTurmaModel): boolean {
  	return !!entregaTurma.nota_maxima;
  }

  getState() {
  	const subscription = this.store
  		.select((state: any) => {
  			this.global.vimeo();
  			this.loading = false;
  			const {
  				usuario,
  				empresas,
  				projetoAtivo,
  				faseAtual,
  				atividadeAtual,
  				showMenuFase,
  				showEntregaArquivo,
  				entregaLoading,
  				estudante,
  				documentViewerUrl,
  				showModalPesquisa,
  				showModalPesquisaSucesso,
  			} = state.newAppState;

  			this.state = {
  				usuario,
  				empresas,
  				projetoAtivo,
  				faseAtual,
  				atividadeAtual,
  				showMenuFase,
  				showEntregaArquivo,
  				entregaLoading,
  				estudante,
  				documentViewerUrl,
  				showModalPesquisa,
  				showModalPesquisaSucesso,
  			};
  			this.addBibliografiaRegistrada();
  			if (this.state.atividadeAtual) {
  				this.objetivoAprendizagemAtual =
            this.state.faseAtual.objetivosAprendizagem.find(
            	(objetivo) =>
            		objetivo.id_objetivo_aprendizagem ==
                this.state.atividadeAtual.id_objetivo_aprendizagem
            );

  				if (this.objetivoAprendizagemAtual) {
  					const indexAtividade =
              this.objetivoAprendizagemAtual.atividadeRoteiroTurma.findIndex(
              	(atividade) =>
              		atividade.id_atividade_roteiro_turma ==
                  this.state.atividadeAtual.id_atividade_roteiro_turma
              );
  					this.objetivoAprendizagemAtual.previousAtividade =
              this.objetivoAprendizagemAtual.atividadeRoteiroTurma[
              	indexAtividade - 1
              ];
  					this.objetivoAprendizagemAtual.nextAtividade =
              this.objetivoAprendizagemAtual.atividadeRoteiroTurma[
              	indexAtividade + 1
              ];
  				}
  			}
  			if (
  				!this.currentFaseID ||
          (this.state.faseAtual &&
            this.currentFaseID !== this.state.faseAtual.id_fase_turma)
  			) {
  				this.currentFaseID = this.state.faseAtual.id_fase_turma;
  				this.resetFileFields();
  			}

  			if (
  				this.state.estudante.id_projeto_turma_estudante_status == 1 ||
          this.state.estudante.id_projeto_turma_estudante_status == 8
  			) {
  				this.avaliacaoBotao = false;
  			}

  			if (this.state.faseAtual && this.state.faseAtual.entregaTurma) {
  				this.avaliacoes = this.getAvaliacoes(
  					this.state.faseAtual.entregaTurma
  				);
  			}
  			if (
  				!this.pesquisaRespondida &&
          !showModalPesquisa &&
          !this.loadingPesquisa
  			) {
  				this.showModalPesquisa();
  			}
  		})
  		.subscribe();
  	this.subscriptions.push(subscription);
  }

  openBVirtualIntegration(event, url) {
  	event.preventDefault();
  	const empresaAtual = this.state.empresas.find(
  		(empresa) => empresa.id_empresa === this.state.projetoAtivo.id_empresa
  	);
  	if (!empresaAtual) {
  		this.global.toast(
  			this.translate.instant('GENERAL.ERROR'),
  			this.translate.instant('GENERAL.OUVINTE_NO_BVIRTUAL')
  		);
  		return;
  	}

  	const form = document.createElement('form');
  	form.method = 'POST';
  	form.action = `https://middleware-bv.am4.com.br/SSO/${empresaAtual.bvirtual_username}`;
  	form.target = 'bvirtual';

  	const inputs = [
  		{ name: 'login', value: this.state.usuario.cpf },
  		{
  			name: 'token',
  			value: Md5.hashStr(
  				`${this.state.usuario.cpf}${empresaAtual.bvirtual_token}`
  			),
  		},
  	];
  	for (const i of inputs) {
  		const input = document.createElement('input');
  		input.type = 'hidden';
  		input.name = i.name;
  		input.id = i.name;
  		input.value = i.value;
  		form.appendChild(input);
  	}

  	document.body.appendChild(form);
  	form.submit();

  	setTimeout(() => window.open(url, 'bvirtual'), 3000);
  }

  getAvaliacoes(entregaTurma: any[]): any {
  	const ret = [];
  	if (entregaTurma.length > 0) {
  		entregaTurma.forEach((entrega) => {
  			if (entrega?.tipoEntregaTurma?.nome?.toLowerCase() === 'quiz') {
  				entrega.avaliacaoEstudante.forEach((avaliacao) => {
  					avaliacao.tentativa = this.orderTentativas(avaliacao.tentativa);
  					ret.push(avaliacao);
  				});
  			}
  			this.setEntregaRevisao(entrega);
  		});
  	}
  	return ret;
  }

  setEntregaRevisao(entrega) {
  	const today = new Date().toISOString();
  	if (entrega.data_revisao_inicio && entrega.data_revisao_final) {
  		if (
  			today >= entrega.data_revisao_inicio &&
        today <= entrega.data_revisao_final
  		)
  			entrega.showRevisao = true;
  	} else {
  		entrega.showRevisao = true;
  	}
  }

  orderTentativas(tentativas: any) {
  	return tentativas.sort((a: any, b: any) => {
  		return new Date(a.data_inicio) <= new Date(b.data_inicio) ? -1 : 1;
  	});
  }

  checkForNewFeedbacks() {
  	if (this.state.showEntregaArquivo) {
  		this.dataService.updateFaseDataAluno();
  	}
  }

  resetFileFields() {
  	this.file = null;
  	this.fileName = null;
  	if (this.upload) {
  		this.upload.nativeElement.value = '';
  	}
  }

  changedForm(entrega, event) {
  	entrega.arquivos = event;
  }

  enviarArquivos(entrega) {
  	if (!entrega.avaliacaoEstudante[0]) {
  		entrega.arquivos = null;
  		this.global.toast(
  			'Avaliação Estudante não existe',
  			'Erro na configuração da entrega. Por favor entre em contato com seu Professor para reportar o problema.'
  		);
  		return;
  	}
  	let idAvaliacaoEstudanteTentativa = -1;
  	this.loading = true;
  	const formData = new FormData();
  	if (entrega.avaliacaoEstudante[0].tentativa.length > 0) {
  		if (entrega.avaliacaoEstudante[0].id_avaliacao_estudante_tentativa)
  			idAvaliacaoEstudanteTentativa =
          entrega.avaliacaoEstudante[0].id_avaliacao_estudante_tentativa;
  	}

  	entrega.arquivos.forEach((arquivo) => {
  		formData.append('arquivo[]', arquivo);
  	});

  	this.dataService
  		.enviarArquivos(
  			entrega.avaliacaoEstudante[0].id_avaliacao_estudante,
  			idAvaliacaoEstudanteTentativa,
  			this.tusUrls,
  			this.state.projetoAtivo.id_projeto_turma,
  			this.state.faseAtual.id_fase_turma
  		)
  		.subscribe(
  			(gabarito: GabaritoModel) => {
  				entrega.avaliacaoEstudante[0].tentativa.push(gabarito.tentativa);

  				if (
  					entrega.avaliacaoEstudante[0].tentativa.length ===
            entrega.tentativas
  				)
  					entrega.avaliacaoEstudante[0].finalizada = true;

  				this.global.toast(
  					this.translate.instant('GENERAL.SUCCESS'),
  					this.translate.instant('CONTENT_FASE.SENDED')
  				);
  				entrega.arquivos = null;
  				this.clearFiles = true;
  				setTimeout(() => {
  					this.clearFiles = false;
  				}, 3000);
  				this.tusUrls = [];
  				this.loading = false;
  				this.dataService.updateFaseDataAluno();
  			},
  			(error: HttpErrorResponse) => {
  				this.loading = false;
  				this.global.toast(
  					this.translate.instant('GENERAL.ERROR'),
  					error.error.message
  				);
  			}
  		);
  }

  updateURLs(event) {
  	this.tusUrls.push(event);
  }

  removeURLs(event) {
  	this.tusUrls = this.tusUrls.filter((url) => {
  		return url != event;
  	});
  }

  verificarDataEntrega(entrega, avaliacao) {
  	const hoje = moment();
  	if (this.state.estudante.id_projeto_turma_estudante_status == 8)
  		return true;

  	if (entrega.data_entrega_inicio) {
  		const inicio = moment(entrega.data_entrega_inicio);
  		if (
  			hoje.isBefore(inicio) &&
        !entrega.permite_entrega_fora_prazo &&
        !avaliacao.permite_entrega_fora_prazo
  		) {
  			this.global.toast(
  				this.translate.instant('GENERAL.NOTICE'),
  				this.translate.instant('GENERAL.EVALUATION_DEADLINE_INIT') +
            inicio.toDate().toLocaleDateString() +
            '.'
  			);
  			return false;
  		}
  	}
  	if (entrega.data_entrega) {
  		const fim = moment(entrega.data_entrega);
  		const entregaProrrogada =
        this.configForaPrazo.valor_booleano &&
        avaliacao.prorrogado &&
        hoje.isBefore(
        	fim.add(
        		this.configForaPrazo.valor_inteiro,
        		this.configForaPrazo.valor_texto
        	)
        );
  		if (
  			fim.isBefore(hoje) &&
        !entrega.permite_entrega_fora_prazo &&
        !avaliacao.permite_entrega_fora_prazo &&
        !entregaProrrogada
  		) {
  			this.global.toast(
  				this.translate.instant('GENERAL.NOTICE'),
  				this.translate.instant('GENERAL.EVALUATION_DEADLINE_FINAL') +
            fim.toDate().toLocaleDateString() +
            '.'
  			);
  			return false;
  		}
  	}
  	return true;
  }

  goToQuizDiscursivo(entrega, avaliacao) {
  	if (!this.verificarDataEntrega(entrega, avaliacao)) {
  		return;
  	}
  	this.store.dispatch(fillSelectedQuizDiscursivo({ payload: entrega }));
  	this.router.navigate(['avaliacao/discursiva']);
  	this.store.dispatch(fillAvaliacaoStage({ payload: 'initial' }));
  }

  goToChat(atividade) {
  	if (atividade.forum && atividade.forum.length > 0) {
  		this.dataService
  			.getAndDispatchMessages(atividade.forum[0].id_forum)
  			.toPromise()
  			.then(
  				(data: any) => {
  					data?.mensagens.forEach((msg) => {
  						msg.mensagem = this.dataUtil.urlToAnchors(msg.mensagem);
  					});
  					this.store.dispatch(showInteracoes({ payload: 'open' }));
  					this.store.dispatch(turmaInteracoes({ payload: false }));
  					this.store.dispatch(fillMensagens({ payload: data.mensagens }));
  					this.store.dispatch(showForumTurma({ payload: true }));
  					this.store.dispatch(selectedForumAtividade({ payload: atividade }));
  					this.store.dispatch(
  						selectedFaseForum({ payload: this.state.faseAtual })
  					);
  				},
  				(error) => {
  					this.global.toast(
  						this.translate.instant('GENERAL.ERROR'),
  						error.error.message
  					);
  				}
  			);
  	} else
  		this.global.toast(
  			this.translate.instant('GENERAL.ERROR'),
  			this.translate.instant('GENERAL.NO_FORUM')
  		);
  }

  toggleInteracoesVisibility() {
  	this.store.dispatch(turmaInteracoes({ payload: true }));
  	this.store.dispatch(showForumTurma({ payload: false }));
  	setTimeout(() => {
  		this.store.dispatch(showInteracoes({ payload: 'open' }));
  	}, 0);
  	document.querySelector('body').setAttribute('class', 'no-scrollbar');
  }

  openAtividadeLti(atividade) {
  	const empresaAtual = this.state.empresas.find(
  		(empresa) => empresa.id_empresa === this.state.projetoAtivo.id_empresa
  	);
  	if (!empresaAtual) {
  		this.global.toast(
  			this.translate.instant('GENERAL.ERROR'),
  			this.translate.instant('GENERAL.OUVINTE_NO_LTI')
  		);
  		return;
  	}
  	if (!empresaAtual.lti_launcher) {
  		this.global.toast(
  			this.translate.instant('GENERAL.ERROR'),
  			this.translate.instant('GENERAL.NO_LAUNCHER')
  		);
  		return;
  	}
  	const isAlgetec = empresaAtual.lti_launcher.indexOf('virtuaslab') !== -1;
  	const signatureUrl = isAlgetec
  		? `${empresaAtual.lti_launcher}/ualabs/launcher.php?produto=ualabid&ualabid=${atividade.id_recurso}`
  		: `${empresaAtual.lti_launcher}?contentId=${atividade.id_recurso}`;
  	const data = {
  		roles: 'urn:lti:role:ims/lis/Learner',
  		lis_person_name_full: this.state.usuario.nome_completo,
  		lis_person_contact_email_primary: this.state.usuario.email,
  		resource_link_id: isAlgetec
  			? atividade.id_recurso
  			: `${this.state.projetoAtivo.sigla}_${atividade.id_recurso}`, // codigoProjeto_codigoUA
  		resource_link_title: atividade.titulo, // nome do recurso
  		context_id: `${this.state.projetoAtivo.sigla}_${this.state.projetoAtivo.id_projeto_turma}_${atividade.id_recurso}`, // codigoProjeto_codigoUA
  		context_title: this.state.projetoAtivo.sigla, // codigo do projeto
  		ext_user_username: this.state.usuario.username,
  		user_id: this.state.usuario.id_usuario,
  		consumer_key: empresaAtual.lti_chave,
  		consumer_secret: empresaAtual.lti_segredo,
  		consumer_launcher: empresaAtual.lti_launcher,
  		lis_outcome_service_url: empresaAtual.lis_outcome_service_url,
  		lis_result_sourcedid: `${this.state.projetoAtivo.sigla}_${this.state.estudante.id_projeto_turma_estudante}_${atividade.id_recurso}`,
  		signatureUrl,
  	};
  	const subscription = this.dataService
  		.getAtividadeLti(data)
  		.pipe(
  			tap((response: any) => {
  				const form = document.createElement('form');
  				form.target = 'view';
  				form.method = 'POST';
  				form.action = signatureUrl;
  				let params = {};
  				Object.keys(response).forEach((key) => {
  					params = {
  						...params,
  						[key]: response[key],
  					};
  				});

  				for (const i in params) {
  					if (Object.prototype.hasOwnProperty.call(params, i)) {
  						const input = document.createElement('input');
  						input.type = 'hidden';
  						input.name = i;
  						input.value = params[i];
  						form.appendChild(input);
  					}
  				}

  				document.body.appendChild(form);
  				form.submit();
  				window.open('', 'view');
  			})
  		)
  		.subscribe();
  	this.subscriptions.push(subscription);
  }

  showResult(tentativa, id): void {
  	tentativa.showResult = !tentativa.showResult;
  }

  getTentativasDiscursiva(tentativa, id) {
  	if (!tentativa.showResult) {
  		if (!tentativa.id_avaliacao_estudante_tentativa || !id) return;
  		this.dataService
  			.getTentativaRespondida(id, tentativa.id_avaliacao_estudante_tentativa)
  			.subscribe(
  				(resultadoResposta: any) => {
  					this.tentativasDiscursiva = resultadoResposta.tentativa.questions;
  					tentativa.showResult = !tentativa.showResult;
  				},
  				(error) => {
  					this.global.toast(
  						this.translate.instant('GENERAL.ERROR'),
  						error.error.message
  					);
  				},
  				() => {
  					return;
  				}
  			);
  	} else tentativa.showResult = !tentativa.showResult;
  }

  isGreaterGrade(avaliacao, tentativa): boolean {
  	return (
  		avaliacao.id_tentativa_escolhida ===
      tentativa.id_avaliacao_estudante_tentativa
  	);
  }

  concluirAtividade(atividade, idObjetivo) {
  	this.loading = true;
  	const objetivo = this.state.faseAtual.objetivosAprendizagem.find(
  		(objetivo) => objetivo.id_objetivo_aprendizagem === idObjetivo
  	);
  	this.dataService.completeAtividade(objetivo, atividade, 1);
  }

  setAtividadeAtual(atividade) {
  	if (this.state.atividadeAtual != atividade) {
  		this.scrollToContent();
  		this.store.dispatch(fillAtividadeAtual({ payload: atividade }));
  		this.store.dispatch(showEntregaArquivo({ payload: false }));
  	}
  }

  likeAtividade(atividade, idObjetivo) {
  	this.loading = true;
  	const concluido = 1;
  	const objetivo = this.state.faseAtual.objetivosAprendizagem.find(
  		(objetivo) => objetivo.id_objetivo_aprendizagem === idObjetivo
  	);

  	const subscription = this.dataService
  		.likeAtividade(atividade, concluido)
  		.pipe(
  			tap((data) => {
  				atividade.atividadeEstudanteTurma[0] = data.atividadeEstudanteTurma;
  				this.dataService.updateAtividadeState(objetivo, concluido);
  			}),
  			catchError((error) => {
  				this.global.toast(
  					this.translate.instant('GENERAL.ERROR'),
  					error.error.message
  				);
  				return EMPTY;
  			}),
  			finalize(() => (this.loading = false))
  		)
  		.subscribe();

  	this.subscriptions.push(subscription);
  }

  deslikeAtividade(atividade, idObjetivo) {
  	this.loading = true;
  	const concluido = 1;
  	const objetivo = this.state.faseAtual.objetivosAprendizagem.find(
  		(objetivo) => objetivo.id_objetivo_aprendizagem === idObjetivo
  	);

  	const subscription = this.dataService
  		.deslikeAtividade(atividade, 1)
  		.pipe(
  			tap((data) => {
  				atividade.atividadeEstudanteTurma[0] = data.atividadeEstudanteTurma;
  				this.dataService.updateAtividadeState(objetivo, concluido);
  			}),
  			catchError((error) => {
  				this.global.toast(
  					this.translate.instant('GENERAL.ERROR'),
  					error.error.message
  				);
  				return EMPTY;
  			}),
  			finalize(() => (this.loading = false))
  		)
  		.subscribe();

  	this.subscriptions.push(subscription);
  }

  showModalPesquisa() {
  	if (!this.state?.faseAtual?.entrega_final) return;
  	let maior = 0;
  	this.state?.faseAtual?.entregaTurma.forEach(
  		(element) =>
  			(maior = element.ordenacao > maior ? element.ordenacao : maior)
  	);
  	const ultimaEntrega = this.state?.faseAtual?.entregaTurma.find(
  		(entrega) => entrega.ordenacao === maior
  	);
  	if (
  		ultimaEntrega?.avaliacaoEstudante[0]?.tentativa.length === 0 ||
      !this.state.projetoAtivo.id_pesquisa_nps
  	)
  		return;
  	this.getPesquisaNps(this.state.projetoAtivo);
  }

  closeModalPesquisa() {
  	this.pesquisaRespondida = true;
  	this.store.dispatch(showModalPesquisa({ payload: false }));
  }

  getPesquisaNps(projetoAtivo) {
  	this.loadingPesquisa = true;
  	const subscription = this.dataService
  		.verificarRespostaEstudante(
  			projetoAtivo.id_pesquisa_nps,
  			this.state.estudante.id_projeto_turma_estudante
  		)
  		.pipe(
  			debounceTime(1000),
  			tap((data) => {
  				this.pesquisaRespondida = data.pesquisa_respondida;
  			}),
  			switchMap((data) =>
  				this.dataService.getPesquisaNps(projetoAtivo.id_pesquisa_nps)
  			),
  			tap((pesquisa: PesquisaNps) => {
  				this.pesquisaNps = pesquisa;
  				if (this.pesquisaRespondida) return;
  				this.store.dispatch(showModalPesquisa({ payload: true }));
  			}),
  			catchError((error) => {
  				this.global.toast(
  					this.translate.instant('GENERAL.ERROR'),
  					error.error.message
  				);
  				return EMPTY;
  			}),
  			finalize(() => (this.loadingPesquisa = false))
  		)
  		.subscribe();
  	this.subscriptions.push(subscription);
  }

  addBibliografiaRegistrada() {
  	const htmlDescricao = this.state?.atividadeAtual?.descricao;
  	if (!htmlDescricao) return;

  	const tempElemt = document.createElement('div');
  	tempElemt.innerHTML = htmlDescricao;
  	let descricaoBase = tempElemt.outerHTML;

  	const tagBibliografiaResgitrada = tempElemt.getElementsByClassName(
  		'bibliografia_registrada'
  	);

  	if (!tagBibliografiaResgitrada) return;

  	for (const span of tagBibliografiaResgitrada) {
  		const spanText = span.outerHTML;
  		const tagA = span.querySelector('a');
  		const libId = span.getAttribute('data-lib');
  		const bibliografias = this.state?.projetoAtivo?.bibliografia;

  		if (!bibliografias || !bibliografias.length) return;

  		const bibliografia = bibliografias.find((b) => b.codigo == libId);
  		if (!bibliografia) return;

  		tagA.href = bibliografia.link_acesso;
  		tagA.textContent = bibliografia.referencia;
  		span.appendChild(tagA);
  		const novoSpan = span.outerHTML;
  		descricaoBase = descricaoBase.replace(spanText, novoSpan);
  	}
  	this.state.atividadeAtual.descricao = descricaoBase;
  }

  scrollToContent() {
  	setTimeout(() => {
  		window.scrollTo({
  			top: 460,
  			behavior: 'smooth',
  		});
  	}, 300);
  }
}
