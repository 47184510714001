import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { EMPTY, Subscription } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { DataService } from 'src/app/services/data-service/data.service';

@Component({
  selector: 'app-certificado-validade',
  templateUrl: './certificado-validade.component.html',
  styleUrls: ['./certificado-validade.component.scss']
})
export class CertificadoValidadeComponent implements OnInit, OnDestroy {
  data: any = {}
  message: string = 'CERTIFICATION.INVALID_CERTIFICATION';
  isLoading: boolean = false;
  valido: boolean = false;
  subscriptions: Array<Subscription> = [];
  ua: any;
  requestError: boolean = false;
  isMobile = false;

  constructor(private route: ActivatedRoute, private dataService: DataService) {
    this.ua = navigator.userAgent;
  }

  ngOnInit(): void {
    this.getDadosCertificado(this.route.snapshot.queryParams);
    this.checkMobile();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  getDadosCertificado(params: Params) {
    this.isLoading = true;
    this.subscriptions.push(this.dataService.validarCertificado(params).pipe(
      tap((data) => this.data = data),
      tap((data) => this.valido = this.setValidate(data)),
      catchError((err) => {
        this.requestError = true;
        return EMPTY
      }),
      finalize(() => this.isLoading = false)
    ).subscribe())
  }

  setValidate(data): boolean {
    if (!data || !Object.keys(data).length) return
    const valido = data.projetoTurmaEstudante?.id_projeto_turma_estudante_status === 5 && data?.usuarioCompetencia?.valor === 100
    this.message = valido ? 'CERTIFICATION.VALID_CERTIFICATION' : 'CERTIFICATION.INVALID_CERTIFICATION'
    return valido
  }

  checkMobile() {
    if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(this.ua))
      this.isMobile = true;
  }
}
