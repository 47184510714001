import { Component, Input } from '@angular/core';

@Component({
	selector: 'ui-avaliacao',
	templateUrl: './avaliacao.component.html',
	styleUrls: ['./avaliacao.component.scss'],
})
export class AvaliacaoComponent {
  @Input() nota: number;
  @Input() notaMaxima: number;
  @Input() tipoAvaliacao: string;
  @Input() numeroTentativas: number;
  @Input() tentativasUtilizadas: number;
  @Input() titulo: string;
  @Input() descricaoAvaliacao: string;
  @Input() dataRevisaoInicio: string;
  @Input() dataRevisaoFinal: string;
  @Input() dataEntregaInicio: string;
  @Input() dataEntregaFim: string;
}
