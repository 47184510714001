<div *ngFor="let message of messages" class="message-box">
    <div class="message-info pt-3 pb-4 border-bottom d-flex flex-direction-row justify-content-between">
        <span
            class="remetente position-relative text-capitalize">{{ showRespostas ? message.duvida : message.author || message.categoria}}
            <div *ngIf="message.isNew" class="message-badge"></div>
        </span>
        <span class="timestamp">{{ message.timestamp || message.data_postagem | date: 'dd/MM/yyyy | HH:mm'}}</span>
    </div>
    <div class="message">
        <span class="text">
            {{ !showRespostas ?  message.text || message.duvida : null}}


            <div *ngIf="showRespostas">
                <div *ngFor="let resposta of message.respostasMesaDuvida">
                    <div class="font-weight-bold mb-3 mt-3 text-capitalize">
                        {{ resposta?.tipo_respondente && resposta?.tipo_respondente == 'estudante' ? 'aluno' : resposta?.tipo_respondente }}:
                    </div>

                    <span class="text">
                        {{ resposta?.resposta }}
                    </span>
                </div>
                <div *ngIf="message.respostasMesaDuvida.length == 0">
                    <span class="text">
                       Ainda não há resposta para esta dúvida.
                    </span>
                </div>
            </div>
        </span>
    </div>
</div>