import { Component, ElementRef, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AtendimentoModel } from 'src/app/models/atendimento.model';
import { GlobalService } from 'src/app/services/global-service/global.service';

@Component({
  selector: 'cls-atendimento-box',
  templateUrl: './atendimento-box.component.html',
  styleUrls: ['./atendimento-box.component.scss']
})
export class AtendimentoBoxComponent implements OnInit {

  @Output() newAtendimento: EventEmitter<AtendimentoModel> = new EventEmitter<AtendimentoModel>();

  file: File;
  fileName: string;
  uploadForm: FormGroup;

  @ViewChild('fileAtendimento') fileAtendimento: ElementRef;

  constructor(
    public atendimento: AtendimentoModel,
    private global: GlobalService
    ) { }

  ngOnInit() {
  }

  clickInput() {
    let input: HTMLElement;
    input = document.querySelector('.file-atendimento');
    input.click();
  }

  sendFileToDOM(event) {
    this.fileName = event.srcElement.files[0].name;
    this.file = event.target.files[0];
  }

  emitForm() {
    if(this.validateForm()){
      this.atendimento.arquivo = this.file;
      this.newAtendimento.emit(this.atendimento);
      this.clearFile();
      this.atendimento = new AtendimentoModel();
    }
  } 

  validateForm(){
    if(!this.atendimento.id_suporte_categoria){
      this.global.toast("Atenção!", "Você precisa selecionar o serviço!");
      return false;
    }
    if(!this.atendimento.assunto){
      this.global.toast("Atenção!", "Você precisa digitar o título do atendimento!");
      return false;
    }
    if(!this.atendimento.mensagem){
      this.global.toast("Atenção!", "Você precisa digitar a mensagem do atendimento!");
      return false;
    }
    return true;
  }

  clearFile() {
    if (this.file) {
      this.file = null;
      this.fileName = null;
      this.fileAtendimento.nativeElement.value = "";
    }
  }

}
