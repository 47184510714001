import { createAction, props } from '@ngrx/store';
import { NotificacaoMensagemTratadaModel } from '../models/notificacao-mensagem.model';

export const showInteracoes = createAction(
	'TOGGLE_INTERACOES',
	props<{ payload: string }>()
);
export const showAgenda = createAction(
	'TOGGLE_AGENDA',
	props<{ payload: string }>()
);
export const showMenu = createAction(
	'TOGGLE_MENU',
	props<{ payload: boolean }>()
);
export const loginLoading = createAction(
	'LOGIN_LOADING',
	props<{ payload: boolean }>()
);
export const photoLoading = createAction(
	'PHOTO_LOADING',
	props<{ payload: boolean }>()
);
export const interacoesLoading = createAction(
	'INTERACOES_LOADING',
	props<{ payload: boolean }>()
);
export const entregaLoading = createAction(
	'ENTREGA_LOADING',
	props<{ payload: boolean }>()
);
export const clearState = createAction('CLEAR_STATE');
export const fillToken = createAction(
	'FILL_TOKEN',
	props<{ payload: any[] }>()
);
export const fillFases = createAction(
	'FILL_FASES',
	props<{ payload: any[] }>()
);
export const fillMarcos = createAction(
	'FILL_MARCOS',
	props<{ payload: any[] }>()
);
export const fillFaseAtual = createAction(
	'FILL_FASE_ATUAL',
	props<{ payload: any[] }>()
);
export const fillActiveProject = createAction(
	'FILL_ACTIVE_PROJECT',
	props<{ payload: any[] }>()
);
export const fillUserData = createAction(
	'FILL_USER_DATA',
	props<{ payload: any[] }>()
);
export const fillStudentData = createAction(
	'FILL_STUDENT_DATA',
	props<{ payload: any[] }>()
);
export const fillEncontroAgendado = createAction(
	'FILL_ENCONTRO_AGENDADO',
	props<{ payload: any[] }>()
);
export const fillTurmaEstudante = createAction(
	'FILL_TURMA_ESTUDANTE',
	props<{ payload: any[] }>()
);
export const fillObjetivoAprendizagemAtivo = createAction(
	'FILL_OBJETIVO_APRENDIZAGEM_ATIVO',
	props<{ payload: any[] }>()
);
export const fillAtividadeAtual = createAction(
	'FILL_ATIVIDADE_ATUAL',
	props<{ payload: any[] }>()
);
export const fillEntregaEstudante = createAction(
	'FILL_ENTREGA_ESTUDANTE',
	props<{ payload: any[] }>()
);
export const fillFaleComTurma = createAction(
	'FILL_FALE_COM_TURMA',
	props<{ payload: any[] }>()
);
export const fillIDForumTurma = createAction(
	'FILL_ID_FORUM_TURMA',
	props<{ payload: any[] }>()
);
export const fillNotificacoes = createAction(
	'FILL_NOTIFICACOES',
	props<{ payload: any[] }>()
);
export const fillNotificationBadge = createAction(
	'FILL_NOTIFICATION_BADGE',
	props<{ payload: boolean }>()
);
export const fillMensagens = createAction(
	'FILL_MENSAGENS',
	props<{ payload: any[] }>()
);
export const fillLastRoute = createAction(
	'FILL_LAST_ROUTE',
	props<{ payload: string }>()
);
export const showModalConfiguracoes = createAction(
	'SHOW_MODAL_CONFIGURACOES',
	props<{ payload: boolean }>()
);
export const showModalPesquisa = createAction(
	'SHOW_MODAL_PESQUISA',
	props<{ payload: boolean }>()
);
export const showModalPesquisaSucesso = createAction(
	'SHOW_MODAL_PESQUISA_SUCESSO',
	props<{ payload: boolean }>()
);
export const showModalFeedback = createAction(
	'SHOW_MODAL_FEEDBACK',
	props<{ payload: boolean }>()
);
export const showSaibaMais = createAction(
	'SHOW_SAIBA_MAIS',
	props<{ payload: boolean }>()
);
export const showChat = createAction(
	'SHOW_CHAT',
	props<{ payload: boolean }>()
);
export const delayAnswerOpen = createAction(
	'DELAY_ANSWER_OPEN',
	props<{ payload: boolean }>()
);
export const toggleMenuFase = createAction(
	'TOGGLE_MENU_FASE',
	props<{ payload: boolean }>()
);
export const forumAtual = createAction(
	'FILL_FORUM_ATUAL',
	props<{ payload: any[] }>()
);
export const forumInteracoes = createAction(
	'FILL_FORUM_INTERACOES',
	props<{ payload: any[] }>()
);
export const showForumFreeTalk = createAction(
	'SHOW_FORUM_FREE_TALK',
	props<{ payload: boolean }>()
);
export const turmaInteracoes = createAction(
	'SHOW_TURMA_INTERACOES',
	props<{ payload: boolean }>()
);
export const getFromSession = createAction(
	'GET_FROM_SESSION',
	props<{ payload: any[] }>()
);
export const fillDuvidas = createAction(
	'FILL_DUVIDAS',
	props<{ payload: any[] }>()
);
export const fillInsideDuvida = createAction(
	'FILL_INSIDE_DUVIDA',
	props<{ payload: any[] }>()
);
export const fillDadosEmpresa = createAction(
	'FILL_DADOS_EMPRESA',
	props<{ payload: any[] }>()
);
export const fillDadosPolo = createAction(
	'FILL_DADOS_POLO',
	props<{ payload: any[] }>()
);
export const fillProjetosAluno = createAction(
	'FILL_PROJETOS_ALUNO',
	props<{ payload: any[] }>()
);
export const toggleLoginAlert = createAction(
	'TOGGLE_LOGIN_ALERT',
	props<{ payload: any }>()
);
export const fillCatFormacao = createAction(
	'FILL_CAT_FORMACAO',
	props<{ payload: any[] }>()
);
export const fillCatCompetencia = createAction(
	'FILL_CAT_COMPETENCIA',
	props<{ payload: any[] }>()
);
export const fillCatIdioma = createAction(
	'FILL_CAT_IDIOMA',
	props<{ payload: any[] }>()
);
export const fillCatExperiencia = createAction(
	'FILL_CAT_EXPERIENCIA',
	props<{ payload: any[] }>()
);
export const fillCatPerfil = createAction(
	'FILL_CAT_PERFIL',
	props<{ payload: any }>()
);
export const fillSelectedCurriculumProfile = createAction(
	'FILL_CURRICULUM_PROFILE',
	props<{ payload: any }>()
);
export const fillCatTestePerfil = createAction(
	'FILL_CAT_TESTE_PERFIL',
	props<{ payload: any }>()
);
export const toggleCurriculoEdit = createAction(
	'TOGGLE_CURRICULO_EDIT',
	props<{ payload: boolean }>()
);
export const showForumTurma = createAction(
	'SHOW_FORUM_TURMA',
	props<{ payload: boolean }>()
);
export const selectedForumAtividade = createAction(
	'SELECTED_FORUM_ATIVIDADE',
	props<{ payload: any[] }>()
);
export const selectedFaseForum = createAction(
	'SELECTED_FASE_FORUM',
	props<{ payload: any[] }>()
);
export const userEmail = createAction(
	'USER_EMAIL',
	props<{ payload: any[] }>()
);
export const showEntregaArquivo = createAction(
	'SHOW_ENTREGA_ARQUIVO',
	props<{ payload: boolean }>()
);
export const avaliacaoState = createAction(
	'AVALIACAO_STATE',
	props<{ payload: any }>()
);
export const fillPerguntaAvaliacao = createAction(
	'FILL_PERGUNTA_AVALIACAO',
	props<{ payload: any }>()
);
export const fillArrayRespostasAluno = createAction(
	'FILL_ARRAY_RESPOSTAS_ALUNO',
	props<{ payload: any[] }>()
);
export const fillDadosAvaliacao = createAction(
	'FILL_DADOS_AVALIACAO',
	props<{ payload: any }>()
);
export const fillToast = createAction('FILL_TOAST', props<{ payload: any }>());
export const fillCurriculoStateTemp = createAction(
	'FILL_CURRICULO_STATE_TEMP',
	props<{ payload: any }>()
);
export const fillSuport = createAction(
	'FILL_SUPORT',
	props<{ payload: any[] }>()
);
export const fillSuportDetails = createAction(
	'FILL_SUPORT_DETAILS',
	props<{ payload: any }>()
);
export const anterioresLoading = createAction(
	'ANTERIORES_LOADING',
	props<{ payload: any }>()
);
export const fillSoftSkills = createAction(
	'FILL_SOFT_SKILLS',
	props<{ payload: any[] }>()
);
export const fillCasosSucesso = createAction(
	'FILL_CASOS_SUCESSO',
	props<{ payload: any[] }>()
);
export const fillConteudos = createAction(
	'FILL_CONTEUDOS',
	props<{ payload: any[] }>()
);
export const fillEventos = createAction(
	'FILL_EVENTOS',
	props<{ payload: any[] }>()
);
export const fillModalVideo = createAction(
	'FILL_MODAL_VIDEO',
	props<{ payload: any[] }>()
);
export const fillConfirmModal = createAction(
	'FILL_CONFIRM_MODAL',
	props<{ payload: any }>()
);
export const showNotificacao = createAction(
	'SHOW_NOTIFICACAO',
	props<{ payload: boolean }>()
);
export const fillSecretariaModal = createAction(
	'FILL_SECRETARIA_MODAL',
	props<{ payload: any }>()
);
export const fillSelectedQuizAvaliacao = createAction(
	'FILL_SELECTED_QUIZ_AVALIACAO',
	props<{ payload: any }>()
);
export const fillSelectedQuizDiscursivo = createAction(
	'FILL_SELECTED_QUIZ_DISCURSIVO',
	props<{ payload: any }>()
);
export const notificacoes = createAction(
	'NOTIFICACOES',
	props<{ payload: NotificacaoMensagemTratadaModel }>()
);
export const quantidadeCompetencias = createAction(
	'QUANTIDADE_COMPETENCIAS',
	props<{ payload: number }>()
);
export const fillTusURLs = createAction(
	'FILL_TUS_URLS',
	props<{ payload: string }>()
);
export const fillEstudanteStatus = createAction(
	'FILL_ESTUDANTE_STATUS',
	props<{ payload: any }>()
);
export const fillAvaliacaoStage = createAction(
	'FILL_AVALIACAO_STAGE',
	props<{ payload: any }>()
);
export const fillAvaliacaoQuestoes = createAction(
	'FILL_AVALIACAO_QUESTOES',
	props<{ payload: any }>()
);
export const fillAvaliacaoQuestao = createAction(
	'FILL_AVALIACAO_QUESTAO',
	props<{ payload: any }>()
);
export const fillAvaliacaoIndex = createAction(
	'FILL_AVALIACAO_INDEX',
	props<{ payload: any }>()
);
export const fillAvaliacaoTentativa = createAction(
	'FILL_AVALIACAO_TENTATIVA',
	props<{ payload: any }>()
);
export const fillAvaliacaoTimer = createAction(
	'FILL_AVALIACAO_TIMER',
	props<{ payload: any }>()
);
export const fillAvaliacaoType = createAction(
	'FILL_AVALIACAO_TYPE',
	props<{ payload: string }>()
);
export const fillAvaliacaoError = createAction(
	'FILL_AVALIACAO_ERROR',
	props<{ payload: any }>()
);
export const fillMensagensSecretaria = createAction(
	'FILL_MENSAGENS_SECRETARIA',
	props<{ payload: any }>()
);
export const fillEmpresas = createAction(
	'FILL_EMPRESAS',
	props<{ payload: any }>()
);
export const fillPolos = createAction('FILL_POLOS', props<{ payload: any }>());
export const fillDocumentViewerUrl = createAction(
	'FILL_DOCUMENT_VIEWER_URL',
	props<{ payload: any }>()
);
export const fillImageViewerUrl = createAction(
	'FILL_IMAGE_VIEWER_URL',
	props<{ payload: any }>()
);
export const fillCentralAjuda = createAction(
	'FILL_CENTRAL_AJUDA',
	props<{ payload: string }>()
);
