import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global-service/global.service';

@Component({
  selector: 'cls-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.scss']
})
export class MainViewComponent implements OnInit {

  @Input() background: string;
  @Input() image: string;

  settedBackground: string

  constructor(public global: GlobalService) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.setBackground();
  }

  setBackground() {
    switch (this.background) {
      case 'experimente':
        this.settedBackground = this.global.generateImageURL('assets/imgs/oferta-blue-background.png');
        break
      case 'carousel':
        this.settedBackground = this.global.generateImageURL('assets/imgs/oferta-blue-background.png');
        break
      default:
        this.settedBackground = this.global.generateImageURL(this.background)
        break
    }
  }

}
