import { Component, OnInit, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/state";
import { FormGroup, FormControl } from "@angular/forms";
import {
  clearState,
  fillSuportDetails,
  fillSuport,
  fillMensagensSecretaria,
  fillFaseAtual,
  fillObjetivoAprendizagemAtivo,
} from "src/app/state/state.actions";
import { Observable, of } from "rxjs";
import { DataService } from "src/app/services/data-service/data.service";
import { GlobalService } from "src/app/services/global-service/global.service";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Router } from "@angular/router";

@Component({
  selector: "app-solicitacoes",
  templateUrl: "./solicitacoes.component.html",
  styleUrls: ["./solicitacoes.component.scss"],
})
export class SolicitacoesComponent implements OnInit, OnDestroy {
  state: any;
  stateSubscription$: any;
  dataSecretariaAreasSubscription$: any;
  dataSecretariaSolicitacoesSubscription$: any;
  dataSuportMessagesSubscription$: any;
  obsFormClientService$: any;
  obsFormClientArea$: any;
  isModalOpen: boolean = false;
  file: any = null;
  fileName: string = "";
  selections = new FormGroup({
    area: new FormControl(""),
    service: new FormControl(""),
    solicitation: new FormControl(""),
    message: new FormControl(""),
  });
  areaOptions: Array<{ id: number; text: string }> = [];
  servicosOptions: Array<{ id: number; text: string }> = [];
  solicitacoesOptions: Array<{ id: number; text: string }> = [];
  mailTo: string;
  tipoAtendimento: string;
  idEmpresa: number;
  tableData = {
    datalist: {
      header: [
        {
          field: "suporte_id_suporte",
          size: "small",
          text: "All",
          type: "checkbox",
        },
        {
          field: "suporte_instance",
          size: "small",
          text: "Protocolo",
          type: "text",
        },
        {
          field: "suporte_categoria_nome",
          size: "small",
          text: "Serviços",
          type: "text",
        },
        {
          field: "suporte_assunto",
          size: "small",
          text: "Assunto",
          type: "text",
        },
        {
          field: "suporte_data_abertura",
          size: "small",
          text: "Data",
          type: "text",
        },
        {
          field: "suporte_status_nome",
          size: "small",
          text: "Status",
          type: "text",
        },
        {
          field: "",
          size: "small",
          text: "Ação",
          type: "actions",
        },
      ],
      data: [],
      actions: {
        single: [
          { method: "get", text: "Visualizar", type: "button", url: "../" },
        ],
      },
    },
    filters: [],
    pagination: { page: 1, pages: 1, records: 1, totalPerPage: 10 },
  };

  constructor(
    private store: Store<AppState>,
    private global: GlobalService,
    private dataService: DataService,
    private translate: TranslateService,
    private router: Router
  ) {}

  async ngOnInit() {

    this.tipoAtendimento = ''
    this.getState();
    this.getSuportMessages();
    this.getSecretariaAreas();
    this.onChanges();
  }

  ngOnDestroy(): void {
    this.stateSubscription$.unsubscribe();
    this.dataSecretariaAreasSubscription$.unsubscribe();
    if(this.dataSecretariaSolicitacoesSubscription$)
      this.dataSecretariaSolicitacoesSubscription$.unsubscribe();
    this.dataSuportMessagesSubscription$.unsubscribe();
    this.obsFormClientService$.unsubscribe();
    this.obsFormClientArea$.unsubscribe();
  }

  onChanges(): void {
    this.obsFormClientArea$ = this.selections
      .get("area")
      .valueChanges.subscribe((newValue) => {
        if (newValue) this.getSecretariaServicos(newValue);
      });
    this.obsFormClientService$ = this.selections
      .get("service")
      .valueChanges.subscribe((newValue) => {
        if (newValue) this.getSecretariaSolicitacoes(newValue);
      });
  }

  getState() {
    this.stateSubscription$ = this.store
      .select((state: any) => {
        const {
          projetosAluno,
          projetoAtivo,
          usuario,
          estudante,
          fases,
          mensagens,
          duvidas,
          insideDuvida,
          mensagensTurma,
          suport,
          suportDetails,
          anterioresLoading,
          estudanteStatus,
          dadosEmpresa,
          empresas,
        } = state.newAppState;
        this.state = {
          projetosAluno,
          projetoAtivo,
          usuario,
          estudante,
          fases,
          mensagens,
          duvidas,
          insideDuvida,
          mensagensTurma,
          suport,
          suportDetails,
          anterioresLoading,
          estudanteStatus,
          dadosEmpresa,
          empresas,
        };


      })
      .subscribe(_ => {
        this.tratarAtendimentoEmpresa()
      });
  }

  getEmpresaFromEmpresas(idEmpresa, empresas) {
    const empresasFilter = empresas.filter(empresa=>empresa.id_empresa === idEmpresa)
    if(empresasFilter.length === 0)
      return null
    return empresasFilter[0]
  }

  getSecretariaAreas() {
    const idProjetoTurma = this.state.projetoAtivo ? this.state.projetoAtivo.id_projeto_turma : -1
    this.dataSecretariaAreasSubscription$ = this.dataService
      .getSecretariaAreas(this.idEmpresa, idProjetoTurma)
      .subscribe(
        (data: any) => {
          this.areaOptions = data.servicos;
        },
        (err) => {
          console.error(err);
        }
      );
  }

  tratarAtendimentoEmpresa() {
    const idEmpresa = 2
    const empresaLiga = this.getEmpresaFromEmpresas(idEmpresa, this.state.empresas)

    // quando nenhum projeto está selecionado enviar obrigatoriamente para a LIGA
    // if(!this.state.projetoAtivo && !empresaLiga) {
    //   this.tipoAtendimento = 'sematendimento'
    //   this.idEmpresa = idEmpresa
    //   return
    // }

    let empresa = this.state.projetoAtivo?.empresa || this.state.projetosAluno[0].empresa

    if(!empresa && empresaLiga) {
      empresa = empresaLiga
    }

    this.idEmpresa = empresa.id_empresa
    if(empresa.id_tipo_suporte === 1){
      this.tipoAtendimento = 'sematendimento'
      return
    }

    if(empresa.id_tipo_suporte === 2){
      this.tipoAtendimento = 'email'
      this.mailTo = "mailto:" + empresa.email_atendimento
      return
    }

    if(empresa.id_tipo_suporte === 3){
      this.tipoAtendimento = 'movidesk'
    }
  }

  getSecretariaServicos(idArea: number) {
    this.dataSecretariaAreasSubscription$ = this.dataService
      .getSecretariaServicos(this.idEmpresa, idArea)
      .subscribe(
        (data: any) => {
          this.servicosOptions = data.servicos;
          this.selections.get("service").setValue(this.servicosOptions[0].id);
        },
        (err) => {
          console.error(err);
        }
      );
  }

  getSecretariaSolicitacoes(idServico: number) {
    this.dataSecretariaSolicitacoesSubscription$ = this.dataService
      .getSecretariaSolicitacoes(this.idEmpresa, idServico)
      .subscribe(
        (data: any) => {
          this.solicitacoesOptions = data.servicos;
        },
        (err) => {
          console.error(err);
        }
      );
  }

  getSuportMessages() {
    this.dataSuportMessagesSubscription$ = this.dataService
      .getSuportMessagesByCompanyAndUser(
        this.state.projetoAtivo?.estudante?.id_projeto_turma_estudante,
        this.idEmpresa,
        this.state.usuario.id_usuario
      )
      .subscribe(
        (data: any) => {
          this.store.dispatch(fillSuport({ payload: data }));
          this.setTableData();
          this.store.dispatch(
            fillMensagensSecretaria({ payload: this.tableData.datalist.data })
          );
        },
        (error) => {
          if (error.error.message === "Usuário inativo!") {
            sessionStorage.clear();
            this.router.navigate(['login']);
            this.store.dispatch(clearState());
          }

          this.global.toast(
            this.translate.instant("GENERAL.ERROR"),
            error.error.message
          );
        }
      );
  }

  setTableData() {
    this.tableData.datalist.data = this.state?.suport || [];
    this.tableData.datalist.data.forEach(
      (data) =>
        (data.suporte_data_abertura = this.formatStringData(
          data.suporte_data_abertura
        ))
    );
  }

  formatStringData(date: Date): string {
    console.log("date",date);

    const fullDate = new Date(date);
    console.log("fullDate",fullDate);

    return `${moment(fullDate).format("DD/MM/YYYY H:mm")}h`;
  }

  attachFile($event: any) {
    if ($event.target.files[0].size > 998900) {
      this.global.toast("Atenção!", "São aceitos arquivos de até 1MB");
      return;
    }
    this.file = $event.target.files[0];
    this.fileName = $event.target.files[0].name;
  }

  clearFileInput(): void {
    this.file = null;
  }

  clearAllInputs(): void {
    this.selections.setValue({
      area: "",
      service: "",
      solicitation: "",
      message: "",
    });
    this.clearFileInput();
  }

  sendMessageOutput($event: any) {
    let form = $event.form;
    const type = this.state.projetoAtivo ? "falecomprofessor" : "secretaria";
    form.append("id_suporte_status", 2);
    form.append("id_suporte", this.state.suportDetails.suporte_id_suporte);
    this.dataService
      .sendSuportMessage(form, type)
      .then((data) => {
        this.store.dispatch(fillSuportDetails({ payload: data }));
        this.global.scrollToBottomViaID("scroll-interacoes");
        this.dataService.getAndSetSuportDetails($event.messages, type);
      })
      .catch(() => {
        this.global.toast(
          "Erro de envio",
          "Ocorreu um erro ao enviar a mensagem!"
        );
      });
  }

  async getSuportAndOpenDetails($event: any) {
    if ($event?.item?.suporte_id_suporte) {
      const data = await this.dataService.getAtendimentoSecretariaDetails(
        $event.item
      );
      this.store.dispatch(fillSuportDetails({ payload: data }));
      this.openModal();
    }
  }

  validateForm() {
    if (!this.selections.value.area) {
      this.global.toast("Atenção!", "Você precisa selecionar a área!");
      return false;
    }
    if (!this.selections.value.service) {
      this.global.toast("Atenção!", "Você precisa selecionar o serviço!");
      return false;
    }
    if (!this.selections.value.solicitation) {
      this.global.toast("Atenção!", "Você precisa selecionar a solicitação!");
      return false;
    }
    if (!this.selections.value.message) {
      this.global.toast("Atenção!", "Você precisa escrever a mensagem!");
      return false;
    }
    return true;
  }

  getSolicitationTextById() {
    return this.solicitacoesOptions.filter(
      (solicitation) => solicitation.id == this.selections.value.solicitation
    )[0]?.text;
  }

  getNewTicketData(): FormData {
    let formData = new FormData();

    if (this.file) formData.append("arquivo", this.file, this.fileName);

    formData.append(
      "titulo",
      `${this.state.usuario?.nome_social} ${this.getSolicitationTextById()}`
    );
    formData.append("assunto", this.selections.value.message);
    formData.append("id_area", this.selections.value.area);
    formData.append("id_servico", this.selections.value.service);
    formData.append("id_solicitacao", this.selections.value.solicitation);
    formData.append("categoria_nome", this.getSolicitationTextById());
    formData.append("data_abertura", moment(new Date()).toISOString());
    formData.append("id_suporte_tipo", "2");
    formData.append("id_suporte_status", "1");
    if (this.state.projetoAtivo)
      formData.append(
        "id_projeto_turma_estudante",
        this.state.projetoAtivo.estudante.id_projeto_turma_estudante
      );
    else {
      formData.append("id_empresa", this.idEmpresa.toString());
      formData.append("id_usuario", this.state.usuario.id_usuario);
    }

    return formData;
  }

  sendNewTicket() {
    if (this.state && this.state.estudanteStatus === "trial")
      this.global.toast(
        this.translate.instant("GENERAL.NOTICE"),
        this.translate.instant("GENERAL.TRIAL_DEFAULT")
      );
    else if (this.validateForm()) {
      this.dataService.createTicket(this.getNewTicketData());
      this.clearAllInputs();
    } else
      this.global.toast(
        this.translate.instant("GENERAL.ATTENTION"),
        this.translate.instant("INTERACTIONS.FIELDS")
      );
  }

  solveTicket() {
    let solveObj = {
      id_suporte_status: 5,
      id_suporte: this.state.suportDetails.suporte_id_suporte,
      mensagem: null,
    };
    this.dataService
      .solveSuportTicket(solveObj, "secretaria")
      .then((data: { suporte_id_suporte: number }) => {
        this.global.toast(
          this.translate.instant("GENERAL.SUCCESS"),
          this.translate.instant("INTERACTIONS.SOLVED")
        );
        this.store.dispatch(fillSuportDetails({ payload: data }));
        this.updateSuportStateArray(data.suporte_id_suporte);
        this.closeModal();
      });
  }

  updateSuportStateArray(suporteIdSuporte: number) {
    let tempArr = this.state.suport;
    tempArr.forEach((temp) => {
      if (temp.suporte_id_suporte == suporteIdSuporte)
        temp.suporte_status_final = true;
    });
    this.store.dispatch(fillSuport({ payload: tempArr }));
  }

  closeModal() {
    this.isModalOpen = false;
  }

  openModal() {
    this.isModalOpen = true;
  }

  get estudanteStatus(): boolean {
    if (this.state.projetoAtivo)
      return this.state.estudanteStatus === "matriculado";
    return true;
  }

  get isServiceDisabled(): boolean {
    return this.selections.value.area == "";
  }

  get isSolicitationDisabled(): boolean {
    return this.selections.value.service == "";
  }

  backToHome(){
    this.store.dispatch(fillFaseAtual(null));
    this.store.dispatch(fillObjetivoAprendizagemAtivo({ payload: null }));

    if(this.state.projetoAtivo)
    this.router.navigate(['projeto/' + this.state.projetoAtivo.id_projeto_turma])
    else
    this.router.navigate(['perfil']);
  }

}
