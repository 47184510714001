import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppState } from 'src/app/state';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { fillLastRoute, fillFaseAtual, fillAtividadeAtual, fillObjetivoAprendizagemAtivo, showEntregaArquivo } from 'src/app/state/state.actions';
import { Location } from '@angular/common';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { DataService } from 'src/app/services/data-service/data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  projectView = true;
  selectedFase: any;
  projetoTurma: any;
  fasesArray = [];
  state: any;
  colorArray = ['#18475e', '#2d7291', '#42a1ca', '#9dc9dd'];
  stateSubscription$: any;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    public global: GlobalService,
    public dataService: DataService,
  ) {
    this.location.onUrlChange(data => {
      this.setCurrentRoute(data);
    })
    this.activatedRoute.params.subscribe(data => {
      let snap: any;
      snap = this.activatedRoute.snapshot;
      this.setCurrentRoute(snap._routerState.url);
    })
  }

  ngOnDestroy(): void {
    this.stateSubscription$.unsubscribe()
  }

  ngOnInit() {
    this.getState();
  }

  setCurrentRoute(route) {
    sessionStorage.setItem('url', route);
    this.store.dispatch(fillLastRoute({ payload: route }))
  }

  getState() {
    this.stateSubscription$ = this.store.select((state: any) => {
      this.state = state.newAppState;
      sessionStorage.setItem('state', JSON.stringify(state.newAppState));
    }).subscribe();
  }

  setFirstAtividade(fase) {
    if (fase?.objetivosAprendizagem.length > 0) {
      const objAprendizado = fase?.objetivosAprendizagem?.filter(objApred => objApred?.atividadeRoteiroTurma?.length > 0)
      if (objAprendizado.length > 0) {
        this.store.dispatch(fillAtividadeAtual({
          payload: objAprendizado[0]?.atividadeRoteiroTurma[0]
        }));
      }
    }
  }

  setSelectedFase(data) {
    if (this.state.estudante.id_projeto_turma_estudante_status !== 8 && (!data.aberta || !data.feedbackEntrega || !this.state || this.state.estudanteStatus === 'trial')) return

    this.store.dispatch(showEntregaArquivo({ payload: data.entrega_final ? true : false }));
    this.store.dispatch(fillAtividadeAtual({ payload: null }));
    this.setFirstAtividade(data);
    this.global.clearInputFase.next();
    this.store.dispatch(fillObjetivoAprendizagemAtivo({ payload: null }))
    this.router.navigate(['/projeto', this.state.projetoAtivo.id_projeto_turma, 'fase', data.id_fase_turma]);
    this.store.dispatch(fillFaseAtual({ payload: data }))
  }

}
