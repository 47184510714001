import { Component, OnInit } from '@angular/core';
import { AppState } from 'src/app/state';
import { Store } from '@ngrx/store';
import {
	fillAtividadeAtual,
	fillLastRoute,
	fillModalVideo,
	quantidadeCompetencias,
	showEntregaArquivo,
	showMenu,
} from 'src/app/state/state.actions';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data-service/data.service';

@Component({
	selector: 'app-perfil',
	templateUrl: './perfil.component.html',
	styleUrls: ['./perfil.component.scss'],
})
export class PerfilComponent implements OnInit {
	stateSubscription: any;
	state: any;
	filtereds = [];
	areaTipo = 0;

	constructor(
    private store: Store<AppState>,
    private router: Router,
    private dataService: DataService
	) {}

	ngOnInit() {
		this.getState();
		this.setCurrentRoute();
		this.filteredCompetencias();
		this.clearProjectRemainState();
		this.dataService.getProjetosAlunoCards();
	}

	clearProjectRemainState() {
		this.store.dispatch(showEntregaArquivo({ payload: false }));
		this.store.dispatch(fillAtividadeAtual({ payload: null }));
	}

	setCurrentRoute() {
		sessionStorage.setItem('url', '/perfil');
		this.store.dispatch(fillLastRoute({ payload: '/perfil' }));
	}

	getState() {
		this.stateSubscription = this.store
			.select((state: any) => {
				const {
					dadosEmpresa,
					dadosPolo,
					usuario,
					projetosAluno,
					showingMenu,
					modalVideo,
					casosSucesso,
					softSkills,
					eventos,
				} = state.newAppState;
				this.state = {
					dadosEmpresa,
					dadosPolo,
					usuario,
					projetosAluno,
					showingMenu,
					modalVideo,
					casosSucesso,
					softSkills,
					eventos,
				};
			})
			.subscribe();
	}

	toggleMenu() {
		this.store.dispatch(showMenu({ payload: true }));
		document.querySelector('body').setAttribute('class', 'no-scrollbar');
	}

	goToCurriculo() {
		this.dataService.getCurriculoAluno();
	}

	filteredCompetencias() {
		const filtered = [];
		if (
			this.state.usuario.usuarioCompetencia &&
      this.state.usuario.usuarioCompetencia.length > 0
		) {
			this.state.usuario.usuarioCompetencia.forEach((competencia) => {
				if (competencia.favorita) filtered.push(competencia);
			});
			this.store.dispatch(
				quantidadeCompetencias({
					payload: this.state.usuario.usuarioCompetencia.length,
				})
			);
		} else this.store.dispatch(quantidadeCompetencias({ payload: 0 }));
		this.filtereds = filtered;
	}

	checkCloseModal(event) {
		if (event.toElement.nodeName == 'CLS-MODAL-VIDEO')
			this.store.dispatch(fillModalVideo({ payload: null }));
	}

	goToSocial(social) {
		if (this.state.usuario[social]) window.open(this.state.usuario[social]);
	}

	receiveAreaTipo($event) {
		this.areaTipo = $event;
	}
}
