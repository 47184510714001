<div class="quiz-container">
    <div class="quiz-box" *ngFor="let quiz of quizArray; let id_quiz=index">
        <div class="badge"><img src="../../../assets/icons/check-quiz.svg"></div>
        <div class="title">{{ 'QUIZ.TITLE' | translate }}</div>
        <label [innerHtml]="quiz.pergunta | sanitizeHtml"></label>
        <div class="resposta-container" (click)="selectResposta(id_resposta, id_quiz, resposta.text)" *ngFor="let resposta of quiz.sortedRespostas; let id_resposta=index">
            <div class="check-resposta" [class.checked]="isSelected(id_resposta, id_quiz)">
                <i class="fas fa-check" *ngIf="isSelected(id_resposta, id_quiz)"></i>
            </div>
            <div class="resposta" [class.bold-border]="id_resposta == 0" [class.correta]="resposta.correta"
                [class.incorreta]="resposta.incorreta"
                [innerHtml]="resposta.text | sanitizeHtml">
            </div>
        </div>
        <div class="feedback-send mt-4">
            <span *ngIf="quiz?.show_feedback_acerto">{{quiz.feedback_correto}}</span>
            <span *ngIf="quiz?.show_feedback_erro && !quiz?.show_feedback_acerto">{{quiz.feedback_incorreto}}</span>
            <button class="btn btn-send float-right" [class.active]="id_quiz == selectedRespostas[id_quiz].id_quiz"
                (click)="sendAnswer(id_quiz)">{{ 'QUIZ.SEND' | translate }}</button>
        </div>
    </div>
</div>