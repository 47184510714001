import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from 'src/app/services/data-service/data.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { avaliacaoState } from 'src/app/state/state.actions';
import { GabaritoModel } from 'src/app/models/gabarito.model';
import { EntregaTurmaModel } from 'src/app/models/avaliacao-estudante.model';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-gabarito-quiz-novo',
	templateUrl: './gabarito-quiz-novo.component.html',
	styleUrls: ['./gabarito-quiz-novo.component.scss'],
})
export class GabaritoQuizNovoComponent implements OnInit {
  @Input() idAvaliacaoEstudante: number;
  @Input() idAvaliacaoEstudanteTentativa: number;
  @Input() useInternalLoading = true;
  @Output() resultadoEmitter = new EventEmitter();
  resultado: GabaritoModel;
  state: any;
  entregaTurma: EntregaTurmaModel;
  isLoading: boolean;

  constructor(
    private dataService: DataService,
    private store: Store<AppState>,
    private router: Router,
    private global: GlobalService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
  	this.isLoading = true;
  	this.getState();

  	if (!this.idAvaliacaoEstudante || !this.idAvaliacaoEstudanteTentativa)
  		return;
  	this.dataService
  		.getTentativaRespondida(
  			this.idAvaliacaoEstudante,
  			this.idAvaliacaoEstudanteTentativa
  		)
  		.subscribe(
  			(resultadoResposta) => {
  				this.resultado = resultadoResposta;
  				this.resultadoEmitter.emit(this.resultado);
  			},
  			(error) => {
  				this.global.toast(
  					this.translate.instant('GENERAL.ERROR'),
  					error.error.message
  				);
  				this.isLoading = false;
  			},
  			() => {
  				this.isLoading = false;
  				return;
  			}
  		);
  }

  getGabaritoPercentage() {
  	let percentageResult = '0';
  	if (!this.resultado) return percentageResult;
  	if (
  		this.resultado.tentativa &&
      this.resultado.avaliacaoEstudante.entregaTurma.questoes_tentativa
  	)
  		percentageResult = (
  			(this.resultado.tentativa.acertos /
          this.resultado.avaliacaoEstudante.entregaTurma.questoes_tentativa) *
        100
  		)
  			.toFixed(2)
  			.toString();
  	return percentageResult;
  }

  exit() {
  	this.store.dispatch(avaliacaoState({ payload: null }));
  	this.router.navigate([
  		'projeto/' +
        this.state.projetoAtivo.id_projeto_turma +
        '/fase/' +
        this.state.faseAtual.id_fase_turma,
  	]);
  }
  getState() {
  	this.store
  		.select((state: any) => {
  			const { projetoAtivo, faseAtual } = state.newAppState;
  			this.state = { faseAtual, projetoAtivo };
  			if (this.state.faseAtual && this.state.faseAtual.entregaTurma[0])
  				this.entregaTurma = this.state.faseAtual.entregaTurma[0];
  		})
  		.subscribe();
  }
}
