import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeMesa'
})
export class TypeMesaPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {

    return value.filter(atividade => atividade.tipo == 'mesa')
  }

}
