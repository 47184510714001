import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from 'src/app/services/global-service/global.service';
import * as moment from 'moment';

@Component({
	selector: 'cls-eventos-box',
	templateUrl: './eventos-box.component.html',
	styleUrls: ['./eventos-box.component.scss'],
})
export class EventosBoxComponent implements OnInit {
  @Input() data: any;
  @Input() layout = 'vertical';
  openModal = false;
  growBox = false;
  releaseLink = false;
  eventoTime: string;
  dateFormated = {
  	dia: null,
  	mes: null,
  };
  btnString = 'SAIBA_MAIS.EVENT';

  constructor(
    public global: GlobalService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
  	this.getFormatDate();
  	this.checkDateToWatch();
  }

  checkDateToWatch() {
  	const today = moment();
  	const inicio = moment(this.data.data_inicio).subtract(15, 'minutes');
  	if (today.isAfter(inicio)) this.releaseLink = true;
  }

  openModalEvent() {
  	this.openModal = true;
  }

  closeModalEvent() {
  	this.openModal = false;
  }

  changeSize(event) {
  	if (event.type == 'mouseover') this.growBox = true;
  	else this.growBox = false;
  }

  getFormatDate() {
  	const dataInicio = new Date(this.data.data_inicio);
  	const dataFim = new Date(this.data.data_fim);
  	const meses = [
  		'Jan',
  		'Fev',
  		'Mar',
  		'Abr',
  		'Mai',
  		'Jun',
  		'Jul',
  		'Ago',
  		'Set',
  		'Out',
  		'Nov',
  		'Dez',
  	];
  	const horaInit = dataInicio.toLocaleTimeString('en-US', {
  		hour: 'numeric',
  		hour12: false,
  	});
  	const initHora = horaInit.split(' ')[0];
  	const periodo = horaInit.split(' ')[1];
  	const minutesInit = this.formatDate(dataInicio.getMinutes());

  	const horaFim = dataFim.toLocaleTimeString('en-US', {
  		hour: 'numeric',
  		hour12: false,
  	});
  	const endHour = horaFim.split(' ')[0];
  	const endMinute = this.formatDate(dataFim.getMinutes());

  	const ano = dataInicio.getFullYear();
  	this.dateFormated.mes = meses[dataInicio.getMonth()];
  	this.dateFormated.dia =
      dataInicio.getDate() < 10
      	? `0${dataInicio.getDate()}`
      	: dataInicio.getDate();
  	this.eventoTime = `${this.dateFormated.dia} ${
  		this.dateFormated.mes
  	}, ${ano} - ${this.translate.instant(
  		'CURRICULO.FROM'
  	)} ${initHora}:${minutesInit}h ${this.translate.instant(
  		'CURRICULO.TO'
  	)} ${endHour}:${endMinute}h`;
  }
  formatDate(time) {
  	return time < 10 ? `0${time}` : time.toString();
  }
}
