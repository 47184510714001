<div class="login-new-container" *ngIf="showLogin">
  <cls-main-view
    *ngIf="viewMode === 'initial'"
    [ngClass]="viewMode"
    [background]="'carousel'"
  >
    <img class="liga-login" src="assets/imgs/liga-login1.png" />
    <button
      class="btn btn-blue upper"
      (click)="changeView('login')"
      data-cy="InitialBtnLogin"
    >
      {{ "LOGIN.SIGN_IN" | translate }}
    </button>
    <button
      class="btn btn-blue upper"
      (click)="goToCadastro()"
      data-cy="InitialBtnRegister"
    >
      {{ "LOGIN.SIGN_UP" | translate }}
    </button>
    <div class="download-text capitalize">
      {{ "LOGIN.DOWNLOAD" | translate }}
    </div>
    <img class="qr-code" src="assets/imgs/qr-code-aluno.png" />
    <div class="d-flex store">
      <img (click)="openStore(true)" src="assets/imgs/botaoandroid.png" />
      <img (click)="openStore(false)" src="assets/imgs/botaoios.png" />
    </div>
  </cls-main-view>

  <cls-main-view
    *ngIf="viewMode === 'login'"
    [ngClass]="viewMode"
    [background]="!state.dadosEmpresa ? 'carousel' : background"
  >
    <div>
      <img
        *ngIf="state.dadosEmpresa"
        class="arrow-back"
        (click)="returnLoginStep()"
        src="assets/icons/arrow-left.svg"
      />
      <div
        class="red customcb"
        style="margin-left: -9px"
        (click)="returnLoginStep()"
      >
        <input
          type="checkbox"
          value="1"
          id="customcb1"
          name="customcb1"
          [checked]="!state.dadosEmpresa"
          disabled
        />
        <label class="inner" for="customcb1"></label>
      </div>
      <div class="red customcb" style="margin-left: 9px">
        <input
          type="checkbox"
          value="1"
          id="customcb2"
          name="customcb2"
          [checked]="state.dadosEmpresa"
          disabled
        />
        <label class="inner" for="customcb2"></label>
      </div>
    </div>
    <!-- <img class="liga-login"
      [src]="state?.dadosPolo?.url_logo || state?.dadosEmpresa?.url_logo || 'assets/imgs/liga-login1.png'"> -->
    <img
      class="liga-login"
      [src]="state?.dadosPolo?.url_logo || state?.dadosEmpresa?.url_logo || ''"
    />
    <div *ngIf="!state.dadosEmpresa" class="txt-blue capitalize">
      {{ "LOGIN.ENTER" | translate }}
    </div>
    <div *ngIf="state.dadosEmpresa" class="txt-blue capitalize">
      {{ "LOGIN.PASSWORD_ENTER" | translate }}
    </div>
    <div *ngIf="!state.dadosEmpresa" class="txt-title">
      {{ "LOGIN.TYPE_EMAIL" | translate }}
    </div>
    <div *ngIf="state.dadosEmpresa" class="txt-title">
      {{ "LOGIN.TYPE_PASSWORD" | translate }}
    </div>
    <input
      *ngIf="!state.dadosEmpresa"
      type="text"
      (keydown)="enterPress($event, 'login')"
      [(ngModel)]="credentials.email"
      placeholder="{{ 'LOGIN.SIGN_IN_EMAIL' | translate }}"
      data-cy="LoginInputCpf"
    />
    <div *ngIf="state.dadosEmpresa" class="input-container">
      <input
        [type]="loginPasswordType"
        [(ngModel)]="credentials.password"
        (keydown)="enterPress($event, 'login')"
        placeholder="{{ 'LOGIN.SIGN_IN_PASSWORD' | translate }}"
        data-cy="LoginInputSenha"
      />
      <!-- <i class="far fa-eye" (click)="togglePasswordType('loginPasswordType')"></i> -->
      <img
        class="eye"
        src="assets/imgs/far-fa-eye.svg"
        (click)="togglePasswordType('loginPasswordType')"
      />
    </div>

    <label
      [hidden]="!state.dadosEmpresa"
      class="forgot-pass"
      (click)="forgotPassword()"
    >
      {{ "LOGIN.FORGOT_PASSWORD" | translate }}
    </label>

    <button
      class="btn btn-blue upper"
      (click)="checkEmail()"
      data-cy="LoginBtnLogin"
    >
      <span *ngIf="!state?.loginLoading && !state.dadosEmpresa">{{
        "BUTTON.CONTINUE" | translate
      }}</span>
      <span *ngIf="!state?.loginLoading && state.dadosEmpresa">{{
        "LOGIN.LOGIN" | translate
      }}</span>
      <div *ngIf="state?.loginLoading" class="text-center">
        <div class="spinner-border spinner-border-md" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </button>
    <button class="btn btn-transparent capitalize" (click)="forgotPassword()">
      <div class="alert" *ngIf="alert">{{ alert }}</div>
      <div class="alert" *ngIf="!alert && state.loginAlert">
        {{ state.loginAlert }}
      </div>
    </button>
    <div class="bg-interrogacao">
      <img
        class="interrogacao"
        src="assets\icons\icone_interrogacao.svg"
        alt="Interrogação"
      />
    </div>
    <div class="help-text">
      {{ "LOGIN.HELP_CENTER_1" | translate }}<br />{{
        "LOGIN.HELP_CENTER_2" | translate
      }}
    </div>
    <button class="btn-acess upper" (click)="goToAjuda()">
      {{ "LOGIN.ACESS" | translate }}
    </button>
    <!-- <div class="download-text capitalize">{{ 'LOGIN.DOWNLOAD' | translate}}</div> -->
    <!-- <img class="qr-code" src="assets/imgs/qr-code-aluno.png"> -->
    <!-- <div class="d-flex store">
      <img (click)="openStore(true)" src="assets/imgs/botaoandroid.png">
      <img (click)="openStore(false)" src="assets/imgs/botaoios.png">
    </div> -->
  </cls-main-view>

  <cls-main-view
    *ngIf="viewMode === 'reset'"
    [ngClass]="viewMode"
    [background]="background"
  >
    <img class="liga-login" src="assets/imgs/liga-login1.png" />
    <div class="txt-blue upper">{{ "LOGIN.RESET_PASSWORD" | translate }}</div>
    <div class="txt-gray capitalize">
      <span>{{ "LOGIN.EMAIL_CHANGE_TEXT1" | translate }}</span>
      {{ "LOGIN.EMAIL_CHANGE_TEXT2" | translate }}
    </div>
    <div class="email-cpf">
      <div class="check">
        <div></div>
      </div>
      <span>{{ credentials.email }}</span>
    </div>
    <div class="btns-reset">
      <button
        class="btn btn-blue upper"
        (click)="changeView('login')"
        data-cy="ResetBtnBack"
      >
        {{ "LOGIN.BACK" | translate }}
      </button>
      <button
        class="btn btn-blue upper"
        (click)="sendEmail()"
        data-cy="ResetBtnSend"
      >
        {{ "LOGIN.SEND" | translate }}
      </button>
      <div class="alert" *ngIf="alert">{{ alert }}</div>
    </div>
  </cls-main-view>

  <cls-main-view
    *ngIf="viewMode === 'reset-confirm'"
    [ngClass]="viewMode"
    [background]="background"
  >
    <img class="liga-login" src="assets/imgs/liga-login1.png" />
    <div class="txt-blue upper">
      {{ "LOGIN.LINK_CONFIRMATION1" | translate }}
    </div>
    <div class="txt-gray capitalize">
      {{ "LOGIN.LINK_CONFIRMATION2" | translate }}
    </div>
    <div class="txt-blue mt-4 mb-0 upper">
      {{ "LOGIN.LINK_CONFIRMATION3" | translate }}
    </div>
    <div class="txt-gray mt-1 capitalize">
      {{ "LOGIN.LINK_CONFIRMATION4" | translate }}
    </div>
  </cls-main-view>

  <cls-main-view
    *ngIf="viewMode === 'cadastro'"
    [ngClass]="viewMode"
    [background]="'carousel'"
  >
    <img class="liga-login" src="assets/imgs/liga-login1.png" />
    <div class="txt-blue capitalize">
      {{ "SIGN_UP_FORM.CREATE_ACCOUNT" | translate }}
    </div>
    <div class="cadastrado capitalize">
      {{ "SIGN_UP_FORM.STUDANT" | translate }}
      <span
        (click)="
          changeView('login'); background = 'assets/imgs/foto-login-liga.png'
        "
        data-cy="LoginSpanCadastreCliqueAqui"
        >{{ "SIGN_UP_FORM.CLICK" | translate }}</span
      >
    </div>
    <input
      [(ngModel)]="trialAccount.nome_completo"
      (keydown)="enterPress($event, 'cadastro')"
      type="text"
      placeholder="{{ 'SIGN_UP_FORM.NAME' | translate }}"
      data-cy="LoginInputCadastreNome"
    />
    <input
      [(ngModel)]="trialAccount.email"
      (keydown)="enterPress($event, 'cadastro')"
      type="text"
      placeholder="{{ 'SIGN_UP_FORM.EMAIL' | translate }}"
      data-cy="LoginInputCadastreEmail"
    />
    <input
      [(ngModel)]="trialAccount.cpf"
      (keydown)="enterPress($event, 'cadastro')"
      type="text"
      mask="000.000.000-00"
      placeholder="{{ 'SIGN_UP_FORM.CPF' | translate }}"
      data-cy="LoginInputCadastreCPF"
    />
    <input
      [(ngModel)]="trialAccount.telefone"
      (keydown)="enterPress($event, 'cadastro')"
      type="text"
      mask="(00) 00000-0000"
      placeholder="{{ 'SIGN_UP_FORM.TELEPHONE' | translate }}"
      data-cy="LoginInputCadastreTelefone"
    />
    <div class="input-container">
      <input
        [type]="passwordType"
        (keydown)="enterPress($event, 'cadastro')"
        [(ngModel)]="trialAccount.password"
        (focusout)="alertPassword.color = '#B3B3B3'"
        (focus)="validatePasswordWithRegex()"
        (keyup)="validatePasswordWithRegex()"
        placeholder="{{ 'SIGN_UP_FORM.PASSWORD' | translate }}"
        data-cy="LoginInputCadastreSenha"
      />
      <!-- <i class="far fa-eye" (click)="togglePasswordType('passwordType')"></i> -->
      <img
        class="eye"
        src="assets/imgs/far-fa-eye.svg"
        (click)="togglePasswordType('loginPasswordType')"
      />
    </div>
    <div class="input-container">
      <input
        [type]="confirmPasswordType"
        (keydown)="enterPress($event, 'cadastro')"
        [(ngModel)]="trialAccount.confirm_password"
        placeholder="{{ 'SIGN_UP_FORM.CONFIRM_PASSWORD' | translate }}"
        data-cy="LoginInputCadastreConfirmarSenha"
      />
      <!-- <i class="far fa-eye" (click)="togglePasswordType('confirmPasswordType')"></i> -->
      <img
        class="eye"
        src="assets/imgs/far-fa-eye.svg"
        (click)="togglePasswordType('loginPasswordType')"
      />
    </div>
    <div class="alert-password capitalize">
      {{ "SIGN_UP_FORM.RULE_PASSWORD" | translate }}
    </div>
    <div class="alert-password red">{{ alertPassword.text }}</div>
    <div class="termos">
      <div
        class="check"
        (click)="checkTermosDeUso()"
        [class.checked]="acceptedTerms"
        data-cy="LoginCheckAceitoTermos"
      >
        <!-- <i class="fas fa-check"></i> -->
        <img src="assets/icons/fas-fa-check.svg" />
      </div>
      {{ "SIGN_UP_FORM.ACCEPT" | translate }}&nbsp;
      <span (click)="download('termos_de_uso_global')">
        {{ "SIGN_UP_FORM.TERMS" | translate }} </span
      >&nbsp;{{ "SIGN_UP_FORM.AND" | translate }}&nbsp;
      <span (click)="download('politica_privacidade')">{{
        "SIGN_UP_FORM.PRIVACY" | translate
      }}</span>
    </div>
    <button
      class="btn btn-blue"
      (click)="createAccount()"
      data-cy="RegisterBtnRegister"
    >
      <span *ngIf="!state?.loginLoading" data-cy="LoginBtnContinuar">{{
        "BUTTON.CONTINUE" | translate
      }}</span>
      <div *ngIf="state?.loginLoading" class="text-center">
        <div class="spinner-border spinner-border-md" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="alert" *ngIf="alert">{{ alert }}</div>
    </button>
  </cls-main-view>

  <cls-main-view
    *ngIf="viewMode === 'cupom'"
    [ngClass]="viewMode"
    [background]="'carousel'"
  >
    <img class="liga-login" src="assets/imgs/liga-login1.png" />
    <div class="txt-blue capitalize">
      {{ "SIGN_UP_FORM.CREATE_ACCOUNT" | translate }}
    </div>
    <div class="cadastrado capitalize">
      {{ "SIGN_UP_FORM.STUDANT" | translate
      }}<span
        (click)="
          changeView('login'); background = 'assets/imgs/foto-login-liga.png'
        "
        >{{ "SIGN_UP_FORM.CLICK" | translate }}</span
      >
    </div>
    <textarea
      [(ngModel)]="selectedCupom"
      (keydown)="changedTextarea($event)"
      [class.pad]="setPadding"
      (focus)="textareaPlaceholder = ''"
      [placeholder]="textareaPlaceholder"
      (click)="setPadding = true"
      data-cy="LoginTxtDigiteCupom"
    ></textarea>
    <div class="select-container">
      <select
        class="form-control"
        [(ngModel)]="selectedIDOfertaCupom"
        (change)="checkClearAlert()"
        [class.visible]="arrayOfertas?.length > 0"
        data-cy="LoginSelectCursoCupom"
      >
        <option value="null" disabled selected>
          {{ "TRIAL.SELECT_COURSE" | translate }}
        </option>
        <option
          *ngFor="let oferta of arrayOfertas"
          [value]="oferta.oferta_cupom_id_oferta_cupom"
        >
          {{ oferta.oferta_nome }}
        </option>
      </select>
      <i
        class="fas select-icon fa-chevron-down"
        [class.visible]="arrayOfertas?.length > 0"
      ></i>
    </div>
    <button
      class="btn btn-blue"
      (click)="validarCupomOuOferta()"
      data-cy="CupomBtnConfirm"
    >
      <span *ngIf="!state?.loginLoading" class="upper">{{
        "BUTTON.CONTINUE" | translate
      }}</span>
      <div *ngIf="state?.loginLoading" class="text-center">
        <div class="spinner-border spinner-border-md" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="alert" *ngIf="alert">{{ alert }}</div>
    </button>
  </cls-main-view>

  <cls-main-view
    *ngIf="viewMode === 'final'"
    [ngClass]="viewMode"
    [background]="background"
    [image]="image"
  >
    <img class="liga-login" src="assets/imgs/liga-login1.png" />
    <div class="congrats capitalize">{{ "TRIAL.CONGRATS" | translate }}</div>
    <div class="divisor"></div>
    <div class="sub-congrats capitalize">
      {{ "TRIAL.REGISTER_SUCCESS" | translate }}
    </div>
    <button
      class="btn btn-blue upper"
      (click)="cadastroConcluido()"
      data-cy="FinalBtnContinue"
    >
      {{ "BUTTON.CONTINUE" | translate }}
    </button>
  </cls-main-view>

  <cls-main-view
    *ngIf="viewMode === 'bem-vindo'"
    [ngClass]="viewMode"
    [background]="background"
  >
    <img class="liga-login" [src]="dadosEmpresaMatricula?.url_logo" />
    <div class="welcome capitalizes">
      {{ "TRIAL.WELCOME_TO_LIGA" | translate }}
    </div>
    <button
      class="btn btn-blue"
      (click)="videoOrLogin()"
      data-cy="BemVindoBtnLogin"
    >
      <span *ngIf="!state?.loginLoading" class="upper">{{
        "BUTTON.CONTINUE" | translate
      }}</span>
      <div *ngIf="state?.loginLoading" class="text-center">
        <div class="spinner-border spinner-border-md" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </button>
  </cls-main-view>
</div>
<cls-modal-video
  *ngIf="state?.modalVideo"
  (closed)="afterVideoLogin()"
  (mousedown)="checkCloseModal($event)"
>
</cls-modal-video>
<cls-confirm-modal
  *ngIf="state?.confirmModal"
  (confirmed)="validarCupom('semcupom', true)"
  (mousedown)="checkCloseModal($event)"
>
</cls-confirm-modal>
