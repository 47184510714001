<div class="home-container">
    <div class="home-page">
        <cls-header></cls-header>
        <div class="overlay">
        </div>
            <cls-projeto [style.background-image]="global.generateImageURL(state?.projetoAtivo.foto_capa_web)">
            </cls-projeto>
        <div class="home-box-container" [style.min-height]="state.faseAtual ? '100px' : '500px'">
            <cls-home-box *ngFor="let fase of state.fases; let i=index" [data]="fase" [color]="colorArray[i]"
                routerLinkActive="" (click)="setSelectedFase(fase)"></cls-home-box>
        </div>
    </div>
    <router-outlet></router-outlet>
</div>