import { Component, OnInit } from '@angular/core';
import {showModalPesquisaSucesso} from 'src/app/state/state.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { PerguntaPesquisaNps, PesquisaNps } from 'src/app/models/pesquisa-nps.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cls-modal-pesquisa-sucesso',
  templateUrl: './modal-pesquisa-sucesso.component.html',
  styleUrls: ['./modal-pesquisa-sucesso.component.scss']
})
export class ModalPesquisaSucessoComponent implements OnInit {

  constructor(
    private store: Store<AppState>,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.store.dispatch(showModalPesquisaSucesso({ payload: false }))
  }
}
