import { Component, OnInit, AfterContentInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { showInteracoes, showChat, turmaInteracoes, showForumTurma, interacoesLoading, selectedForumAtividade, selectedFaseForum, fillMensagens, showForumFreeTalk, delayAnswerOpen, fillSuportDetails, fillSuport, anterioresLoading } from 'src/app/state/state.actions';
import { DataService } from 'src/app/services/data-service/data.service';
import { GlobalService } from 'src/app/services/global-service/global.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { DataUtil } from "src/app/util/dataUtil";

@Component({
  selector: 'app-interacoes',
  templateUrl: './interacoes.component.html',
  styleUrls: ['./interacoes.component.scss'],
  animations: [
    trigger('Interacoes', [
      state('open', style({
        right: '0px'
      })),
      state('closed', style({
        right: '-613px'
      })),
      transition('open => closed', [
        animate('.5s 0ms ease-in-out')
      ]),
      transition('closed => open', [
        animate('.5s 0ms ease-in-out')
      ]),
    ]),
  ]
})
export class InteracoesComponent implements OnInit, AfterContentInit {

  dataUtil: DataUtil = new DataUtil()
  state: any;
  stateSubscription: any;
  fases = [];
  transition = 'closed';
  showAnteriores = true;
  showNovoAtendimento = false;
  newSuport = { assunto: '', titulo: '', data_abertura: null, id_projeto_turma_estudante: null, id_suporte_categoria: 3, id_suporte_status: 1, id_solicitacao: null };
  statusMatriculado = false;
  SOLICITACOES_FALE_COM_PROF_PARENT_ID = 402746 // PARENT ID DO MOVIDESK
  solicitacoesOptions: any[];
  projetoVigente: any = {
    vigente: null,
    dataFim: ''
  }
  constructor(
    private store: Store<AppState>,
    private dataService: DataService,
    public global: GlobalService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.getState();
    this.setStatusMatricula();
    this.delayOpenOfAnswers();
    this.dataService.getAndSetSuportMessages();
    this.dataService.getFaleComProfessorSolicitacoes(this.state.projetoAtivo.empresa.id_empresa, this.SOLICITACOES_FALE_COM_PROF_PARENT_ID).pipe(map((data: any) => this.solicitacoesOptions = data.servicos)).subscribe()
  }

  ngAfterContentInit() {
    setTimeout(() => {
      if (this.state.showingInteracoes == 'open')
        this.transition = 'open';
    }, 300);
  }

  delayOpenOfAnswers() {
    setTimeout(() => {
      this.store.dispatch(delayAnswerOpen({ payload: true }));
    }, 870);
  }

  closeInteracoes() {
    this.store.dispatch(delayAnswerOpen({ payload: false }));
    this.transition = 'closed';
    setTimeout(() => {
      this.store.dispatch(showInteracoes({ payload: 'closed' }));
      this.store.dispatch(showChat({ payload: false }))
      document.querySelector('body').removeAttribute('class');
      this.closeInsideView();
    }, 500);
  }

  handleClickOutside(data) {
    if (this.state.imageViewerUrl) return
    setTimeout(() => {
      if (!(data.target.className.indexOf('pointer menu-label interacoes-animation') !== -1 || data.target.className.indexOf('btn btn-avaliacao') !== -1))
        this.closeInteracoes()
    }, 100);
  }

  getState() {
    this.stateSubscription = this.store.select((state: any) => {
      const { showingInteracoes, projetosAluno, interacoesLoading, projetoAtivo, usuario, estudante, fases, turmaInteracoes, showForumTurma, selectedForumAtividade, selectedFaseForum, mensagens, duvidas, insideDuvida, showForumFreeTalk, mensagensTurma, id_forum_turma, delayAnswerOpen, suport, suportDetails, anterioresLoading, estudanteStatus, imageViewerUrl } = state.newAppState;
      this.state = { showingInteracoes, projetosAluno, interacoesLoading, projetoAtivo, usuario, estudante, fases, turmaInteracoes, showForumTurma, selectedForumAtividade, selectedFaseForum, mensagens, duvidas, insideDuvida, showForumFreeTalk, mensagensTurma, id_forum_turma, delayAnswerOpen, suport, suportDetails, anterioresLoading, estudanteStatus, imageViewerUrl }
    }).subscribe();
    const today = new Date();
    this.projetoVigente.dataFim = new Date(this.state.projetoAtivo.data_fim);
    this.projetoVigente.vigente = today < this.projetoVigente.dataFim;
  }

  setStatusMatricula() {
    this.state.projetosAluno.forEach(projeto => {
      if (this.state.projetoAtivo.id_projeto_turma == projeto.id_projeto_turma && (this.state.estudanteStatus === 'matriculado' || this.state.estudanteStatus === 'trial')) {
        this.statusMatriculado = true;
      }
    })
  }

  setView(view) {
    if (view == 'professor') {
      this.store.dispatch(showForumTurma({ payload: false }));
      this.store.dispatch(turmaInteracoes({ payload: true }));
    }
    else {
      this.store.dispatch(showForumTurma({ payload: false }));
      this.store.dispatch(turmaInteracoes({ payload: false }));
    }
  }

  getAtividadesMesaByFase(fase) {
    let atividades = [];
    this.state.fases.forEach(fs => {
      if (fs.id_fase_turma == fase.id_fase_turma) {
        fs.objetivosAprendizagem.forEach(objetivo => {
          objetivo.atividadeRoteiroTurma.forEach(atividade => {
            if (atividade.tipo == 'mesa')
              atividades.push(atividade);
          });
        });
      }
    });
    return atividades;
  }

  showForumByAtividade(atividade, fase) {
    const dataInicio = new Date(fase.data_inicio)
    if(!fase.aberta && this.state.estudante.id_projeto_turma_estudante_status !== 8) return this.global.toast(this.translate.instant("GENERAL.NOTICE"), `${this.translate.instant("GENERAL.PHASE_NOT_STARTED")} ${dataInicio.toLocaleDateString()}.`)

    if (atividade.forum && atividade.forum.length > 0) {
      this.dataService.getAndDispatchMessages(atividade.forum[0].id_forum).toPromise().then((data: any) => {
        data?.mensagens.forEach((msg) => {
          msg.mensagem = this.dataUtil.urlToAnchors(msg.mensagem)
        })
        this.store.dispatch(fillMensagens({ payload: data.mensagens }))
        this.store.dispatch(showForumTurma({ payload: true }));
        this.store.dispatch(selectedForumAtividade({ payload: atividade }));
        this.store.dispatch(selectedFaseForum({ payload: fase }));
      }, error => {
        console.log(error);
        this.store.dispatch(interacoesLoading({ payload: false }));
        this.global.toast(this.translate.instant("GENERAL.ERROR"), error.error.message);
      })
    } else
      this.global.toast(this.translate.instant("GENERAL.ERROR"), this.translate.instant("GENERAL.NO_FORUM"));
  }

  showFreeTalk() {
    this.store.dispatch(showForumFreeTalk({ payload: true }));
  }

  closeInsideView() {
    this.store.dispatch(showForumTurma({ payload: false }));
    this.store.dispatch(showForumFreeTalk({ payload: false }));
    this.store.dispatch(selectedForumAtividade({ payload: null }));
    this.store.dispatch(selectedFaseForum({ payload: null }));
  }

  sendNewTicket() {
    switch(true){
      case (this.state && this.state.estudanteStatus === 'trial'):
        this.global.toast(this.translate.instant("GENERAL.NOTICE"), this.translate.instant("GENERAL.TRIAL_DEFAULT"));
        break
      case (!this.projetoVigente.vigente):
        // this.global.toast(this.translate.instant("GENERAL.NOTICE"), this.translate.instant("GENERAL.END_OF_PROJECT"))
        this.global.toast(this.translate.instant("GENERAL.NOTICE"), `${this.translate.instant("GENERAL.END_OF_PROJECT")} ${this.projetoVigente.dataFim.toLocaleDateString()}.`)
        break
      case (!!this.newSuport.id_solicitacao && !!this.newSuport.assunto):
        this.newSuport.data_abertura = moment(new Date()).toISOString();
        this.newSuport.titulo = this.solicitacoesOptions.filter(option => option.id == this.newSuport.id_solicitacao).pop().text;
        this.newSuport.id_projeto_turma_estudante = this.state.projetoAtivo.estudante.id_projeto_turma_estudante;
        this.dataService.createTicket(this.newSuport);
        this.newSuport = { assunto: '', titulo: '', data_abertura: null, id_projeto_turma_estudante: null, id_suporte_categoria: 3, id_suporte_status: 1, id_solicitacao: null };
        break
      default:
        this.global.toast(this.translate.instant("GENERAL.ATTENTION"), this.translate.instant("INTERACTIONS.FIELDS"));
        break
    }
  }

  solveTicket(idSuporteStatus: number) {
    let solveObj = {
      id_suporte_status: idSuporteStatus,
      id_suporte: this.state.suportDetails.suporte_id_suporte,
      mensagem: null
    }
    this.dataService.solveSuportTicket(solveObj).then(
      data => {
        this.global.toast(this.translate.instant("GENERAL.SUCCESS"), this.translate.instant("INTERACTIONS.SOLVED"));
        this.store.dispatch(fillSuportDetails({ payload: data }));
        this.updateSuportStateArray(data);
      });
  }

  updateSuportStateArray(data) {
    let tempArr = this.state.suport;
    tempArr.forEach(temp => {
      if (temp.suporte_id_suporte == data.suporte_id_suporte)
        temp.suporte_status_final = true;
    });
    this.store.dispatch(fillSuport({ payload: tempArr }));
  }

  sendMessageOutput(event) {
    let form = event.form;
    form.append('id_suporte_status', 2);
    form.append('id_suporte', this.state.suportDetails.suporte_id_suporte);
    form.append('respondido_pelo_professor', false);
    this.dataService.sendSuportMessage(form).then(
      data => {
        this.store.dispatch(fillSuportDetails({ payload: data }));
        this.global.scrollToBottomViaID("scroll-interacoes");
        this.dataService.getAndSetSuportDetails(event.messages);
      });
  }

  goToDetails(suporte) {
    this.store.dispatch(anterioresLoading({ payload: suporte.suporte_id_suporte }));
    this.dataService.getAndSetSuportDetails(suporte);
  }

  closeDetails() {
    this.store.dispatch(fillSuportDetails({ payload: null }));
  }

}
