import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { OrderPipe } from 'ngx-order-pipe';
import { Params, Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import * as moment from 'moment';
import { GlobalService } from '../global-service/global.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RespostaPesquisaNps } from 'src/app/models/pesquisa-nps.model';

import {
	anterioresLoading,
	clearState,
	entregaLoading,
	fillActiveProject,
	fillCasosSucesso,
	fillCatCompetencia,
	fillCatExperiencia,
	fillCatFormacao,
	fillCatIdioma,
	fillCatPerfil,
	fillCatTestePerfil,
	fillDadosEmpresa,
	fillDadosPolo,
	fillEmpresas,
	fillEncontroAgendado,
	fillEstudanteStatus,
	fillEventos,
	fillFaleComTurma,
	fillFaseAtual,
	fillFases,
	fillIDForumTurma,
	fillMarcos,
	fillNotificationBadge,
	fillPolos,
	fillProjetosAluno,
	fillSoftSkills,
	fillStudentData,
	fillSuport,
	fillSuportDetails,
	fillToken,
	fillUserData,
	getFromSession,
	loginLoading,
	photoLoading,
	toggleLoginAlert,
	toggleMenuFase,
} from 'src/app/state/state.actions';
import { TranslateService } from '@ngx-translate/core';
import { DataUtil } from 'src/app/util/dataUtil';

@Injectable({
	providedIn: 'root',
})
export class DataService {
	gatewayAPI = environment.api;
	gatewayNew = environment.authApi;
	token: any;
	state: any;

	uploadForm: FormGroup;

	dataUtil: DataUtil = new DataUtil();

	constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private orderPipe: OrderPipe,
    private router: Router,
    private global: GlobalService,
    private formBuilder: FormBuilder,
    private translate: TranslateService
	) {
		this.updateComponentState();
	}

	refreshUserData() {
		this.http
			.get(
				this.gatewayAPI +
          'usuario/' +
          this.state.usuario.id_usuario +
          '/curriculos',
				this.getHeader()
			)
			.subscribe(
				(data: any) => {
					this.refreshCurriculoCategories(data);
					this.setTestePerfil(data.testeDePerfil);
					this.store.dispatch(fillUserData({ payload: data }));
				},
				(error) => {
					this.global.toast(
						this.translate.instant('GENERAL.ERROR'),
						error.error.message
					);
				}
			);
	}

	refreshCurriculoCategories(data) {
		data.curriculoUsuario[0].categorias.forEach((categoria) => {
			if (categoria.titulo == 'Formação')
				this.store.dispatch(fillCatFormacao({ payload: categoria.entradas }));
			if (categoria.titulo == 'Competência')
				this.store.dispatch(
					fillCatCompetencia({ payload: categoria.entradas })
				);
			if (categoria.titulo == 'Idioma')
				this.store.dispatch(fillCatIdioma({ payload: categoria.entradas }));
			if (categoria.titulo == 'Experiência')
				this.store.dispatch(
					fillCatExperiencia({ payload: categoria.entradas })
				);
			if (categoria.titulo == 'Perfil')
				this.store.dispatch(fillCatPerfil({ payload: categoria.entradas }));
		});
	}

	automaticLogin(token, id_usuario) {
		this.store.dispatch(fillToken({ payload: token }));
		this.http
			.get(this.gatewayAPI + 'usuario/' + id_usuario, this.getHeader())
			.subscribe(
				(data) => {
					const sessionState = JSON.parse(sessionStorage.getItem('state'));
					this.store.dispatch(getFromSession({ payload: sessionState }));
					this.setNoScrollBar(sessionState);
					this.store.dispatch(loginLoading({ payload: false }));
					this.afterLoginRoutine(data);
					if (this.state.lastRoute)
						this.router.navigate([this.state.lastRoute]);
					else this.router.navigate(['perfil']);
				},
				(error) => {
					console.log(error);
					this.store.dispatch(loginLoading({ payload: false }));
					if (error.status == 0)
						this.global.toast(
							this.translate.instant('GENERAL.ERROR'),
							this.translate.instant('GENERAL.NO_INTERNET')
						);
					else if (error.error && error.error.code == 'refreshtoken')
						this.refreshTokenRoutine();
					else {
						this.logout();
					}
					this.store.dispatch(fillToken({ payload: null }));
				}
			);
	}

	doSingleSignOnLogin(credentials) {
		let url = '';
		if ('hash' in credentials && credentials.hash !== null) {
			url =
        this.gatewayNew +
        `auth/sso/${credentials.hash}&email=${credentials.email}`;
			return this.http.post(url, {});
		}
		url =
      this.gatewayNew +
      'auth/login/' +
      credentials.key +
      '/' +
      credentials.user;
		return this.http.post(url, {});
	}

	getToken(credentials) {
		const headers = { headers: { 'Access-Control-Allow-Origin': '*' } };
		return this.http.post(this.gatewayNew + 'auth/login', credentials, headers);
	}

	getUsuarioByIdentificador(identificador) {
		return this.http.get(
			this.gatewayNew + 'auth/dados-perfil-usuario/' + identificador,
			this.getHeader()
		);
	}

	checkFirstLogin(credentials) {
		const headers = { headers: { 'Access-Control-Allow-Origin': '*' } };
		return this.http.post(
			this.gatewayNew + 'auth/deprecated/login',
			credentials,
			headers
		);
	}

	afterLoginRoutine(data) {
		if (!data || !data.refreshToken || !data.token) return;
		sessionStorage.setItem('token', data.token);
		sessionStorage.setItem('refreshtoken', data.refreshToken);
		this.store.dispatch(toggleMenuFase({ payload: true }));
		this.store.dispatch(fillToken({ payload: data.token }));
	}

	setLanguage(data = null) {
		if (data && data.usuario) {
			this.translate.setDefaultLang(
				data.usuario.idioma_aplicacao.toLowerCase()
			);
			this.translate.currentLang = data.usuario.idioma_aplicacao.toLowerCase();
		} else if (this.state && this.state.usuario) {
			this.translate.setDefaultLang(
				this.state.usuario.idioma_aplicacao.toLowerCase()
			);
			this.translate.currentLang =
        this.state.usuario.idioma_aplicacao.toLowerCase();
		}
	}

	sendUltimoAcesso(tipoAcesso: string, instance: number | null) {
		const obj = {
			sistema: this.global.getOSName(),
			agente: this.global.getBrowserName(),
			acesso_tipo: tipoAcesso,
			instance,
		};
		this.http
			.post(this.gatewayAPI + 'acesso/salvar-acesso', obj, this.getHeader())
			.subscribe(
				(data) => {
					return EMPTY;
				},
				(error) =>
					this.global.toast(
						this.translate.instant('GENERAL.ERROR'),
						error.error.message
					)
			);
	}

	logAccess(id_projeto_turma): void {
		this.sendUltimoAcesso('projeto', id_projeto_turma);
	}

	setTestePerfil(teste_de_perfil: any): void {
		this.store.dispatch(fillCatTestePerfil({ payload: teste_de_perfil }));
	}

	setInitialDataAndNavigate(projeto) {
		this.logAccess(projeto.id_projeto_turma);
		this.store.dispatch(fillStudentData({ payload: projeto.estudante }));
		this.store.dispatch(fillFases({ payload: null }));
		this.store.dispatch(
			fillEstudanteStatus({
				payload: projeto.estudante.id_projeto_turma_estudante_status,
			})
		);
		this.store.dispatch(fillActiveProject({ payload: projeto }));
		this.getAndDispatchFases();
		this.getAndDispatchMarcos();
		this.router.navigate(['projeto/' + projeto.id_projeto_turma]);
	}

	setProjectInitialData(projeto) {
		this.logAccess(projeto.id_projeto_turma);
		this.store.dispatch(fillStudentData({ payload: projeto.estudante }));
		this.store.dispatch(fillFases({ payload: null }));
		this.store.dispatch(
			fillEstudanteStatus({
				payload: projeto.estudante.id_projeto_turma_estudante_status,
			})
		);
		this.store.dispatch(fillActiveProject({ payload: projeto }));
	}

	getProjetosAluno() {
		let response: any;
		this.http
			.get(
				this.gatewayAPI +
          'usuario/' +
          this.state.usuario.id_usuario +
          '/projetos',
				this.getHeader()
			)
			.subscribe(
				(projetos) => {
					response = projetos;
					this.store.dispatch(fillSoftSkills({ payload: response.softSkills }));
					this.store.dispatch(
						fillCasosSucesso({ payload: response.casoSucesso })
					);
					if (
						response &&
            response.meusProjetos &&
            response.meusProjetos.length > 0
					) {
						this.verifyTrialAndDispatch(response.meusProjetos);
						this.store.dispatch(
							fillProjetosAluno({ payload: response.meusProjetos })
						);
					}
				},
				(error) => {
					console.log(error);
					this.global.toast(
						this.translate.instant('GENERAL.ERROR'),
						error.error.message
					);
				}
			);
	}

	getSectionHome(section): Observable<any> {
		return this.http.get(
			`${this.gatewayAPI}usuario/${this.state.usuario.id_usuario}/home/${section}`,
			this.getHeader()
		);
	}

	getSectionDetails(section): Observable<any> {
		return this.http.get(
			`${this.gatewayAPI}usuario/${this.state.usuario.id_usuario}/detalhes/${section}`,
			this.getHeader()
		);
	}

	getProjetosAlunoCards() {
		let response: any;
		this.http
			.get(
				this.gatewayAPI +
          'usuario/' +
          this.state.usuario.id_usuario +
          '/projetos-cards',
				this.getHeader()
			)
			.subscribe(
				(projetos) => {
					response = projetos;
					if (
						response &&
            response.meusProjetos &&
            response.meusProjetos.length == 0
					) {
						this.logout();
						this.global.toast(
							this.translate.instant('LOGIN.SORRY'),
							this.translate.instant('LOGIN.NO_PROJECTS')
						);
					}
					response.meusProjetos.sort((a, b) => {
						return (
							new Date(b.data_inicio).getTime() -
              new Date(a.data_inicio).getTime()
						);
					});
					if (
						response &&
            response.meusProjetos &&
            response.meusProjetos.length > 0
					) {
						this.verifyTrialAndDispatch(response.meusProjetos);
						this.store.dispatch(
							fillProjetosAluno({ payload: response.meusProjetos })
						);
					}
					this.store.dispatch(fillSoftSkills({ payload: response.softSkills }));
					this.store.dispatch(
						fillCasosSucesso({ payload: response.casoSucesso })
					);
					this.store.dispatch(fillEventos({ payload: response.eventos }));
				},
				(error) => {
					console.log(error);
					this.global.toast(
						this.translate.instant('GENERAL.ERROR'),
						error.error.message
					);
				}
			);
	}

	verifyTrialAndDispatch(projetos) {
		projetos.forEach((projeto) => {
			if (projeto.estudante.id_projeto_turma_estudante_status === 7)
				this.store.dispatch(
					fillEstudanteStatus({
						payload: projeto.estudante.id_projeto_turma_estudante_status,
					})
				);
		});
	}

	navigateToPerfil() {
		this.router.navigate(['perfil']);
		this.store.dispatch(loginLoading({ payload: false }));
	}

	logout() {
		sessionStorage.clear();
		this.store.dispatch(clearState());
		this.router.navigate(['login']);
		this.translate.setDefaultLang(environment.defaultLanguage);
		this.translate.currentLang = environment.defaultLanguage;
	}

	refreshTokenRoutine() {
		const refresh = { refresher: sessionStorage.getItem('refreshtoken') };
		this.http.post(this.gatewayNew + 'auth/refreshtoken', refresh).subscribe(
			(data: any) => {
				this.afterLoginRoutine(data);
				return EMPTY;
			},
			(error) => {
				console.log(error);
				this.global.toast(
					this.translate.instant('GENERAL.ERROR'),
					error.error.message
				);
				this.logout();
			}
		);
	}

	setNoScrollBar(state) {
		if (
			state.showingAgenda == 'open' ||
      state.showingInteracoes == 'open' ||
      state.showingMenu ||
      state.showSaibaMais
		)
			document.querySelector('body').setAttribute('class', 'no-scrollbar');
		else document.querySelector('body').removeAttribute('class');
	}

	initialRoutine(usuario) {
		sessionStorage.setItem('id_usuario', usuario.id_usuario);
		this.setLanguage({ usuario });
		usuario.usuarioCompetencia = usuario.competencias;
		delete usuario.competencias;
		document.querySelector('body').removeAttribute('class');
		this.store.dispatch(fillUserData({ payload: usuario }));
		this.navigateToPerfil();
	}

	updateComponentState() {
		const sessionData = JSON.parse(sessionStorage.getItem('state'));
		this.store
			.select((state: any) => {
				if (sessionData && sessionData.usuario && !state.newAppState.usuario)
					this.state = sessionData;
				else this.state = state.newAppState;
				sessionStorage.setItem('state', JSON.stringify(this.state));
			})
			.subscribe();
	}

	getCurriculoAluno() {
		let response;
		this.http
			.get(
				this.gatewayAPI +
          'usuario/' +
          this.state.usuario.id_usuario +
          '/curriculos',
				this.getHeader()
			)
			.subscribe(
				(data: any) => {
					response = data;
					this.setTestePerfil(response.testeDePerfil);
					this.store.dispatch(fillUserData({ payload: response }));
					this.setCurriculoCategorias();
				},
				(error) => {
					this.store.dispatch(
						toggleLoginAlert({ payload: error.error.message })
					);
				}
			);
	}

	setCurriculoCategorias() {
		if (!this.state.usuario.curriculoUsuario[0].categorias) return;
		this.state.usuario.curriculoUsuario[0].categorias.forEach((categoria) => {
			if (categoria.titulo == 'Formação')
				this.store.dispatch(fillCatFormacao({ payload: categoria.entradas }));
			if (categoria.titulo == 'Competência')
				this.store.dispatch(
					fillCatCompetencia({ payload: categoria.entradas })
				);
			if (categoria.titulo == 'Idioma')
				this.store.dispatch(fillCatIdioma({ payload: categoria.entradas }));
			if (categoria.titulo == 'Experiência')
				this.store.dispatch(
					fillCatExperiencia({ payload: categoria.entradas })
				);
			if (categoria.titulo == 'Perfil')
				this.store.dispatch(fillCatPerfil({ payload: categoria.entradas }));
		});
	}

	getDocument(doc) {
		return this.http.get(this.gatewayAPI + 'sistema/' + doc, this.getHeader());
	}

	updateCurriculo(curriculo) {
		return this.http
			.post(
				this.gatewayAPI +
          'usuario/' +
          this.state.usuario.id_usuario +
          '/curriculos',
				curriculo,
				this.getHeader()
			)
			.toPromise();
	}

	getAndDispatchFases() {
		this.http
			.get(
				this.gatewayAPI +
          'projeto/dados-projeto/' +
          this.state.projetoAtivo.id_projeto_turma,
				this.getHeader()
			)
			.subscribe(
				(fases: any) => {
					if (fases.projeto) {
						this.store.dispatch(
							fillFases({
								payload: this.orderPipe.transform(
									fases.projeto.faseTurma,
									'id_fase_turma'
								),
							})
						);
						this.getAndSetEntregasAluno();
					} else
						this.global.toast(
							'Aviso!',
							this.translate.instant('GENERAL.PHASE_EMPTY')
						);
				},
				(error) => {
					console.log(error);
					if (error.error.message === 'Usuário inativo!') {
						this.logout();
					}
					this.global.toast(
						this.translate.instant('GENERAL.ERROR'),
						error.error.message
					);
				}
			);
	}

	getFases() {
		return this.http.get(
			this.gatewayAPI +
        'projeto/dados-projeto/' +
        this.state.projetoAtivo.id_projeto_turma,
			this.getHeader()
		);
	}

	sendMessageToForum(msg): Observable<any> {
		const headers = {
			'Content-Type': 'multipart/form-data',
			...this.getHeader(),
		};
		return this.http.post(
			this.gatewayAPI + 'interacoes/criar-mensagem-forum',
			msg,
			headers
		);
	}

	createTicket(ticket) {
		this.http
			.post(this.gatewayAPI + 'suporte', ticket, this.getHeader())
			.subscribe(
				(data) => {
					const temp = this.state.suport;
					temp.unshift(data);
					this.store.dispatch(fillSuport({ payload: temp }));
					this.global.toast(
						this.translate.instant('GENERAL.SUCCESS'),
						this.translate.instant('DATA_SERVICE.TICKET_OPEN')
					);
				},
				(error) => {
					console.log(error);
					this.global.toast(
						this.translate.instant('GENERAL.ERROR'),
						error.error.message
					);
				}
			);
	}

	createTicketAndMessage(ticket) {
		const headers = {
			'Content-Type': 'multipart/form-data',
			...this.getHeader(),
		};
		this.uploadForm = this.formBuilder.group({ arquivo: null });
		const formData = new FormData();
		this.uploadForm.get('arquivo').setValue(ticket.arquivo);

		if (this.uploadForm.get('arquivo').value)
			formData.append(
				'arquivo',
				this.uploadForm.get('arquivo').value,
				ticket.arquivo.name
			);

		formData.append('id_suporte_status', '1');
		formData.append('id_suporte_categoria', ticket.id_suporte_categoria);
		formData.append('id_suporte_tipo', ticket.id_suporte_tipo);
		formData.append(
			'id_projeto_turma_estudante',
			ticket.id_projeto_turma_estudante
		);
		formData.append('assunto', ticket.assunto);
		formData.append('primeiraMensagem', ticket.mensagem);

		this.http.post(this.gatewayAPI + 'suporte', formData, headers).subscribe(
			(data: any) => {
				this.global.toast(
					this.translate.instant('GENERAL.SUCCESS'),
					this.translate.instant('DATA_SERVICE.ATENDIMENTO_OPEN')
				);
				this.global.updateDatatable();
			},
			(error) => {
				console.log(error);
				this.global.toast(
					this.translate.instant('GENERAL.ERROR'),
					error.error.message
				);
			}
		);
	}

	getAtendimentoSecretariaDetails(item) {
		return this.http
			.get(
				this.gatewayAPI +
          'suporte/secretaria/' +
          item.suporte_id_suporte +
          '/detalhes',
				this.getHeader()
			)
			.toPromise();
	}

	getAndSetSuportDetails(suporte: any, type = 'falecomprofessor') {
		const endpoint = `suporte/${type}/${suporte.suporte_id_suporte}/detalhes`;
		this.http.get(this.gatewayAPI + endpoint, this.getHeader()).subscribe(
			(dados: any) => {
				dados?.detalhes.forEach((detalhe) => {
					detalhe.mensagem = this.dataUtil.urlToAnchors(detalhe.mensagem);
				});
				this.store.dispatch(fillSuportDetails({ payload: dados }));
				this.store.dispatch(anterioresLoading({ payload: null }));
			},
			(error) => {
				this.store.dispatch(anterioresLoading({ payload: null }));
				console.error(error);
				this.global.toast(
					this.translate.instant('GENERAL.ERROR'),
					error.error.message
				);
			}
		);
	}

	sendSuportMessage(body: any, type = 'falecomprofessor') {
		const headers = {
			'Content-Type': 'multipart/form-data',
			...this.getHeader(),
		};
		const endpoint = `suporte/${type}/${body.get('id_suporte')}/mensagem`;
		return this.http
			.post(this.gatewayAPI + endpoint, body, headers)
			.toPromise();
	}

	sendSecretariaMessage(body) {
		const headers = {
			'Content-Type': 'multipart/form-data',
			...this.getHeader(),
		};
		return this.http
			.post(
				this.gatewayAPI +
          'suporte/secretaria/' +
          body.get('id_suporte') +
          '/mensagem',
				body,
				headers
			)
			.toPromise();
	}

	solveSuportTicket(body: any, type = 'falecomprofessor') {
		const endpoint = `suporte/${type}/${body.id_suporte}/mensagem`;
		return this.http
			.post(this.gatewayAPI + endpoint, body, this.getHeader())
			.toPromise();
	}

	markAsRead(_id) {
		this.http
			.post(
				this.gatewayAPI +
          'notification/student/' +
          this.state.estudante.id_estudante +
          '/markasunread',
				{ ids: [_id] },
				this.getHeader()
			)
			.subscribe(
				(data) => {
					this.store.dispatch(fillNotificationBadge({ payload: false }));
				},
				(error) => {
					console.log(error);
					this.global.toast(
						this.translate.instant('GENERAL.ERROR'),
						error.error.message
					);
				}
			);
	}

	setNotificationBadge(notifications) {
		let hasNotification = false;
		notifications.forEach((notification) => {
			if (notification.recipients[0].read) hasNotification = true;
		});
		this.store.dispatch(fillNotificationBadge({ payload: hasNotification }));
	}

	getAndSetEntregasAluno() {
		let response: any;
		const updatedFases = [];
		let cont = 0;
		this.state.fases.forEach(
			(fase) => {
				this.http
					.get(
						this.gatewayAPI +
              'fase-turma/dados-fase-turma/' +
              fase.id_fase_turma +
              '?id_estudante=' +
              this.state.estudante.id_estudante,
						this.getHeader()
					)
					.subscribe((dados) => {
						cont++;
						response = dados;
						updatedFases.push(response.faseTurma[0]);
						if (this.state?.fases?.length == cont)
							this.store.dispatch(
								fillFases({
									payload: this.orderPipe.transform(
										updatedFases,
										'id_fase_turma'
									),
								})
							);
					});
			},
			(error) => {
				this.global.toast(
					this.translate.instant('GENERAL.ERROR'),
					error.error.message
				);
			}
		);
	}

	updateFaseDataAluno() {
		this.http
			.get(
				this.gatewayAPI +
          'fase-turma/dados-fase-turma/' +
          this.state.faseAtual.id_fase_turma +
          '?id_estudante=' +
          this.state.estudante.id_estudante,
				this.getHeader()
			)
			.subscribe(
				(response: any) => {
					const updatedFases = this.state.fases;
					updatedFases.forEach((fase) => {
						if (fase.id_fase_turma == this.state.faseAtual.id_fase_turma)
							fase = response.faseTurma[0];
					});
					this.store.dispatch(fillFases({ payload: updatedFases }));
					this.store.dispatch(
						fillFaseAtual({ payload: response.faseTurma[0] })
					);
				},
				(error) => {
					this.global.toast(
						this.translate.instant('GENERAL.ERROR'),
						error.error.message
					);
				}
			);
	}

	completeAtividade(objetivo, atividade, concluido) {
		let response: any;
		const requestAtividade = {
			concluido: concluido,
			data_conclusao: moment(new Date().toUTCString()).toISOString(),
			id_atividade_roteiro_turma: atividade.id_atividade_roteiro_turma,
			id_estudante: this.state.estudante.id_estudante,
		};
		this.http
			.post(
				this.gatewayAPI + 'fase-turma/atividade/entrega',
				requestAtividade,
				this.getHeader()
			)
			.subscribe(
				(data) => {
					response = data;
					atividade.atividadeEstudanteTurma[0] =
            response.atividadeEstudanteTurma;
					this.updateAtividadeState(objetivo, concluido);
					if (concluido)
						this.global.toast(
							this.translate.instant('GENERAL.SUCCESS'),
							this.translate.instant('DATA_SERVICE.ACTIVITY_CHECK')
						);
					else
						this.global.toast(
							this.translate.instant('GENERAL.SUCCESS'),
							this.translate.instant('DATA_SERVICE.ACTIVITY_UNCHECK')
						);
				},
				(error) => {
					this.global.toast(
						this.translate.instant('GENERAL.ERROR'),
						error.error.message
					);
				}
			);
	}

	likeAtividade(atividade, concluido): Observable<any> {
		const requestAtividade = {
			concluido: concluido,
			data_conclusao: moment(new Date().toUTCString()).toISOString(),
			id_atividade_roteiro_turma: atividade.id_atividade_roteiro_turma,
			id_estudante: this.state.estudante.id_estudante,
		};

		return this.http.post(
			this.gatewayAPI + 'fase-turma/atividade/like',
			requestAtividade,
			this.getHeader()
		);
	}

	deslikeAtividade(atividade, concluido): Observable<any> {
		const requestAtividade = {
			concluido: concluido,
			data_conclusao: moment(new Date().toUTCString()).toISOString(),
			id_atividade_roteiro_turma: atividade.id_atividade_roteiro_turma,
			id_estudante: this.state.estudante.id_estudante,
		};

		return this.http.post(
			this.gatewayAPI + 'fase-turma/atividade/deslike',
			requestAtividade,
			this.getHeader()
		);
	}

	updateAtividadeState(objetivo, concluido) {
		const tempFaseAtual = this.state.faseAtual;
		tempFaseAtual.objetivosAprendizagem.forEach((obj) => {
			if (obj.id_objetivo_aprendizagem == objetivo.id_objetivo_aprendizagem) {
				obj = objetivo;
				if (concluido) obj.totalAtividadesConcluidas++;
				else obj.totalAtividadesConcluidas--;
			}
		});
		this.store.dispatch(fillFaseAtual({ payload: tempFaseAtual }));
	}

	sendEntrega(entrega) {
		let response;
		if (entrega) {
			const headers = {
				'Content-Type': 'multipart/form-data',
				...this.getHeader(),
			};

			entrega.append('id_estudante', this.state.estudante.id_estudante);
			entrega.append('id_fase_turma', this.state.faseAtual.id_fase_turma);

			this.http
				.post(this.gatewayAPI + 'fase-turma/entrega', entrega, headers)
				.subscribe(
					(data) => {
						response = data;
						const fase = this.state.faseAtual;
						fase.arquivoEstudanteFaseTurma = [];
						fase.concluida = true;
						fase.arquivoEstudanteFaseTurma.push(
							response.arquivoEstudanteFaseTurma
						);
						this.store.dispatch(fillFaseAtual({ payload: fase }));
						this.global.toast(
							this.translate.instant('GENERAL.SUCCESS'),
							this.translate.instant('DATA_SERVICE.UPLOAD')
						);
						this.store.dispatch(entregaLoading({ payload: false }));
						this.updateEntregaFase();
					},
					(error) => {
						this.store.dispatch(entregaLoading({ payload: false }));
						console.log(error);
						this.global.toast(
							this.translate.instant('GENERAL.ERROR'),
							error.error.message
						);
					}
				);
		}
	}

	updateEntregaFase() {
		const updatedFases = this.state.fases;
		updatedFases.forEach((fase) => {
			if (this.state.faseAtual.id_fase_turma == fase.id_fase_turma)
				fase.concluida = true;
		});
		this.store.dispatch(fillFases({ payload: updatedFases }));
	}

	changePicture(foto) {
		let response: any;
		if (foto) {
			const headers = {
				'Content-Type': 'multipart/form-data',
				...this.getHeader(),
			};
			this.http
				.post(
					this.gatewayAPI +
            'usuario/' +
            this.state.usuario.id_usuario +
            '/changephoto',
					foto,
					headers
				)
				.subscribe(
					(data) => {
						response = data;
						this.updateUserImage(response.usuario.foto);
						this.global.toast(
							this.translate.instant('GENERAL.SUCCESS'),
							this.translate.instant('DATA_SERVICE.PHOTO')
						);
						this.store.dispatch(photoLoading({ payload: false }));
					},
					(error) => {
						console.log(error);
						this.store.dispatch(photoLoading({ payload: false }));
						this.global.toast(
							this.translate.instant('GENERAL.ERROR'),
							error.error.message
						);
					}
				);
		}
	}

	updateUserImage(foto) {
		const stUser = this.state.usuario;
		stUser.foto = foto;
		this.store.dispatch(fillUserData({ payload: stUser }));
	}

	checkEntregaByFase(id_fase_turma): Observable<any> {
		return this.http.get(
			this.gatewayAPI +
        'fase-turma/dados-fase-turma/' +
        id_fase_turma +
        '?id_estudante=' +
        this.state.estudante.id_estudante,
			this.getHeader()
		);
	}

	agendarOuReagendarEncontro(id_turma_agenda_horario, route) {
		let response: any;
		const encontro = {
			id_turma_agenda_horario: id_turma_agenda_horario,
			id_estudante: this.state.estudante.id_estudante,
		};
		this.http
			.post(this.gatewayAPI + route, encontro, this.getHeader())
			.subscribe(
				(data) => {
					response = data;
					if (response.encontro.id_encontro) {
						this.findAndSetEncontroAgendado(response.encontro);
						this.global.toast(
							this.translate.instant('GENERAL.SUCCESS'),
							this.translate.instant('NOTIFICATIONS.MEETING_SCHEDULED')
						);
					}
				},
				(error) => {
					this.global.toast(
						this.translate.instant('GENERAL.ERROR'),
						error.error.message
					);
				}
			);
	}

	cancelarEncontro(encontro) {
		let response: any;
		this.http
			.delete(
				this.gatewayAPI + 'agenda/cancelar-encontro/' + encontro.id_encontro,
				this.getHeader()
			)
			.subscribe(
				(data) => {
					response = data;
					if (response.cancelado) {
						this.global.toast(
							this.translate.instant('GENERAL.SUCCESS'),
							this.translate.instant('NOTIFICATIONS.MEETING_CANCEL')
						);
						this.store.dispatch(fillEncontroAgendado({ payload: null }));
						this.state.marcos.forEach((marco) => {
							marco.turmaAgenda[0].turmaAgendaHorario.forEach((horario) => {
								if (
									horario.id_turma_agenda_horario ==
                  encontro.id_turma_agenda_horario
								) {
									if (horario.id_encontro) delete horario.id_encontro;
									else if (horario.encontro.length > 0) horario.encontro = [];
								}
							});
						});
					}
				},
				(error) => {
					console.log(error);
					this.global.toast(
						this.translate.instant('GENERAL.ERROR'),
						error.error.message
					);
				}
			);
	}

	getAndSetSuportMessages() {
		this.http
			.get(
				this.gatewayAPI +
          'suporte/falecomprofessor/listar-suporte-aluno/' +
          this.state.projetoAtivo.estudante.id_projeto_turma_estudante,
				this.getHeader()
			)
			.subscribe(
				(response: any) => {
					this.store.dispatch(fillSuport({ payload: response }));
				},
				(error) => {
					if (error.error.message === 'Usuário inativo!') {
						this.logout();
					}
					this.global.toast(
						this.translate.instant('GENERAL.ERROR'),
						error.error.message
					);
				}
			);
	}

	setNewPassword(credentials) {
		let response: any;
		this.http
			.patch(
				this.gatewayAPI + 'actions/reset-password/save',
				credentials,
				this.getHeader()
			)
			.subscribe(
				(data) => {
					response = data;
					this.global.toast(
						this.translate.instant('GENERAL.SUCCESS'),
						this.translate.instant('DATA_SERVICE.PASSWORD')
					);
					setTimeout(() => {
						this.router.navigate(['login']);
						this.global.toast(
							this.translate.instant('GENERAL.SUCCESS'),
							this.translate.instant('DATA_SERVICE.CREDENTIALS')
						);
					}, 5200);
					this.store.dispatch(loginLoading({ payload: false }));
				},
				(error) => {
					this.store.dispatch(loginLoading({ payload: false }));
					this.global.toast(
						this.translate.instant('GENERAL.ERROR'),
						error.error.message
					);
				}
			);
	}

	resetPassword(cpf_email): Observable<any> {
		let obj = {};
		if (cpf_email.indexOf('@') != -1) obj = { email: cpf_email };
		else obj = { cpf: cpf_email };
		return this.http.post(
			this.gatewayAPI + 'actions/reset-password',
			obj,
			this.getHeader()
		);
	}

	checkCpf(cpf): Observable<any> {
		return this.http.post(
			this.gatewayAPI + 'actions/forget-email',
			{ cpf: cpf },
			this.getHeader()
		);
	}

	checkEmailAndSetEmpresa(email) {
		let response: any;
		this.http
			.get(this.gatewayAPI + 'usuario/' + email + '/empresas', this.getHeader())
			.subscribe(
				(data: any) => {
					response = data;
					if (response.dados.empresas.length > 0) {
						this.store.dispatch(
							fillDadosEmpresa({ payload: response.dados.empresas[0] })
						);
						this.store.dispatch(
							fillEmpresas({ payload: response.dados.empresas })
						);
						this.store.dispatch(
							fillDadosPolo({
								payload:
                  response.dados.polos.length > 0 ? data.dados.polos[0] : [],
							})
						);
						this.store.dispatch(fillPolos({ payload: data.dados.polos }));
					} else
						this.global.toast(
							this.translate.instant('GENERAL.ERROR'),
							this.translate.instant('GENERAL.NO_COMPANY')
						);
					this.store.dispatch(loginLoading({ payload: false }));
				},
				(error) => {
					this.store.dispatch(loginLoading({ payload: false }));
					this.store.dispatch(
						toggleLoginAlert({ payload: error.error.message })
					);
				}
			);
	}

	changePassword(passwords) {
		let response: any;
		this.http
			.post(
				this.gatewayAPI +
          'usuario/' +
          this.state.usuario.id_usuario +
          '/changePassword',
				passwords,
				this.getHeader()
			)
			.subscribe(
				(data) => {
					response = data;
					if (response.usuario.id_usuario)
						this.global.toast(
							this.translate.instant('GENERAL.SUCCESS'),
							this.translate.instant('DATA_SERVICE.PASSWORD')
						);
				},
				(error) => {
					console.log(error);
					this.global.toast(
						this.translate.instant('GENERAL.ERROR'),
						error.error.message
					);
				}
			);
	}

	findAndSetEncontroAgendado(encontro) {
		this.state.marcos.forEach((marco) => {
			marco.turmaAgenda[0].turmaAgendaHorario.forEach((horario) => {
				if (
					horario.id_turma_agenda_horario == encontro.id_turma_agenda_horario
				) {
					horario.id_encontro = encontro.id_encontro;
					this.store.dispatch(fillEncontroAgendado({ payload: horario }));
				}
			});
		});
	}

	getCalendario(month, year, idUsuario, idProjetoTurma = 0): Observable<any> {
		let url =
      this.gatewayAPI + `calendario/${month}/${year}/usuario/${idUsuario}`;
		if (idProjetoTurma) url += `/projeto/${idProjetoTurma}`;
		return this.http.get(url, this.getHeader());
	}

	getAndDispatchMarcos() {
		this.http
			.get(
				this.gatewayAPI +
          'agenda/listar-marcos-por-projeto/' +
          this.state.projetoAtivo.id_projeto_turma,
				this.getHeader()
			)
			.subscribe(
				(data: any) => {
					this.store.dispatch(fillMarcos({ payload: data.marcoTurma }));
				},
				(error) => {
					this.global.toast(
						this.translate.instant('GENERAL.ERROR'),
						error.error.message
					);
				}
			);
	}

	interacoesTurmaRoutine() {
		this.http
			.get(
				this.gatewayAPI +
          'interacoes/listar-interacoes-por-projeto/' +
          this.state.projetoAtivo.id_projeto_turma,
				this.getHeader()
			)
			.subscribe(
				(data: any) => {
					this.setMensagensTurmaViaForum(
						data.projetoTurma[0].forum[0].id_forum
					);
				},
				(error) => {
					this.global.toast(
						this.translate.instant('GENERAL.ERROR'),
						error.error.message
					);
				}
			);
	}

	setMensagensTurmaViaForum(id_forum) {
		this.getAndDispatchMessages(id_forum)
			.toPromise()
			.then((data: any) => {
				data?.mensagens.forEach((msg) => {
					msg.mensagem = this.dataUtil.urlToAnchors(msg.mensagem);
				});
				this.store.dispatch(fillFaleComTurma({ payload: data.mensagens }));
				this.store.dispatch(fillIDForumTurma({ payload: id_forum }));
			});
	}

	getAndDispatchMessages(id_forum: number): Observable<any> {
		return this.http.get(
			this.gatewayAPI +
        'interacoes/buscar-ultimas-mensagens-por-forum/' +
        id_forum,
			this.getHeader()
		);
	}

	getMoreMessages(id_forum: number, ultima): Observable<any> {
		return this.http.get(
			this.gatewayAPI +
        'interacoes/buscar-ultimas-mensagens-por-forum/' +
        id_forum +
        '?ultima=' +
        ultima,
			this.getHeader()
		);
	}

	getQuestoesAvaliacao(id_avaliacao_estudante: number): Observable<any> {
		return this.http.get(
			this.gatewayAPI +
        'avaliacao/' +
        id_avaliacao_estudante +
        '/tentativa?useSession=1',
			this.getHeader()
		);
	}

	responderAvaliacao(
		id_avaliacao_estudante: number,
		id_avaliacao_estudante_tentativa: number,
		bodyData: any,
		sessao: number
	): Observable<any> {
		const queryString = sessao ? `?sessao=${sessao}` : '';
		if (!bodyData.finalizar && bodyData.questions) {
			for (const question of bodyData.questions) {
				question.correta = null;
			}
		}
		return this.http.post(
			this.gatewayAPI +
        'avaliacao/' +
        id_avaliacao_estudante +
        '/tentativa/' +
        id_avaliacao_estudante_tentativa +
        '/responder' +
        queryString,
			bodyData,
			this.getHeader()
		);
	}

	getTentativaRespondida(
		idValiacaoEstudante: number,
		idAvaliacaoEstudanteTentativa: number
	): Observable<any> {
		return this.http.get(
			this.gatewayAPI +
        'avaliacao/' +
        idValiacaoEstudante +
        '/tentativa/' +
        idAvaliacaoEstudanteTentativa +
        '/revisao',
			this.getHeader()
		);
	}

	getAvaliacao(idValiacaoEstudante: number) {
		return this.http.get(
			this.gatewayAPI + 'avaliacao/' + idValiacaoEstudante,
			this.getHeader()
		);
	}

	getDadosProjeto(idProjeto: number) {
		return this.http.get(
			this.gatewayAPI + 'projeto/dados-projeto/' + idProjeto,
			this.getHeader()
		);
	}

	enviarArquivos(
		idValiacaoEstudante: number,
		idAvaliacaoEstudanteTentativa: number,
		urls,
		idProjeto,
		idFase
	) {
		return this.http.post(
			`${this.gatewayAPI}avaliacao/${idValiacaoEstudante}/tentativa/${idAvaliacaoEstudanteTentativa}/responder-arquivo`,
			{
				arquivo: urls,
				id_projeto_turma: idProjeto,
				id_fase: idFase,
			},
			this.getHeader()
		);
	}

	criarContaTrial(dados) {
		return this.http.post(
			this.gatewayAPI + 'oferta/usuario/novo',
			dados,
			this.getHeader()
		);
	}

	validarCupom(cupom, usuario) {
		return this.http.post(
			this.gatewayAPI + 'validar-cupom?cupom=' + cupom,
			usuario,
			this.getHeader()
		);
	}

	matricularAlunoOferta(id_usuario, id_oferta_cupom) {
		return this.http.post(
			this.gatewayAPI +
        'v2/matricular/' +
        id_usuario +
        '/oferta/' +
        id_oferta_cupom,
			null,
			this.getHeader()
		);
	}

	getHeader(token = null) {
		const tokenFromStorage = sessionStorage.getItem('token');
		let usedToken;
		if (token) usedToken = token;
		else if (tokenFromStorage) usedToken = tokenFromStorage;
		else usedToken = this.state.token;
		const header = {
			headers: new HttpHeaders().set('Authorization', 'bearer ' + usedToken),
		};
		return header;
	}

	getProfileTestQuestions(id_perfil_teste: number) {
		return this.http.get(
			this.gatewayAPI + 'perfil/realizar-teste/' + id_perfil_teste,
			this.getHeader()
		);
	}

	sendProfileTestAnsweredQuestions(
		answerArray: Array<any>,
		id_perfil_teste: number,
		id_curriculo_usuario: number
	) {
		return this.http.post(
			`${this.gatewayAPI}curriculo-usuario-perfil/${id_curriculo_usuario}/responder`,
			{
				id_perfil_teste: id_perfil_teste,
				questoes: answerArray,
			},
			this.getHeader()
		);
	}

	getCurriculumProfile(id) {
		return this.http.get(this.gatewayAPI + 'perfil/' + id, this.getHeader());
	}

	getSuportMessagesByCompanyAndUser(
		idProjetoTurmaEstudante: number,
		idEmpresa: number,
		idUsuario: number
	) {
		let endpoint = '';
		if (idProjetoTurmaEstudante)
			endpoint = `suporte/secretaria/listar-suporte-aluno/${idProjetoTurmaEstudante}`;
		else
			endpoint = `suporte/secretaria/listar-suporte-aluno-empresa/${idEmpresa}/${idUsuario}`;
		return this.http.get(this.gatewayAPI + endpoint, this.getHeader());
	}

	getSecretariaAreas(idEmpresa: number, idProjetoTurma: number | null = -1) {
		return this.http.get(
			`${this.gatewayAPI}suporte/secretaria/areas/${idEmpresa}/${idProjetoTurma}`,
			this.getHeader()
		);
	}

	getSecretariaServicos(idEmpresa: number, idArea: number) {
		return this.http.get(
			this.gatewayAPI +
        'suporte/secretaria/servicos/' +
        idEmpresa +
        '/' +
        idArea,
			this.getHeader()
		);
	}

	getSecretariaSolicitacoes(idEmpresa: number, idServico: number) {
		return this.http.get(
			this.gatewayAPI +
        'suporte/secretaria/solicitacoes/' +
        idEmpresa +
        '/' +
        idServico,
			this.getHeader()
		);
	}
	getFaleComProfessorSolicitacoes(idEmpresa: number, idServico: number) {
		return this.http.get(
			this.gatewayAPI +
        'suporte/falecomprofessor/solicitacoes/' +
        idEmpresa +
        '/' +
        idServico,
			this.getHeader()
		);
	}

	getCertificadoGenerate(idCompetencia: number): Observable<any> {
		return this.http.get(
			this.gatewayAPI + 'certificado-generate/' + idCompetencia,
			this.getHeader()
		);
	}

	validarCertificado(params: Params): Observable<any> {
		return this.http.post(
			this.gatewayAPI + 'certificado-validade',
			params,
			this.getHeader()
		);
	}

	certificadoUpload(body: any): Observable<any> {
		return this.http.post(
			this.gatewayAPI + 'certificado-upload-res',
			body,
			this.getHeader()
		);
	}

	getSistemaConfigs(idSistemaConfigs: number): Observable<any> {
		return this.http.get(
			this.gatewayAPI + 'sistema_config/' + idSistemaConfigs,
			this.getHeader()
		);
	}

	dataApi(body: any): Observable<any> {
		return this.http.post(this.gatewayAPI + 'data-api', body, this.getHeader());
	}

	getAtividadeLti(data: any): Observable<any> {
		return this.http.post(this.gatewayAPI + 'lti/run', data, this.getHeader());
	}

	getArquivo(url: string): Observable<any> {
		let header: any = this.getHeader();
		header = {
			...header,
			responseType: 'blob',
		};
		return this.http.get(url, header);
	}

	getPesquisaNps(id: number): Observable<any> {
		return this.http.get(
			this.gatewayAPI + 'pesquisa-nps/' + id,
			this.getHeader()
		);
	}

	verificarRespostaEstudante(
		idPesquisaNps: number,
		idProjetoTurmaEstudante: number
	): Observable<any> {
		return this.http.get(
			this.gatewayAPI +
        'pesquisa-nps/verificar-resposta-estudante/' +
        idPesquisaNps +
        '/' +
        idProjetoTurmaEstudante,
			this.getHeader()
		);
	}

	responderPesquisaNps(respostas: Array<RespostaPesquisaNps>): Observable<any> {
		return this.http.post(
			this.gatewayAPI + 'pesquisa-nps/responder',
			{ respostas },
			this.getHeader()
		);
	}

	agendarOuAcessarEvento(
		idEvento: number,
		idUsuario: number,
		type: number
	): Observable<any> {
		return this.http.post(
			this.gatewayAPI + `evento/${idEvento}/usuario/${idUsuario}/type/${type}`,
			null,
			this.getHeader()
		);
	}
}
