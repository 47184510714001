<div
  class="upload-container"
  (drop)="onDrop($event)"
  (dragover)="allowDrop($event)"
>
  <label class="upload-label" [attr.for]="'inputUpload' + index">
    <div [class]="classeUpload">
      <!-- <div class="row img-baixar pointer">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="50" viewBox="0 0 40 36.861">
          <defs>
            <style>
              .cls-1 {
                fill: #24a1cd
              }
            </style>
          </defs>
          <g id="icon-nav-baixar" transform="translate(-73.589 -79.9)">
            <path id="Caminho_260"
              d="M122.33 1132.8a.594.594 0 0 0-.593.593v3.943H90.886v-3.945a.593.593 0 1 0-1.186 0v4.538a.594.594 0 0 0 .593.593h32.04a.594.594 0 0 0 .593-.593v-4.538a.6.6 0 0 0-.596-.591z"
              class="cls-1" data-name="Caminho 260" transform="translate(-12.724 -1025.151)" />
            <path id="Caminho_261"
              d="M110.9 91.483a.592.592 0 0 0-.838 0l-15.3 15.3v-26.29a.593.593 0 0 0-1.186 0v26.344L78.212 91.483a.593.593 0 0 0-.838.838l16.343 16.343a.592.592 0 0 0 .838 0L110.9 92.321a.592.592 0 0 0 0-.838z"
              class="cls-1 seta-animacao" data-name="Caminho 261" transform="translate(-.553)" />
            <path id="Caminho_265"
              d="M129.1 1128.8a.594.594 0 0 0-.593.593v7.33H90.886v-7.333a.593.593 0 1 0-1.186 0v7.926a.594.594 0 0 0 .593.593h38.814a.594.594 0 0 0 .593-.593v-7.926a.6.6 0 0 0-.6-.59z"
              class="cls-1" data-name="Caminho 265" transform="translate(-16.111 -1021.151)" />
          </g>
        </svg>
      </div> -->
      <div class="row img-baixar pointer">
        <img src="assets/icons/download-blue.svg" alt="" />
      </div>
      <div class="row upload-titulo pointer">
        <span
          [attr.for]="'inputUpload' + index"
          *ngIf="limiteDeArquivos === 1 || !limiteDeArquivos"
          class="capitalize"
        >
          {{ "UPLOAD_ARQUIVOS.DRAG_FILE" | translate }}
        </span>
        <span
          [attr.for]="'inputUpload' + index"
          *ngIf="limiteDeArquivos > 1"
          class="capitalize"
        >
          {{ "UPLOAD_ARQUIVOS.DRAG_FILE" | translate }}
        </span>
      </div>
      <div class="row upload-text pointer">
        <span [attr.for]="'inputUpload' + index" class="capitalize">{{
          "UPLOAD_ARQUIVOS.CLICK_ATTACH" | translate
        }}</span>
      </div>
      <div class="row upload-text-limite" *ngIf="limiteDeArquivos">
        <label [attr.for]="'inputUpload' + index" class="capitalize">
          {{ "UPLOAD_ARQUIVOS.LIMIT1" | translate }} {{ limiteDeArquivos }}
          {{ "UPLOAD_ARQUIVOS.LIMIT2" | translate }}
        </label>
      </div>
      <div class="d-flex justify-content-center">
        <cls-progress
          *ngIf="filePercentage"
          [percentage]="filePercentage"
        ></cls-progress>
      </div>
    </div>
  </label>
  <input
    #uploadId
    [id]="'inputUpload' + index"
    style="display: none"
    multiple
    type="file"
    (change)="upload($event)"
  />
  <div *ngIf="loadingFile">
    Estamos processando seu arquivo, aguarde uns instantes...
  </div>
</div>

<div *ngFor="let arquivo of arquivosUpload; index as i">
  <div class="upload-nome-arquivo">
    <span class="nome-check">
      {{ arquivo.name }}
    </span>
    <button class="btn-lixeira" (click)="deletarArquivo(i)">
      <img alt="Excluir" src="assets/icons/trash.svg" />
    </button>
  </div>
  <hr />
</div>
