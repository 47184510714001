import { Directive, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[CloseOnClickOutside]'
})
export class CloseOnClickOutsideDirective {
  constructor(private _elementRef: ElementRef) { }

  @Output() clickOutside: EventEmitter<any> = new EventEmitter();
  @HostListener('document:click', ['$event.target'])

  public onClick(targetElement) {
    let clickedInside = this.checkIfIsChild(targetElement)
    if (!clickedInside) this.clickOutside.emit({ element: this._elementRef.nativeElement.parentNode.nodeName, target: targetElement });
  }

  checkIfIsChild(element): boolean {
    return this._elementRef.nativeElement.contains(element);
  }
}