<div class="resposta-avaliacao-container">
  <div class="main">
    <div class="main-card">
      <div class="back-fase" *ngIf="hasBack" (click)="emitExit()">
        <i class="fas fa-chevron-left"></i>
        <div class="capitalize">{{ "AVALIACAO.BACK" | translate }}</div>
      </div>
      <div class="progresso">{{ index }}/{{ total }}</div>
      <div class="bars">
        <div class="bar" [class.marked]="bar" *ngFor="let bar of bars"></div>
      </div>
      <div class="timer" *ngIf="timer.minutes > 0 || timer.seconds > 0">
        <i class="far fa-clock"></i>
        {{ timer.minutes | number : "2.0-0" }}:{{
          timer.seconds | number : "2.0-0"
        }}
      </div>
      <div class="title">Pergunta {{ index }}</div>
      <div class="question" [innerHtml]="questao?.text | sanitizeHtml"></div>
      <editor
        class="edit"
        modelEvents="change input nodechange undo redo"
        [init]="getTinyMCEConfigs()"
        [(ngModel)]="answer[index - 1]"
        (onKeyUp)="emitAnswer($event)"
        apiKey="j6n6nv1nte1qoly2azmdxwjialx5fnlqxwpeh3kxcdgl3zfb"
      >
      </editor>
      <div class="btns">
        <button class="btn upper" (click)="emitBefore()" *ngIf="index > 1">
          {{ "QUESTIONARIO_AVALIACAO.QUESTION_PREVIOUS" | translate }}
        </button>
        <button class="btn upper" *ngIf="index !== total" (click)="openModal()">
          {{ "QUESTIONARIO_AVALIACAO.QUESTION_NEXT" | translate }}
        </button>
        <button class="btn upper" *ngIf="index === total" (click)="openModal()">
          {{ "QUESTIONARIO_AVALIACAO.FINISH" | translate }}
        </button>
      </div>

      <div class="overlay" *ngIf="isOpenModal" (click)="closeModal()">
        <div class="confirm-modal">
          <p class="confirm-modal__text">
            {{ "QUESTIONARIO_AVALIACAO.MODAL_QUESTION_TITLE" | translate }}
          </p>
          <p class="confirm-modal__text">
            {{ "QUESTIONARIO_AVALIACAO.MODAL_QUESTION_DISCURSIVE" | translate }}
          </p>
          <div class="confirm-modal__divisor"></div>
          <div class="confirm-modal__btns">
            <!-- <button class="btn upper" (click)="closeModal()">{{ 'BUTTON.NO' | translate }}</button> -->
            <button class="btn upper" (click)="closeModal()">OK</button>
          </div>
        </div>
      </div>
    </div>
    <div class="detail-1"></div>
    <div class="detail-2"></div>
  </div>
</div>
