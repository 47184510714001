<div class="avaliacao-container">
  <div class="main">
    <div *ngIf="isLoading" class="text-center">
      <div class="spinner-border spinner-border-md" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div *ngIf="!isLoading" class="main-card">
      <!--<div class="title mt-4">Resultado</div>
             <div *ngIf="this.entregaTurma.nota_maxima" class="nota mb-4">
                Sua nota é <span>{{resultado?.tentativa.nota}}</span> de
                {{ this.entregaTurma.nota_maxima }}
            </div> -->
      <div *ngIf="!this.entregaTurma.nota_maxima" class="nota mb-4">
        {{ "GABARITO.ACERTOS" | translate }}
        <span> {{ resultado?.tentativa.acertos }}</span> de
        {{ this.entregaTurma.questoes_tentativa }}
      </div>
      <div class="d-flex justify-content-center">
        <cls-circle
          [size]="'big'"
          [textColor]="'#0D0D0D'"
          [strokeFill]="'#F5F5F5'"
          [percentage]="getGabaritoPercentage()"
          [value]="resultado?.tentativa?.acertos"
        >
        </cls-circle>
      </div>
      <div class="title mt-4 capitalize">
        {{ "GABARITO.TITLE" | translate }}
      </div>
      <div
        *ngFor="let question of resultado?.tentativa?.questions; let i = index"
      >
        <div class="label-gabarito mt-3 mb-1">
          {{ "GABARITO.ASK" | translate }} {{ question.index }}
        </div>
        <div class="question-text-and-tag">
          <div [innerHTML]="question.text" class="question mt-3"></div>
          <div *ngIf="question.anulada" class="anulada mt-3">
            <span class="x-mark">X</span>
            {{ "GABARITO.REVOKED_QUESTION" | translate }}
          </div>
        </div>
        <div class="answer" *ngFor="let option of question.options">
          <div
            class="check"
            [class.checked]="option == question.correta"
            [class.right]="
              option == question.correta && option == question.option
            "
            [class.wrong]="
              option != question.correta && option == question.option
            "
          >
            <i
              *ngIf="!(option != question.correta && option == question.option)"
              class="fas fa-check"
            ></i>
            <i
              *ngIf="option != question.correta && option == question.option"
              class="fas fa-times"
            ></i>
          </div>
          {{ option }}
        </div>
      </div>
    </div>
  </div>
</div>
