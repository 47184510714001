<div class="notification-container">
  <div class="d-flex justify-content-start flex-row align-items-center mb-5" *ngIf="titule">
    <span class="voltar-container pointer" (click)="back()">
      <img  class="voltar" src="assets/icons/icone_voltar.svg" alt="">
    </span>
    <div class="notification-title text-uppercase pointer" (click)="back()">
      {{titule}}
    </div>
  </div>
  <ng-content></ng-content>
</div>
