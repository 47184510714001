import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderHomeComponent } from './header-home.component';
import { SidebarContentModule } from '../sidebar-content/sidebar-content.module';
import { CardDataModule } from '../../layout/card-data/card-data.module';
import { MensagemListModule } from '../../layout/mensagem/mensagem-list.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { createTranslateLoader } from 'src/app/app.module';
import { HttpClient } from '@angular/common/http';
import { HomeSortPipeNullFirst } from '../../../../../../src/app/pipes/home-sort-date-null-first/home-sort-date-null-first.pipe';
import { HomeSortPipeArrayDateNullFirst } from '../../../../../../src/app/pipes/home-sort-array-date-null-first/home-sort-array-date-null-first.pipe';

@NgModule({
	declarations: [
		HeaderHomeComponent,
		HomeSortPipeNullFirst,
		HomeSortPipeArrayDateNullFirst,
	],
	imports: [
		CommonModule,
		SidebarContentModule,
		CardDataModule,
		MensagemListModule,
		TranslateModule.forRoot({
			defaultLanguage: environment.defaultLanguage,
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
		}),
	],
	exports: [HeaderHomeComponent],
})
export class HeaderHomeModule {}
