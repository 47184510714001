import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { Router, ActivatedRoute } from '@angular/router';
import { avaliacaoState, fillPerguntaAvaliacao, fillArrayRespostasAluno, fillDadosAvaliacao } from 'src/app/state/state.actions';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { DataService } from 'src/app/services/data-service/data.service';

@Component({
  selector: 'cls-avaliacao',
  templateUrl: './avaliacao.component.html',
  styleUrls: ['./avaliacao.component.scss']
})
export class AvaliacaoComponent implements OnInit {
  constructor(

  ) { }
  ngOnInit(): void {
  }
}
