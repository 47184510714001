import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DataService } from "src/app/services/data-service/data.service";
import * as momentTimezone from "moment-timezone";
import * as moment from "moment";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/state";
import { fillDocumentViewerUrl, fillImageViewerUrl } from "src/app/state/state.actions";

@Component({
  selector: "cls-scrollable-chat",
  templateUrl: "./scrollable-chat.component.html",
  styleUrls: ["./scrollable-chat.component.scss"],
})
export class ScrollableChatComponent implements OnInit {
  @Input() messages;
  @Input() status_aluno: any;
  @Input() containerClass: string;
  @Input() withShadow: string = "";
  @Output() reply = new EventEmitter();
  @Output() solve = new EventEmitter();

  @ViewChild("attach") attach: ElementRef;

  fileName = null;
  file: File;
  uploadForm: FormGroup;
  refresh: any;
  imageExt = ['.jpeg', '.jpg', '.gif', '.png', '.apng', '.svg', '.bmp'];

  newMessage = { mensagem: null, arquivo: null, tipo_arquivo: "imagem" };

  ticketStatus = {
    in_attendance: 2,
    resolved: 5, 
    closed: 6
  }

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.uploadForm = this.formBuilder.group({ arquivo: null });
    this.automaticCallUpdateMessages();
  }

  ngOnChanges() {
    this.messages?.detalhes?.forEach((msg) => {
      let gmt = moment(msg.data_postagem)
        .tz(momentTimezone.tz.guess())
        .format("Z");
      gmt = gmt.substr(1, 2);
      msg.data_postagem = moment(msg.data_postagem).subtract(gmt, "hours");
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.refresh);
  }

  verifyExt(message): boolean {
    if (this.imageExt.some((extension) => message.media_name.indexOf(extension) !== -1))
      return true
    return false
  }

  viewFile(message) {
    this.store.dispatch(fillDocumentViewerUrl({ payload: message.media_url }))
  }

  viewImage(message) {
    this.store.dispatch(fillImageViewerUrl({ payload: message.media_url }))
  }

  downloadFile(message: any): void {
    const link = document.createElement('a');
    link.href = message.media_url;
    link.download = message.media_name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  attachFile(event) {
    if (event.srcElement.files[0].size > 998900) {
      alert("Atenção! São aceitos arquivos de até 1MB");
      return;
    }
    this.fileName = event.srcElement.files[0].name;
    this.file = event.target.files[0];
  }

  clickInputUpload() {
    let input: HTMLElement;
    input = document.querySelector(".ipt-file");
    input.click();
  }

  sendMessage() {
    let formData = new FormData();
    this.uploadForm.get("arquivo").setValue(this.file);

    if (this.uploadForm.get("arquivo").value) {
      formData.append(
        "arquivo",
        this.uploadForm.get("arquivo").value,
        this.fileName
      );
      formData.append('media_type', this.file.type);
    }

    formData.append("mensagem", this.newMessage.mensagem);
    this.reply.emit({ form: formData, messages: this.messages });
    this.clearInputFields();
  }

  solveTicket() {
    this.solve.emit(this.ticketStatus.resolved);
  }

  questionSolved(isSolved: boolean) {
    if(isSolved) this.solve.emit(this.ticketStatus.closed);
    else this.solve.emit(this.ticketStatus.in_attendance);
  }

  clearInput() {
    this.file = null;
    this.fileName = null;
    if (this.attach) this.attach.nativeElement.value = "";
  }

  clearInputFields() {
    this.newMessage = { mensagem: null, arquivo: null, tipo_arquivo: "imagem" };
    this.file = null;
    this.fileName = null;
    if (this.attach) this.attach.nativeElement.value = "";
  }

  automaticCallUpdateMessages() {
    let element = document.querySelector(".scrollable-chat-container");
    this.scrollToBottomViaID("scroll-interacoes");
    this.scrollToBottomViaID("scrollable-chat");

    this.refresh = setInterval(() => {
      if (
        element.scrollHeight - Math.floor(element.scrollTop) ==
        element.clientHeight ||
        element.scrollHeight - Math.ceil(element.scrollTop) ==
        element.clientHeight
      ) {
        this.dataService.getAndSetSuportDetails(this.messages);
      }
    }, 13000);
  }

  scrollToBottomViaID(id) {
    setTimeout(() => {
      var elmnt = document.getElementById(id);
      if (elmnt)
        elmnt.scrollBy({
          top: elmnt.scrollHeight,
          behavior: "smooth",
        });
    }, 1000);
  }

  get isTicketSolved(): boolean {
    return this.messages?.suporte_status_id_suporte_status != this.ticketStatus.resolved 
    && this.messages?.suporte_status_id_suporte_status != this.ticketStatus.closed
  }
}
