import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { InteracoesComponent } from './components/interacoes/interacoes.component';
import { AgendaComponent } from './components/agenda/agenda.component';
import { AuthGuardService } from './services/auth-service/auth-guard.service';
import { LoginComponent } from './components/login/login.component';
import { SolicitacoesComponent } from './components/solicitacoes/solicitacoes.component';
import { CertificadoValidadeComponent } from './components/certificado-validade/certificado-validade.component';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { clearState } from 'src/app/state/state.actions';
import { FaseDetailsComponent } from './components/fase-details/fase-details.component';
import { CurriculoComponent } from './components/curriculo/curriculo.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { AvaliacaoComponent } from './components/avaliacao/avaliacao.component';
import { GlobalService } from './services/global-service/global.service';
import { SingleSignOnComponent } from './components/single-sign-on/single-sign-on.component';
import { QuestionarioPerfilComponent } from './components/questionario-perfil/questionario-perfil.component';
import { PerfilUsuarioComponent } from './components/perfil-usuario/perfil-usuario.component';
import { AvaliacaoDiscursivaComponent } from './components/avaliacao-discursiva/avaliacao-discursiva.component';
import { TranslateService } from '@ngx-translate/core';
import { Homev2Component } from './pages/homev2/homev2.component';
import { CarrosselDetailsComponent } from './pages/homev2/carrossel-details/carrossel-details.component';

const routes: Routes = [
	{ path: '', component: LoginComponent },
	{ path: 'cadastro', component: LoginComponent },
	{ path: 'login', component: LoginComponent },
	{
		path: 'single-sign-on/:hash/user/:email',
		component: SingleSignOnComponent,
	},
	{ path: 'single-sign-on/:key/:user', component: SingleSignOnComponent },
	{ path: 'single-sign-on', component: SingleSignOnComponent },
	{ path: 'reset-password/:token', component: ResetPasswordComponent },
	{
		path: 'perfil',
		component: Homev2Component,
		canActivate: [AuthGuardService],
	},
	{
		path: 'perfil/:type/detalhes',
		component: CarrosselDetailsComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'curriculo',
		component: CurriculoComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'projeto',
		component: HomeComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'projeto/:id',
		component: HomeComponent,
		canActivate: [AuthGuardService],
		children: [
			{
				path: 'fase/:idFase',
				component: FaseDetailsComponent,
				canActivate: [AuthGuardService],
				children: [
					{
						path: 'interacoes',
						component: InteracoesComponent,
						canActivate: [AuthGuardService],
					},
					{
						path: 'agenda',
						component: AgendaComponent,
						canActivate: [AuthGuardService],
					},
				],
			},
			{
				path: 'interacoes',
				component: InteracoesComponent,
				canActivate: [AuthGuardService],
			},
			{
				path: 'agenda',
				component: AgendaComponent,
				canActivate: [AuthGuardService],
			},
		],
	},
	{
		path: 'solicitacoes',
		component: SolicitacoesComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'avaliacao',
		component: AvaliacaoComponent,
		canActivate: [AuthGuardService],
		children: [
			{
				path: 'questionario',
				component: AvaliacaoDiscursivaComponent,
				canActivate: [AuthGuardService],
			},
			{
				path: 'discursiva',
				component: AvaliacaoDiscursivaComponent,
				canActivate: [AuthGuardService],
			},
			//  {
			//   path: 'gabarito/:idAvaliacaoEstudante/tentativa/:idAvaliacaoEstudanteTentativa',
			//   component: GabaritoAvaliacaoComponent, canActivate: [AuthGuardService]
			// }
		],
	},
	{
		path: 'questionario-perfil/:idProfileTest',
		component: QuestionarioPerfilComponent,
	},
	{
		path: 'perfil-usuario/:idCurriculumProfile',
		component: PerfilUsuarioComponent,
	},
	{ path: 'certificado-validade', component: CertificadoValidadeComponent },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {
	constructor(
    private router: Router,
    private store: Store<AppState>,
    private global: GlobalService,
    private translate: TranslateService
	) {
		this.router.errorHandler = (error) => {
			console.log(error);
			sessionStorage.clear();
			this.store.dispatch(clearState());
			this.router.navigate(['login']);
			this.global.toast(
				this.translate.instant('GENERAL.ERROR'),
				this.translate.instant('GENERAL.LOGIN_AGAIN')
			);
		};
	}
}
