import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import {
	notificacoes,
	photoLoading,
} from '../../../../../../src/app/state/state.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../src/app/state';
import { Router } from '@angular/router';
import { NotificacaoService } from '../../../../../../src/app/services/notificacao/notificacao.service';
import { NotificacaoMensagemTratadaModel } from '../../../../../../src/app/models/notificacao-mensagem.model';
import { Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { HeaderBase } from '../classes-abstratas/header-base';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { DataService } from 'src/app/services/data-service/data.service';

@Component({
	selector: 'ui-header-home',
	templateUrl: './header-home.component.html',
	styleUrls: ['./header-home.component.scss'],
})
export class HeaderHomeComponent
	extends HeaderBase
	implements OnChanges, OnDestroy
{
  @Input() showQuantidadeCompetencia: boolean;
  @Input() voltarParaPerfil: boolean;
  @Input() isCurriculo: boolean;
  quantidadeDeCompetencias: number;
  competencias: number;
  showNotificacao: boolean;
  subscriptions: Subscription[] = [];
  notificacoes: NotificacaoMensagemTratadaModel;
  empresa: any;
  polo: any;
  backgroundImg;
  estudanteStatus: any;
  uploadPhoto: FormData;
  photoName: string;
  notificacoesNaoLidas = false;

  constructor(
    protected store: Store<AppState>,
    public router: Router,
    protected notificacaoService: NotificacaoService,
    public global: GlobalService,
    private dataService: DataService
  ) {
  	super(store, notificacaoService);
  }

  setBackground() {
  	if (this.polo && this.polo.banner_perfil_web)
  		this.backgroundImg = this.global.generateImageURL(
  			this.polo.banner_perfil_web
  		);
  	else
  		this.backgroundImg = this.empresa?.banner_perfil_web
  			? this.global.generateImageURL(this.empresa.banner_perfil_web)
  			: this.global.generateImageURL(
  				'../../assets/imgs/background-perfil.jpg'
  			);
  }

  ngOnChanges(changes: any) {
  	if (changes.quantidadeDeCompetencias) {
  		this.competencias = changes.quantidadeDeCompetencias.currentValue;
  	}
  }

  ngOnDestroy() {
  	this.subscriptions.forEach((subscription: Subscription) => {
  		subscription.unsubscribe();
  	});
  }

  getState() {
  	const stateSubscription = this.store
  		.select((state: any) => {
  			this.quantidadeDeCompetencias =
          state.newAppState.quantidadeCompetencias;
  			this.showNotificacao = state.newAppState.showNotificacao;
  			this.empresa = state.newAppState.dadosEmpresa;
  			this.polo = state.newAppState.dadosPolo;
  			this.usuario = state.newAppState.usuario;
  			this.estudanteStatus = state.newAppState.estudanteStatus;
  			if (state.newAppState.notificacoes) {
  				this.notificacoes = state.newAppState.notificacoes;
  			}
  			this.setBackground();
  			this.checkNotificacoesNaoLidas(this.notificacoes?.mensagensNaoLidas);
  		})
  		.subscribe();
  	this.subscriptions.push(stateSubscription);
  }

  insertData() {
  	this.router.navigate(['curriculo']);
  }

  callInput() {
  	const input: HTMLElement = document.querySelector('.upload-picture');
  	input.click();
  }

  prepareUpload(event) {
  	this.uploadPhoto = new FormData();
  	if (event && event.target.files && event.target.files[0]) {
  		this.uploadPhoto.append(
  			'foto',
  			event.target.files[0],
  			event.target.files[0].name
  		);
  		this.photoName = event.target.files[0].name;
  		this.uploadPicture();
  	}
  }

  uploadPicture() {
  	this.store.dispatch(photoLoading({ payload: true }));
  	this.dataService.changePicture(this.uploadPhoto);
  }

  irParaPerfil() {
  	if (this.voltarParaPerfil) {
  		this.router.navigate(['/perfil']).then();
  	}
  }

  marcarComoLido(id: number) {
  	// const idNotificacao = [id];
  	// const subscription = this.notificacaoService
  	// 	.marcarNotificacaoComoLida(this.usuario.id_usuario, idNotificacao)
  	// 	.pipe(
  	// 		mergeMap((data) =>
  	// 			this.notificacaoService.getNotificacao(this.usuario.id_usuario)
  	// 		)
  	// 	)
  	// 	.subscribe((data: NotificacaoMensagemTratadaModel) => {
  	// 		this.store.dispatch(notificacoes({ payload: data }));
  	// 	});
  	// this.subscriptions.push(subscription);
  }

  checkNotificacoesNaoLidas(mensagensNaoLidas: any) {
  	if (mensagensNaoLidas && mensagensNaoLidas.length) {
  		this.notificacoesNaoLidas = true;
  	}
  }
}
