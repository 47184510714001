import {Component, Input, OnInit} from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'ui-container-detalhes',
  templateUrl: './container-detalhes.component.html',
  styleUrls: ['./container-detalhes.component.scss']
})
export class ContainerDetalhesComponent {

  @Input() titule: string;

  constructor(private location: Location) { }

  back(){
    this.location.back();
  }

}
