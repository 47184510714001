<div class="modal fade show" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header-pesquisa">
                <div class="flex-end">
                    <img src="assets/icons/close-pesquisa.svg" alt="" class="close" (click)="handleCloseModal();$event.stopPropagation()">
                </div>
                <div class="check-bg-blue">
                    <img src="assets/icons/check-pesquisa-white.svg">
                </div>
                <h5 class="modal-title capitalize" id="exampleModalLabel">{{ 'NPS_SURVEY.SATISFACTION_SURVEY' | translate }}</h5>
            </div>
            <div class="modal-body">
                <div *ngFor="let pergunta of pesquisa?.perguntas; let i = index">
                    <cls-pergunta-ranking *ngIf="pergunta.tipo == 1" [pergunta]="pergunta" [naoRespondida]="perguntasNaoRespondidas[i]" (selectedNumberEvent)="handleResposta($event, i)"></cls-pergunta-ranking>
                    <cls-pergunta-discursiva *ngIf="pergunta.tipo == 2" [pergunta]="pergunta" [naoRespondida]="perguntasNaoRespondidas[i]" (respostaEvent)="handleResposta($event, i)"></cls-pergunta-discursiva>
                </div>
            </div>
            <div class="button-row">
                <button type="button" class="btn btn-avaliacao btn-pesquisa" (click)="submit()">{{ 'NPS_SURVEY.SEND' | translate }}</button>
            </div>
        </div>
    </div>
</div>
