import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
	SimpleChanges,
	ViewChild,
	forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { GlobalService } from 'src/app/services/global-service/global.service';
import * as tus from 'tus-js-client';

@Component({
	selector: 'app-upload-lista-arquivos',
	templateUrl: './upload-lista-arquivos.component.html',
	styleUrls: ['./upload-lista-arquivos.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => UploadListaArquivosComponent),
			multi: true,
		},
	],
})
export class UploadListaArquivosComponent implements OnDestroy {
  // Caso não tenha limite de arquivos deixe esse parâmetro como null
  @Input() limiteDeArquivos: number;
  @Input() index: number;
  @Input() clearFiles = false;
  @Output() tusURL = new EventEmitter();
  @Output() removeURL = new EventEmitter();
  @Output() changeForm = new EventEmitter();
  arquivosUpload: any[] = [];
  classeUpload = 'contorno-tracejado';
  value: string;
  onTouched: () => void;
  subscriptions: Subscription[] = [];
  @ViewChild('uploadId') uploadELement: ElementRef;
  filePercentage = '';
  state: any;
  stateSubscription: any;
  loadingFile = false;
  constructor(
    public global: GlobalService,
    private translate: TranslateService
  ) {}

  ngOnDestroy() {
  	this.subscriptions.forEach((subscription) => {
  		subscription.unsubscribe();
  	});
  }

  writeValue(value: any): void {
  	this.value = value;
  }

  registerOnTouched(fn: any): void {
  	this.onTouched = fn;
  }

  public deletarArquivo(index) {
  	const file = this.arquivosUpload.splice(index, 1);
  	this.writeValue(this.arquivosUpload);
  	this.limparUpload();
  	this.filePercentage = '';
  	this.removeURL.emit(file[0].serverURL);
  	this.loadingFile = false;
  }

  public onDrop(event) {
  	this.classeUpload = 'contorno-tracejado';
  	event.preventDefault();
  	const arquivos = event.dataTransfer.files;
  	if (this.validandoQuantidadeDeArquivos(arquivos)) {
  		Array.from(arquivos).map((element) => {
  			this.tusUpload(element);
  		});
  	}
  }

  public allowDrop(event) {
  	event.preventDefault();
  	this.classeUpload = 'contorno-tracejado allow-drop';
  }

  public upload(event) {
  	const arquivos = event.target.files;
  	if (this.validandoQuantidadeDeArquivos(arquivos)) {
  		Array.from(arquivos).map((element) => {
  			this.tusUpload(element);
  		});
  	}
  }

  validateArquivos(arquivo, url) {
  	if (arquivo) {
  		const subscription = this.iterarObjeto(arquivo, url).subscribe((data) => {
  			this.writeValue(this.arquivosUpload);
  			this.limparUpload();
  			this.changeForm.emit(this.arquivosUpload);
  		});
  		this.subscriptions.push(subscription);
  	}
  	this.tusURL.emit(url);
  }

  ngOnChanges(changes: SimpleChanges) {
  	if (this.clearFiles === true) {
  		this.arquivosUpload = [];
  		this.filePercentage = null;
  	}
  }

  tusUpload(arquivo) {
  	const file = arquivo;
  	if (!file.type)
  		return this.global.toast(
  			this.translate.instant('GENERAL.ERROR'),
  			this.translate.instant('UPLOAD_ARQUIVOS.FILE_NOT_SUPPORTED')
  		);

  	if (!file || file.size > 500000000) {
  		this.global.toast('Atenção!', 'O limite máximo para upload é de 500mb!');
  		return false;
  	}

  	this.loadingFile = true;
  	const doneUpload = (url, upload) => {
  		this.validateArquivos(arquivo, url);
  	};

  	const sendPercentage = (percentage) => {
  		this.filePercentage = percentage;
  	};

  	const upload = new tus.Upload(file, {
  		// endpoint: "https://lo-fileservice-3-dev.celsotech.io/uploads",
  		endpoint: 'https://lo-tus.celsotech.io/files/',
  		retryDelays: [500],
  		chunkSize: 8 * 1024 * 1024,
  		metadata: { filename: file.name, filetype: file.type },
  		// headers: { "Access-Control-Allow-Origin": "*" },
  		onError: function (error) {
  			console.log('Failed because: ' + error);
  			this.global.toast(
  				this.translate.instant('GENERAL.ERROR'),
  				this.translate.instant('UPLOAD_ARQUIVOS.ERROR')
  			);
  			this.loadingFile = false;
  		},
  		onProgress: function (bytesUploaded, bytesTotal) {
  			const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
  			sendPercentage(percentage);
  		},
  		onSuccess: function () {
  			doneUpload(upload.url, this);
  			this.loadingFile = false;
  		},
  	});

  	upload.findPreviousUploads().then(function (previousUploads) {
  		if (previousUploads.length)
  			upload.resumeFromPreviousUpload(previousUploads[0]);
  		upload.start();
  	});
  }

  iterarObjeto(arquivo: any, url: string): Observable<File[]> {
  	return new Observable<File[]>((observer) => {
  		arquivo.serverURL = url;
  		this.arquivosUpload.push(arquivo);
  		observer.next();
  		observer.complete();
  	});
  }

  validandoQuantidadeDeArquivos(arquivos: File[]): boolean {
  	let ret = true;
  	if (
  		(this.limiteDeArquivos &&
        arquivos &&
        this.arquivosUpload &&
        arquivos.length > this.limiteDeArquivos) ||
      this.arquivosUpload.length + arquivos.length > this.limiteDeArquivos
  	) {
  		this.global.toast(
  			'Atenção!',
  			'O limite de arquivos para esta entrega é de ' +
          this.limiteDeArquivos +
          ' arquivo(s)!'
  		);
  		ret = false;
  	}
  	return ret;
  }

  limparUpload() {
  	if (this.uploadELement) {
  		this.uploadELement.nativeElement.value = '';
  	}
  }
}
