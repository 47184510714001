<div class="gabarito-questao-container" [class.open]="isOpen">
  <div class="header">
    <span>Questão {{ index }}</span>
    <div class="pontuacao">
      <div *ngIf="questao?.correcao?.nota">
        Sua pontuação: {{ questao?.correcao?.nota }}
      </div>
      <div>
        Valor da questão:
        {{ entregaTurma?.nota_maxima / entregaTurma?.questoes_tentativa }}
      </div>
    </div>
    <div class="chevron-container">
      <div class="chevron" (click)="isOpen = !isOpen">
        <!-- <i class="fas fa-chevron-down"></i> -->
        <img src="assets\icons\fas-fa-chevron-down-white.svg" />
      </div>
    </div>
  </div>
  <div class="label-questao">Pergunta</div>
  <div class="text-questao" [innerHtml]="questao.text | sanitizeHtml"></div>
  <div class="label-resposta">Sua resposta</div>
  <div class="text" [innerHtml]="questao.response | sanitizeHtml"></div>
  <hr *ngIf="questao?.correcao?.feedback" />
  <div class="label-feedback" *ngIf="questao?.correcao?.feedback">
    Feedback do professor
  </div>
  <div class="text">{{ questao?.correcao?.feedback }}</div>
  <div class="div-btn" *ngIf="questao?.correcao?.url_anexo">
    <button class="btn liga-btn upper">
      <a [href]="questao?.correcao?.url_anexo">{{
        "BUTTON.DOWNLOAD_FILE" | translate
      }}</a>
    </button>
    <span class="conferencia">
      O arquivo disponibilizado para download serve apenas para simples
      conferência do aluno.
    </span>
  </div>
</div>
