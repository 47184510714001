import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { fillSecretariaModal } from 'src/app/state/state.actions';
import { DataService } from 'src/app/services/data-service/data.service';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cls-secretaria-modal',
  templateUrl: './secretaria-modal.component.html',
  styleUrls: ['./secretaria-modal.component.scss']
})
export class SecretariaModalComponent implements OnInit {

  state: any;
  stateSubscription: any;

  constructor(
    private store: Store<AppState>, 
    private dataService: DataService,
    private translate: TranslateService,
    private global: GlobalService) { }

  ngOnInit() {
    this.getState();
  }

  getState() {
    this.stateSubscription = this.store.select((state: any) => {
      const { secretariaModal } = state.newAppState;
      this.state = { secretariaModal };
    }).subscribe();
  }

  close(){
    this.store.dispatch(fillSecretariaModal({ payload: null}));
  }

  sendReply(reply){
   reply.append('id_suporte_status', 2);
   reply.append('id_suporte', this.state.secretariaModal.suporte_id_suporte);
    this.dataService.sendSecretariaMessage(reply).then(
      data => {
        this.store.dispatch(fillSecretariaModal({ payload: data }))
        this.global.scrollToBottomViaID('scroll-chat');
      }, error => {
        console.log(error);
        this.global.toast(this.translate.instant("GENERAL.ERROR"), error.error.message);
      })
  }

}
