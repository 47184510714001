<div class="curriculo-container">
  <ui-header-home
    [showQuantidadeCompetencia]="false"
    [voltarParaPerfil]="true"
    [isCurriculo]="true"
  ></ui-header-home>
  <div class="main-container" *ngIf="!state?.curriculoEdit">
    <div class="info">
      <div class="name" data-cy="CurriculoNomeSocial">
        {{ state?.usuario?.nome_social }}
        <img
          src="assets/icons/lapis.svg"
          (click)="setCurriculoEdition(true)"
          data-cy="CurriculoEditar"
        />
      </div>
      <div class="competencias">
        <div
          class="competencia"
          *ngFor="let competencia of state?.usuario?.usuarioCompetencia"
        >
          <div *ngIf="competencia.favorita">
            <span>#</span>{{ competencia.nome }}
          </div>
        </div>
      </div>
      <div class="dado chevron" [class.open]="showDados">
        <label>
          <span class="upper">{{ "CURRICULO.PERSONAL_DATA" | translate }}</span>
          <i
            class="fas"
            (click)="showDados = !showDados"
            [class.fa-chevron-down]="!showDados"
            [class.fa-chevron-up]="showDados"
            data-cy="CurriculoSetaMostrarDados"
          ></i>
        </label>
        <span data-cy="CurriculoDadosEmailPessoal">{{
          state?.usuario.email_pessoal
        }}</span>
        <span data-cy="CurriculoDadosCelular"
          >Cel.: {{ state?.usuario.celular }}</span
        >
        <span data-cy="CurriculoDadosSkype">{{
          state?.usuario.rede_skype
        }}</span>
      </div>
      <div class="divisor"></div>
      <div class="dado">
        <label class="upper">{{ "CURRICULO.ABOUT" | translate }}</label>
        <span
          class="openable"
          [class.open]="
            state?.usuario.curriculoUsuario[0]?.sobremim?.length <= 120 ||
            moreSobremim
          "
          data-cy="CurriculoSobreMim"
          >{{ state?.usuario.curriculoUsuario[0]?.sobremim }}</span
        >
        <div
          class="more capitalize"
          *ngIf="
            state?.usuario.curriculoUsuario[0]?.sobremim?.length > 120 &&
            !moreSobremim
          "
          (click)="moreSobremim = true"
        >
          <div>
            <!-- <i class="fas fa-chevron-down"></i> -->
            <img src="assets\icons\fas-fa-chevron-down.svg" />
          </div>
          {{ "CURRICULO.EXIBIR_MAIS" | translate }}
        </div>
        <div
          class="more capitalize"
          *ngIf="
            state?.usuario.curriculoUsuario[0]?.sobremim?.length > 120 &&
            moreSobremim
          "
          (click)="moreSobremim = false"
        >
          <div><i class="fas fa-chevron-up mb-1"></i></div>
          {{ "CURRICULO.EXIBIR_MENOS" | translate }}
        </div>
      </div>
      <div class="divisor"></div>
      <div class="dado">
        <label class="upper">{{ "CURRICULO.INTERESTS" | translate }}</label>
        <span
          class="openable"
          [class.open]="
            state?.usuario.curriculoUsuario[0]?.interesses?.length <= 120 ||
            moreInteresses
          "
          ata-cy="CurriculoInteresses"
          >{{ state?.usuario.curriculoUsuario[0]?.interesses }}</span
        >
        <div
          class="more capitalize"
          *ngIf="
            state?.usuario.curriculoUsuario[0]?.interesses?.length > 120 &&
            !moreInteresses
          "
          (click)="moreInteresses = true"
        >
          <div>
            <!-- <i class="fas fa-chevron-down"></i> -->
            <img src="assets\icons\fas-fa-chevron-down.svg" />
          </div>
          {{ "CURRICULO.EXIBIR_MAIS" | translate }}
        </div>
        <div
          class="more capitalize"
          *ngIf="
            state?.usuario.curriculoUsuario[0]?.interesses?.length > 120 &&
            moreInteresses
          "
          (click)="moreInteresses = false"
        >
          <div><i class="fas fa-chevron-up mb-1"></i></div>
          {{ "CURRICULO.EXIBIR_MENOS" | translate }}
        </div>
      </div>
      <div class="divisor"></div>
      <div class="dado">
        <label class="upper">{{ "CURRICULO.SOCIAL_MEDIA" | translate }}</label>
        <div class="social-container">
          <div
            class="social"
            data-cy="CurriculoRedesSociaisLinkedin"
            (click)="goToSocialMedia(state?.usuario?.rede_linkedin, 'linkedin')"
          >
            <!-- <i class="fab fa-linkedin"></i> -->
            <img src="assets/icons/linkedin.svg" alt="linkedin" />
          </div>
          <div
            class="social"
            data-cy="CurriculoRedesSociaisTwitter"
            (click)="goToSocialMedia(state?.usuario?.rede_twitter, 'twitter')"
          >
            <!-- <i class="fab fa-twitter"></i> -->
            <img src="assets/icons/icon_x.svg" alt="X" />
          </div>
          <div
            class="social"
            data-cy="CurriculoRedesSociaisFacebook"
            (click)="goToSocialMedia(state?.usuario?.rede_facebook, 'facebook')"
          >
            <!-- <i class="fab fa-facebook-f"></i> -->
            <img src="assets/icons/facebook.svg" alt="facebook" />
          </div>
          <div
            class="social"
            data-cy="CurriculoRedesSociaisInstagram"
            (click)="
              goToSocialMedia(state?.usuario?.rede_instagram, 'instagram')
            "
          >
            <!-- <i class="fab fa-instagram"></i> -->
            <img src="assets/icons/instagram.svg" alt="instagram" />
          </div>
        </div>
      </div>
    </div>
    <div class="cv-data">
      <div
        class="back"
        (click)="goToPerfil()"
        data-cy="CurriculoDivVoltarPerfil"
      >
        <img src="assets/icons/arrow-left.svg" />
        <span class="upper">{{ "CURRICULO.BACK" | translate }} </span>
      </div>
      <div class="form-cv capitalize">
        <label>
          <img src="assets/icons/book-open.svg" />
          <span class="capitalize"> {{ "CURRICULO.COURSE" | translate }}</span>
        </label>
        <div class="box-container">
          <div class="box" *ngFor="let formacao of state?.catFormacao">
            <div class="left" data-cy="CurriculoPorcentagemFormacao">
              <cls-circle
                [size]="'medium'"
                [textColor]="'#8e8e8e'"
                [strokeFill]="'#F5F5F5'"
                [percentage]="formacao.valor_progresso.toString()"
              >
              </cls-circle>
            </div>
            <div class="right">
              <div class="title" data-cy="CurriculoFormacaoCurso">
                {{ formacao.curso }}
              </div>
              <div class="sub-title" data-cy="CurriculoFormacaoNivelEnsino">
                {{ formacao.nivel_ensino }}
              </div>
              <div class="sub-title" data-cy="CurriculoFormacaoInstituicao">
                {{ formacao.instituicao }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-cv">
        <label>
          <img src="../../../assets/icons/award-blue.svg" />
          <span class="capitalize">{{ "CURRICULO.SKILLS" | translate }} </span>
        </label>
        <div class="box-container">
          <div
            class="box small"
            *ngFor="
              let competencia of state?.catCompetencia | slice : 0 : limitComp
            "
          >
            <div class="left">
              <cls-circle
                [size]="'medium'"
                [textColor]="'#8e8e8e'"
                [value]="competencia?.valor?.toString()"
                [strokeFill]="'#F5F5F5'"
                [percentage]="competencia?.valor"
              >
              </cls-circle>
              <div
                class="lock"
                *ngIf="
                  competencia.valor == null &&
                  competencia.data_finalizacao == null
                "
              >
                <img src="../../../assets/icons/icone_cadeado.svg" />
              </div>
            </div>
            <div class="right pointer" (click)="goToProjeto(competencia)">
              <div class="title">{{ competencia.descricao }}</div>
              <div class="sub-title mt-1"># {{ competencia.nome }}</div>
            </div>
            <div
              *ngIf="
                sistema_configs.sistema_certificados &&
                checkIfAprovado(competencia) &&
                checkIfHasSignature(competencia)
              "
              class="certified"
            >
              <button
                *ngIf="!competencia.certificado_url"
                [disabled]="loading"
                (click)="emitCertified(competencia)"
                class="btn align-items-center"
              >
                <span>{{ "BUTTON.CERTIFICATION" | translate }}</span>
              </button>
              <button
                *ngIf="competencia.certificado_url"
                [disabled]="loading"
                (click)="downloadCertified(competencia)"
                class="btn align-items-center"
              >
                <span>{{ "BUTTON.DOWNLOAD_CERTIFICATION" | translate }}</span>
              </button>
            </div>
            <div class="star">
              <img
                *ngIf="!competencia.favorita"
                src="../../../assets/icons/star.svg"
              />
              <img
                *ngIf="competencia.favorita"
                src="../../../assets/icons/star-blue.svg"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="more capitalize"
        *ngIf="!showAllCompetencias && state?.catCompetencia?.length > 5"
        data-cy="CurriculoDivMostrarCompetencias"
        (click)="toggleShowAllCompetencias()"
      >
        {{ "CURRICULO.ALL_SKILLS" | translate }}
        <img src="../../../assets/icons/arrow-down.svg" />
      </div>
      <div
        class="more capitalize"
        *ngIf="showAllCompetencias && state?.catCompetencia?.length > 5"
        data-cy="CurriculoDivSomenteFavoritas"
        (click)="toggleShowAllCompetencias()"
      >
        {{ "CURRICULO.FAVS_SKILLS" | translate }}
        <img src="../../../assets/icons/arrow-up.svg" />
      </div>
      <div class="form-cv">
        <label>
          <img src="../../../assets/icons/globe.svg" />
          <span class="capitalize"
            >{{ "CURRICULO.LANGUAGE" | translate }}
          </span>
        </label>
        <div class="box-container">
          <div class="box small" *ngFor="let idioma of state?.catIdioma">
            <div class="left fluencias">
              <div class="fluencia marked"></div>
              <div
                class="fluencia"
                [class.marked]="
                  idioma.fluencia == 'Fluente' ||
                  idioma.fluencia == 'Avançado' ||
                  idioma.fluencia == 'Intermediário'
                "
              ></div>
              <div
                class="fluencia"
                [class.marked]="
                  idioma.fluencia == 'Fluente' || idioma.fluencia == 'Avançado'
                "
              ></div>
              <div
                class="fluencia"
                [class.marked]="idioma.fluencia == 'Fluente'"
              ></div>
            </div>
            <div class="right">
              <div class="title" data-cy="CurriculoIdiomaTitulo">
                {{ idioma.idioma }}
              </div>
              <div class="sub-title mt-1" data-cy="CurriculoIdiomaFluencia">
                {{ idioma.fluencia }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-cv">
          <label>
            <img src="../../../assets/icons/briefcase.svg" />
            <span class="capitalize"
              >{{ "CURRICULO.EXPERIENCE" | translate }}
            </span>
          </label>
          <div class="box-container">
            <div
              class="box small"
              *ngFor="let experiencia of state?.catExperiencia"
            >
              <div class="left">
                <img
                  class="mt-1 tamanho"
                  src="../../../assets/icons/briefcase.svg"
                />
              </div>
              <div class="right">
                <div class="title" data-cy="CurriculoExperienciaCorporacao">
                  {{ experiencia.corporacao }}
                  <div class="date" data-cy="CurriculoExperienciaDuracao">
                    de {{ experiencia.data_inicio }} até
                    {{ experiencia.data_final }}
                  </div>
                </div>
                <div class="sub-title mt-1" data-cy="CurriculoExperienciaCargo">
                  {{ experiencia.cargo }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-cv">
          <label>
            <img src="../../../assets/icons/user-blue.svg" />
            <span class="capitalize"
              >{{ "CURRICULO.BEHAVIORAL_PROFILE" | translate }}
            </span>
          </label>
          <div class="box-container">
            <div class="box small" *ngFor="let perfil of state?.catPerfil">
              <div class="left">
                <img
                  class="box-container__icon mt-1"
                  src="../../../assets/icons/star-blue-boarded.svg"
                />
              </div>
              <div class="right">
                <div class="title">
                  {{ perfil.titulo }}
                  <button
                    (click)="goToProfile(perfil)"
                    class="btn box-container__button-beggin"
                  >
                    {{ "BUTTON.VIEW_PROFILE" | translate }}
                  </button>
                </div>
                <div class="sub-title mt-1">
                  {{ perfil?.principal_caracteristica }}
                </div>
              </div>
            </div>
            <div class="box small" *ngIf="state?.catPerfil.length == 0">
              <div class="left">
                <img
                  class="box-container__icon mt-1"
                  src="../../../assets/icons/star-blue-boarded.svg"
                />
              </div>
              <div class="right">
                <div class="title" style="padding-top: 4px">
                  {{ "CURRICULO.NO_TEST" | translate }}
                  <button
                    (click)="doToProfileTest()"
                    class="btn box-container__button-beggin"
                  >
                    {{ "BUTTON.TEST" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="box-container__right"
            *ngIf="state?.catPerfil.length != 0"
          >
            <button
              class="btn box-container__right-button"
              (click)="doToProfileTest()"
            >
              {{ "BUTTON.RETAKE_TEST" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="main-container"
    [class.edit]="state.curriculoEdit"
    *ngIf="state.curriculoEdit"
  >
    <div class="info">
      <div class="name">
        <input
          class="big"
          type="text"
          [(ngModel)]="state?.usuario.nome_social"
          data-cy="CurriculoDivNomeSocial"
        />
      </div>
      <div class="competencias">
        <div
          class="competencia"
          *ngFor="let competencia of state?.usuario.competencias"
        >
          <span>#</span>{{ competencia.nome }}
        </div>
      </div>
      <div class="dado">
        <label class="upper">{{ "CURRICULO.PERSONAL_DATA" | translate }}</label>
        <input
          type="text"
          placeholder="{{ 'CURRICULO_EDIT.PERSONAL_DATA_EMAIL' | translate }}"
          [(ngModel)]="state?.usuario.email_pessoal"
          data-cy="CurriculoInputEmail"
        />
        <input
          type="text capitalize"
          placeholder="{{ 'CURRICULO_EDIT.PERSONAL_DATA_CEL' | translate }}"
          class="mt-2"
          [(ngModel)]="state?.usuario.celular"
          data-cy="CurriculoInputCelular"
        />
        <input
          type="text capitalize"
          placeholder="{{ 'CURRICULO_EDIT.PERSONAL_DATA_SKYPE' | translate }}"
          class="mt-2"
          [(ngModel)]="state?.usuario.rede_skype"
          data-cy="CurriculoInputSkype"
        />
      </div>
      <div class="dado">
        <label class="upper">{{ "CURRICULO.ABOUT" | translate }}</label>
        <textarea
          placeholder="{{ 'CURRICULO_EDIT.ABOUT' | translate }}"
          data-cy="CurriculoTextSobreMim"
          [(ngModel)]="state?.usuario.curriculoUsuario[0].sobremim"
        ></textarea>
      </div>
      <div class="dado">
        <label class="upper">{{ "CURRICULO.INTERESTS" | translate }}</label>
        <textarea
          placeholder="{{ 'CURRICULO_EDIT.INTERESTS' | translate }}"
          data-cy="CurriculoTextInteresses"
          [(ngModel)]="state?.usuario.curriculoUsuario[0].interesses"
        ></textarea>
      </div>
      <div class="dado">
        <label class="upper">{{ "CURRICULO.SOCIAL_MEDIA" | translate }}</label>
        <div class="social-container flex-column">
          <div class="d-flex align-items-baseline">
            <div class="social">
              <!-- <i class="fab fa-linkedin"></i> -->
              <img src="assets/icons/linkedin.svg" alt="linkedin" />
            </div>
            <input
              type="text"
              class="italic"
              data-cy="CurriculoInputLinkedin"
              placeholder="https://www.linkedin.com/in/nome"
              [(ngModel)]="state?.usuario.rede_linkedin"
            />
          </div>
          <div class="d-flex align-items-baseline">
            <div class="social mt-2">
              <!-- <i class="fab fa-twitter"></i> -->
              <img src="assets/icons/icon_x.svg" alt="X" />
            </div>
            <input
              type="text"
              class="italic"
              data-cy="CurriculoInputTwitter"
              placeholder="https://twitter.com/nome"
              [(ngModel)]="state?.usuario.rede_twitter"
            />
          </div>
          <div class="d-flex align-items-baseline">
            <div class="social mt-2">
              <!-- <i class="fab fa-facebook-f"></i> -->
              <img src="assets/icons/facebook.svg" alt="facebook" />
            </div>
            <input
              type="text"
              class="italic"
              data-cy="CurriculoInputFacebook"
              placeholder="https://facebook.com/nome"
              [(ngModel)]="state?.usuario.rede_facebook"
            />
          </div>
          <div class="d-flex align-items-baseline">
            <div class="social mt-2">
              <!-- <i class="fab fa-instagram"></i> -->
              <img src="assets/icons/instagram.svg" alt="instagram" />
            </div>
            <input
              type="text"
              class="italic"
              data-cy="CurriculoInputInstagram"
              placeholder="https://www.instagram.com/nome"
              [(ngModel)]="state?.usuario.rede_instagram"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="cv-data">
      <div class="form-cv">
        <label>
          <img src="../../../assets/icons/book-open.svg" />
          <span class="capitalize">
            {{ "CURRICULO.FORMATION" | translate }}
          </span>
        </label>
        <div class="box-container formacao">
          <div
            class="box"
            *ngFor="let formacao of state?.catFormacao; let i = index"
          >
            <div class="left">
              <cls-circle
                [size]="'medium'"
                [textColor]="'#8e8e8e'"
                [strokeFill]="'#F5F5F5'"
                [percentage]="formacao.valor_progresso.toString()"
              >
              </cls-circle>
            </div>
            <div class="right">
              <input
                type="text"
                class="sz-limit"
                placeholder="{{
                  'CURRICULO_EDIT.FORMATION_COURSE' | translate
                }}"
                data-cy="CurriculoInputCurso"
                [(ngModel)]="formacao.curso"
              />
              <select
                class="sz-limit mt-2"
                [(ngModel)]="formacao.nivel_ensino"
                data-cy="CurriculoSelectNivelEnsino"
              >
                <option value="" selected disabled>
                  {{ "CURRICULO_EDIT.FORMATION_LEVEL" | translate }}
                </option>
                <option value="Graduação" data-cy="CurriculoOptionGraduacao">
                  {{ "CURRICULO_EDIT.FORMATION_GRADUATION" | translate }}
                </option>
                <option
                  value="Pós-Graduação"
                  data-cy="CurriculoOptionPosGraduacao"
                >
                  {{ "CURRICULO_EDIT.FORMATION_POST_GRADUATION" | translate }}
                </option>
                <option value="Extensão" data-cy="CurriculoOptionExtensao">
                  {{ "CURRICULO_EDIT.FORMATION_EXTENSION" | translate }}
                </option>
              </select>
              <input
                type="text"
                class="sz-limit mt-2 capitalize"
                placeholder="{{
                  'CURRICULO_EDIT.FORMATION_INSTITUTION' | translate
                }}"
                [(ngModel)]="formacao.instituicao"
                data-cy="CurriculoInputInstituicao"
              />
            </div>
            <img
              (click)="toggleFormacao(i)"
              class="trash pointer mr-3"
              src="../../../assets/icons/trash-2.svg"
              data-cy="CurriculoFormacaoDeletar"
            />
          </div>
        </div>
      </div>
      <div class="add upper">
        <span
          (click)="toggleFormacao()"
          data-cy="CurriculoDivAdicionarFormacao"
          >{{ "CURRICULO_EDIT.FORMATION_ADD" | translate }}</span
        >
      </div>
      <div class="form-cv">
        <label>
          <img src="../../../assets/icons/award-blue.svg" />
          <span class="capitalize"> {{ "CURRICULO.SKILLS" | translate }} </span>
        </label>
        <div class="box-container">
          <div
            class="box small"
            *ngFor="let competencia of state?.catCompetencia"
          >
            <div class="left">
              <cls-circle
                *ngIf="competencia?.valor > 0"
                [size]="'medium'"
                [value]="competencia?.valor?.toString()"
                [textColor]="'#8e8e8e'"
                [strokeFill]="'#F5F5F5'"
                [percentage]="competencia?.valor"
              >
              </cls-circle>
              <div
                class="lock"
                *ngIf="
                  competencia.valor == null &&
                  competencia.data_finalizacao == null
                "
              >
                <img src="../../../assets/icons/icone_cadeado.svg" />
              </div>
            </div>
            <div class="right">
              <div class="title">{{ competencia.descricao }}</div>
              <div class="sub-title mt-1"># {{ competencia.nome }}</div>
            </div>
            <div class="star" data-cy="CurriculoDivFavoriteCompetencia">
              <img
                class="pointer"
                (click)="toggleFavorita(competencia)"
                *ngIf="!competencia.favorita"
                src="../../../assets/icons/star.svg"
              />
              <img
                class="pointer"
                (click)="toggleFavorita(competencia)"
                *ngIf="competencia.favorita"
                src="../../../assets/icons/star-blue.svg"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="form-cv">
        <label>
          <img src="../../../assets/icons/globe.svg" />
          <span class="capitalize">
            {{ "CURRICULO.LANGUAGE" | translate }}
          </span>
        </label>
        <div class="box-container">
          <div
            class="box small"
            *ngFor="let idioma of state?.catIdioma; let i = index"
          >
            <div class="left fluencias">
              <div class="fluencia marked"></div>
              <div
                class="fluencia"
                [class.marked]="
                  idioma.fluencia == 'Fluente' ||
                  idioma.fluencia == 'Avançado' ||
                  idioma.fluencia == 'Intermediário'
                "
              ></div>
              <div
                class="fluencia"
                [class.marked]="
                  idioma.fluencia == 'Fluente' || idioma.fluencia == 'Avançado'
                "
              ></div>
              <div
                class="fluencia"
                [class.marked]="idioma.fluencia == 'Fluente'"
              ></div>
            </div>
            <div class="right flex-row align-items-center">
              <select
                class="sz-limit"
                [(ngModel)]="idioma.idioma"
                data-cy="CurriculoSelectIdioma"
              >
                <option value="" disabled selected>
                  {{ "CURRICULO_EDIT.LANGUAGE_SELECT" | translate }}
                </option>
                <option *ngFor="let lang of idiomasArray">{{ lang }}</option>
              </select>
              <select
                class="ml-3"
                [(ngModel)]="idioma.fluencia"
                data-cy="CurriculoSelectFluencia"
              >
                <option value="Básico" data-cy="CurriculoOptionFluenciaBasico">
                  {{ "CURRICULO_EDIT.LANGUAGE_SELECT1" | translate }}
                </option>
                <option
                  value="Intermediário"
                  data-cy="CurriculoOptionFluenciaIntermediario"
                >
                  {{ "CURRICULO_EDIT.LANGUAGE_SELECT2" | translate }}
                </option>
                <option
                  value="Avançado"
                  data-cy="CurriculoOptionFluenciaAvancado"
                >
                  {{ "CURRICULO_EDIT.LANGUAGE_SELECT3" | translate }}
                </option>
                <option
                  value="Fluente"
                  data-cy="CurriculoOptionFluenciaFluente"
                >
                  {{ "CURRICULO_EDIT.LANGUAGE_SELECT4" | translate }}
                </option>
              </select>
            </div>
            <img
              (click)="toggleIdioma(i)"
              class="trash pointer mr-3"
              data-cy="CurriculoIdiomaDeletar"
              src="../../../assets/icons/trash-2.svg"
            />
          </div>
        </div>
      </div>
      <div class="add upper">
        <span (click)="toggleIdioma()" data-cy="CurriculoAdicionarIdioma">{{
          "CURRICULO_EDIT.LANGUAGE_ADD" | translate
        }}</span>
      </div>
      <div class="form-cv">
        <label>
          <img src="../../../assets/icons/briefcase.svg" />
          <span class="capitalize">
            {{ "CURRICULO.EXPERIENCE" | translate }}
          </span>
        </label>
        <div class="box-container">
          <div
            class="box small"
            *ngFor="let experiencia of state?.catExperiencia; let i = index"
          >
            <div class="left">
              <img
                class="mt-1 tamanho"
                src="../../../assets/icons/briefcase.svg"
              />
            </div>
            <div class="right pr-5">
              <div class="d-flex pr-5 align-items-center justify-content-end">
                <input
                  class="sz-limit mr-auto"
                  placeholder="{{
                    'CURRICULO_EDIT.EXPERIENCE_CORP' | translate
                  }}"
                  [(ngModel)]="experiencia.corporacao"
                  data-cy="CurriculoInputCorporacao"
                />
                <span>DE:</span>
                <input
                  type="text"
                  placeholder="MM/YYYY"
                  [(ngModel)]="experiencia.data_inicio"
                  class="w-date"
                  maxlength="7"
                  data-cy="CurriculoInputDataInicio"
                />
              </div>
              <div
                class="d-flex mt-2 pr-5 align-items-center justify-content-end"
              >
                <input
                  class="sz-limit mr-auto"
                  placeholder="{{
                    'CURRICULO_EDIT.EXPERIENCE_OFFICE' | translate
                  }}"
                  [(ngModel)]="experiencia.cargo"
                  data-cy="CurriculoInputCargo"
                />
                <span>ATÉ:</span>
                <input
                  type="text"
                  placeholder="MM/YYYY"
                  [(ngModel)]="experiencia.data_final"
                  class="w-date"
                  maxlength="7"
                  data-cy="CurriculoInputDataFinal"
                />
              </div>
            </div>
            <img
              (click)="toggleExperiencia(i)"
              class="trash pointer mr-3"
              data-cy="CurriculoExperienciaDeletar"
              src="../../../assets/icons/trash-2.svg"
            />
          </div>
        </div>
      </div>
      <div class="add upper">
        <span
          (click)="toggleExperiencia()"
          data-cy="CurriculoAdicionarExperiencia"
          >{{ "CURRICULO_EDIT.EXPERIENCE_ADD" | translate }}</span
        >
      </div>
    </div>
  </div>
  <div class="footer-edit" *ngIf="state.curriculoEdit">
    <button
      class="btn cancel mr-5 upper"
      (click)="setCurriculoEdition(false)"
      data-cy="CurriculoBtnCancelarEdicao"
    >
      {{ "CURRICULO_EDIT.EDITION_CANCEL" | translate }}
    </button>
    <button
      class="btn save mr-5 upper"
      (click)="saveData()"
      data-cy="CurriculoBtnSalvar"
    >
      {{ "CURRICULO_EDIT.EDITION_SAVE" | translate }}
    </button>
  </div>
</div>
<app-menu *ngIf="state?.showingMenu"></app-menu>
