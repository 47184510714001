<div class="carousel-container" [ngClass]="size">
  <div class="carousel-items" #carousel>
    <img
      (click)="
        !scrooling ? scrollProjetosSmoothTemp('left') : $event.stopPropagation()
      "
      [src]="
        countScrolls > 0
          ? 'assets/icons/chevron-right-blue.svg'
          : 'assets/icons/chevron-right-gray.svg'
      "
    />
    <div class="overlay-left"></div>

    <ng-content></ng-content>

    <div class="overlay-right"></div>
    <img
      (click)="
        !scrooling
          ? scrollProjetosSmoothTemp('right')
          : $event.stopPropagation()
      "
      [src]="
        showRightArrow
          ? 'assets/icons/chevron-right-blue.svg'
          : 'assets/icons/chevron-right-gray.svg'
      "
    />
  </div>
</div>
