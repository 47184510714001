import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DatatableService {

  state: any;
  stateSubscription: any;
  gatewayAPI = environment.api;

  constructor(private store: Store<AppState>, private http: HttpClient) {
    this.getState();
  }

  getState() {
    this.stateSubscription = this.store.select((state: any) => {
      const { token, projetoAtivo } = state.newAppState;
      this.state = { token, projetoAtivo }
    }).subscribe();
  }

  getHeader() {
    const header = {
      headers: new HttpHeaders()
        .set('Authorization', 'bearer ' + this.state.token)
    };
    return header;
  }

  getAtendimentosSecretaria(filters = null) {
    const projectField = {field: 'projeto_turma_id_projeto_turma', value: this.state.projetoAtivo.id_projeto_turma};

    if (!filters)
      filters = {filters: [projectField]};
    else
      filters.filters.push(projectField);

    filters.sort = [
      {
        field: 'suporte_status_final',
        orientation: 'asc'
      },
      {
        field: 'suporte_data_abertura',
        orientation: 'desc'
      }
    ];
    return this.http.post(this.gatewayAPI + 'suporte/secretaria/datalist', filters, this.getHeader()).toPromise();
  }

}
