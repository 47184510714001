<div class="side-home">
  <div class="sidebutton-container sidebutton-up">
    <button
      id="scroll-button"
      class="sidebutton"
      (click)="
        !scrooling && countScrolls > 0
          ? scrollMenuSmoothTemp('up')
          : $event.stopPropagation()
      "
    >
      <img
        [src]="
          countScrolls > 0
            ? 'assets/icons/chevron-right-blue.svg'
            : 'assets/icons/chevron-right-gray.svg'
        "
        class="sidebutton-img"
      />
    </button>
  </div>
  <section class="side-scroll" #carousel>
    <div class="sidebutton-container">
      <button
        id="notificacao-button"
        class="sidebutton"
        [ngClass]="
          notificacoes?.mensagensNaoLidas?.length > 0
            ? 'notificacao-button'
            : ''
        "
        (click)="abrirNotificacao()"
      >
        <img
          src="assets/icons/icone_notificacoes_gray.svg"
          class="sidebutton-img"
        />
        <label
          class="notificacao-numero"
          for="notificacao-button"
          *ngIf="notificacoes?.mensagensNaoLidas?.length > 0"
        >
          {{ notificacoes?.mensagensNaoLidas?.length }}
        </label>
      </button>
    </div>
    <div class="sidebutton-container">
      <button
        id="configuracoes-button"
        class="sidebutton"
        (click)="showModalConfiguracoes()"
      >
        <img
          src="assets/icons/icone_configuracoes.svg"
          class="sidebutton-img"
        />
      </button>
    </div>
    <div class="sidebutton-container">
      <button id="calendar-button" class="sidebutton" (click)="goToAgenda()">
        <img src="assets/icons/calendar.svg" class="sidebutton-img" />
      </button>
    </div>
    <div class="sidebutton-container">
      <button id="help-button" class="sidebutton" (click)="goToCentralAjuda()">
        <img
          src="assets/icons/question-menu.svg"
          class="sidebutton-img-question"
        />
        <!-- <img src="assets/old/question-menu_old.svg" class="sidebutton-img-question" /> -->
      </button>
    </div>
    <div class="sidebutton-container">
      <button id="logout-button" class="sidebutton" (click)="logout()">
        <img src="assets/icons/icone_sair.svg" class="sidebutton-img" />
      </button>
    </div>
  </section>
  <div class="sidebutton-container sidebutton-down">
    <button
      id="scroll-button"
      class="sidebutton"
      (click)="
        !scrooling && showDownArrow
          ? scrollMenuSmoothTemp('down')
          : $event.stopPropagation()
      "
    >
      <img
        [src]="
          showDownArrow
            ? 'assets/icons/chevron-right-blue.svg'
            : 'assets/icons/chevron-right-gray.svg'
        "
        class="sidebutton-img"
      />
    </button>
  </div>
</div>

<!--NOTIFICAÇÕES-->
<ui-sidebar-content
  titulo="{{ 'NOTIFICATIONS.TITLE' | translate }}"
  class="capitalize"
  (closeSidebar)="closeSidebar()"
  (marcarTodasNotificacoes)="marcarNotificacaoComoLida()"
  [showSidebar]="showNotificacao"
  [notificacoesNaoLidas]="notificacoesNaoLidas"
>
  <ui-card-data
    *ngFor="let mensagens of notificacoes?.mensagens | sortArrayDateNullFirst"
    [data]="mensagens[0].notificacao_usuario_data_envio"
  >
    <ui-mensagem-list
      *ngFor="
        let mensagem of mensagens | sortDateNullFirst : 'data_leitura';
        let i = index
      "
      [mensagem]="mensagem"
      [ultimaMensagem]="mensagens.length - 1 === i"
      [mensagemLida]="!!mensagem.data_leitura"
      (marcarLido)="marcarComoLido($event)"
    ></ui-mensagem-list>
  </ui-card-data>
  <p *ngIf="notificacoes?.mensagens?.length === 0" class="capitalize">
    {{ "NOTIFICATIONS.NONE" | translate }}
  </p>
</ui-sidebar-content>
<!--NOTIFICAÇÕES-->

<app-agenda
  *ngIf="state?.showingAgenda == 'open'"
  (closeMenu)="closeMenu()"
></app-agenda>

<cls-modal-configuracoes
  *ngIf="state?.showModalConfiguracoes"
></cls-modal-configuracoes>
