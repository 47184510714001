<div class="secretaria-modal-container">
    <div class="close" (click)="close()"><i class="fas fa-times"></i></div>
    <div class="header">
        <div class="asides">
            <div>
                <div class="capitalize"><span>{{ 'SECRETARIA.PROTOCOL' | translate }}</span>{{state?.secretariaModal?.suporte_instance}}</div>
                <div class="capitalize"><span>{{ 'SECRETARIA.SERVICE' | translate }}</span>{{state?.secretariaModal?.suporte_categoria_nome | slice: 0:20}}</div>
                <div class="capitalize"><span>{{ 'SECRETARIA.TOPIC' | translate }}</span>{{state?.secretariaModal?.suporte_assunto  | slice: 0:20}}</div>
            </div>
            <div>
                <div class="capitalize"><span>{{ 'SECRETARIA.STATUS' | translate }}</span>{{state?.secretariaModal?.suporte_status_nome}}</div>
                <div class="capitalize"><span>{{ 'SECRETARIA.OPEN' | translate }}</span>{{state?.secretariaModal?.suporte_data_abertura | date:'dd/MM/yyyy - HH:mm'}}</div>
            </div>
        </div>
    </div>
    <cls-hub-scrollable-chat [messages]="state?.secretariaModal" (reply)="sendReply($event)"></cls-hub-scrollable-chat>
</div>