<div class="avaliacao-perfil">
    <div>
        <div class="avaliacao-perfil__button-back" (click)="exit()">
            <img class="avaliacao-perfil__button-back-arrow"
                 src="../../../assets/icons/chevron-left-white.svg">
            <p class="avaliacao-perfil__button-back-text capitalize">{{ 'BEHAVIORAL_PROFILE.BACK' | translate }}</p>
        </div>
        <div class="avaliacao-perfil__main-card">
            <app-spinner *ngIf="isLoading" class="avaliacao-perfil__spinner"></app-spinner>
            <div *ngIf="!isLoading">
                <div class="avaliacao-perfil__header">
                    <div class="avaliacao-perfil__header-img">
                        <img class="avaliacao-perfil__header-img-arrow"
                            src='{{ curriculumProfile?.imagem}}'
                        >
                    </div>
                    <div class="avaliacao-perfil__header-description">
                        <p class="avaliacao-perfil__header-description-title capitalize">{{ 'BEHAVIORAL_PROFILE.YOU_ARE' | translate }} {{ curriculumProfile?.titulo || ''}}</p>
                        <div class="avaliacao-perfil__header-description-line"></div>
                        <p class="avaliacao-perfil__header-description-desc capitalize">{{ 'BEHAVIORAL_PROFILE.MAIN' | translate }}</p>
                        <div 
                            class="avaliacao-perfil__header-description-desctext" 
                            [innerHTML]="curriculumProfile?.principal_caracteristica"
                        ></div>
                    </div>
                </div>
                <div class="avaliacao-perfil__descriptions">
                    <div class="avaliacao-perfil__descriptions-item" *ngFor="let curriculum of curriculumProfileExistingItems">
                        <p class="avaliacao-perfil__descriptions-item-title">{{curriculum.title}}</p>
                        <div class="avaliacao-perfil__descriptions-item-img">
                            <div class="avaliacao-perfil__descriptions-item-img-arrow--white"></div>
                            <div class="avaliacao-perfil__descriptions-item-img-arrow--blue"></div>
                            <div class="avaliacao-perfil__descriptions-item-img-line"></div>
                        </div>
                        <div 
                            class="avaliacao-perfil__descriptions-item-desc" 
                            [innerHTML]="curriculum.description | sanitizeHtml"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="detail-1"></div>
        <div class="detail-2"></div>
    </div>
</div>

