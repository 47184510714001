import { BrowserModule } from '@angular/platform-browser';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ClsLigaComponentsModule } from 'cls-liga-components'; // calendar component
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MomentModule } from 'ngx-moment';
import { OrderModule } from 'ngx-order-pipe';
import { NgxMaskModule } from 'ngx-mask';
import { ClickOutsideModule } from 'ng-click-outside';

import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { ProjetoComponent } from './components/projeto/projeto.component';
import { HomeBoxComponent } from './components/home-box/home-box.component';
import { FooterComponent } from './components/footer/footer.component';

import { KeepHtmlPipe } from './pipes/keep-html/heep-html.pipe';
import { SafeUrl } from './pipes/safe-url/safe-url.pipe';
import { ConvertTimePipe } from './pipes/convert-time/convert-time.pipe';
import { ConvertHourPipe } from './pipes/convert-hour/convert-hour.pipe';
import { SortPipeDateNullFirst } from './pipes/sort-date-null-first/sort-date-null-first.pipe';
import { SortPipeArrayDateNullFirst } from './pipes/sort-array-date-null-first/sort-array-date-null-first.pipe';

import { SaibaMaisComponent } from './components/saiba-mais/saiba-mais.component';
import { InteracoesComponent } from './components/interacoes/interacoes.component';

import { StoreModule } from '@ngrx/store';
import { reducer } from '../app/state/state.reducer';
import { TypeMesaPipe } from './pipes/atividades/type-mesa.pipe';
import { AgendaComponent } from './components/agenda/agenda.component';
import { LoginComponent } from './components/login/login.component';
import { MenuComponent } from './components/menu/menu.component';
import { CloseOnClickOutsideDirective } from './directives/close-on-click-outside.directive';
import { SolicitacoesComponent } from './components/solicitacoes/solicitacoes.component';
import { ModalConfiguracoesComponent } from './components/modal-configuracoes/modal-configuracoes.component';
import { Router, Scroll } from '@angular/router';
import { CommonModule, ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';
import { QuizComponent } from './components/quiz/quiz.component';
import { MenuFaseComponent } from './components/menu-fase/menu-fase.component';
import { ContentFaseComponent } from './components/content-fase/content-fase.component';
import { CircleComponent } from './components/circle/circle.component';
import { ChatComponent } from './components/chat/chat.component';
import { MessageBoxComponent } from './components/_shared/message-box/message-box.component';
import { FaseDetailsComponent } from './components/fase-details/fase-details.component';
import { PerfilComponent } from './pages/perfil/perfil/perfil.component';
import { ProjetoBoxComponent } from './components/projeto-box/projeto-box.component';
import { CurriculoComponent } from './components/curriculo/curriculo.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { AvaliacaoComponent } from './components/avaliacao/avaliacao.component';
import { GabaritoAvaliacaoComponent } from './components/gabarito-avaliacao/gabarito-avaliacao.component';
import { QuestionarioAvaliacaoComponent } from './components/questionario-avaliacao/questionario-avaliacao.component';
import { ToastComponent } from './shared-components/toast/toast.component';
import { ScrollableChatComponent } from './shared-components/scrollable-chat/scrollable-chat.component';
import { CarouselComponent } from './shared-components/carousel/carousel.component';
import { SingleSignOnComponent } from './components/single-sign-on/single-sign-on.component';
import { SoftSkillBoxComponent } from './components/soft-skill-box/soft-skill-box.component';
import { ModalVideoComponent } from './shared-components/modal-video/modal-video.component';
import { CasoSucessoBoxComponent } from './components/caso-sucesso-box/caso-sucesso-box.component';
import { DownloadArquivoComponent } from './shared-components/ui/download-arquivo/download-arquivo.component';
import { AccordionTentativaComponent } from './shared-components/ui/accordion-tentativa/accordion-tentativa.component';
import { ArquivoAvaliacaoAlunoComponent } from './components/arquivo-avaliacao-aluno/arquivo-avaliacao-aluno.component';
import { UploadListaArquivosComponent } from './shared-components/ui/upload-lista-arquivos/upload-lista-arquivos.component';
import { ContainerDetalhesModule } from '../../projects/ui/src/lib/layout/container-detalhes/container-detalhes.module';
import { AvaliacaoModule } from '../../projects/ui/src/lib/layout/avaliacao/avaliacao.module';
import { HeaderHomeModule } from '../../projects/ui/src/lib/core/header-home/header-home.module';
import { SidebarContentModule } from '../../projects/ui/src/lib/core/sidebar-content/sidebar-content.module';
import { CardDataModule } from '../../projects/ui/src/lib/layout/card-data/card-data.module';
import { MensagemListModule } from '../../projects/ui/src/lib/layout/mensagem/mensagem-list.module';
import { AtendimentoBoxComponent } from './components/atendimento-box/atendimento-box.component';
import { DatatableComponent } from './shared-components/datatable/datatable.component';
import { AtendimentoModel } from './models/atendimento.model';
import { SecretariaModalComponent } from './components/secretaria-modal/secretaria-modal.component';
import { HubScrollableChatComponent } from './shared-components/hub-scrollable-chat/hub-scrollable-chat.component';
import { ProgressComponent } from './shared-components/ui/progress/progress.component';
import { MainViewComponent } from './components/login/components/main-view/main-view.component';
import { CarouselLoginComponent } from './components/login/components/carousel-login/carousel-login.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ConfirmModalComponent } from './shared-components/confirm-modal/confirm-modal.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { CardAvaliacaoComponent } from './components/avaliacao-discursiva/components/card-avaliacao/card-avaliacao.component';
import { RespostaAvaliacaoComponent } from './components/avaliacao-discursiva/components/resposta-avaliacao/resposta-avaliacao.component';
import { RevisaoAvaliacaoComponent } from './components/avaliacao-discursiva/components/revisao-avaliacao/revisao-avaliacao.component';
import { AcordionRevisaoComponent } from './components/avaliacao-discursiva/components/acordion-revisao/acordion-revisao.component';
import { GabaritoQuestaoComponent } from './components/avaliacao-discursiva/components/gabarito-questao/gabarito-questao.component';
import { QuestionarioPerfilComponent } from './components/questionario-perfil/questionario-perfil.component';
import { PerfilUsuarioComponent } from './components/perfil-usuario/perfil-usuario.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { RespostaAvaliacaoQuizComponent } from './components/avaliacao-discursiva/components/resposta-avaliacao-quiz/resposta-avaliacao-quiz.component';
import { AvaliacaoDiscursivaComponent } from './components/avaliacao-discursiva/avaliacao-discursiva.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { DocumentViewerComponent } from './components/document-viewer/document-viewer.component';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { SanitizeHtmlModule } from 'projects/ui/src/lib/layout/sanitize-html/sanitize-html.module';
import { ModalPesquisaComponent } from './components/modal-pesquisa/modal-pesquisa.component';
import { PerguntaRankingComponent } from './components/modal-pesquisa/components/pergunta-ranking/pergunta-ranking.component';
import { PerguntaDiscursivaComponent } from './components/modal-pesquisa/components/pergunta-discursiva/pergunta-discursiva.component';
import { ModalPesquisaSucessoComponent } from './components/modal-pesquisa/components/modal-pesquisa-sucesso/modal-pesquisa-sucesso.component';
import { CertificadoValidadeComponent } from './components/certificado-validade/certificado-validade.component';
import { RespostaAvaliacaoQuizNovoComponent } from './components/avaliacao-discursiva/components/resposta-avaliacao-quiz-novo/resposta-avaliacao-quiz-novo.component';
import { GabaritoQuizNovoComponent } from './components/gabarito-quiz-novo/gabarito-quiz-novo.component';
import { RevisaoQuizNovoComponent } from './components/avaliacao-discursiva/components/revisao-quiz-novo/revisao-quiz-novo.component';
import { EventosBoxComponent } from './components/eventos-box/eventos-box.component';
import { ModalEventoComponent } from './components/eventos-box/modal-evento/modal-evento.component';
import { ProjetoCardComponent } from './components/projeto-card/projeto-card.component';
import { Homev2Component } from './pages/homev2/homev2.component';
import { Perfilv2Component } from './pages/homev2/perfilv2/perfilv2.component';
import { ContentsBoxComponent } from './components/contents-box/contents-box.component';
import { CarrosselDetailsComponent } from './pages/homev2/carrossel-details/carrossel-details.component';
import { SideMenuComponent } from './pages/homev2/side-menu/side-menu.component';
import { CarrosselFiltersComponent } from './pages/homev2/carrossel-details/carrossel-filters/carrossel-filters.component';
import { MaterialModule } from './material/material.module';

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		HeaderComponent,
		ProjetoComponent,
		HomeBoxComponent,
		FaseDetailsComponent,
		FooterComponent,
		// SanitizeHtmlPipe,
		KeepHtmlPipe,
		ConvertTimePipe,
		ConvertHourPipe,
		SafeUrl,
		SaibaMaisComponent,
		InteracoesComponent,
		TypeMesaPipe,
		AgendaComponent,
		LoginComponent,
		ChatComponent,
		MenuComponent,
		CloseOnClickOutsideDirective,
		SolicitacoesComponent,
		MessageBoxComponent,
		ModalConfiguracoesComponent,
		QuizComponent,
		MenuFaseComponent,
		ContentFaseComponent,
		CircleComponent,
		PerfilComponent,
		ProjetoBoxComponent,
		CurriculoComponent,
		ResetPasswordComponent,
		AvaliacaoComponent,
		GabaritoAvaliacaoComponent,
		QuestionarioAvaliacaoComponent,
		ToastComponent,
		ScrollableChatComponent,
		DownloadArquivoComponent,
		AccordionTentativaComponent,
		ArquivoAvaliacaoAlunoComponent,
		UploadListaArquivosComponent,
		CarouselComponent,
		SingleSignOnComponent,
		SoftSkillBoxComponent,
		ModalVideoComponent,
		CasoSucessoBoxComponent,
		AtendimentoBoxComponent,
		DatatableComponent,
		SecretariaModalComponent,
		HubScrollableChatComponent,
		ProgressComponent,
		MainViewComponent,
		CarouselLoginComponent,
		ConfirmModalComponent,
		AvaliacaoDiscursivaComponent,
		RespostaAvaliacaoQuizComponent,
		CardAvaliacaoComponent,
		RespostaAvaliacaoComponent,
		RevisaoAvaliacaoComponent,
		AcordionRevisaoComponent,
		GabaritoQuestaoComponent,
		QuestionarioPerfilComponent,
		PerfilUsuarioComponent,
		SpinnerComponent,
		DocumentViewerComponent,
		ImageViewerComponent,
		ModalPesquisaComponent,
		PerguntaRankingComponent,
		PerguntaDiscursivaComponent,
		ModalPesquisaSucessoComponent,
		CertificadoValidadeComponent,
		RespostaAvaliacaoQuizNovoComponent,
		GabaritoQuizNovoComponent,
		RevisaoQuizNovoComponent,
		EventosBoxComponent,
		ModalEventoComponent,
		SortPipeDateNullFirst,
		SortPipeArrayDateNullFirst,
		ProjetoCardComponent,
		Homev2Component,
		Perfilv2Component,
		PerfilComponent,
		ContentsBoxComponent,
		CarrosselDetailsComponent,
		SideMenuComponent,
		CarrosselFiltersComponent,
	],
	imports: [
		DragDropModule,
		EditorModule,
		SanitizeHtmlModule,
		BrowserModule,
		CommonModule,
		FormsModule,
		HttpClientModule,
		AppRoutingModule,
		ClsLigaComponentsModule,
		StoreModule.forRoot({ newAppState: reducer }),
		BrowserAnimationsModule,
		MomentModule,
		OrderModule,
		NgxMaskModule.forRoot(),
		ClickOutsideModule,
		ReactiveFormsModule,
		ContainerDetalhesModule,
		AvaliacaoModule,
		HeaderHomeModule,
		SidebarContentModule,
		CardDataModule,
		MensagemListModule,
		CarouselModule,
		TranslateModule.forRoot({
			defaultLanguage: environment.defaultLanguage,
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
		}),
		NgxDocViewerModule,
		CommonModule,
		MaterialModule,
	],
	providers: [
		FormBuilder,
		AtendimentoModel,
		{ provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(private router: Router, viewportScroller: ViewportScroller) {
		this.router.events
			.pipe(filter((e) => e instanceof Scroll))
			.subscribe((e: any) => {
				if (e.position) {
					// backward navigation
					viewportScroller.scrollToPosition(e.position);
				} else if (e.anchor) {
					// anchor navigation
					viewportScroller.scrollToAnchor(e.anchor);
				} else {
					// forward navigation
					viewportScroller.scrollToPosition([0, 0]);
				}
			});
	}
}
