import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'cls-revisao-avaliacao',
  templateUrl: './revisao-avaliacao.component.html',
  styleUrls: ['./revisao-avaliacao.component.scss']
})
export class RevisaoAvaliacaoComponent implements OnInit {

  @Input() questions: any;
  @Output() congrats = new EventEmitter();

  constructor() { }

  ngOnInit(): void {}

  emitCongrats(){
    this.congrats.emit(true);
  }

}
