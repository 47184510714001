import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterCarrosselModel } from './models/filter.model';
import * as moment from 'moment';

@Component({
	selector: 'app-carrossel-filters',
	templateUrl: './carrossel-filters.component.html',
	styleUrls: ['./carrossel-filters.component.scss'],
})
export class CarrosselFiltersComponent {
  @Output() clearFilters = new EventEmitter();
  @Output() applyFilters = new EventEmitter();
  @Input() objectData!: any;
  filters: FilterCarrosselModel[] = [];
  allComplete = false;
  inset = 0;

  limparFiltros() {
  	this.filters = [];
  	this.objectData.model.forEach((model) => {
  		if (model.type == 'date-range') {
  			model.options.forEach((option) => (option[model.fieldValue] = ''));
  		}
  		model.filterValue = '';
  		this.setAll(false, model.options);
  	});
  	this.allComplete = false;
  	this.clearFilters.emit();
  }

  aplicarFiltros() {
  	this.applyFilters.emit(this.filters);
  }

  toggleValue(object, field, type, multiple = false) {
  	let filter = this.filters.find((filter) => filter.field === field);
  	if (!filter) {
  		filter = { field, type, values: [] };
  		this.filters.push(filter);
  	}

  	if (!multiple) {
  		filter.values = [object[field]];
  		return;
  	}
  	filter.values = filter.values.includes(object[field])
  		? filter.values.filter((value) => value !== object[field])
  		: [...filter.values, object[field]];
  }

  setDateRange(object, field, type) {
  	let filter = this.filters.find((filter) => filter.field === field);
  	if (!filter) {
  		filter = { field, type, field1: '', field2: '', values: [] };
  		this.filters.push(filter);
  	}

  	if (type === 'date-buttons') {
  		if (
  			filter.values[0] === moment(object.data_inicio).format() &&
        filter.values[1] === moment(object.data_fim).format()
  		) {
  			filter.values = [];
  			return;
  		}
  		filter.field1 = 'data_inicio';
  		filter.values[0] = moment(object.data_inicio).format();

  		filter.field2 = 'data_fim';
  		filter.values[1] = moment(object.data_fim).format();
  		return;
  	}

  	if (object.field === 'data_inicio') {
  		filter.field1 = object.field;
  		filter.values[0] = moment(object[field]).format();
  	} else {
  		filter.field2 = object.field;
  		filter.values[1] = moment(object[field]).format();
  	}
  }

  filterSelected(object, field): boolean {
  	this.inset = this.inset + 1;
  	if (this.inset === 0 && this.filters.length > 0) {
  		console.log('object', object, field);
  		console.log('this.filters', this.filters);
  	}

  	const filter = this.filters.find((filter) => filter.field === field);
  	if (!filter) return false;
  	if (field === 'range') {
  		return (
  			filter.values.toString() ===
        [object.data_inicio, object.data_fim].toString()
  		);
  	}
  	return filter.values.includes(object[field]);
  }

  displayLabel = (number) => {
  	return `${number}h`;
  };

  updateAllComplete(item, filterObject) {
  	const options = filterObject.options;

  	if (!filterObject.multiple) {
  		options.forEach(
  			(option) =>
  				(option.completed = item.completed
  					? item[filterObject.fieldValue] === option[filterObject.fieldValue]
  					: false)
  		);
  	}
  	this.allComplete = options != null && options.every((t) => t.completed);

  	let filter = this.filters.find(
  		(filter) => filter.field === filterObject.fieldValue
  	);
  	if (!filter) {
  		filter = {
  			field: filterObject.fieldValue,
  			type: filterObject.type,
  			values: [],
  		};
  		this.filters.push(filter);
  	}

  	filter.values = options
  		.filter((option) => option.completed)
  		.map((option) => option[filterObject.fieldValue]);
  }

  someComplete(options: any[]): boolean {
  	if (options == null) {
  		return false;
  	}
  	return options.filter((t) => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean, options: any[]) {
  	this.allComplete = completed;
  	if (options == null) {
  		return;
  	}
  	options.forEach((t) => (t.completed = completed));
  }
  getOptionsFiltered(filter) {
  	if (!filter.filterValue) return filter.options;

  	return filter.options.filter((option) => {
  		const optionName = option[filter.fieldText].toLowerCase();
  		const textValue = filter.filterValue.toLowerCase();

  		return optionName.includes(textValue);
  	});
  }
}
