import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { DataService } from 'src/app/services/data-service/data.service';
import { AppState } from 'src/app/state';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { catchError, tap, mergeMap } from 'rxjs/operators';
import { EMPTY, Subscription } from 'rxjs';
import * as moment from 'moment';
import { NotificacaoService } from '../../../../../../../src/app/services/notificacao/notificacao.service';
import { NotificacaoMensagemTratadaModel } from '../../../../../../../src/app/models/notificacao-mensagem.model';
import { notificacoes } from '../../../../../../../src/app/state/state.actions';



@Component({
  selector: 'ui-modal-notificacao',
  templateUrl: './modal-notificacao.component.html',
  styleUrls: ['./modal-notificacao.component.scss']
})
export class ModalNotificacaoComponent implements OnInit {

  constructor(
    public global: GlobalService,
    private translate: TranslateService,
    protected notificacaoService: NotificacaoService,
    private dataService: DataService,
    private store: Store<AppState>
  ) {
    this.locale = this.translate.currentLang;
  }

  @Input() data: any;
  @Input() type: string = '';
  @Input() lida: boolean = false;
  @Output() close = new EventEmitter();
  tipoEvento: string;
  atividade = 'EVENT.ACTIVITY';
  btnInscrever = 'EVENT.SUBSCRIBE';
  btnAssistir = 'EVENT.WATCH';
  btnPresencial = 'EVENT.IN_PERSON';
  notificacoes: NotificacaoMensagemTratadaModel;
  locale: string;
  state: any;
  subscriptions: Subscription[] = [];
  releaseLink = false;
  dateFormated = {
  	dia: null,
  	mes: null,
  	diaSemana: null,
  	hora: null,
  };
  eventos: any;
  event: any;
  openModalNotificacao: boolean = true;
  openModalEvent: boolean = false;

  ngOnInit(): void {
    document.querySelector('html')?.setAttribute('style', 'overflow:hidden');
    this.getState();
    this.checkDateToWatch();
    this.getFormatDate();
    this.setStyle()
  }

  ngOnDestroy() {
    document.querySelector('html')?.setAttribute('style', 'overflow:auto');
    if(!this.lida)
      this.marcarComoLido(this.data.id_notificacao)
    this.subscriptions
  }

  getState() {
  	const subscription = this.store
  		.select((state: any) => {
  			const { usuario, eventos } = state.newAppState;
  			this.state = {
  				usuario,
          eventos
  			};
  		})
  		.subscribe();

    if (this.state?.eventos?.eventosUser.length > 0) {
  		this.eventos = [...this.state.eventos.eventosUser];
      this.getEventAndTipe()
    }
  	this.subscriptions.push(subscription);
  }

  closeModel() {
  	this.close.emit(true);
  }

  agendarEvento() {
  	const type = 1; // type 1 indica criar agenda
  	const subscription = this.dataService
  		.agendarOuAcessarEvento(
  			this.event.id_evento,
  			this.state.usuario.id_usuario,
  			type
  		)
  		.pipe(
  			tap((data) => {
  				if (data.jaAgendado)
  					this.global.toast(
  						this.translate.instant('EVENT.ALREADY_SCHEDULED'),
  						''
  					);
  				else
  					this.global.toast(
  						this.translate.instant('EVENT.TOAST_SCHEDULE'),
  						''
  					);
  			}),
  			catchError((error) => {
  				this.global.toast(
  					this.translate.instant('GENERAL.ERROR'),
  					error.error.message
  				);
  				return EMPTY;
  			})
  		)
  		.subscribe();
  	this.subscriptions.push(subscription);
  }

  getEventAndTipe() {

    this.event = this.eventos.find((e:any)=> {
      if (e.id_notificacao === this.data.id_notificacao)
        return e
    })

    if(!this.event) return
  	const canal = this.event.canal_tipo;
  	switch (canal) {
  	case 1:
  		this.tipoEvento = 'EVENT.IN_PERSON';
  		break;
  	default:
  		this.tipoEvento = 'EVENT.ONLINE';
  	}
  }

  goToEvent() {
  	const type = 2; //type 2 indica registro primeiro acesso ao evento
  	if (this.event && !this.event.agenda_data_acesso) {
  		const subscription = this.dataService
  			.agendarOuAcessarEvento(
  				this.event.id_evento,
  				this.state.usuario.id_usuario,
  				type
  			)
  			.subscribe();
  		this.subscriptions.push(subscription);
  	}
  	window.open(this.event.link);
  }

  checkDateToWatch() {
  	const date = moment();
  	const today = date.format('DD/MM/YYYY HH:mm');
  	const dateInicio = moment(this.data.data_inicio).subtract(15, 'minutes');
  	const inicio = dateInicio.format('DD/MM/YYYY HH:mm');
  	if (today > inicio) this.releaseLink = true;
  }

  getFormatDate() {
    if(!this.event) return
  	const dataInicio = new Date(this.event.data_inicio);
  	const dataFim = new Date(this.event.data_fim);
  	const meses = [
  		'Jan',
  		'Fev',
  		'Mar',
  		'Abr',
  		'Mai',
  		'Jun',
  		'Jul',
  		'Ago',
  		'Set',
  		'Out',
  		'Nov',
  		'Dez',
  	];
  	const horaInit = dataInicio.getHours();
  	const minutesInit = dataInicio.getMinutes();
  	const horaEnd = dataFim.getHours();
  	const minutesEnd = dataFim.getMinutes();
  	const diaNome = dataInicio.toLocaleDateString('pt-BR', { weekday: 'long' });

    const diaI = dataInicio.getDate();
    const diaF = dataFim.getDate();
    const mesF = meses[dataFim.getMonth()]


  	this.dateFormated.mes = meses[dataInicio.getMonth()];
  	this.dateFormated.dia = dataInicio.getDate();
  	this.dateFormated.diaSemana =
    diaNome.charAt(0).toUpperCase() + diaNome.slice(1);

    if (diaI === diaF)
      this.dateFormated.hora =
        `${horaInit}:${minutesInit}h às ${horaEnd}:${minutesEnd}h`;
    if (diaI !== diaF) {
        this.dateFormated.hora =
        `${horaInit}:${minutesInit}h até ${diaF} de ${mesF} às ${horaEnd}:${minutesEnd}h`
      }
  }

  marcarComoLido(id: number) {
  	const idNotificacao = [id];
  	const subscription = this.notificacaoService
  		.marcarNotificacaoComoLida(this.state.usuario.id_usuario, idNotificacao)
  		.pipe(
  			mergeMap((data) =>
  				this.notificacaoService.getNotificacao(this.state.usuario.id_usuario)
  			)
  		)
  		.subscribe((data: NotificacaoMensagemTratadaModel) => {
  			this.store.dispatch(notificacoes({ payload: data }));
  		});
  	this.subscriptions.push(subscription);
  }

  setStyle() {
    const root = document.documentElement;
    root.style.setProperty('--margin-top-head', '31px')
    root.style.setProperty('--overlay-padding','15vh 30%')
    if (this.data.banner) {
      root.style.setProperty('--margin-top-head', '17px')
    }
  }

  goToLink(link: string) {
  	window.open(link, '_Blank');
  }

  openEvento() {
    this.openModalNotificacao = !this.openModalNotificacao
    const root = document.documentElement;
    root.style.setProperty('--overlay-padding','15vh 0 0vh 40%')
    this.openModalEvent = !this.openModalEvent
  }
}
