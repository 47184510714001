<div
  class="scrollable-chat-container"
  id="scrollable-chat"
  [ngClass]="containerClass"
>
  <div class="shadow-top"></div>
  <div
    class="msg"
    *ngFor="let msg of messages?.detalhes"
    [class.prof]="msg?.usuario_papel == 'professor'"
    [class.aluno]="msg?.usuario_papel == 'aluno'"
  >
    <img *ngIf="msg?.usuario_foto" [src]="msg?.usuario_foto" />
    <img
      *ngIf="!msg?.usuario_foto"
      src="../../../../../assets/imgs/profile-img.svg"
    />
    <div class="tutor upper">{{ "SCROLLABLE_CHAT.TEACHER" | translate }}</div>
    <div class="name">{{ msg?.usuario_nome_social }}</div>
    <div class="txt" [innerHTML]="msg?.mensagem"></div>

    <div class="link-block" *ngIf="msg?.media_name && verifyExt(msg)">
      <div class="message-file">
        <img
          class="img-preview pointer"
          [src]="msg.media_url"
          (click)="viewImage(msg)"
        />
        <img
          (click)="downloadFile(msg)"
          class="img-icon pointer"
          src="assets/icons/download.svg"
        />
      </div>
    </div>

    <div class="link-block" *ngIf="msg?.media_name && !verifyExt(msg)">
      <div class="message-file">
        <div class="row align-items-center ml-0">
          <img
            class="img-icon pointer"
            src="assets/icons/file-light.svg"
            (click)="viewFile(msg)"
          />
          <span *ngIf="msg?.media_name?.length > 50"
            >{{ msg?.media_name | slice : 0 : 50 }}...</span
          >
          <span *ngIf="msg?.media_name?.length <= 50">{{
            msg?.media_name
          }}</span>
        </div>
        <img
          (click)="downloadFile(msg)"
          class="img-icon pointer"
          src="assets/icons/download.svg"
        />
      </div>
    </div>

    <!-- <a *ngIf="msg?.media_name?.length > 50" [href]="msg?.media_url">{{msg?.media_name | slice: 0:50}}...</a>
    <a *ngIf="msg?.media_name?.length <= 50" [href]="msg?.media_url">{{msg?.media_name}}</a> -->

    <div class="date">
      {{ msg?.data_postagem | date : "dd/MM/yyyy - HH:mm" }}
    </div>
  </div>
  <div class="block-bottom" *ngIf="isTicketSolved"></div>
</div>
<div
  class="shadow-answer"
  *ngIf="withShadow && isTicketSolved && status_aluno"
  [class.down]="
    messages?.suporte_status_id_suporte_status == ticketStatus.resolved
  "
></div>
<div
  class="answer"
  *ngIf="isTicketSolved && status_aluno"
  [ngClass]="containerClass"
>
  <img
    *ngIf="!file"
    src="assets/icons/paperclip.svg"
    (click)="clickInputUpload()"
  />
  <img
    class="uploaded"
    *ngIf="file"
    src="assets/icons/uploaded.png"
    (click)="clearInput(); $event.stopPropagation()"
  />
  <input
    type="file"
    #attach
    class="d-none ipt-file"
    (change)="attachFile($event)"
  />
  <input
    type="text"
    class="upper"
    placeholder="{{ 'INTERACTIONS.NEW_SERVICE_DO_QUESTION_TEXT' | translate }}"
    [(ngModel)]="newMessage.mensagem"
  />
  <img class="send" (click)="sendMessage()" src="assets/icons/send-msg.png" />
</div>
<div class="solve" *ngIf="isTicketSolved" [ngClass]="containerClass">
  <button class="btn btn-solved upper" (click)="solveTicket()">
    <i class="fas fa-check"></i> {{ "SCROLLABLE_CHAT.SOLVED" | translate }}
  </button>
</div>
<div
  class="solve"
  *ngIf="messages?.suporte_status_id_suporte_status == ticketStatus.resolved"
  [ngClass]="containerClass"
>
  <div class="flex-col" style="margin-bottom: 36px">
    <span class="name">{{
      "SCROLLABLE_CHAT.IS_TICKET_SOLVED" | translate
    }}</span>
    <div class="flex-row justify-space-around">
      <img
        class="thumbs"
        src="assets/icons/thumbs-up-black.svg"
        (click)="questionSolved(true)"
      />
      <img
        class="thumbs"
        src="assets/icons/thumbs-down-black.svg"
        (click)="questionSolved(false)"
      />
    </div>
  </div>
</div>
