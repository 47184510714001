<div class="overlay" [class.grow]="growBox"></div>
<div
  class="projeto-box-container"
  [class.grow]="growBox"
  (mouseover)="changeSize($event)"
  (mouseout)="changeSize($event)"
  [style.background-image]="global.generateImageURL(data.foto_capa_web)"
  [class.background-corp]="data?.educacao_corporativa"
  [class.background-sup]="!data?.educacao_corporativa"
  (click)="goToHome()"
  data-cy="PerfilCardProject"
  data-id="{{ data?.id_projeto_turma }}"
>
  <cls-circle
    [size]="'medium-big'"
    [percentage]="extractPercentage(data.atividades_percentual)"
  ></cls-circle>
  <div class="description">
    <div class="background-bottom"></div>
    <div class="img-branca">
      <img
        *ngIf="
          projetoPolo?.imagem_logo_branca || data?.empresa?.imagem_logo_branca
        "
        [src]="
          projetoPolo?.imagem_logo_branca || data?.empresa?.imagem_logo_branca
        "
      />
    </div>
    <div
      *ngIf="data.nome?.length > 90"
      [innerHtml]="data.nome.slice(0, 90) + '...'"
    ></div>
    <div *ngIf="data.nome?.length <= 90" [innerHtml]="data.nome"></div>
    <div class="divisor" *ngIf="data?.nome_disciplina"></div>
    <div class="nome" *ngIf="data?.nome_disciplina?.length < 30">
      {{ data?.nome_disciplina }}
    </div>
    <div class="nome" *ngIf="data?.nome_disciplina?.length >= 30">
      {{ data?.nome_disciplina | slice : 0 : 30 }}...
    </div>
  </div>
  <div class="btns">
    <img
      *ngIf="
        data?.projetoTurmaTutor?.length == 0 ||
        (data?.projetoTurmaTutor?.length > 0 &&
          !data?.projetoTurmaTutor[0]?.foto)
      "
      src="../../../assets/imgs/profile-img.svg"
    />
    <div
      class="img-tutor"
      *ngIf="
        data?.projetoTurmaTutor?.length > 0 && data?.projetoTurmaTutor[0]?.foto
      "
      [style.background-image]="
        global.generateImageURL(data?.projetoTurmaTutor[0]?.foto)
      "
    ></div>
    <div class="nivel" [class.smaller]="data?.id_nivel_ensino === 2">
      {{ data.turma_nivel_ensino }}
    </div>
    <div class="duracao">
      <img src="../../../assets/icons/clock.svg" />{{ data.duracao }}hs
    </div>
  </div>
  <div class="tutor">
    {{ data?.projetoTurmaTutor[0]?.nome_social?.toLowerCase() }}
  </div>
  <div class="competencia">
    <img src="../../../assets/icons/award-white.svg" />
    <div class="ajuste-comp">
      <!-- <div class="label upper">{{ 'PROJETO_BOX.SKILL' | translate }}</div> -->
      <div
        class="nome"
        *ngIf="
          data?.projetoTurmaCompetencia[0] &&
          data?.projetoTurmaCompetencia[0]?.nome?.length < 28
        "
      >
        # {{ data?.projetoTurmaCompetencia[0]?.nome }}
      </div>
      <div
        class="nome"
        *ngIf="
          data?.projetoTurmaCompetencia[0] &&
          data?.projetoTurmaCompetencia[0]?.nome?.length >= 28
        "
      >
        # {{ data?.projetoTurmaCompetencia[0]?.nome | slice : 0 : 28 }}...
      </div>
    </div>
  </div>
</div>
