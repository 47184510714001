<div
  class="caso-sucesso-container"
  (click)="openVideoModal()"
  [class.grow]="growBox"
  (mouseout)="changeSize($event)"
  (mouseover)="changeSize($event)"
  [style.background-image]="global.generateImageURL(data?.imagem_principal)"
>
  <div class="play">
    <!-- <i class="fas fa-play"></i> -->
    <img src="assets/icons/fas-fa-play.svg" alt="" />
  </div>
</div>
