
<div class="file" (click)="downloadEntrega(urlArquivo)">
  <div class="mr-2 pointer">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#b3b3b3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
      <polyline class="icon-upload" points="7 10 12 15 17 10"></polyline>
      <line class="icon-upload" x1="12" y1="15" x2="12" y2="3"></line>
    </svg>
  </div>
  {{reduzirTamanhoDoNome(nomeArquivo)}}
</div>
