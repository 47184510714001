import { Component, OnInit } from '@angular/core';
import { CurriculumProfile } from '../../models';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { DataService } from 'src/app/services/data-service/data.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-perfil-usuario',
  templateUrl: './perfil-usuario.component.html',
  styleUrls: ['./perfil-usuario.component.scss']
})
export class PerfilUsuarioComponent implements OnInit {

  idCurriculumProfile: number = null;
  isLoading: boolean = true;
  curriculumProfile: CurriculumProfile;
  curriculumProfileExistingItems: Array<Object> = [];
  obsCurriculumProfile: Subscription;
  CURRICULUM_ITEMS_TITLE: Object = {
    comportamentos: "Comportamentos",
    pontos_fortes: "Pontos fortes",
    pontos_fracos: "Pontos de melhorias",
    valor_na_equipe: "Valor na equipe",
    ambiente_ideal: "Ambiente ideal",
    sob_pressao: "Sob pressão",
    motivacoes: "Motivações",
    valores: "Valores",
    lideranca: "Liderança",
    ranking_competencia: "Ranking das competências",
    cursos: "Cursos",
    areas_profissionais: "Áreas profissionais",
    estilo_aprendizagem: "Estilo de aprendizagem",
  }

  constructor (
    private activatedRouter: ActivatedRoute,
    private dataService: DataService,
    public global: GlobalService,
    private router: Router,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.loadPage()
  }

  ngOnDestroy() {
    this.obsCurriculumProfile?.unsubscribe();
  }

  async loadPage() {
    this.idCurriculumProfile = this.activatedRouter.snapshot.params.idCurriculumProfile;
    this.obsCurriculumProfile = this.dataService
      .getCurriculumProfile(this.idCurriculumProfile)
      .subscribe((data: CurriculumProfile) => {
        this.curriculumProfile = data
        this.verifyExistingCurriculumData()
        setTimeout(() => this.isLoading = false, 1000) 
      }, error => {
        this.global.toast(this.translate.instant("GENERAL.ERROR"), error.error.message)
        setTimeout(() => this.exit(), 2000) 
      })
  }

  async verifyExistingCurriculumData() {
    Object.keys(this.curriculumProfile).forEach((data) => {
      if(this.curriculumProfile[data] && this.CURRICULUM_ITEMS_TITLE[data]) {
        this.curriculumProfileExistingItems.push({
          title: this.CURRICULUM_ITEMS_TITLE[data],
          description: this.curriculumProfile[data]
        })
      }
    })
  }

  exit() {
    this.router.navigate(['curriculo']);
  }
}
