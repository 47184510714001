import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
	fillActiveProject,
	fillMarcos,
	fillObjetivoAprendizagemAtivo,
	selectedFaseForum,
	selectedForumAtividade,
	showAgenda,
	showForumFreeTalk,
	showForumTurma,
	showInteracoes,
} from 'src/app/state/state.actions';
import { AppState } from 'src/app/state';
import { Router } from '@angular/router';
import { fillFaseAtual } from 'src/app/state/state.actions';
import { NotificacaoMensagemTratadaModel } from '../../models/notificacao-mensagem.model';
import { HeaderBase } from '../../../../projects/ui/src/lib/core/classes-abstratas/header-base';
import { NotificacaoService } from '../../services/notificacao/notificacao.service';
import { GlobalService } from 'src/app/services/global-service/global.service';

@Component({
	selector: 'cls-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	animations: [],
})
export class HeaderComponent extends HeaderBase implements OnInit {
	state: any;
	isShowingMenu = false;
	notificacoes: NotificacaoMensagemTratadaModel;
	temProjetoAtivo = false;
	permiteInteracoes = true;
	notificacoesNaoLidas = false;

	constructor(
    protected store: Store<AppState>,
    private router: Router,
    protected notificacaoService: NotificacaoService,
    public global: GlobalService
	) {
		super(store, notificacaoService);
	}

	goToPerfil() {
		document.querySelector('body').removeAttribute('class');
		this.router.navigate(['perfil']);
		this.resetMainStates();
		this.resetInteracoesState();
	}

	resetMainStates() {
		this.store.dispatch(fillFaseAtual(null));
		this.store.dispatch(fillMarcos(null));
		this.store.dispatch(fillActiveProject(null));
	}

	resetInteracoesState() {
		this.store.dispatch(showInteracoes({ payload: 'closed' }));
		document.querySelector('body').removeAttribute('class');
		this.store.dispatch(showForumTurma({ payload: false }));
		this.store.dispatch(showForumFreeTalk({ payload: false }));
		this.store.dispatch(selectedForumAtividade({ payload: null }));
		this.store.dispatch(selectedFaseForum({ payload: null }));
	}

	goToProject() {
		this.router.navigate([
			'/projeto/' + this.state.projetoAtivo.id_projeto_turma,
		]);
		this.store.dispatch(fillFaseAtual(null));
		this.store.dispatch(fillObjetivoAprendizagemAtivo({ payload: null }));
	}

	getState() {
		this.store
			.select((state: any) => {
				this.state = state.newAppState;
				this.isShowingMenu = this.state.showingMenu;
				this.showNotificacao = state.newAppState.showNotificacao;
				this.usuario = state.newAppState.usuario;
				if (state.newAppState.notificacoes) {
					this.notificacoes = state.newAppState.notificacoes;
				}
				this.checkNotificacoesNaoLidas(this.notificacoes?.mensagensNaoLidas);
			})
			.subscribe((dados) => {
				this.temProjetoAtivo = this.state.projetoAtivo;
			});

		this.permiteInteracoes = this.state?.projetoAtivo?.permite_interacoes;
	}

	toggleInteracoesVisibility() {
		this.store.dispatch(showInteracoes({ payload: 'open' }));
		document.querySelector('body').setAttribute('class', 'no-scrollbar');
	}

	toggleAgendaVisibility() {
		this.store.dispatch(showAgenda({ payload: 'open' }));
		document.querySelector('body').setAttribute('class', 'no-scrollbar');
	}

	marcarComoLido(id: number) {
		// const idNotificacao = [id];
		// const subscription = this.notificacaoService
		// 	.marcarNotificacaoComoLida(this.usuario.id_usuario, idNotificacao)
		// 	.pipe(
		// 		mergeMap((data) =>
		// 			this.notificacaoService.getNotificacao(this.usuario.id_usuario)
		// 		)
		// 	)
		// 	.subscribe((data: NotificacaoMensagemTratadaModel) => {
		// 		this.store.dispatch(notificacoes({ payload: data }));
		// 	});
		// this.subscriptions.push(subscription);
	}

	checkNotificacoesNaoLidas(mensagensNaoLidas: any) {
		if (mensagensNaoLidas && mensagensNaoLidas.length) {
			this.notificacoesNaoLidas = true;
		}
	}
}
