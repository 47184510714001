<div class="tentativa-card mt-4 mb-4">
  <div
    class="tentativa-header d-flex flex-row justify-content-between align-items-center align-items-baseline pointer"
  >
    <p [class.tentativa-title]="true" class="capitalize">
      {{ "ACCORDION.ATTEMPT" | translate }}
      {{ numeroTentativa | number : "2.0-0" }}
    </p>
    <hr />
    <span [class.grade]="true" *ngIf="showNota">
      <p class="capitalize">
        {{ "ACCORDION.NOTE" | translate }}
        {{ tentativa.nota | number : "1.0-2" }}
      </p>
    </span>
    <div
      class="btn-expand ml-4"
      (click)="abrirAcordion()"
      *ngIf="showRevisao"
      [class.rotate-180]="accordionAberto"
    >
      <!-- <i class="fas fa-chevron-down"></i> -->
      <img class="chevron" src="assets\icons\arrow-up.svg" />
    </div>
  </div>
  <span *ngIf="tentativa.data_finalizacao || tentativa.data_finalizacao">
    <p class="capitalize">
      {{ "ACCORDION.DELIVERED" | translate }}
      {{ tentativa?.data_finalizacao | date : "dd/MM/yy" }} às
      {{ tentativa?.data_finalizacao | date : "HH:mm" }}
    </p>
  </span>

  <div *ngIf="accordionAberto">
    <ng-content></ng-content>
  </div>
</div>
