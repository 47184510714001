<div
  class="contents-container"
  (click)="openVideoModal()"
  [class.grow]="growBox"
  (mouseout)="changeSize($event)"
  (mouseover)="changeSize($event)"
  [style.background-image]="global.generateImageURL(data?.imagem_principal)"
>
  <div class="text-area">
    <div class="title" *ngIf="data?.titulo?.length <= 22">
      {{ data?.titulo }}
    </div>
    <div class="title" *ngIf="data?.titulo?.length > 22">
      {{ data?.titulo | slice : 0 : 20 }}...
    </div>
    <div class="sub-title" *ngIf="data?.subtitulo?.length <= 42">
      {{ data?.subtitulo | slice : 0 : 42 }}
    </div>
    <div class="sub-title" *ngIf="data?.subtitulo?.length > 42">
      {{ data?.subtitulo | slice : 0 : 42 }}...
    </div>
  </div>
  <div class="overlay"></div>
</div>
