<div
  class="interacoes-container"
  [class.turma]="!state?.turmaInteracoes"
  id="scroll-interacoes"
  (clickOutside)="handleClickOutside($event)"
  [@Interacoes]="transition"
>
  <div class="cover-header"></div>
  <div class="header">
    <img
      *ngIf="
        state?.showForumTurma || state?.insideDuvida || state.showForumFreeTalk
      "
      (click)="closeInsideView(); $event.stopPropagation()"
      src="assets/icons/chevron-left.svg"
    />
    <span class="capitalize">{{
      "INTERACTIONS.INTERACTIONS" | translate
    }}</span>
    <div class="close-pattern" (click)="closeInteracoes()">
      <!-- <i class="fas fa-times pointer"></i> -->
      <img src="assets/icons/fas-fa-times-pointer.svg" />
    </div>
  </div>
  <div class="d-flex">
    <div
      class="tab"
      (click)="setView('turma')"
      [class.selected]="!state?.turmaInteracoes"
    >
      <img
        *ngIf="!state?.turmaInteracoes"
        src="../../../assets/icons/users-blue.svg"
      />
      <img
        *ngIf="state?.turmaInteracoes"
        src="../../../assets/icons/users.svg"
      />
      <span class="upper">{{ "INTERACTIONS.TALK_CLASS" | translate }}</span>
    </div>
    <div
      class="tab"
      (click)="setView('professor')"
      [class.selected]="state?.turmaInteracoes"
    >
      <img
        *ngIf="state?.turmaInteracoes"
        src="../../../assets/icons/user-blue.svg"
      />
      <img
        *ngIf="!state?.turmaInteracoes"
        src="../../../assets/icons/user.svg"
      />
      <span class="upper">{{ "INTERACTIONS.TALK_TEACHER" | translate }}</span>
    </div>
  </div>
  <div *ngIf="!state?.turmaInteracoes">
    <div *ngIf="!state?.showForumTurma && !state.showForumFreeTalk">
      <div class="list" *ngFor="let fase of state?.fases">
        <div class="label">
          {{ fase.nome.toLowerCase() }}
          <span class="toshow">
            {{ fase.dataToShow }}
          </span>
        </div>
        <div
          class="atividade"
          (click)="showForumByAtividade(atividade, fase)"
          *ngFor="let atividade of getAtividadesMesaByFase(fase)"
        >
          <img
            *ngIf="fase.aberta"
            class="mr-3"
            src="../../../assets/icons/chat-icon.svg"
          />
          <img
            *ngIf="!fase.aberta"
            class="mr-3"
            src="../../../assets/icons/chat-icon-gray.svg"
          />
          <div class="d-flex flex-column">
            <div class="sub-label upper">
              {{ "INTERACTIONS.ACTIVITY" | translate }}
            </div>
            <div class="title" *ngIf="atividade.titulo.length > 50">
              {{ atividade.titulo.toLowerCase() | slice : 0 : 50 }}...
            </div>
            <div class="title" *ngIf="atividade.titulo.length <= 50">
              {{ atividade.titulo.toLowerCase() }}
            </div>
          </div>
          <div class="ml-auto">
            <span>
              <img
                *ngIf="!fase.aberta"
                class="cadeado ml-auto"
                src="../../../assets/icons/icone_cadeado.svg"
              />
            </span>
            <span>
              <img
                class="chev ml-auto"
                src="../../../assets/icons/chevron-right.svg"
              />
            </span>
          </div>
        </div>
      </div>
      <div class="list">
        <div class="label capitalize">
          {{ "INTERACTIONS.OTHERS" | translate }}
        </div>
        <div
          class="atividade"
          (click)="showFreeTalk(); $event.stopPropagation()"
        >
          <img class="mr-3" src="../../../assets/icons/chat-icon.svg" />
          <div class="d-flex flex-column">
            <div class="sub-label upper">
              {{ "INTERACTIONS.FREE" | translate }}
            </div>
            <div class="title capitalize">
              {{ "INTERACTIONS.FREE_TALK" | translate }}
            </div>
          </div>
          <img
            class="chev ml-auto"
            src="../../../assets/icons/chevron-right.svg"
          />
        </div>
      </div>
    </div>
    <cls-chat
      *ngIf="state?.showForumTurma"
      [label]="state?.selectedFaseForum?.nome"
      [subtitle]="state?.selectedForumAtividade?.titulo"
      [description]="state?.selectedForumAtividade?.forum[0]?.descricao"
      [status_aluno]="statusMatriculado"
      [id_forum]="state?.selectedForumAtividade?.forum[0]?.id_forum"
      [messages]="state?.mensagens"
      [projeto_vigente]="projetoVigente"
    >
    </cls-chat>
    <cls-chat
      *ngIf="state?.showForumFreeTalk"
      [label]="'INTERACTIONS.FREE_TALK' | translate"
      [subtitle]="'INTERACTIONS.SPACE' | translate"
      [isTurma]="true"
      [status_aluno]="statusMatriculado"
      [id_forum]="state?.id_forum_turma"
      [messages]="state?.mensagensTurma"
      [projeto_vigente]="projetoVigente"
    >
    </cls-chat>
  </div>
  <div
    *ngIf="state?.turmaInteracoes && !state?.suportDetails"
    class="duvidas-container"
  >
    <div
      class="new-suporte"
      [class.show]="showNovoAtendimento"
      *ngIf="statusMatriculado && projetoVigente.vigente"
    >
      <div class="anteriores">
        <div>
          <span class="capitalize">{{
            "INTERACTIONS.NEW_SERVICE" | translate
          }}</span>
          <i
            class="fas"
            [class.fa-chevron-down]="!showNovoAtendimento"
            [class.fa-chevron-up]="showNovoAtendimento"
            (click)="showNovoAtendimento = !showNovoAtendimento"
          ></i>
        </div>
      </div>
      <div class="form-atendimento">
        <div class="form-group">
          <!-- <textarea [(ngModel)]="newSuport.titulo" class="form-control upper" placeholder="{{ 'INTERACTIONS.NEW_SERVICE_NEW_QUESTION_TEXT' | translate }}"
                        rows="2"></textarea> -->
          <div class="select-label flex-col">
            <div>
              <label class="capitalize">{{
                "INTERACTIONS.NEW_SERVICE_NEW_QUESTION" | translate
              }}</label>
              <select
                [(ngModel)]="newSuport.id_solicitacao"
                name="id_solicitacao"
                id="id_solicitacao"
              >
                <option value="" disabled="disabled" selected="true">
                  {{ "ATTENDANCE.SELECT_HERE" | translate }}
                </option>
                <option
                  *ngFor="let option of solicitacoesOptions"
                  [value]="option?.id"
                >
                  {{ option?.text }}
                </option>
                <!-- <option *ngFor="let option of areaOptions" [selected]="option?.id==selections.value.area" [value]="option?.id"> -->
              </select>
            </div>
            <div class="flex-row align-center">
              <img src="assets/icons/chevron_down.svg" />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="capitalize">{{
            "INTERACTIONS.NEW_SERVICE_DO_QUESTION" | translate
          }}</label>
          <textarea
            [(ngModel)]="newSuport.assunto"
            class="form-control upper"
            placeholder="{{
              'INTERACTIONS.NEW_SERVICE_DO_QUESTION_TEXT' | translate
            }}"
            rows="5"
          ></textarea>
        </div>
      </div>
      <button class="btn btn-send float-right upper" (click)="sendNewTicket()">
        {{ "INTERACTIONS.SEND" | translate }}
      </button>
    </div>
    <div class="full-background"></div>
    <div class="anteriores">
      <div>
        <span
          ><label class="capitalize">{{
            "INTERACTIONS.OLD_SERVICE" | translate
          }}</label></span
        >
        <i
          class="fas"
          [class.fa-chevron-down]="!showAnteriores"
          [class.fa-chevron-up]="showAnteriores"
          (click)="showAnteriores = !showAnteriores"
        ></i>
      </div>
    </div>
    <div class="suporte-container" [class.show]="showAnteriores">
      <div class="shadow-top"></div>
      <div
        class="box"
        [class.solved]="sup?.suporte_status_final"
        *ngFor="let sup of state?.suport"
      >
        <div class="mrk-left">
          <i
            class="fas"
            [class.fa-times]="!sup?.suporte_status_final"
            [class.fa-check]="sup?.suporte_status_final"
          ></i>
        </div>
        <div class="shadow-left"></div>
        <div class="top">
          <div class="title">{{ sup?.suporte_titulo }}</div>
          <div class="ticket">
            <span class="capitalize">{{
              "INTERACTIONS.OLD_SERVICE_TICKET" | translate
            }}</span>
            {{ sup?.suporte_instance }}
          </div>
        </div>
        <div class="assunto">
          <div class="txt">
            <span class="capitalize">{{
              "INTERACTIONS.OLD_SERVICE_TOPIC" | translate
            }}</span
            >{{ sup?.suporte_assunto }}
          </div>
          <button
            class="btn btn-send"
            (click)="goToDetails(sup); $event.stopPropagation()"
          >
            <!-- <span *ngIf="!sup?.suporte_status_final && state?.anterioresLoading != sup?.suporte_id_suporte"><span class="upper">{{ 'INTERACTIONS.VIEW' | translate }}</span></span> -->
            <span
              class="upper"
              *ngIf="!(state?.anterioresLoading == sup?.suporte_id_suporte)"
              >{{ "INTERACTIONS.VIEW" | translate }}</span
            >
            <div
              *ngIf="state?.anterioresLoading == sup?.suporte_id_suporte"
              class="text-center"
            >
              <div class="spinner-border spinner-border-md" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="suporte-details"
    *ngIf="state?.turmaInteracoes && state?.suportDetails"
  >
    <div
      class="box grow"
      [class.solved]="state?.suportDetails?.suporte_status_final"
    >
      <div class="mrk-left">
        <i
          class="fas"
          [class.fa-times]="!state?.suportDetails?.suporte_status_final"
          [class.fa-check]="state?.suportDetails?.suporte_status_final"
        ></i>
      </div>
      <div class="top">
        <div class="title">{{ state?.suportDetails?.suporte_titulo }}</div>
        <div class="ticket">
          <span class="capitalize">{{
            "INTERACTIONS.OLD_SERVICE_TICKET" | translate
          }}</span>
          {{ state?.suportDetails?.suporte_instance }}
        </div>
        <div class="close" (click)="closeDetails(); $event.stopPropagation()">
          <i class="fas fa-times"></i>
        </div>
      </div>
      <div class="assunto">
        <div class="txt">
          <span class="capitalize">{{
            "INTERACTIONS.OLD_SERVICE_TOPIC" | translate
          }}</span
          >{{ state?.suportDetails?.suporte_assunto }}
        </div>
      </div>
    </div>
    <cls-scrollable-chat
      [messages]="state?.suportDetails"
      (reply)="sendMessageOutput($event)"
      [status_aluno]="statusMatriculado"
      (solve)="solveTicket($event)"
      containerClass="min-height"
      withShadow="true"
    ></cls-scrollable-chat>
  </div>
</div>
