<div class="perfil-container">
  <ui-header-home [showQuantidadeCompetencia]="true"></ui-header-home>
  <div class="main-content">
    <div class="student-data">
      <div class="indicators">
        <div class="title">{{ state?.usuario?.nome_social }}</div>
        <div class="sub-title">
          {{ state?.dadosPolo?.nome || state?.dadosEmpresa?.nome }}
        </div>
        <div class="tag-container">
          <div class="tag" *ngFor="let competencia of filtereds">
            <img src="assets/icons/award-blue.svg" />
            <span *ngIf="competencia?.nome?.length > 30">
              #
              <span class="nome-competencia">
                {{ competencia.nome | slice : 0 : 30 }}...</span
              >
            </span>
            <span *ngIf="competencia?.nome?.length <= 30">
              # <span class="nome-competencia">{{ competencia.nome }}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="link" data-cy="PerfilMeuCurriculo">
        <div class="d-flex" (click)="goToCurriculo()">
          <img class="award" src="assets/icons/award-paper.png" />
          <span class="upper">{{ "PERFIL.MY_RESUME" | translate }}</span>
          <img class="edit" src="assets/icons/lapis.svg" />
        </div>
      </div>
    </div>
    <div class="box-label capitalize">
      {{ "PERFIL.MY_PROJECTS" | translate }}
    </div>
    <!-- <cls-carousel [component]="'cls-projeto-box'"></cls-carousel> -->
    <div class="label mt-4" *ngIf="state?.eventos?.eventosUser?.length > 0">
      <div class="box-label capitalize">{{ "PERFIL.EVENTS" | translate }}</div>
    </div>
    <!-- <cls-carousel
      *ngIf="state?.eventos?.areaTipo?.length > 0"
      [component]="'area-eventos'"
      (areaTipoOut)="receiveAreaTipo($event)"
    ></cls-carousel> -->
    <!-- <cls-carousel
      *ngIf="state?.eventos?.eventosUser.length > 0"
      [component]="'cls-eventos-box'"
      [areaTipoIn]="areaTipo"
    ></cls-carousel> -->
  </div>
  <div class="market">
    <div class="label" *ngIf="state?.casosSucesso?.length > 0">
      <div class="title capitalize">
        {{ "PERFIL.TRAJECTORIES" | translate }}
      </div>
      <div class="sub-title">
        <span class="capitalize">{{
          "PERFIL.TRAJECTORIES_TEXT" | translate
        }}</span>
        <span class="capitalize">{{
          "PERFIL.TRAJECTORIES_TEXT_2" | translate
        }}</span>
      </div>
    </div>
    <!-- <cls-carousel
      *ngIf="state?.casosSucesso?.length > 0"
      [component]="'cls-caso-sucesso-box'"
    ></cls-carousel> -->
    <div class="label mt-4" *ngIf="state?.softSkills?.length > 0">
      <div class="title capitalize">{{ "PERFIL.SOFT_SKILLS" | translate }}</div>
      <div class="sub-title capitalize">
        {{ "PERFIL.SOFT_SKILLS_TEXT" | translate }}
      </div>
    </div>
    <!-- <cls-carousel
      *ngIf="state?.softSkills?.length > 0"
      [component]="'cls-soft-skill-box'"
    ></cls-carousel> -->
    <!-- <div class="footer">
            FACULDADE {{state?.dadosEmpresa?.nome}}
            <img src="assets/imgs/liga-login1.png">
        </div> -->
  </div>
</div>
<app-menu *ngIf="state?.showingMenu"></app-menu>
<cls-modal-video
  *ngIf="state?.modalVideo"
  (mousedown)="checkCloseModal($event)"
></cls-modal-video>
