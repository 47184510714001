import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import {
	AfterViewInit,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { HeaderBase } from 'projects/ui/src/lib/core/classes-abstratas/header-base';
import { Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { NotificacaoMensagemTratadaModel } from 'src/app/models/notificacao-mensagem.model';
import { DataService } from 'src/app/services/data-service/data.service';
import { NotificacaoService } from 'src/app/services/notificacao/notificacao.service';
import { AppState } from 'src/app/state';
import {
	clearState,
	fillCentralAjuda,
	notificacoes,
	showAgenda,
	showMenu,
	showModalConfiguracoes,
} from 'src/app/state/state.actions';

@Component({
	selector: 'app-side-menu',
	templateUrl: './side-menu.component.html',
	styleUrls: ['./side-menu.component.scss'],
	animations: [
		trigger('Menu', [
			state(
				'open',
				style({
					right: '0px',
				})
			),
			state(
				'closed',
				style({
					right: '-500px',
				})
			),
			transition('open => closed', [animate('.5s 0ms ease-in-out')]),
			transition('closed => open', [animate('.5s 0ms ease-in-out')]),
		]),
	],
})
export class SideMenuComponent
	extends HeaderBase
	implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('carousel') carousel: ElementRef;
  subscriptions: Subscription[] = [];
  state: AppState = {} as AppState;
  notificacoes: NotificacaoMensagemTratadaModel =
    {} as NotificacaoMensagemTratadaModel;
  isShowingMenu = false;
  showNotificacao = false;
  notificacoesNaoLidas = false;
  transition = 'closed';
  scrolls = 0;
  scrooling = false;
  countScrolls = 0;
  maxScrollTop = 0;
  showDownArrow = false;

  constructor(
    private router: Router,
    protected store: Store<AppState>,
    private dataService: DataService,
    protected notificacaoService: NotificacaoService
  ) {
  	super(store, notificacaoService);
  }

  ngOnInit(): void {
  	this.getState();
  }

  ngAfterViewInit() {
  	setTimeout(() => {
  		this.checkForScroll();
  	}, 1000);
  }

  ngOnDestroy(): void {
  	this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  getState() {
  	const subscription = this.store
  		.select((state: any) => {
  			const {
  				usuario,
  				showModalConfiguracoes,
  				centralAjuda,
  				showingAgenda,
  				notificacoes,
  			} = state.newAppState;
  			this.state = {
  				usuario,
  				showModalConfiguracoes,
  				centralAjuda,
  				showingAgenda,
  				notificacoes,
  			} as AppState;

  			if (this.state.notificacoes) {
  				this.notificacoes = this.state.notificacoes;
  			}
  			if (!centralAjuda && !!Object.keys(this.state).length)
  				this.getLinkAjuda();
  			this.isShowingMenu = state.newAppState.showingMenu;
  			this.showNotificacao = state.newAppState.showNotificacao;
  			this.checkNotificacoesNaoLidas(this.notificacoes?.mensagensNaoLidas);
  		})
  		.subscribe();

  	this.subscriptions.push(subscription);
  }

  marcarComoLido(id: number) {
  	const idNotificacao = [id];
  	const subscription = this.notificacaoService
  		.marcarNotificacaoComoLida(this.state.usuario.id_usuario, idNotificacao)
  		.pipe(
  			mergeMap(() =>
  				this.notificacaoService.getNotificacao(this.state.usuario.id_usuario)
  			)
  		)
  		.subscribe((data: NotificacaoMensagemTratadaModel) => {
  			this.store.dispatch(notificacoes({ payload: data }));
  		});
  	this.subscriptions.push(subscription);
  }

  checkNotificacoesNaoLidas(mensagensNaoLidas: any) {
  	if (mensagensNaoLidas && mensagensNaoLidas.length) {
  		this.notificacoesNaoLidas = true;
  	}
  }

  showModalConfiguracoes() {
  	this.store.dispatch(showModalConfiguracoes({ payload: true }));
  }

  goToAgenda() {
  	this.store.dispatch(showAgenda({ payload: 'open' }));
  	document.querySelector('body').setAttribute('class', 'no-scrollbar');
  }

  closeMenu() {
  	this.transition = 'closed';
  	setTimeout(() => {
  		this.store.dispatch(showMenu({ payload: false }));
  		document.querySelector('body').removeAttribute('class');
  	}, 500);
  }

  goToCentralAjuda() {
  	window.open(this.state?.centralAjuda);
  }

  getLinkAjuda() {
  	this.dataService
  		.getDocument('url_central_ajuda')
  		.toPromise()
  		.then((data: any) => {
  			this.store.dispatch(fillCentralAjuda({ payload: data.valor_texto }));
  		});
  }

  logout() {
  	sessionStorage.clear();
  	this.router.navigate(['login']);
  	this.store.dispatch(clearState());
  }

  checkForScroll() {
  	if (
  		this.carousel.nativeElement.scrollHeight -
        this.carousel.nativeElement.clientHeight >
      0
  	)
  		this.showDownArrow = true;
  }

  scrollMenuSmoothTemp(direction) {
  	const TIME_SCROLL = 10;
  	const SCROLL_DISTANCE = 278;
  	this.scrooling = true;
  	setTimeout(() => {
  		this.scrooling = false;
  	}, 700);
  	const element = this.carousel.nativeElement;
  	const maxScrolledTop = element.scrollHeight - element.clientHeight - 2;

  	const distance = direction === 'down' ? TIME_SCROLL : -TIME_SCROLL;

  	const heightDown =
      direction === 'down' &&
      element.scrollTop + SCROLL_DISTANCE < maxScrolledTop
      	? SCROLL_DISTANCE
      	: maxScrolledTop - element.scrollTop;

  	const heightUp =
      direction === 'up' && element.scrollTop - SCROLL_DISTANCE > 0
      	? SCROLL_DISTANCE
      	: element.scrollTop;

  	const targetScroll =
      direction === 'down'
      	? element.scrollTop + heightDown
      	: element.scrollTop - heightUp;

  	const animateScroll = () => {
  		element.scrollTop += distance;
  		if (
  			(direction === 'down' && element.scrollTop >= targetScroll) ||
        (direction === 'up' && element.scrollTop <= targetScroll)
  		) {
  			this.maxScrollTop = element.scrollHeight - element.clientHeight - 2;
  			if (direction === 'down') {
  				this.countScrolls++;
  			} else if (direction === 'up' && this.countScrolls > 0) {
  				this.countScrolls--;
  			}
  			this.showDownArrow = element.scrollTop < this.maxScrollTop;

  			return;
  		}

  		requestAnimationFrame(animateScroll);
  	};

  	animateScroll();
  }
}
