<div
  class="sidebar-content-container"
  [class.turma]="!showSidebar"
  id="scroll-sidebar-content"
  [@Sidebar-content]="transition"
  (clickOutside)="closeSidebarContent()"
>
  <div class="header">
    <i
      class="fas fa-chevron-left"
      (click)="closeSidebarContent()"
      data-cy="SidebarIconVoltar"
    ></i>
    <div class="title upper">
      <img src="assets/icons/icone_notificacoes.svg" />
      {{ titulo }}
    </div>
    <div
      class="close"
      (click)="closeSidebarContent()"
      data-cy="SidebarDivClose"
    >
      <img src="assets/icons/fas-fa-times-pointer.svg" />
    </div>
  </div>
  <ng-content></ng-content>
</div>
