import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/state";
import { ActivatedRoute, Router } from "@angular/router";
import { GlobalService } from "src/app/services/global-service/global.service";
import { DataService } from "src/app/services/data-service/data.service";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-questionario-perfil",
  templateUrl: "./questionario-perfil.component.html",
  styleUrls: ["./questionario-perfil.component.scss"],
})
export class QuestionarioPerfilComponent implements OnInit {
  state: any;
  isLoading: boolean = true;
  stateOfScreen: number = 0;
  idProfileTest: number = 0;
  timerToExecute: boolean = true;
  arrayOfQuestions: Array<any>;
  objOfAnswers: Object = {};
  numberOfQuestions: number = 0;
  numberOfResolvedQuestions: number = 0;
  idCurriculoUsuario: number = 0;
  questionaryProgressionPercentage: number = 0;
  obsDataServiceAnswers: Subscription;
  obsDataServiceQuestions: Subscription;
  obsStore: Subscription;
  SCROLL_DISTANCE: number = 204;
  testResultId: any;

  constructor(
    private store: Store<AppState>,
    private dataService: DataService,
    private activatedRouter: ActivatedRoute,
    public global: GlobalService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.idProfileTest = this.activatedRouter.snapshot.params.idProfileTest;
    this.getState();
    this.setIdUserCurriculum();
    this.getProfileQuestions();
    this.begin();
    setTimeout(() => (this.isLoading = false), 1000);
  }

  ngOnDestroy() {
    this.obsDataServiceAnswers?.unsubscribe();
    this.obsDataServiceQuestions?.unsubscribe();
    this.obsStore?.unsubscribe();
  }

  getState() {
    this.obsStore = this.store
      .select((state: any) => {
        const { usuario } = state.newAppState;
        this.state = { usuario };
      })
      .subscribe();
  }

  handleSelectAnswer(event: any, index: number): void {
    // var eventElement
    // if(navigator.userAgent.includes("Firefox")) eventElement = event.originalTarget
    // else eventElement = event.toElement
    var eventElement = event.target;
    if (
      !this.timerToExecute ||
      eventElement.previousSibling?.getAttribute("disabled")
    )
      return;

    if (index == this.numberOfQuestions - 1) {
      this.markQuestionAsResolved(
        index,
        eventElement?.getAttribute("for")?.split("-")[1]
      );
      this.smoothScroll(this.getScrollDistanceByIndex(index) + 25, 600);
      return;
    }

    const nextElement = document.getElementById(`radio-1-${index + 1}`);
    this.removeDisableFromAllInputs(nextElement);
    this.smoothScroll(this.getScrollDistanceByIndex(index), 600);
    this.markQuestionAsResolved(
      index,
      eventElement.getAttribute("for").split("-")[1]
    );

    this.timerToExecute = false;
    setTimeout(() => (this.timerToExecute = true), 500);
  }

  getScrollDistanceByIndex(index: number): number {
    this.SCROLL_DISTANCE = document.getElementById(
      "opts-blk-" + index
    ).clientHeight;
    return this.SCROLL_DISTANCE * (index + 1);
  }

  markQuestionAsResolved(indexOfQuestion: number, indexOfAnswer: number): void {
    if (indexOfQuestion != undefined && indexOfAnswer != undefined) {
      const answerObj =
        this.arrayOfQuestions[indexOfQuestion]?.perfilTesteQuestaoAlternativa[
          indexOfAnswer
        ];
      this.objOfAnswers[indexOfQuestion] = answerObj;
      localStorage.setItem(
        "profileTestAnswersState",
        JSON.stringify(this.objOfAnswers)
      );
      this.setQuestionaryProgressionPercentage();
    }
  }

  removeDisableFromAllInputs(childElement: any) {
    const inputsElement =
      childElement.parentNode.parentNode.getElementsByTagName("input");
    Array.from(inputsElement).forEach((input: any) => {
      input.removeAttribute("disabled");
    });
  }

  markInputAsSelected(childElement: any, textOfAnswer: string) {
    const inputsElement =
      childElement.parentNode.parentNode.getElementsByTagName("input");

    Array.from(inputsElement).forEach((input: any) => {
      if (input.nextSibling.innerText == textOfAnswer)
        input.setAttribute("checked", "true");
    });
  }

  smoothScroll(targetPosition: any, duration: number): void {
    const elementFrom = document.getElementsByClassName(
      "avaliacao-perfil__questions-qblock-opts"
    )[0];
    const startPosition = elementFrom.scrollTop;
    const distance = targetPosition - startPosition;
    var currentTime = null;
    var increment = 20;

    function ease(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    }

    function animation() {
      currentTime += increment;
      var positionToScroll = ease(
        currentTime,
        startPosition,
        distance,
        duration
      );
      elementFrom.scrollTop = positionToScroll;
      if (currentTime < duration) setTimeout(animation, increment);
    }

    animation();
  }

  setIdUserCurriculum() {
    this.idCurriculoUsuario =
      this.state.usuario?.curriculoUsuario[0]?.id_curriculo_usuario;
    if (this.idCurriculoUsuario)
      localStorage.setItem(
        "profileIdCurriculoUsuarioState",
        JSON.stringify(this.idCurriculoUsuario)
      );
    else if (localStorage.hasOwnProperty("profileIdCurriculoUsuarioState"))
      this.idCurriculoUsuario = parseInt(
        localStorage.getItem("profileIdCurriculoUsuarioState")
      );
  }

  beginTest() {
    this.stateOfScreen = 1;
    this.removeDisableFromFirstInput();
  }

  begin() {
    if (localStorage.hasOwnProperty("profileTestAnswersState")) {
      this.stateOfScreen = 1;
      this.objOfAnswers = JSON.parse(
        localStorage.getItem("profileTestAnswersState")
      );

      if (Object.values(this.objOfAnswers).length != 0) {
        setTimeout(() => {
          this.removeDisableFromFirstInput();
          Object.values(this.objOfAnswers).forEach((answerObj, index) => {
            this.removeDisableFromAllInputs(
              document.getElementById(`radio-1-${index + 1}`)
            );
            this.markInputAsSelected(
              document.getElementById(`radio-1-${index}`),
              answerObj.texto
            );
          });
          this.setQuestionaryProgressionPercentage();
          const scrollTo =
            Object.values(this.objOfAnswers).length * this.SCROLL_DISTANCE;
          this.smoothScroll(scrollTo, 600);
        }, 500);
      } else {
        this.removeDisableFromFirstInput();
      }
      return;
    }
  }

  removeDisableFromFirstInput() {
    setTimeout(() => {
      const firstInput = document.getElementById(`radio-1-0`);
      this.removeDisableFromAllInputs(firstInput);
    }, 5);
  }

  goToAnswers() {
    localStorage.setItem(
      "scrollTo",
      JSON.stringify({ page: "curriculo", to: "bottom" })
    );
    this.exit();
  }

  exit() {
    localStorage.removeItem("profileTestAnswersState");
    localStorage.removeItem("profileIdCurriculoUsuarioState");
    if (this.testResultId) {
      this.router.navigate([`perfil-usuario/${this.testResultId}`]);
    } else {
      this.router.navigate(["curriculo"]);
    }
  }

  submitAnswers() {
    this.isLoading = true;
    const answersArray = Object.values(this.objOfAnswers);
    const idPerfilTeste = this.idProfileTest;
    this.obsDataServiceAnswers = this.dataService
      .sendProfileTestAnsweredQuestions(
        answersArray,
        idPerfilTeste,
        this.idCurriculoUsuario
      )
      .subscribe(
        (data: any) => {
          this.dataService.refreshUserData();
          localStorage.removeItem("profileTestAnswersState");
          localStorage.removeItem("profileIdCurriculoUsuarioState");
          this.stateOfScreen = 2;
          this.testResultId = data.length > 0 ? data[0].id_perfil_catalogo : 0;
          setTimeout(() => (this.isLoading = false), 1000);
        },
        (error) => {
          this.global.toast(
            this.translate.instant("GENERAL.ERROR"),
            error.error.message
          );
          setTimeout(() => this.exit(), 2000);
        }
      );
  }

  setQuestionaryProgressionPercentage() {
    this.numberOfResolvedQuestions = Object.values(this.objOfAnswers).length;
    if (this.numberOfResolvedQuestions <= this.numberOfQuestions)
      this.questionaryProgressionPercentage = Math.floor(
        (this.numberOfResolvedQuestions / this.numberOfQuestions) * 100
      );
  }

  public get stringWithWidthAndProgression() {
    return `width:${this.questionaryProgressionPercentage}%`;
  }

  public get isAllowedSendAnswers() {
    return this.numberOfQuestions != this.numberOfResolvedQuestions;
  }

  getProfileQuestions() {
    this.obsDataServiceQuestions = this.dataService
      .getProfileTestQuestions(this.idProfileTest)
      .subscribe(
        (data: any) => {
          this.arrayOfQuestions = data.perfilTesteQuestao;
          this.numberOfQuestions = this.arrayOfQuestions.length;
        },
        (error) => {
          this.global.toast(
            this.translate.instant("GENERAL.ERROR"),
            error.error.message
          );
          setTimeout(() => this.exit(), 2000);
        }
      );
  }
}
