import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { fillModalVideo } from 'src/app/state/state.actions';

@Component({
	selector: 'cls-modal-video',
	templateUrl: './modal-video.component.html',
	styleUrls: ['./modal-video.component.scss'],
})
export class ModalVideoComponent implements OnInit {
	stateSubscription: any;
	state: any;
	videoIframe = '';

  @Output() closed: any = new EventEmitter();

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
  	this.getState();
  }

  getState() {
  	this.stateSubscription = this.store
  		.select((state: any) => {
  			const { modalVideo } = state.newAppState;
  			this.state = { modalVideo };
  			this.videoIframe =
          modalVideo && modalVideo.includes('<iframe')
          	? modalVideo
          	: this.generateVideoFrame(modalVideo);
  		})
  		.subscribe();
  }

  generateVideoFrame(url: string) {
  	// usar embed link
  	const videoId = this.getVideoIdFromUrl(url);
  	const iframe = url.includes('vimeo')
  		? `<iframe src="https://player.vimeo.com/video/${videoId}" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>`
  		: `<iframe id='ytplayer' type='text/html' width='663' height='372' src="https://www.youtube.com/embed/${videoId}" frameborder='0'>`;

  	return iframe;
  }

  getVideoIdFromUrl(url: string): string {
  	const YOUTUBE_VIDEO_ID_LENGTH = 11;
  	const regExpEmbed =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  	const match = url.match(regExpEmbed);

  	if (match && match[2].length >= YOUTUBE_VIDEO_ID_LENGTH) return match[2];
  	else {
  		let videoUrl = url;
  		if (videoUrl.includes('https://')) videoUrl = videoUrl.slice(8);
  		const index = videoUrl.indexOf('/');
  		return index !== -1 ? videoUrl.slice(videoUrl.indexOf('/') + 1) : 'error';
  	}
  }

  close() {
  	this.store.dispatch(fillModalVideo({ payload: null }));
  	this.closed.emit(true);
  }
}
