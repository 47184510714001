import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MensagemListComponent } from './mensagem-list.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { createTranslateLoader } from 'src/app/app.module';
import { HttpClient } from '@angular/common/http';
import { SanitizeHtmlModule } from '../sanitize-html/sanitize-html.module';
import { ModalNotificacaoComponent } from './modal-notificacao/modal-notificacao.component';



@NgModule({
  declarations: [
    MensagemListComponent,
    ModalNotificacaoComponent
  ],
  imports: [
    CommonModule,
    SanitizeHtmlModule,
    TranslateModule.forRoot({
      defaultLanguage: environment.defaultLanguage,
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  exports: [MensagemListComponent]
})
export class MensagemListModule { }
