import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardDataComponent } from './card-data.component';



@NgModule({
  declarations: [CardDataComponent],
  imports: [
    CommonModule
  ],
  exports: [CardDataComponent]
})
export class CardDataModule { }
