<div class="login-container background-empresa">
    <div class="login-box">
        <div class="d-flex indicators">
            <div class="indicator blank"></div>
            <div class="indicator filled"></div>
        </div>
        <img src="../../../assets/imgs/liga-login1.png" class="empresa-logo">
        <label class="upper">{{ 'PASSWORD_RESET.TITLE' | translate }}</label>
        <div class="instruction mt-3 capitalize">{{ 'PASSWORD_RESET.INSTRUCTION' | translate }}</div>
        
        <div class="d-flex position-relative mt-4">
            <input type="password" placeholder="{{ 'PASSWORD_RESET.NEW_PASS' | translate }}" [(ngModel)]="credentials.password" class="form-control small"> 
            <img class="img-input" src="../../../assets/icons/lock.svg">
        </div>
        <div class="d-flex position-relative">
            <input type="password" placeholder="{{ 'PASSWORD_RESET.NEW_PASS_CONFIRM' | translate }}" [(ngModel)]="credentials.passwordConfirmation" class="form-control small"> 
            <img class="img-input" src="../../../assets/icons/lock.svg">
        </div>
        <div class="alert-reset capitalize" *ngIf="alert">{{ 'PASSWORD_RESET.INVALID' | translate }}</div>
        <div class="btns-password mt-4"><button (click)="setNewPassword()" class="btn btn-send upper">{{ 'PASSWORD_RESET.CONFIRM' | translate }}</button></div>
    </div>
    <div class="liga-wallpaper"></div>
</div>