<div class="avaliacao-perfil">
    <div>
        <div class="avaliacao-perfil__button-back" *ngIf="stateOfScreen==0" (click)="exit()">
            <img class="avaliacao-perfil__button-back-arrow"
                 src="../../../assets/icons/chevron-left-white.svg">
            <p class="avaliacao-perfil__button-back-text capitalize">{{ 'QUESTIONARIO_PERFIL.BACK' | translate }}</p>
        </div>
        
        <div class="avaliacao-perfil__card-instrunctions" *ngIf="stateOfScreen==0">
            <app-spinner *ngIf="isLoading" class="avaliacao-perfil__spinner"></app-spinner>
            <div *ngIf="!isLoading" class="avaliacao-perfil__instrucs">
                <div class="avaliacao-perfil__instrucs-title mt-2 capitalize">{{ 'QUESTIONARIO_PERFIL.WELCOME' | translate }}</div>
                <div class="avaliacao-perfil__instrucs-head">
                    <img class="avaliacao-perfil__instrucs-head-clock" src="../../../assets/icons/relogio.svg">
                    <p class="avaliacao-perfil__instrucs-head-subtitle capitalize">{{ 'QUESTIONARIO_PERFIL.DURATION' | translate }}<br>{{ 'QUESTIONARIO_PERFIL.DURATION1' | translate }}</p>
                </div>

                <div class="avaliacao-perfil__instrucs-how mt-2 capitalize">{{ 'QUESTIONARIO_PERFIL.WHAT_PROFILE' | translate }}</div>
                <p class="capitalize">{{ 'QUESTIONARIO_PERFIL.WHAT_PROFILE_TEXT1' | translate }}</p>
                <p class="capitalize">{{ 'QUESTIONARIO_PERFIL.WHAT_PROFILE_TEXT2' | translate }}</p>
                <p class="capitalize">{{ 'QUESTIONARIO_PERFIL.WHAT_PROFILE_TEXT3' | translate }}</p>

                <div class="avaliacao-perfil__instrucs-buttons">
                    <button class="avaliacao-perfil__instrucs-buttons-beggin btn ml-2 upper" (click)="beginTest()">{{ 'QUESTIONARIO_PERFIL.START' | translate }}</button>
                </div>
            </div>
        </div>
        <div class="avaliacao-perfil__detail-instrunctions" *ngIf="stateOfScreen==0">
            <div class="avaliacao-perfil__detail-instrunctions--1"></div>
            <div class="avaliacao-perfil__detail-instrunctions--2"></div>
        </div>
        <div class="avaliacao-perfil__card-questions" *ngIf="stateOfScreen==1">
            <div class="avaliacao-perfil__questions">
                <div class="avaliacao-perfil__questions-progress" [style]="stringWithWidthAndProgression">
                    <div class="avaliacao-perfil__questions-progress-marks">
                        <p class="avaliacao-perfil__questions-progress-marks-percent">{{questionaryProgressionPercentage}}%</p>
                    </div>
                    <div class="avaliacao-perfil__questions-progress-marks">
                        <span class="avaliacao-perfil__questions-progress-marks-point"></span>
                    </div>
                    <div class="progress-bar avaliacao-perfil__questions-progress-bar" role="progressbar" aria-valuenow="80"
                        aria-valuemin="0" aria-valuemax="100" style="width:100%;">
                    </div>
                </div>
                <div class="avaliacao-perfil__questions-qblock"> 
                    <span class="avaliacao-perfil__questions-qblock-title">
                        <p class="avaliacao-perfil__questions-qblock-title-text capitalize">{{ 'QUESTIONARIO_PERFIL.YOU_ARE' | translate }}<br/>{{ 'QUESTIONARIO_PERFIL.YOU_ARE1' | translate }}</p>
                    </span>
                    
                    <span class="avaliacao-perfil__questions-qblock-icon">
                        <img class="avaliacao-perfil__questions-qblock-icon-arrow"
                            src="../../../assets/icons/seta_cheia.svg"
                        >
                    </span>
                    
                    <div class="avaliacao-perfil__questions-qblock-opts" id="scroll-block">
                        <div 
                            *ngFor="let question of arrayOfQuestions; let i = index" 
                            class="avaliacao-perfil__questions-qblock-opts-blks"
                            id="{{'opts-blk-' + i}}"
                            >
                            <hr style="margin: 10px;" size="10" width="100%">
                            <div class="radio" (click)="handleSelectAnswer($event,i)">   
                                <input disabled="true" id="{{'radio-0-' + i}}" name="{{'radio'+i}}" type="radio">
                                <label for="{{'radio-0-' + i}}" class="radio-label">{{question?.perfilTesteQuestaoAlternativa[0].texto}}</label>
                            </div>

                            <div class="radio" (click)="handleSelectAnswer($event,i)">
                                <input disabled="true" id="{{'radio-1-' + i}}" name="{{'radio'+i}}" type="radio">
                                <label for="{{'radio-1-' + i}}" class="radio-label">{{question?.perfilTesteQuestaoAlternativa[1].texto}}</label>
                            </div>

                            <div class="radio" (click)="handleSelectAnswer($event,i)" *ngIf="question?.perfilTesteQuestaoAlternativa.length>2">   
                                <input disabled="true" id="{{'radio-2-' + i}}" name="{{'radio'+i}}" type="radio">
                                <label for="{{'radio-2-' + i}}" class="radio-label">{{question?.perfilTesteQuestaoAlternativa[2].texto}}</label>
                            </div>

                            <div class="radio" (click)="handleSelectAnswer($event,i)" *ngIf="question?.perfilTesteQuestaoAlternativa.length>3">
                                <input disabled="true" id="{{'radio-3-' + i}}" name="{{'radio'+i}}" type="radio">
                                <label for="{{'radio-3-' + i}}" class="radio-label">{{question?.perfilTesteQuestaoAlternativa[3].texto}}</label>
                            </div>
                        </div>
                        <div class="btn avaliacao-perfil__questions-qblock-opts-btn">
                            <hr style="margin: 20px;" size="10" width="100%">
                            <button (click)='submitAnswers()' [disabled]="isAllowedSendAnswers" class="btn avaliacao-perfil__questions-qblock-opts-btn-button upper">{{ 'QUESTIONARIO_PERFIL.SEND' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="avaliacao-perfil__detail-questions" *ngIf="stateOfScreen==1">
            <div class="avaliacao-perfil__detail-questions--1"></div>
            <div class="avaliacao-perfil__detail-questions--2"></div>
        </div>
        <div class="avaliacao-perfil__card-response" *ngIf="stateOfScreen==2">
            <app-spinner *ngIf="isLoading" class="avaliacao-perfil__spinner"></app-spinner>
            <div *ngIf="!isLoading" class="avaliacao-perfil__response">
                <div class="avaliacao-perfil__response-left">
                    <img class="avaliacao-perfil__response-left-img"
                            src="../../../assets/icons/perfil_estrelas.svg"
                        >
                </div>
                <div class="avaliacao-perfil__response-right">
                    <p class="avaliacao-perfil__response-right-text capitalize">
                        {{ 'QUESTIONARIO_PERFIL.CONGRATS1' | translate }} <br/>{{ 'QUESTIONARIO_PERFIL.CONGRATS2' | translate }} <br/>{{ 'QUESTIONARIO_PERFIL.CONGRATS3' | translate }}
                    </p>
                    <button class="btn avaliacao-perfil__response-right-button upper" (click)="goToAnswers()" >{{ 'QUESTIONARIO_PERFIL.SEE_RESULT' | translate }} </button>
                </div>
            </div>
        </div>
        <div class="avaliacao-perfil__detail-questions" *ngIf="stateOfScreen==2">
            <div class="avaliacao-perfil__detail-questions--1"></div>
            <div class="avaliacao-perfil__detail-questions--2"></div>
        </div>
    </div>
</div>
