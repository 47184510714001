<div class="flex">
  <div [class]="mensagemLida ? 'message-content__grey' : 'message-content'"
    (click)="openModalEvento()">
    <div
      [class]="mensagemLida ? 'message-box__grey' : 'message-box'">
      <div class="head-mensagem">
        <span class="titulo">
          {{ mensagem.titulo | translate }}
        </span>
        <div [ngSwitch]="locale">
          <span *ngSwitchCase="'pt-br'" class="date_time">{{
            mensagem.notificacao_usuario_data_envio | date : "dd/MM/yyyy | HH:mm"
          }}h</span>
          <span *ngSwitchCase="'en-us'" class="date_time">{{
            mensagem.notificacao_usuario_data_envio | date : "MM/dd/YYYY | h:mm a"
          }}h</span>
        </div>
      </div>
      <div>
        <span
          class="message-text"
          *ngIf="mensagem?.texto_rico"
          [innerHTML]="mensagem.texto_rico | sanitizeHtml"
        ></span>
        <p
          class="message-text"
          *ngIf="!mensagem?.texto_rico">
          {{ mensagem?.mensagem }}
        </p>
      </div>
    </div>
    <div [class]="mensagemLida ? 'cinza' : 'azul'">
      <i class="fas fa-chevron-right" data-cy="NotificationIconVoltar"></i>
    </div>
  </div>
  <div *ngIf="!mensagemLida" class="barra"></div>
</div>

<ui-modal-notificacao
  *ngIf="openModalEvent"
  [data]="mensagem"
  [lida]="mensagemLida"
  (close)="closeModalEvent()">
</ui-modal-notificacao>
