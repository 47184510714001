<div class="datatable-container">
    <label>{{label}}</label>
    <button class="btn-filter d-none" (click)="filter()"></button>
    <table>
        <thead>
            <tr>
                <th *ngFor="let head of headers">
                    <span *ngIf="head.type != 'checkbox'">{{head.text}}</span>
                    <div class="check" *ngIf="head.type == 'checkbox'"
                        [class.checked]="selection?.length === dataArray?.length" (click)="mark()">
                        <i *ngIf="selection?.length === dataArray?.length" class="fas fa-check"></i>
                    </div>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of dataArray">
                <td *ngFor="let head of headers">
                    <span *ngIf="isDefaultOrText(head.type, item[head.field])">{{item[head.field]}}</span>
                    <div class="check" *ngIf="head.type == 'checkbox'" [class.checked]="isChecked(item)"
                        (click)="mark(item)">
                        <i *ngIf="isChecked(item)" class="fas fa-check"></i>
                    </div>
                    <div class="actions" *ngIf="head.type == 'actions'">
                        <button class="btn btn-visualizar upper" (click)="emitAction(item)">
                            {{ 'BUTTON.TO_VIEW' | translate }}
                        </button>
                    </div>
                    <div *ngIf="head.type == 'datetime'">{{item[head.field] | date:'dd/MM/yyyy HH:mm'}}</div>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="paginator">
        <div (click)="paginate(false)"><i class="fas fa-chevron-left"></i></div>
        <div *ngFor="let bef of before">
            <span *ngIf="bef < 2">{{bef}}</span>
            <i class="fas fa-ellipsis-h" *ngIf="bef >= 2"></i>
        </div>
        <div class="selected">{{pagination?.page}}</div>
        <div *ngIf="(pagination?.pages - pagination?.page) >= 2"><i class="fas fa-ellipsis-h"></i></div>
        <div *ngIf="pagination?.pages != pagination?.page && pagination?.pages >= 3">{{pagination?.pages}}</div>
        <div *ngIf="pagination?.pages == 2 && pagination?.page == 1">2</div>
        <div (click)="paginate(true)"><i class="fas fa-chevron-right"></i></div>
    </div>
</div>

