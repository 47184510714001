import { Component } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "cls-carousel-login",
  templateUrl: "./carousel-login.component.html",
  styleUrls: ["./carousel-login.component.scss"],
})
export class CarouselLoginComponent {
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    autoWidth: false,
    navSpeed: 700,
    navText: [
      "<img src='assets/imgs/arrow-trial-left.svg'>",
      "<img src='assets/imgs/arrow-trial-right.svg'>",
    ],
    items: 1,
    nav: true,
  };
}
