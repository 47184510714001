import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import Player from '@vimeo/player';
import { Observable, Subject } from 'rxjs';
import { AppState } from 'src/app/state';
import { fillToast } from 'src/app/state/state.actions';

@Injectable({
	providedIn: 'root',
})
export class GlobalService {
	fasesArray: any;
	vimeoPlayer: any;
	clearInputFase = new Subject<any>();

	constructor(private store: Store<AppState>) {}

	generateImageURL(url) {
		return 'url(' + url + ')';
	}

	vimeo() {
		const divs = document.getElementsByName('vimeo');
		if (this.vimeoPlayer) {
			this.vimeoPlayer.destroy();
			divs.forEach((div) => {
				if (div.childNodes[0]) div.removeChild(div.childNodes[0]);
			});
		}

		setTimeout(() => {
			divs.forEach((div) => {
				if (div.getAttribute('id') != 'null') {
					div.setAttribute('data-vimeo-url', div.getAttribute('id'));
					this.vimeoPlayer = new Player(div);
				}
			});
		}, 1500);
	}

	getMessage(): Observable<any> {
		return this.clearInputFase.asObservable();
	}

	getMonth(date) {
		return date.substr(5, 2);
	}

	getYear(date) {
		return date.substr(0, 4);
	}

	getMonthAndYear(date) {
		return date.substr(0, 7);
	}

	scrollToBottomViaID(id) {
		setTimeout(() => {
			const elmnt = document.getElementById(id);
			elmnt.scrollBy({
				top: elmnt.scrollHeight,
				behavior: 'smooth',
			});
		}, 1000);
	}

	shuffle(array) {
		let currentIndex = array.length;
		let temporaryValue;
		let randomIndex;
		while (0 !== currentIndex) {
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex -= 1;
			temporaryValue = array[currentIndex];
			array[currentIndex] = array[randomIndex];
			array[randomIndex] = temporaryValue;
		}
		return array;
	}

	getBrowserName() {
		const nAgt = navigator.userAgent;
		let browserName = navigator.appName;
		let fullVersion = '' + parseFloat(navigator.appVersion);
		let nameOffset, verOffset, ix;

		// In Opera, the true version is after "Opera" or after "Version"
		if ((verOffset = nAgt.indexOf('Opera')) != -1) {
			browserName = 'Opera';
			fullVersion = nAgt.substring(verOffset + 6);
			if ((verOffset = nAgt.indexOf('Version')) != -1)
				fullVersion = nAgt.substring(verOffset + 8);
		}
		// In MSIE, the true version is after "MSIE" in userAgent
		else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
			browserName = 'Microsoft Internet Explorer';
			fullVersion = nAgt.substring(verOffset + 5);
		}
		// In Chrome, the true version is after "Chrome"
		else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
			browserName = 'Chrome';
			fullVersion = nAgt.substring(verOffset + 7);
		}
		// In Safari, the true version is after "Safari" or after "Version"
		else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
			browserName = 'Safari';
			fullVersion = nAgt.substring(verOffset + 7);
			if ((verOffset = nAgt.indexOf('Version')) != -1)
				fullVersion = nAgt.substring(verOffset + 8);
		}
		// In Firefox, the true version is after "Firefox"
		else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
			browserName = 'Firefox';
			fullVersion = nAgt.substring(verOffset + 8);
		}
		// In most other browsers, "name/version" is at the end of userAgent
		else if (
			(nameOffset = nAgt.lastIndexOf(' ') + 1) <
      (verOffset = nAgt.lastIndexOf('/'))
		) {
			browserName = nAgt.substring(nameOffset, verOffset);
			fullVersion = nAgt.substring(verOffset + 1);
			if (browserName.toLowerCase() == browserName.toUpperCase()) {
				browserName = navigator.appName;
			}
		}

		return browserName;
	}

	getOSName() {
		let OSName = 'Unknown OS';
		if (navigator.appVersion.indexOf('Win') != -1) OSName = 'Windows';
		if (navigator.appVersion.indexOf('Mac') != -1) OSName = 'MacOS';
		if (navigator.appVersion.indexOf('X11') != -1) OSName = 'UNIX';
		if (navigator.appVersion.indexOf('Linux') != -1) OSName = 'Linux';

		return OSName;
	}

	checkClose(event, elementName) {
		if (
			navigator.userAgent.indexOf('Chrome') !== -1 &&
      event.target == elementName
		)
			return true;
		else if (event.toElement.nodeName == elementName) return true;
		return false;
	}

	updateDatatable() {
		const input: HTMLElement = document.querySelector('.btn-filter');
		if (input) input.click();
	}

	toast(title, body) {
		this.store.dispatch(fillToast({ payload: { title: title, body: body } }));
	}

	validateEmail(email) {
		const re =
      /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	clearString(string) {
		return string.replace(/[^\d]+/g, ''); //limpando pontos e hífens
	}

	validateTelefone(telefone) {
		let ret = false;
		if (telefone && (telefone.length === 10 || telefone.length === 11))
			ret = true;
		return ret;
	}

	validateCPF(cpf) {
		cpf = this.clearString(cpf);
		let Soma;
		let Resto;
		Soma = 0;
		if (cpf == '00000000000') return false;

		for (let i = 1; i <= 9; i++)
			Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
		Resto = (Soma * 10) % 11;

		if (Resto == 10 || Resto == 11) Resto = 0;
		if (Resto != parseInt(cpf.substring(9, 10))) return false;

		Soma = 0;
		for (let i = 1; i <= 10; i++)
			Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
		Resto = (Soma * 10) % 11;

		if (Resto == 10 || Resto == 11) Resto = 0;
		if (Resto != parseInt(cpf.substring(10, 11))) return false;
		return true;
	}

	setToPascalCase(nome) {
		const pascal = nome.replace(/\w+/g, function (w) {
			return w[0].toUpperCase() + w.slice(1).toLowerCase();
		});
		return pascal;
	}

	getStringTentativa(value) {
		let ret = '';
		switch (value) {
		case 0:
			ret = 'primeira';
			break;
		case 1:
			ret = 'segunda';
			break;
		case 2:
			ret = 'terceira';
			break;
		case 3:
			ret = 'quarta';
			break;
		case 4:
			ret = 'quinta';
			break;
		case 5:
			ret = 'sexta';
			break;
		case 6:
			ret = 'sétima';
			break;
		case 7:
			ret = 'oitava';
			break;
		case 8:
			ret = 'nona';
			break;
		case 9:
			ret = 'décima';
			break;
		}
		return ret;
	}

	getTinyMCEDefaultConfigs() {
		return {
			setup: function (editor: any) {
				editor.on('init', function (args: any) {
					const dom = editor.dom;
					editor = args.target;
				});
			},
			height: 300,
			paste_preprocess: function (plugin, args) {
				args.content = '';
			},
			menubar: false,
			branding: false,
			elementpath: false,
			keep_styles: false,
			object_resizing: false,
			media_dimensions: false,
			image_dimensions: false,
			font_family_formats: 'Lato=Lato',
			image_caption: true,
			content_style:
        '@import url(\'https://fonts.googleapis.com/css2?family=Lato&display=swap\'); body { font-family: Lato; font-size: 20px }; progress[value]::-webkit-progress-value {background-color: #24a1cd}; img {width: 750px; height: auto}',
			extended_valid_elements:
        'svg[*],path[*],circle[*],line[*], img[*], iframe[*], video[*]',
			paste_as_text: true,
			toolbar: 'undo redo |  alignleft',
			cursor: 'pointer',
		};
	}
}
