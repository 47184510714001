import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { fillDocumentViewerUrl } from 'src/app/state/state.actions';
@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss']
})
export class DocumentViewerComponent implements OnInit {


  @Input() project;
  state: any;
  stateSubscription$: any;

  constructor(private store: Store<AppState>) { }

  ngOnDestroy(): void {
    this.stateSubscription$.unsubscribe()
  }

  ngOnInit() {
    this.getState();
  }

  getState() {
    this.stateSubscription$ = this.store.select((state: any) => {
      this.state = state.newAppState;
    }).subscribe();
  }


  closeSaibaMais() {
    this.store.dispatch(fillDocumentViewerUrl({ payload: '' }))
    document.querySelector('body').removeAttribute('class');
  }

  handleClickOutside(event) {
    // setTimeout(() => {
    //   this.closeSaibaMais();
    // }, 300);
  }
}
