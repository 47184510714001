import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {showModalPesquisa, showModalPesquisaSucesso} from 'src/app/state/state.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { PerguntaPesquisaNps, PesquisaNps, RespostaPesquisaNps } from 'src/app/models/pesquisa-nps.model';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data-service/data.service';
import { EMPTY, Subscription } from 'rxjs';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { catchError, finalize, tap } from 'rxjs/operators';


@Component({
  selector: 'cls-modal-pesquisa',
  templateUrl: './modal-pesquisa.component.html',
  styleUrls: ['./modal-pesquisa.component.scss']
})
export class ModalPesquisaComponent implements OnInit {
  @Input() pesquisa: PesquisaNps
  @Input() id_projeto_turma_estudante: number
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  respostas = [];
  perguntasNaoRespondidas = [];
  subscriptions: Subscription[] = [];

  constructor(
    private store: Store<AppState>,
    private translate: TranslateService,
    private dataService: DataService,
    private global: GlobalService,
    ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  getPerguntasNaoRespondidas() {
    this.perguntasNaoRespondidas = [];
    for (let i = 0; i < this.pesquisa.perguntas.length; i++) {
      if (!this.respostas[i])
        this.perguntasNaoRespondidas[i] = true;
    }
  }

  handleResposta (value, index) {
    this.respostas[index] = value;
  }

  validateRespostas(): boolean {
    this.getPerguntasNaoRespondidas()
    for (let perguntaNaoRespondida of this.perguntasNaoRespondidas) {
      if (perguntaNaoRespondida)
      return false;
    }
    return true;
  }

  submit() {
    console.log(this.respostas);
    console.log(this.perguntasNaoRespondidas);
    if (!this.validateRespostas())
      return;
    let respostasResult = this.composeRespostas(this.respostas);
    console.log(respostasResult);
    const subscription = this.dataService.responderPesquisaNps(respostasResult).pipe(
      tap((data) => {
        console.log(data);
      }),
      catchError((error) => {
        this.global.toast(
          this.translate.instant("GENERAL.ERROR"),
          error.error.message
        );
        return EMPTY;
      }),
      finalize(() => {
        this.closeModal.emit(true)
        this.store.dispatch(showModalPesquisaSucesso({ payload: true }))
      })
    ).subscribe();
    this.subscriptions.push(subscription);
  }

  composeRespostas(respostas): Array<RespostaPesquisaNps> {
    let respostasResult = [];
    for(let [index, resposta] of respostas.entries()) {
      respostasResult.push({
        id_pesquisa_nps_pergunta: this.pesquisa.perguntas[index].id_pesquisa_nps_pergunta,
        id_projeto_turma_estudante: this.id_projeto_turma_estudante,
        resposta: resposta.toString()
      });
    };
    return respostasResult;
  }

  handleCloseModal() {
    this.closeModal.emit(true);
  }
}
