<cls-header></cls-header>

<div *ngIf="tipoAtendimento == 'sematendimento'">
  <div class="solicitacoes-container email">
    <div class="d-flex justify-content-start flex-row align-items-center mb-4">
      <span class="voltar-container pointer" (click)="backToHome()"
        ><img
          class="voltar"
          src="../../../assets/icons/icone_voltar.svg"
          alt=""
      /></span>
      <div class="title upper">{{ "ATTENDANCE.ATTENDANCE" | translate }}</div>
    </div>
    <div class="label pl-4 capitalize">
      {{ "ATTENDANCE.NO_ATTENDANCE" | translate }}
    </div>
  </div>
</div>

<div *ngIf="tipoAtendimento == 'email'">
  <div class="solicitacoes-container email">
    <div class="d-flex justify-content-start flex-row align-items-center mb-4">
      <span class="voltar-container pointer" (click)="backToHome()"
        ><img
          class="voltar"
          src="../../../assets/icons/icone_voltar.svg"
          alt=""
      /></span>
      <div class="title upper">{{ "ATTENDANCE.ATTENDANCE" | translate }}</div>
    </div>
    <div *ngIf="state.projetoAtivo?.empresa?.email_atendimento">
      <div class="label pl-4 capitalize">
        {{ "ATTENDANCE.TALK_US" | translate }}
      </div>
      <div class="contato pl-4 capitalize">
        {{ "ATTENDANCE.CONTACT_LINK" | translate }}
      </div>
      <address class="pl-4 mt-1">
        <a class="ajuda" target="_blank" [href]="mailTo">{{
          state.projetoAtivo?.empresa.email_atendimento
        }}</a>
        <!-- <a class="ajuda" target="_blank" *ngIf="!state.projetoAtivo?.empresa?.email_atendimento" href="mailto:ajuda@ligaeducacional.com.br">ajuda@ligaeducacional.com.br</a> -->
      </address>
    </div>
    <div
      *ngIf="!state.projetoAtivo?.empresa?.email_atendimento"
      class="label pl-4 capitalize"
    >
      {{ "ATTENDANCE.SECRETARY" | translate }}
    </div>
  </div>
</div>

<div *ngIf="tipoAtendimento == 'movidesk'">
  <div class="overlay" *ngIf="isModalOpen">
    <div class="modal-container">
      <div class="flex-row justify-end" (click)="closeModal()">
        <p class="modal-container__btn-close">x</p>
      </div>
      <div class="modal-container__header">
        <div class="flex-row">
          <div class="flex-col">
            <span class="flex-row modal-container__label">
              {{ "ATTENDANCE.PROTOCOL" | translate }}:
              <p class="modal-container__label--data">
                {{ state?.suportDetails?.suporte_instance }}
              </p>
            </span>
            <span class="flex-row modal-container__label">
              {{ "ATTENDANCE.SERVICES" | translate }}:
              <p class="modal-container__label--data">
                {{ state?.suportDetails?.suporte_categoria_nome }}
              </p>
            </span>
            <span class="flex-row modal-container__label">
              {{ "ATTENDANCE.SUBJECT" | translate }}:
              <p class="modal-container__label--data">
                {{ state?.suportDetails?.suporte_assunto }}
              </p>
            </span>
          </div>
          <div class="flex-col" style="margin-left: 24px">
            <span class="flex-row modal-container__label">
              {{ "ATTENDANCE.STATUS" | translate }}:
              <p class="modal-container__label--data">
                {{ state?.suportDetails?.suporte_status_nome }}
              </p>
            </span>
            <span class="flex-row modal-container__label">
              {{ "ATTENDANCE.DATE" | translate }}:
              <p class="modal-container__label--data">
                {{
                  formatStringData(state?.suportDetails?.suporte_data_abertura)
                }}
              </p>
            </span>
            <span
              *ngIf="
                !!state?.suportDetails?.detalhes[0]?.movideskFields
                  ?.slaSolutionDate
              "
              class="flex-row modal-container__label"
            >
              {{ "ATTENDANCE.SLA_SOLUTION_DATE" | translate }}:
              <p class="modal-container__label--data">
                {{
                  formatStringData(
                    state?.suportDetails?.detalhes[0]?.movideskFields
                      ?.slaSolutionDate
                  )
                }}
              </p>
            </span>
          </div>
        </div>
      </div>
      <div class="modal-container__chat">
        <cls-scrollable-chat
          [messages]="state?.suportDetails"
          (reply)="sendMessageOutput($event)"
          [status_aluno]="estudanteStatus"
          (solve)="solveTicket()"
        ></cls-scrollable-chat>
      </div>
    </div>
  </div>
  <div class="solicitacoes-container movidesk">
    <form class="form-card" [formGroup]="selections">
      <div class="flex-row">
        <p class="form-card__title upper">
          {{ "ATTENDANCE.ATTENDANCE" | translate }}
        </p>
        <hr class="form-card__line" />
      </div>
      <div class="flex-row">
        <div class="select-label flex-col">
          <label>{{ "ATTENDANCE.CHOOSE_AREA" | translate }}</label>
          <div class="flex-row">
            <select formControlName="area">
              <option value="" disabled="disabled" selected="selected">
                {{ "ATTENDANCE.SELECT_HERE" | translate }}
              </option>
              <option
                *ngFor="let option of areaOptions"
                [selected]="option?.id == selections.value.area"
                [value]="option?.id"
              >
                {{ option?.text }}
              </option>
            </select>
            <div class="flex-row align-center">
              <img src="assets/icons/chevron_down.svg" />
            </div>
          </div>
        </div>
        <div class="select-label flex-col" style="margin: 0 42px">
          <label>{{ "ATTENDANCE.CHOOSE_SERVICE" | translate }}</label>
          <div class="flex-row">
            <select
              [attr.disabled]="isServiceDisabled ? '' : null"
              formControlName="service"
            >
              <option value="" disabled="disabled" selected="selected">
                {{ "ATTENDANCE.SELECT_HERE" | translate }}
              </option>
              <option
                *ngFor="let option of servicosOptions"
                [selected]="option?.id == selections.value.service"
                [value]="option?.id"
              >
                {{ option?.text }}
              </option>
            </select>
            <div class="flex-row align-center">
              <img src="assets/icons/chevron_down.svg" />
            </div>
          </div>
        </div>
        <div class="select-label flex-col">
          <label>{{ "ATTENDANCE.ATTENDANCE_SOLICITATION" | translate }}</label>
          <div class="flex-row">
            <select
              [attr.disabled]="isSolicitationDisabled ? '' : null"
              formControlName="solicitation"
            >
              <option value="" disabled="disabled" selected="selected">
                {{ "ATTENDANCE.SELECT_HERE" | translate }}
              </option>
              <option
                class="opts"
                *ngFor="let option of solicitacoesOptions"
                [selected]="option?.id == selections.value.solicitation"
                [value]="option?.id"
              >
                {{ option?.text }}
              </option>
            </select>
            <div class="flex-row align-center">
              <img src="assets/icons/chevron_down.svg" />
            </div>
          </div>
        </div>
      </div>
      <div class="text-area flex-col" style="margin-top: 28px">
        <label>{{ "ATTENDANCE.TYPE_MESSAGE" | translate }}</label>
        <textarea
          name="text"
          rows="5"
          [placeholder]="'ATTENDANCE.TYPE_MESSAGE' | translate"
          formControlName="message"
        >
        </textarea>
      </div>
      <div class="flex-col upload">
        <label for="upload-file" class="flex-row" style="cursor: pointer">
          <input id="upload-file" type="file" (change)="attachFile($event)" />
          <img *ngIf="!file" src="assets/icons/paperclip.svg" />
          <img
            class="form-card__uploaded"
            *ngIf="file"
            src="assets/icons/uploaded.png"
            (click)="clearFileInput(); $event.stopPropagation()"
          />
          <p class="form-card__text">
            {{ "ATTENDANCE.ATTACH_FILE" | translate }}
          </p>
        </label>
      </div>
      <div class="flex-row justify-end" (click)="sendNewTicket()">
        <p class="btn modal-container__btn-create upper">
          {{ "ATTENDANCE.CREATE" | translate }}
        </p>
      </div>
    </form>

    <div class="flex-row" style="margin: 74px 0">
      <cls-datatable
        style="width: 100%"
        [label]="'ATTENDANCE.PREVIOUS_ATTENDANCES' | translate"
        [data]="tableData"
        method="store.mensagensSecretaria"
        (editUser)="getSuportAndOpenDetails($event)"
      >
      </cls-datatable>
    </div>
  </div>
</div>
