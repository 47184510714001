import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificacaoMensagemModel } from '../../../../../../src/app/models/notificacao-mensagem.model';

@Component({
	selector: 'ui-mensagem-list',
	templateUrl: './mensagem-list.component.html',
	styleUrls: ['./mensagem-list.component.scss'],
})
export class MensagemListComponent {
  @Input() mensagem: NotificacaoMensagemModel;
  @Input() ultimaMensagem: boolean;
  @Input() mensagemLida: boolean;
  @Output() marcarLido = new EventEmitter<number>();
  openModalEvent: boolean = false;
  locale: string;

  constructor(private translate: TranslateService) {
  	this.locale = this.translate.currentLang
  		? this.translate.currentLang.toLowerCase()
  		: this.translate.defaultLang.toLowerCase();
  }

  openModalEvento() {
  	this.openModalEvent = true;
  }

  closeModalEvent() {
  	this.openModalEvent = false;
  }
}
