import {
	AfterContentInit,
	Component,
	EventEmitter,
	OnInit,
	Output,
} from '@angular/core';
import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { Store } from '@ngrx/store';
import { OrderPipe } from 'ngx-order-pipe';
import { AppState } from 'src/app/state';
import {
	fillAtividadeAtual,
	fillFaseAtual,
	fillFases,
	fillObjetivoAprendizagemAtivo,
	showAgenda,
	showEntregaArquivo,
} from 'src/app/state/state.actions';
import { DataService } from 'src/app/services/data-service/data.service';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
	selector: 'app-agenda',
	templateUrl: './agenda.component.html',
	styleUrls: ['./agenda.component.scss'],
	animations: [
		trigger('Agenda', [
			state(
				'open',
				style({
					right: '0px',
				})
			),
			state(
				'closed',
				style({
					right: '-613px',
				})
			),
			transition('open => closed', [animate('.5s 0ms ease-in-out')]),
			transition('closed => open', [animate('.5s 0ms ease-in-out')]),
		]),
	],
})
export class AgendaComponent implements OnInit, AfterContentInit {
	state: any;
	stateSubscription: any;
	transition = 'closed';
	currentMonthStr: string;
	currentDate: string;
	encontrosArray = [];
	encontrosCalendar = [];
	currentMarcos = [];
	rangesArray = [];
	activities = [];
	currentActivities = [];
	currentDay;
	eventos: any;
	openModal = false;
	dataEvent: any;
	noActivity = 'GENERAL.NOT_ACTIVITY';
  @Output() closeMenu = new EventEmitter();

  constructor(
    private store: Store<AppState>,
    private dataService: DataService,
    private global: GlobalService,
    private route: ActivatedRoute,
    private router: Router,
    private orderPipe: OrderPipe
  ) {
  	this.setInitialCurrentMonth();
  	this.getState();
  	//this.getMarcosByMonth(this.currentDate);
  	//this.getEncontrosByMarcos();
  }

  ngOnInit() {
  	//this.dataService.getAndDispatchMarcos();
  	this.getCalendario(this.currentDate);
  }

  ngAfterContentInit() {
  	setTimeout(() => {
  		if (this.state.showingAgenda == 'open') this.transition = 'open';
  		this.setNoClickOnEmptyCells();
  	}, 300);
  }

  setInitialCurrentMonth() {
  	const today = new Date();
  	this.currentDay = today.toISOString().split('-')[2].split('T')[0];
  	this.currentMonthStr = today.toLocaleString('default', { month: 'long' });
  	this.currentDate = today.toISOString();
  }

  setMonthData(event) {
  	this.currentMonthStr = event.month;
  	this.getCalendario(event.date);
  	setTimeout(() => {
  		this.setNoClickOnEmptyCells();
  	}, 1000);
  }

  getMarcosByMonth(date) {
  	const month = this.global.getMonth(date);
  	const year = this.global.getYear(date);

  	this.currentMarcos = [];

  	if (
  		this.state.marcos.turmaAgenda &&
      this.state.marcos.turmaAgenda.length > 0
  	) {
  		this.state.marcos.forEach((marco) => {
  			const init = marco.turmaAgenda[0].data_inicial;
  			const end = marco.turmaAgenda[0].data_final;

  			if (
  				this.global.getMonthAndYear(init) == this.global.getMonthAndYear(end)
  			) {
  				if (
  					this.global.getMonth(init) == month &&
            this.global.getYear(init) == year
  				) {
  					if (!this.hasMarco(marco)) this.currentMarcos.push(marco);
  				}
  			} else if (
  				this.global.getMonth(init) == month ||
          this.global.getMonth(end) == month
  			)
  				this.currentMarcos.push(marco); // caso o marco comece em um mês e termine no outro mês
  		});
  	}
  }

  hasMarco(marco) {
  	let result = false;
  	this.currentMarcos.forEach((mrc) => {
  		if (marco.id_marco_turma == mrc.id_marco_turma) result = true;
  	});
  	return result;
  }

  getState() {
  	this.stateSubscription = this.store
  		.select((state: any) => {
  			const {
  				showingAgenda,
  				marcos,
  				encontroAgendado,
  				usuario,
  				projetoAtivo,
  				fases,
  				projetosAluno,
  				eventos,
  			} = state.newAppState;
  			this.state = {
  				showingAgenda,
  				marcos,
  				encontroAgendado,
  				usuario,
  				projetoAtivo,
  				fases,
  				projetosAluno,
  				eventos,
  			};
  		})
  		.subscribe();
  	if (this.state?.eventos?.eventosUser.length > 0)
  		this.eventos = [...this.state.eventos.eventosUser];
  }

  getEncontrosByMarcos() {
  	if (this.currentMarcos.length > 0) {
  		this.currentMarcos.forEach((marco) => {
  			if (
  				marco.turmaAgenda[0].turmaAgendaHorario &&
          marco.turmaAgenda[0].turmaAgendaHorario.length > 0
  			)
  				this.setEncontros(marco.turmaAgenda[0].turmaAgendaHorario);
  		});
  	}
  }

  setEncontros(turma_agenda_horario) {
  	this.encontrosArray = [];
  	this.encontrosCalendar = [];
  	turma_agenda_horario.forEach((horario) => {
  		if (horario.encontro.length > 0) {
  			this.encontrosCalendar.push(horario.data_agenda.substr(0, 10));
  			this.encontrosArray.push(horario);
  		}
  	});
  }

  closeAgenda() {
  	this.transition = 'closed';
  	document.querySelector('body').removeAttribute('class');
  	this.store.dispatch(showAgenda({ payload: 'closed' }));
  }

  underConstruction() {
  	this.global.toast('Aguarde!', 'Esta tela está em construção!');
  }

  handleClickOutside(data) {
  	setTimeout(() => {
  		if (
  			!(
  				data.target.className.indexOf(
  					'pointer menu-label agenda-animation'
  				) !== -1
  			)
  		)
  			this.closeAgenda();
  	}, 100);
  }

  clickedDay(event) {
  	this.currentDay = event.day;
  	const date = new Date(event.year, event.month - 1, event.day).toISOString();
  	this.setCurrentActivities(date);
  }

  getCalendario(date) {
  	const month = this.global.getMonth(date);
  	const year = this.global.getYear(date);
  	const idUsuario = this.state.usuario.id_usuario;
  	const idProjetoTurma = this.state.projetoAtivo?.id_projeto_turma;
  	this.dataService
  		.getCalendario(month, year, idUsuario, idProjetoTurma)
  		.subscribe((data) => {
  			this.encontrosCalendar = data.full_schedules.map((x: string) =>
  				moment(x).format('YYYY-MM-DD')
  			);
  			this.rangesArray = data.full_ranges.map((x: string[]) =>
  				x.map((y) => moment(y).format('YYYY-MM-DD'))
  			);
  			this.activities = data.activities.map((x: any) => {
  				const formatedDate = moment(x.full_date).format();
  				x.data = moment(x.full_date).format('YYYY-MM-DD');
  				x.hora = formatedDate.split('T')[1].slice(0, 5);

  				return x;
  			});
  			this.setCurrentActivities(date);
  		});
  }

  setCurrentActivities(date) {
  	this.currentActivities = this.activities.filter(
  		(activity) => activity.data === date.split('T')[0]
  	);
  }

  clickActivity(event) {
  	switch (event.tipo) {
  	case 'fase':
  	case 'entrega':
  		this.goToFase(event);
  		break;
  	case 'evento':
  		this.showEvent(event);
  		break;
  	}
  }

  showEvent(event: any) {
  	const data = this.eventos.filter(
  		(x: any) => x.id_evento === event.id_evento
  	);
  	this.dataEvent = data[0];
  	this.openModal = true;
  }

  goToFase(event: any) {
  	const idProjeto = this.route.snapshot.params?.id;
  	if (!idProjeto) {
  		const projeto = this.state.projetosAluno.find(
  			(item) => item.id_projeto_turma === event.id_projeto_turma
  		);
  		this.dataService.setProjectInitialData(projeto);
  		this.dataService
  			.getFases()
  			.pipe(
  				tap((fases: any) => {
  					if (fases.projeto) {
  						this.store.dispatch(
  							fillFases({
  								payload: this.orderPipe.transform(
  									fases.projeto.faseTurma,
  									'id_fase_turma'
  								),
  							})
  						);
  						this.fillFase(event);
  						this.dataService.getAndSetEntregasAluno();
  						this.closeAgenda();
  						this.closeMenu.emit();
  						this.router.navigate([
  							'projeto/' +
                  event.id_projeto_turma +
                  '/fase/' +
                  event.id_fase_turma,
  						]);
  					} else
  						this.global.toast(
  							'Aviso!',
  							'Não existem fases cadastradas para este projeto!'
  						);
  				})
  			)
  			.subscribe();
  	} else {
  		this.fillFase(event);
  		this.router.navigate([
  			'projeto/' + event.id_projeto_turma + '/fase/' + event.id_fase_turma,
  		]);
  		this.closeAgenda();
  		this.closeMenu.emit();
  	}
  }

  setNoClickOnEmptyCells() {
  	const tbody = document.getElementsByTagName('tbody');
  	for (const element of tbody) {
  		const td = element.getElementsByTagName('td');
  		for (const item of td) {
  			if (!/\S/.test(item.innerText)) {
  				item.classList.add('no-click');
  			} else {
  				if (item.classList.contains('no-click'))
  					item.classList.remove('no-click');
  			}
  		}
  	}
  }

  fillFase(selectedFase) {
  	const fase = this.state.fases.find(
  		(fase) => fase.id_fase_turma == selectedFase.id_fase_turma
  	);
  	this.store.dispatch(
  		showEntregaArquivo({ payload: fase.entrega_final ? true : false })
  	);
  	this.store.dispatch(fillAtividadeAtual({ payload: null }));
  	this.setFirstAtividade(fase);
  	this.global.clearInputFase.next();
  	this.store.dispatch(fillObjetivoAprendizagemAtivo({ payload: null }));
  	this.store.dispatch(fillFaseAtual({ payload: fase }));
  }

  setFirstAtividade(fase) {
  	if (fase?.objetivosAprendizagem.length > 0) {
  		const objAprendizado = fase?.objetivosAprendizagem?.filter(
  			(objApred) => objApred?.atividadeRoteiroTurma?.length > 0
  		);
  		if (objAprendizado.length > 0) {
  			this.store.dispatch(
  				fillAtividadeAtual({
  					payload: objAprendizado[0]?.atividadeRoteiroTurma[0],
  				})
  			);
  		}
  	}
  }
  closeModalEvent() {
  	this.openModal = false;
  }
}
