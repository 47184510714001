import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { AppState } from 'src/app/state';
import { Store } from '@ngrx/store';
import { DataService } from 'src/app/services/data-service/data.service';
import { fillMensagens, interacoesLoading, fillFaleComTurma, fillImageViewerUrl, fillDocumentViewerUrl } from 'src/app/state/state.actions';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { TranslateService } from '@ngx-translate/core';
import { DataUtil } from 'src/app/util/dataUtil';

@Component({
  selector: 'cls-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

  @Input() messages: Array<any> = [];
  @Input() label: string;
  @Input() subtitle: string;
  @Input() description: string;
  @Input() isTurma = false;
  @Input() id_forum: any;
  @Input() status_aluno: any;
  @Input() projeto_vigente: any;

  @ViewChild('attachFileMsg') attachFileMsg: ElementRef;

  stateSubscription: any;
  state: any;
  newMessage: string;
  hideMoreMessages = false;
  file: File;
  fileName: string;
  uploadForm: FormGroup;
  currentIDForum: any;
  imageTypes = ['image/png', 'image/jpeg', 'imagem'];
  imageExt = ['.jpeg', '.jpg', '.gif', '.png', '.apng', '.svg', '.bmp'];

  dataUtil: DataUtil = new DataUtil()

  constructor(
    private store: Store<AppState>,
    private dataService: DataService,
    private global: GlobalService,
    private formBuilder: FormBuilder,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.getState();
    this.uploadForm = this.formBuilder.group({ arquivo: null });
    this.automaticCallUpdateMessages();
  }

  verifyExt(message): boolean {
    if (this.imageTypes.includes(message.media_type) || this.imageExt.some((extension) => {

      if (!message?.media_name) return false

      return message?.media_name?.indexOf(extension) !== -1
    }))
      return true
    return false
  }

  viewFile(message) {
    this.store.dispatch(fillDocumentViewerUrl({ payload: message.media_url }))
  }

  viewImage(message) {
    this.store.dispatch(fillImageViewerUrl({ payload: message.media_url }))
  }

  downloadFile(message: any): void {
    window.open(message.media_url);
  }

  ngOnDestroy() {
    this.id_forum = null;
    this.stateSubscription.unsubscribe();
  }

  getState() {
    this.stateSubscription = this.store.select((state: any) => {
      const { interacoesLoading, usuario, delayAnswerOpen, estudanteStatus } = state.newAppState;
      this.state = { interacoesLoading, usuario, delayAnswerOpen, estudanteStatus }
    }).subscribe();
  }

  automaticCallUpdateMessages() {
    let element = document.querySelector(".interacoes-container");

    this.scrollToBottomViaID('scroll-interacoes');

    setInterval(() => {
      if ((element.scrollHeight - Math.floor(element.scrollTop) == element.clientHeight || element.scrollHeight - Math.ceil(element.scrollTop) == element.clientHeight) && this.id_forum) {
        this.dataService.getAndDispatchMessages(this.id_forum).toPromise().then((data: any) => {
          data?.mensagens.forEach((msg) => {
            msg.mensagem = this.dataUtil.urlToAnchors(msg.mensagem)
          })
          if (this.isTurma)
            this.store.dispatch(fillFaleComTurma({ payload: data.mensagens }));
          else
            this.store.dispatch(fillMensagens({ payload: data.mensagens }));
        }, error => {
          this.global.toast(this.translate.instant("GENERAL.ERROR"), error.error.message);
        })
      }
    }, 10000);
  }

  scrollToBottomViaID(id) {
    setTimeout(() => {
      var elmnt = document.getElementById(id);
      if (elmnt) {
        elmnt.scrollBy({
          top: elmnt.scrollHeight,
          behavior: 'smooth'
        });
      }
    }, 1000);
  }

  tryGetMoreMessages() {
    this.dataService.getMoreMessages(this.id_forum, this.messages[this.messages.length - 1].id_forum_mensagem).toPromise().then((data: any) => {
      let updatedMsgs = this.messages;
      data.mensagens.forEach(newMsg => {
        updatedMsgs.push(newMsg);
      });
      if (data.mensagens.length == 0) {
        this.global.toast("Atenção!", "Não existem mais mensagens neste fórum!");
        this.hideMoreMessages = true;
      }
      if (this.isTurma)
        this.store.dispatch(fillFaleComTurma({ payload: updatedMsgs }));
      else
        this.store.dispatch(fillMensagens({ payload: updatedMsgs }))
    }, error => {
      this.global.toast(this.translate.instant("GENERAL.ERROR"), error.error.message);
      this.store.dispatch(interacoesLoading({ payload: false }));
    })
  }

  attachFileToMsg() {
   if (!this.projeto_vigente.vigente) {
    return this.global.toast(this.translate.instant("GENERAL.NOTICE"), `${this.translate.instant("GENERAL.END_OF_PROJECT")} ${this.projeto_vigente.dataFim.toLocaleDateString()}.`)
    }
    let input: HTMLElement;
    input = document.querySelector('.file-msg');
    input.click();
  }

  sendFileToDOM(event) {
    this.fileName = event.srcElement.files[0].name;
    this.file = event.target.files[0];
  }

  validateNewMessage() {
    let ret = true;
    if (this.state.estudanteStatus === 'trial') {
      this.global.toast("Aviso!", "Este recurso está disponível apenas para a versão completa");
      ret = false;
    }
    else if (!this.projeto_vigente.vigente) {
      this.global.toast(this.translate.instant("GENERAL.NOTICE"), `${this.translate.instant("GENERAL.END_OF_PROJECT")} ${this.projeto_vigente.dataFim.toLocaleDateString()}.`)
      ret = false;
    }
    else if (!this.newMessage || this.newMessage.length == 0) {
      this.global.toast(this.translate.instant("GENERAL.ERROR"), this.translate.instant("GENERAL.TYPE_MESSAGE"));
      ret = false;
    }
    return ret;
  }

  sendMessage() {
    let msg: any;
    if (this.file && !this.newMessage)
      this.newMessage = ' ';
    if (!this.validateNewMessage()) return;
    this.store.dispatch(interacoesLoading({ payload: true }));


    if (!this.file) {
      msg = {
        id_forum: this.id_forum,
        id_usuario: this.state.usuario.id_usuario,
        mensagem: this.newMessage,
        media_type: "text"
      }
    } else {
      msg = new FormData();
      this.uploadForm.get('arquivo').setValue(this.file);
      if (this.uploadForm.get('arquivo').value)
        msg.append('arquivo', this.uploadForm.get('arquivo').value, this.fileName);
      msg.append('id_forum', this.id_forum);
      msg.append('id_usuario', this.state.usuario.id_usuario);
      msg.append('mensagem', this.newMessage);
      msg.append('media_type', this.file.type);
    }
    this.dataService.sendMessageToForum(msg).toPromise().then((data: any) => {
      this.updateStateMessages(data);
      this.afterSendMsg();
      this.store.dispatch(interacoesLoading({ payload: false }));
    }, error => {
      this.global.toast(this.translate.instant("GENERAL.ERROR"), error.error.message);
      this.store.dispatch(interacoesLoading({ payload: false }));
    })
  }

  updateStateMessages(data) {
    let updatedMsgs = this.messages;
    data.mensagem.usuario = this.state.usuario;
    data.mensagem.usuario.tipo = 'aluno';
    updatedMsgs.push(data.mensagem);
    this.global.toast(this.translate.instant("GENERAL.SUCCESS"), this.translate.instant("CHAT.SENDED"));
    if (!this.isTurma)
      this.store.dispatch(fillMensagens({ payload: updatedMsgs }));
    else
      this.store.dispatch(fillFaleComTurma({ payload: updatedMsgs }));
  }

  afterSendMsg() {
    this.newMessage = "";
    this.file = null;
    this.fileName = null;
    this.attachFileMsg.nativeElement.value = "";
    this.global.scrollToBottomViaID('scroll-interacoes');
  }
}
