<div class="wrapper">
  <router-outlet></router-outlet>
  <cls-footer *ngIf="state?.usuario"></cls-footer>
</div>
<cls-toast *ngIf="state?.toast" [data]="state?.toast"></cls-toast>
<div *ngIf="this.state.documentViewerUrl">
  <app-document-viewer></app-document-viewer>
</div>
<div *ngIf="this.state.imageViewerUrl">
  <app-image-viewer></app-image-viewer>
</div>
