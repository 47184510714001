<div class="avaliacao-card position-relative">
  <div class="quiz-icon position-absolute">
    <!-- <i class="far far-2x fa-check-square"></i> -->
    <img src="assets/icons/fa-check-square.svg" alt="" />
  </div>
  <div
    class="avaliacao-header d-flex flex-row justify-content-between align-items-baseline"
  >
    <p class="avaliacao-heading">{{ titulo }}</p>
    <span class="avaliacao-grade" *ngIf="nota"
      >{{ "AVALIACAO.NOTE" | translate }} {{ nota | number : "1.0-2" }}
      {{ "GABARITO.HITS_OF" | translate
      }}{{ notaMaxima | number : "1.0-2" }}</span
    >
  </div>
  <div class="d-flex-flex-column justify-content-start">
    <p class="info-text">
      {{ "AVALIACAO.DELIVERY_DATE" | translate }}:
      <span class="avaliacao-value">
        {{
          dataEntregaInicio
            ? (dataEntregaInicio | date : "dd/MM/yyyy HH:mm")
            : "--/--/----"
        }}
        até
        {{
          dataEntregaFim
            ? (dataEntregaFim | date : "dd/MM/yyyy HH:mm")
            : "--/--/----"
        }}
      </span>
    </p>
    <p class="info-text">
      {{ "AVALIACAO.TYPE" | translate }}:
      <span class="avaliacao-value">
        {{ tipoAvaliacao }}
      </span>
    </p>
    <p class="info-text">
      {{ "AVALIACAO.DESCRIPTION" | translate }}:
      <span class="avaliacao-value">
        {{ descricaoAvaliacao }}
      </span>
    </p>
    <p class="info-text">
      {{ "AVALIACAO.ATTEMPTS" | translate }}:
      <span class="avaliacao-value">
        {{ numeroTentativas }}
      </span>
    </p>
    <p class="info-text">
      {{ "AVALIACAO.ATTEMPTS_USED" | translate }}:
      <span class="avaliacao-value">
        {{ tentativasUtilizadas }}
      </span>
    </p>
    <p class="info-text" *ngIf="dataRevisaoInicio && dataRevisaoFinal">
      {{ "AVALIACAO.REVIEW" | translate }}:
      <span class="avaliacao-value">
        {{ dataRevisaoInicio | date : "dd/MM/yyyy HH:mm" }} até
        {{ dataRevisaoFinal | date : "dd/MM/yyyy HH:mm" }}
      </span>
    </p>
  </div>
  <ng-content></ng-content>
</div>
