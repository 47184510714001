<div class="acordion-revisao-container" [class.open]="isOpen">
  <div class="header">
    <span>Questão {{ index }}</span>
    <div class="chevron-container">
      <div class="chevron" (click)="isOpen = !isOpen">
        <!-- <i class="fas fa-chevron-down"></i> -->
        <img src="assets\icons\fas-fa-chevron-down-white.svg" />
      </div>
    </div>
  </div>
  <div class="hint">
    Arquivo avaliado pelo professor. O arquivo disponibilizado para download
    serve apenas para simples conferência do aluno.
  </div>
  <div class="label">Resposta</div>
  <div class="answer" [innerHtml]="resposta.response | sanitizeHtml"></div>
</div>
