import { Component, OnInit, Input } from '@angular/core';
import { GlobalService } from 'src/app/services/global-service/global.service';

@Component({
  selector: 'cls-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {

  @Input() quizArray: any;
  selectedRespostas = [];

  constructor(public global: GlobalService) { }

  ngOnInit() { }

  ngOnChanges(){
    this.sortQuizArrayRandomly();
  }

  sortQuizArrayRandomly() {
    this.quizArray.forEach((quiz, index) => {
      let tempRespostas = [];
      tempRespostas.push({ text: quiz.resposta_correta, class: "" })
      tempRespostas.push({ text: quiz.resposta_incorreta1, class: "" })
      tempRespostas.push({ text: quiz.resposta_incorreta2, class: "" })
      tempRespostas.push({ text: quiz.resposta_incorreta3, class: "" })
      tempRespostas.push({ text: quiz.resposta_incorreta4, class: "" })
      this.selectedRespostas[index] = { id_resposta: null, id_quiz: null, resposta: null };
      quiz.sortedRespostas = this.global.shuffle(tempRespostas);
    });
  }

  isSelected(id_resposta, id_quiz) {
    if (id_resposta == this.selectedRespostas[id_quiz].id_resposta && id_quiz == this.selectedRespostas[id_quiz].id_quiz)
      return true;
    else
      return false;
  }

  sendAnswer(id_quiz) {
    if (id_quiz == this.selectedRespostas[id_quiz].id_quiz) {
      if (this.quizArray[id_quiz].resposta_correta == this.selectedRespostas[id_quiz].resposta) {
        this.clearQuiz();
        this.quizArray[id_quiz].sortedRespostas[this.selectedRespostas[id_quiz].id_resposta].correta = true;
        this.quizArray[id_quiz].show_feedback_erro = false;
        this.quizArray[id_quiz].show_feedback_acerto = true;
      }
      else {
        this.clearRightAnswers();
        this.quizArray[id_quiz].sortedRespostas[this.selectedRespostas[id_quiz].id_resposta].incorreta = true;
        this.quizArray[id_quiz].show_feedback_acerto = false;
        this.quizArray[id_quiz].show_feedback_erro = true;
      }
    }
  }

  clearRightAnswers(){
    this.quizArray.forEach(quiz => {
      quiz.sortedRespostas.forEach(resposta => {
        delete resposta.correta;
      });
    });
  }

  clearQuiz() {
    this.quizArray.forEach(quiz => {
      delete quiz.show_feedback_acerto;
      delete quiz.show_feedback_erro;
      quiz.sortedRespostas.forEach(resposta => {
        delete resposta.incorreta;
        delete resposta.correta;
      });
    });
  }

  selectResposta(id_resposta, id_quiz, resposta) {
    this.selectedRespostas[id_quiz].id_resposta = id_resposta;
    this.selectedRespostas[id_quiz].resposta = resposta;
    this.selectedRespostas[id_quiz].id_quiz = id_quiz;
  }

}
