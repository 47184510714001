import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { AppState } from 'src/app/state';
import { fillLastRoute } from 'src/app/state/state.actions';
import { FilterModel } from './carrossel-filters/models/filter.model';
import { DataService } from 'src/app/services/data-service/data.service';
import { tap } from 'rxjs/operators';

@Component({
	selector: 'app-carrossel-details',
	templateUrl: './carrossel-details.component.html',
	styleUrls: ['./carrossel-details.component.scss'],
})
export class CarrosselDetailsComponent implements OnInit, OnDestroy {
	state: AppState = {} as AppState;
	objectType = {
		title: '',
		model: {},
		type: 0,
	};
	type = '';
	title = '';
	backgroundImg = '';
	subscriptions: Subscription[] = [];
	contentsBox: any[] = [];
	projetosBox: any[] = [];
	eventosBox: any[] = [];
	allProjects: any[] = [];
	allEvents: any = { eventosUser: [], areaTipo: [] };

	constructor(
    private store: Store<AppState>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private global: GlobalService,
    private dataService: DataService
	) {
		this.setCurrentRoute();
	}

	ngOnInit(): void {
		this.getState();
		this.setBackground();
		if (this.type === 'projetos') this.getALlProjects();
		if (this.type === 'eventos') this.getMoreEvents();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}

	getState() {
		const subscription = this.store
			.select((state: any) => {
				const { dadosEmpresa, dadosPolo, usuario, conteudos, eventos } =
          state.newAppState;
				this.state = {
					dadosEmpresa,
					dadosPolo,
					usuario,
					conteudos,
					eventos,
				} as AppState;
			})
			.subscribe();
		this.applyDefaultObjects();
		this.fillObjectType();
		this.subscriptions.push(subscription);
	}

	getALlProjects() {
		const subscription = this.dataService
			.getSectionDetails('projetos')
			.pipe(
				tap((response) => {
					this.allProjects = response || [];
					this.projetosBox = [...(this.allProjects || [])];
					this.fillObjectType();
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	getMoreEvents() {
		const subscription = this.dataService
			.getSectionDetails('eventos')
			.pipe(
				tap((response) => {
					this.allEvents = response || { eventosUser: [], areaTipo: [] };
					this.eventosBox = [...this.allEvents.eventosUser];
					this.fillObjectType();
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	applyDefaultObjects() {
		this.contentsBox = [...(this.state.conteudos || [])];
		this.projetosBox = [...(this.allProjects || [])];
		this.eventosBox = [...this.allEvents.eventosUser];
	}

	setCurrentRoute() {
		const snap: any = this.activatedRoute.snapshot;
		const route = snap._routerState.url;
		this.type = snap.params.type;
		sessionStorage.setItem('url', route);
		this.store.dispatch(fillLastRoute({ payload: route }));
	}

	setBackground() {
		this.backgroundImg = this.global.generateImageURL(
			'../../assets/imgs/background-perfil.jpg'
		);
	}

	fillObjectType() {
		switch (this.type) {
		case 'projetos':
			this.objectType = {
				title: 'MY_PROJECTS',
				model: this.getProjectsModel(),
				type: 1,
			};
			break;
		case 'eventos':
			this.objectType = {
				title: 'MY_EVENTS',
				model: this.getEventsModel(),
				type: 2,
			};
			break;
		case 'conteudos':
			this.objectType = {
				title: 'MY_CONTENTS',
				model: this.getContentsModel(),
				type: 3,
			};
			break;
		default:
			break;
		}
	}

	getProjectsModel(): FilterModel[] {
		return [
			{
				title: 'EVENT.NIVEL_ENSINO',
				fieldValue: 'id_nivel_ensino',
				fieldText: 'nivel_ensino',
				type: 'buttons',
				multiple: true,
				options: this.allProjects
					.map((projeto) => projeto.id_nivel_ensino)
					.filter(
						(idNivelEnsino, index, self) =>
							index === self.findIndex((t) => t === idNivelEnsino)
					)
					.map((idNivelEnsino) => {
						const projeto = this.projetosBox.find(
							(proj) => proj.id_nivel_ensino === idNivelEnsino
						);
						return {
							id_nivel_ensino: idNivelEnsino,
							nivel_ensino: projeto.nivel_ensino,
						};
					}),
			},
			{
				title: 'EVENT.CAPACITY_FILTER',
				fieldValue: 'id_competencia',
				fieldText: 'nome',
				type: 'tag',
				multiple: true,
				filterValue: '',
				options: this.allProjects
					.map((projeto) => projeto.projetoTurmaCompetencia[0])
					.filter(
						(competencia, index, self) =>
							index ===
              self.findIndex(
              	(t) => t.id_competencia === competencia.id_competencia
              )
					)
					.map((competencia) => ({
						id_competencia: competencia.id_competencia,
						nome: competencia.nome,
						completed: false,
					})),
			},
			{
				title: 'EVENT.WORKLOAD',
				fieldValue: 'duracao',
				fieldText: null,
				type: 'range',
				multiple: false,
				options: [
					{
						nome: 'EVENT.CURRENT_MONTH',
						duracao: 90,
					},
				],
			},
			{
				title: 'EVENT.TEACHERS',
				fieldValue: 'nome_tutor',
				fieldText: 'nome_tutor',
				type: 'image',
				multiple: true,
				filterValue: '',
				options: this.allProjects
					.map((projeto) => projeto.projetoTurmaTutor[0])
					.filter((tutor) => tutor.nome_social || tutor.foto)
					.filter(
						(tutor, index, self) =>
							index ===
              self.findIndex((t) => t.nome_social === tutor.nome_social)
					)
					.map((tutor) => ({
						nome_tutor: tutor.nome_social,
						foto: tutor.foto,
					})),
			},
		];
	}
	getEventsModel(): FilterModel[] {
		return [
			{
				title: 'EVENT.SCHOOL_FILTER',
				fieldValue: 'area_tipo',
				fieldText: 'nome',
				type: 'buttons',
				multiple: true,
				options: this.state.eventos.areaTipo
					.filter((area) =>
						this.state.eventos.eventosUser
							.map((events) => events.area_tipo)
							.includes(area.id_evento_area_tipo)
					)
					.map((area) => ({ ...area, area_tipo: area.id_evento_area_tipo })),
			},
			{
				title: 'EVENT.DATE_FILTER',
				fieldValue: 'date_range',
				fieldText: null,
				type: 'date-range',
				multiple: true,
				options: [
					{
						field: 'data_inicio',
						placeholder: 'EVENT.DATE_FILTER_START_PLACEHOLDER',
						date_range: moment().startOf('month').format(),
					},
					{
						field: 'data_fim',
						placeholder: 'EVENT.DATE_FILTER_END_PLACEHOLDER',
						date_range: moment().endOf('month').format(),
					},
				],
			},
			{
				title: 'EVENT.MONTH_FILTER',
				fieldValue: 'data_fim',
				fieldText: 'nome',
				type: 'date-buttons',
				multiple: false,
				options: [
					{
						nome: 'EVENT.CURRENT_MONTH',
						data_inicio: moment().startOf('month').format(),
						data_fim: moment().endOf('month').format(),
					},
					{
						nome: 'EVENT.LAST_MONTH',
						data_inicio: moment()
							.startOf('month')
							.subtract(1, 'month')
							.format(),
						data_fim: moment().endOf('month').subtract(1, 'month').format(),
					},
					{
						nome: 'EVENT.LAST_3_MONTHS',
						data_inicio: moment().startOf('month').format(),
						data_fim: moment().endOf('month').add(2, 'months').format(),
					},
					{
						nome: 'EVENT.LAST_6_MONTHS',
						data_inicio: moment().startOf('month').format(),
						data_fim: moment().endOf('month').add(5, 'months').format(),
					},
					{
						nome: 'EVENT.LAST_12_MONTHS',
						data_inicio: moment().startOf('month').format(),
						data_fim: moment().startOf('month').add(1, 'y').format(),
					},
					{
						nome: 'EVENT.ALL_MONTHS',
						data_inicio: moment().startOf('month').format(),
						data_fim: moment().startOf('month').add(10, 'y').format(),
					},
				],
			},
			{
				title: 'EVENT.SUBSCRIBE_FILTER',
				fieldValue: 'agenda',
				fieldText: 'nome',
				hideSearchBox: true,
				type: 'tag',
				multiple: false,
				filterValue: '',
				options: [
					{
						agenda: true,
						nome: 'EVENT.EVENTS_SUBSCRIBED',
						completed: false,
					},
					{
						agenda: 0,
						nome: 'EVENT.ALL_EVENTS',
						completed: false,
					},
				],
			},
			{
				title: 'EVENT.TYPE_FILTER',
				fieldValue: 'canal_tipo',
				fieldText: 'nome',
				hideSearchBox: true,
				type: 'tag',
				multiple: false,
				filterValue: '',
				options: [
					{
						canal_tipo: 1,
						nome: 'EVENT.IN_PERSON',
						completed: false,
					},
					{
						canal_tipo: 0,
						nome: 'EVENT.ONLINE',
						completed: false,
					},
				],
			},
		];
	}
	getContentsModel(): FilterModel[] {
		return [];
	}

	applyFilters(event) {
		console.log('event', event);
		this.applyDefaultObjects();
		let objectType = '';
		let startDateValid = false;
		let endDateValid = false;
		switch (this.type) {
		case 'eventos':
			objectType = 'eventosBox';
			break;
		case 'conteudos':
			objectType = 'contentsBox';
			break;
		case 'projetos':
			objectType = 'projetosBox';
			break;
		default:
			break;
		}

		if (!objectType) return;
		if (event.length === 0) {
			return;
		}

		event.forEach((filter) => {
			if (filter.values.length === 0) return;
			this[objectType] = this[objectType].filter((object) => {
				switch (true) {
				case filter.type === 'buttons':
					return filter.values.includes(object[filter.field]);
				case filter.type === 'date-buttons':
				case filter.type === 'date-range':
					startDateValid = moment(object[filter.field1]).isBetween(
						moment(filter.values[0]),
						moment(filter.values[1])
					);
					endDateValid = moment(object[filter.field2]).isBetween(
						moment(filter.values[0]),
						moment(filter.values[1])
					);
					if (!startDateValid)
						startDateValid = moment(filter.values[0]).isBetween(
							moment(object[filter.field1]),
							moment(object[filter.field2])
						);
					if (!endDateValid)
						endDateValid = moment(filter.values[1]).isBetween(
							moment(object[filter.field1]),
							moment(object[filter.field2])
						);

					return startDateValid || endDateValid;
				case filter.type === 'range':
					return object[filter.field] < filter.values[0];
				case filter.type === 'tag': {
					if (filter.values[0] == 0 && filter.field == 'canal_tipo')
						return object[filter.field] != 1; //devolve todos que não são 1
					if (filter.values[0] == 0) return true; // devolve todos
					return filter.values.includes(object[filter.field]); // devolve a igualdade
				}
				case filter.type === 'image':
					return filter.values.includes(object[filter.field]);
				default:
					return true;
				}
			});
		});
	}

	backToHome() {
		this.store.dispatch(fillLastRoute({ payload: '/perfil' }));
		this.router.navigate(['/perfil']);
	}
}
