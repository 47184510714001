import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';

@Component({
	selector: 'ui-sidebar-content',
	templateUrl: './sidebar-content.component.html',
	styleUrls: ['./sidebar-content.component.scss'],
	animations: [
		trigger('Sidebar-content', [
			state(
				'open',
				style({
					right: '0px',
				})
			),
			state(
				'closed',
				style({
					right: '-613px',
				})
			),
			transition('open => closed', [animate('.5s 0ms ease-in-out')]),
			transition('closed => open', [animate('.5s 0ms ease-in-out')]),
		]),
	],
})
export class SidebarContentComponent implements OnChanges {
  @Input() titulo: string;
  @Input() showSidebar: boolean;
  @Input() notificacoesNaoLidas = false;
  @Output() closeSidebar: EventEmitter<any> = new EventEmitter<any>();
  @Output() marcarTodasNotificacoes: EventEmitter<any> =
  	new EventEmitter<any>();
  transition = 'closed';

  ngOnChanges(changes: SimpleChanges) {
  	if (changes && changes.showSidebar && changes.showSidebar.currentValue)
  		this.transition = 'open';
  }

  closeSidebarContent() {
  	this.closeSidebar.emit();
  	this.transition = 'closed';
  }

  markAllNotifications() {
  	this.marcarTodasNotificacoes.emit();
  	this.notificacoesNaoLidas = false;
  }
}
