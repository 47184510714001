<div
  class="scrollable-chat-container"
  [class.solved]="messages?.suporte_status_id_suporte_status == 5"
  id="scroll-chat"
>
  <div class="shadow-top"></div>
  <div
    class="msg"
    *ngFor="let msg of messages?.detalhes"
    [class.prof]="msg?.usuario_papel == 'professor'"
    [class.aluno]="msg?.usuario_papel == 'aluno'"
  >
    <img *ngIf="msg?.usuario_foto" [src]="msg?.usuario_foto" />
    <img
      *ngIf="!msg?.usuario_foto"
      src="../../../../../assets/imgs/profile-img.svg"
    />
    <div class="tutor upper">{{ "SCROLLABLE_CHAT.TEACHER" | translate }}</div>
    <div class="name">{{ msg?.usuario_nome_social }}</div>
    <div class="message" *ngIf="msg?.mensagem">{{ msg?.mensagem }}</div>
    <a
      *ngIf="msg?.media_name && msg?.media_name?.length > 50"
      [href]="msg?.media_url"
      >{{ msg?.media_name | slice : 0 : 50 }}...</a
    >
    <a
      *ngIf="msg?.media_name && msg?.media_name?.length <= 50"
      [href]="msg?.media_url"
      >{{ msg?.media_name }}</a
    >
    <div class="date">
      {{ msg?.data_postagem | date : "dd/MM/yyyy - HH:mm" }}
    </div>
  </div>
  <div
    class="shadow-bottom"
    [class.mb-0]="messages?.suporte_status_id_suporte_status == 5"
  ></div>
</div>
<div class="answer" *ngIf="messages?.suporte_status_id_suporte_status != 5">
  <img
    *ngIf="!file"
    src="assets/icons/paperclip.svg"
    (click)="clickInputUpload()"
  />
  <img
    class="uploaded"
    *ngIf="file"
    src="assets/icons/uploaded.png"
    (click)="clearInput(); $event.stopPropagation()"
  />
  <input
    type="file"
    #attach
    class="d-none ipt-file"
    (change)="attachFile($event)"
  />
  <input
    type="text"
    class="upper"
    placeholder="{{ 'INTERACTIONS.NEW_SERVICE_DO_QUESTION_TEXT' | translate }}"
    [(ngModel)]="newMessage.mensagem"
  />
  <img class="send" (click)="sendReply()" src="assets/icons/send-msg.png" />
</div>
