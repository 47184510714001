<div class="avaliacao-container">
    <div class="main" *ngIf="this.errorModal">
        <div class="main-card">
            <img src='assets/imgs/popup7.jpg'>

            <div class="title mt-2">{{ 'QUESTIONARIO_AVALIACAO.INVALID' | translate }}</div>

            <p class="capitalize">{{ 'QUESTIONARIO_AVALIACAO.INVALID1' | translate }}</p>
            <p class="capitalize">{{ 'QUESTIONARIO_AVALIACAO.INVALID2' | translate }}</p>
            

            <div class="btns">
                <button class="btn red mr-2 upper" (click)="exit()">{{ 'QUESTIONARIO_AVALIACAO.EXIT' | translate }}</button>
            </div>
        </div>
        <div class="detail-1"></div>
        <div class="detail-2"></div>
    </div>
    <div class="main" *ngIf="!this.errorModal && !this.state.avaliacaoState">
        <div class="main-card">
            <!-- <img src="assets/imgs/popup-avaliacao.jpg"> -->
            <div class="title mt-2">{{ 'QUESTIONARIO_AVALIACAO.TITLE' | translate }}</div>
            <p class="capitalize">{{ 'QUESTIONARIO_AVALIACAO.DESCRIPTION1' | translate }}</p>
            <p class="capitalize">{{ 'QUESTIONARIO_AVALIACAO.DESCRIPTION2' | translate }}</p>
            <p class="capitalize">{{ 'QUESTIONARIO_AVALIACAO.DESCRIPTION3' | translate }}</p>
            <p class="capitalize"><b>{{ 'QUESTIONARIO_AVALIACAO.DESCRIPTION4' | translate }}</b></p>
            <!-- <p>3. Depois de concluir você terá a chance de realizar o quiz uma segunda vez. A que tiver o melhor
                resultado será automaticamente considerado como o resultado vigente.</p> -->
            <p *ngIf="isTimed" class="capitalize">
                {{ 'QUESTIONARIO_AVALIACAO.DESCRIPTION5' | translate }} {{ entregaTurma?.duracao_tentativa }} {{ 'QUESTIONARIO_AVALIACAO.DESCRIPTION6' | translate }}
            </p>
            <div class="btns">
                <button class="btn red mr-2 upper" (click)="exit()">{{ 'QUESTIONARIO_AVALIACAO.NEED_TIME' | translate }}</button>
                <button *ngIf="this.state.selectedQuizAvaliacao.questoes_tentativa" class="btn ml-2 upper" (click)="begin()">{{ 'QUESTIONARIO_AVALIACAO.START' | translate }}</button>
            </div>
        </div>
        <div class="detail-1"></div>
        <div class="detail-2"></div>
    </div>
    <div class="main" *ngIf="!this.errorModal && this.state.avaliacaoState === 'question'">
        <div class="main-card">
            <div class="counter mr-2">{{this.currentQuestion + 1}}/{{this.questoes.length}}</div>
            <div class="mt-2 mb-2 d-flex justify-content-between flex-row">
                <div class="bar mr-2" *ngFor="let item of this.questoes; let i = index"
                    [class.selected-bar]="i == this.currentQuestion">
                </div>
            </div>
            <!-- TIMER -->
            <div *ngIf="isTimed" class="d-flex justify-content-end">
                <i class="far fa-clock d-flex align-self-center mr-2"></i>
                <span class="mr-1">
                    <span>{{ minutes | number:'2.0-0'}}</span>:<span>{{ seconds | number:'2.0-0'}}</span>
                </span>
                <span class="mr-1">
                    de
                </span>
                <span class="mr-2">
                    {{this.entregaTurma?.duracao_tentativa | number: '2.0-0'}}:00
                </span>
            </div>
            <div class="title mt-2 capitalize">{{ 'QUESTIONARIO_AVALIACAO.QUESTION' | translate }} {{this.currentQuestion + 1}}</div>
            <div [innerHTML]="this.questoes[this.currentQuestion]?.pergunta" class="question"></div>
            <div class="answer mb-3 pt-2 pb-2" *ngFor="let option of this.questoes[this.currentQuestion]?.options"
                (click)="markAnswer(option)">
                <div class="check" [class.checked]="this.questoes[this.currentQuestion].option == option">
                    <i class="fas fa-check"></i>
                </div>
                {{option}}

            </div>
            <div class="btns">
                <button class="btn mr-2 upper" [class.hide]="this.currentQuestion == 0" (click)="previous()">{{ 'QUESTIONARIO_AVALIACAO.QUESTION_PREVIOUS' | translate }}</button>
                <button class="btn upper" *ngIf="this.currentQuestion + 1 <this.questoes.length"
                    (click)="next(this.questoes[this.currentQuestion])">{{ 'QUESTIONARIO_AVALIACAO.QUESTION_NEXT' | translate }}
                </button>
                <button class="btn upper" *ngIf="this.currentQuestion + 1 === this.questoes.length" (click)="final()">
                    <span *ngIf="!this.isSavingQuiz" class="upper">{{ 'QUESTIONARIO_AVALIACAO.FINISH' | translate }}</span>
                    <div *ngIf="this.isSavingQuiz" class="text-center">
                        <div class="spinner-border spinner-border-md" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </button>
            </div>
        </div>
        <div class="detail-1"></div>
        <div class="detail-2"></div>
    </div>
</div>
