import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'cls-card-avaliacao',
  templateUrl: './card-avaliacao.component.html',
  styleUrls: ['./card-avaliacao.component.scss']
})
export class CardAvaliacaoComponent implements OnInit {

  @Output() exit = new EventEmitter();
  @Input() hasBack = true;
  @Input() imageURL = null;

  constructor() { }

  ngOnInit(): void {
  }

  emitExit(){
    this.exit.emit(true);
  }

}
