import {Component, Input, OnInit} from '@angular/core';
import {TentativaModel} from '../../../models/avaliacao-estudante.model';

@Component({
  selector: 'app-accordion-tentativa',
  templateUrl: './accordion-tentativa.component.html',
  styleUrls: ['./accordion-tentativa.component.scss']
})
export class AccordionTentativaComponent {

  @Input() tentativa: TentativaModel;
  @Input() numeroTentativa: number;
  @Input() showNota: boolean;
  @Input() showRevisao: false;
  accordionAberto = false;

  constructor() { }

  abrirAcordion() {
    this.accordionAberto = !this.accordionAberto;
  }

}
