import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data-service/data.service';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { AppState } from 'src/app/state';
import {
	fillAvaliacaoError,
	fillAvaliacaoIndex,
	fillAvaliacaoQuestao,
	fillAvaliacaoQuestoes,
	fillAvaliacaoStage,
	fillAvaliacaoTentativa,
	fillAvaliacaoTimer,
	fillAvaliacaoType,
} from 'src/app/state/state.actions';

@Component({
	selector: 'cls-avaliacao-discursiva',
	templateUrl: './avaliacao-discursiva.component.html',
	styleUrls: ['./avaliacao-discursiva.component.scss'],
})
export class AvaliacaoDiscursivaComponent implements OnInit {
	state: any;
	subscriptions: Subscription[] = [];
	updateTimer: any;
	timer = { minutes: 0, seconds: 0 };
	clockInterval: any;
	saveTimerInterval: any;
	hasTimer = false;
	errorModal = false;
	displayedError = null;
	canRetry = true;
	stoppedTimer = false;
	prepDate = '';
	loading = false;
	quantidadeErros = 0;
	online = true;
	initFinish = false;
	idAvaliacaoEstudanteTentativaFinalizada = 0;
	showRevisao = false;

	constructor(
    private store: Store<AppState>,
    private router: Router,
    public global: GlobalService,
    private translate: TranslateService,
    private dataService: DataService
	) {
		if (this.translate.currentLang === 'pt-br') this.prepDate = 'às';
		else if (this.translate.currentLang === 'en-us') this.prepDate = 'at';
	}

	ngOnInit(): void {
		this.getState();
		// this.answerChanged
		// .pipe(
		//   debounceTime(250),
		//   distinctUntilChanged()
		//   )
		// .subscribe((event) => this.handleAnswerChange(event))
	}

	ngOnDestroy(): void {
		this.exit();
	}
	handleAnswerChange(event) {
		const novaQuestao = Object.assign({}, event.questao);

		if (this.state.avaliacaoType === 'discursiva') {
			novaQuestao.response = event.answer ? event.answer : '';
		} else novaQuestao.option = event.answer;

		const tempQuestoes = [];

		this.state.avaliacaoQuestoes.forEach((questao) => {
			if (questao.id !== novaQuestao.id)
				tempQuestoes.push(Object.assign({}, questao));
			else tempQuestoes.push(Object.assign({}, novaQuestao));
		});
		this.store.dispatch(fillAvaliacaoQuestao({ payload: novaQuestao }));
		this.store.dispatch(fillAvaliacaoQuestoes({ payload: tempQuestoes }));

		// this.saveTimer();
	}

	getState() {
		const subscription = this.store
			.select((state: any) => {
				const {
					faseAtual,
					projetoAtivo,
					selectedQuizDiscursivo,
					avaliacaoStage,
					avaliacaoQuestoes,
					avaliacaoIndex,
					avaliacaoQuestao,
					avaliacaoTentativa,
					avaliacaoTimer,
					avaliacaoType,
					avaliacaoError,
				} = state.newAppState;
				this.state = {
					faseAtual,
					projetoAtivo,
					selectedQuizDiscursivo,
					avaliacaoStage,
					avaliacaoQuestoes,
					avaliacaoIndex,
					avaliacaoQuestao,
					avaliacaoTentativa,
					avaliacaoTimer,
					avaliacaoType,
				};

				const avaliacaoErrorAux = avaliacaoError || {};
				this.errorModal =
          'errorModal' in avaliacaoErrorAux
          	? avaliacaoErrorAux.errorModal
          	: false;
				this.displayedError =
          'displayedError' in avaliacaoErrorAux
          	? avaliacaoErrorAux.displayedError
          	: false;
				this.canRetry =
          'canRetry' in avaliacaoErrorAux ? avaliacaoErrorAux.canRetry : false;

				if (
					this.state.avaliacaoTimer &&
          (this.state.avaliacaoTimer.minutes > 0 ||
            this.state.avaliacaoTimer.seconds > 0) &&
          this.timer.minutes === 0 &&
          this.timer.seconds === 0 &&
          this.state.avaliacaoStage === 'resposta' &&
          !this.errorModal
				) {
					this.timer = Object.assign({}, this.state.avaliacaoTimer);
					this.initTimer();
				}

				if (
					this.state.avaliacaoStage === 'resposta' &&
          !this.errorModal &&
          !this.saveTimerInterval
				)
					this.initAutoSaving();
			})
			.subscribe();
		this.subscriptions.push(subscription);
	}

	begin() {
		this.loading = true;
		this.dataService
			.getQuestoesAvaliacao(
				this.state.selectedQuizDiscursivo.avaliacaoEstudante[0]
					.id_avaliacao_estudante
			)
			.toPromise()
			.then((response: any) => {
				this.store.dispatch(
					fillAvaliacaoType({
						payload:
              this.state.selectedQuizDiscursivo.tipoEntregaTurma.nome.toLowerCase(),
					})
				);
				this.verifyExistingTimer(response.tentativa.timer);
				this.store.dispatch(fillAvaliacaoStage({ payload: 'resposta' }));
				this.store.dispatch(
					fillAvaliacaoTentativa({ payload: response.tentativa })
				);
				this.store.dispatch(
					fillAvaliacaoQuestoes({ payload: response.tentativa.questions })
				);
				this.store.dispatch(
					fillAvaliacaoQuestao({ payload: response.tentativa.questions[0] })
				);
				this.store.dispatch(fillAvaliacaoIndex({ payload: 0 }));

				this.store.dispatch(
					fillAvaliacaoError({
						payload: {
							errorModal: false,
							displayedError: null,
							canRetry: true,
						},
					})
				);

				if (this.hasTimer) this.initTimer();

				if (
					this.state.avaliacaoStage === 'resposta' &&
          !this.errorModal &&
          !this.saveTimerInterval
				)
					this.initAutoSaving();
			})
			.finally(() => {
				this.loading = false;
			});
	}

	next() {
		this.store.dispatch(
			fillAvaliacaoIndex({ payload: ++this.state.avaliacaoIndex })
		);
		this.store.dispatch(
			fillAvaliacaoQuestao({
				payload: this.state.avaliacaoQuestoes[this.state.avaliacaoIndex],
			})
		);
		this.saveTimer();
	}

	before() {
		this.store.dispatch(
			fillAvaliacaoIndex({ payload: --this.state.avaliacaoIndex })
		);
		this.store.dispatch(
			fillAvaliacaoQuestao({
				payload: this.state.avaliacaoQuestoes[this.state.avaliacaoIndex],
			})
		);
		this.saveTimer();
	}

	restart() {
		this.errorModal = false;
		this.displayedError = null;
		this.canRetry = true;
		this.stoppedTimer = false;
		this.saveTimerInterval = false;

		this.store.dispatch(
			fillAvaliacaoError({
				payload: {
					errorModal: this.errorModal,
					displayedError: this.displayedError,
					canRetry: this.canRetry,
				},
			})
		);

		this.begin();
	}

	verifyExistingTimer(timer) {
		if (timer && timer !== '00:00') {
			this.timer.minutes = Number(timer.split(':')[0]);
			this.timer.seconds = Number(timer.split(':').pop());
			this.hasTimer = true;
		} else if (timer === '00:00') this.hasTimer = true;
		else this.hasTimer = false;
	}

	changeAnswer(event) {
		const novaQuestao = Object.assign({}, event.questao);

		if (this.state.avaliacaoType === 'discursiva')
			novaQuestao.response = event.answer ? event.answer : '';
		else novaQuestao.option = event.answer;

		const tempQuestoes = [];

		this.state.avaliacaoQuestoes.forEach((questao) => {
			if (questao.id !== novaQuestao.id)
				tempQuestoes.push(Object.assign({}, questao));
			else tempQuestoes.push(Object.assign({}, novaQuestao));
		});
		this.store.dispatch(fillAvaliacaoQuestao({ payload: novaQuestao }));
		this.store.dispatch(fillAvaliacaoQuestoes({ payload: tempQuestoes }));
	}

	getQuestoesRespondidas(questoes) {
		const questoesRespondidas = [];
		for (const questao of questoes) {
			switch (questao.id_quiz_entrega_turma_tipo) {
			case 1:
			case 2:
				for (const resposta of questao.respostas) {
					if (resposta.marcada) {
						questoesRespondidas.push(questao);
						break;
					}
				}
				break;
			case 3:
				questoesRespondidas.push(questao);
				break;
			}
		}
		return questoesRespondidas;
	}

	congratsOrSave(timer, finalizar = false) {
		const questoesRespondidas = this.getQuestoesRespondidas(
			this.state.avaliacaoQuestoes || []
		);
		const agora = new Date().toISOString();

		if (this.initFinish) return;
		if (finalizar) this.initFinish = true;

		let validacao = false;
		if (
			!this.state.selectedQuizDiscursivo.data_entrega ||
      (this.state.selectedQuizDiscursivo.data_entrega &&
        this.state.selectedQuizDiscursivo.data_entrega > agora)
		)
			validacao = true;

		if (
			finalizar &&
      questoesRespondidas.length === 0 &&
      (this.state.avaliacaoType === 'quiz' ||
        this.state.avaliacaoType === 'quiz novo') &&
      validacao
		) {
			this.global.toast(
				'Nenhuma questão respondida!',
				'Para finalizar sua avaliação você deverá responder pelo menos 1 questão.'
			);
			this.initFinish = false;
			return;
		}

		if (this.errorModal || this.state.avaliacaoStage != 'resposta')
			return false;

		const obj = {
			finalizar: finalizar,
			questions: this.state.avaliacaoQuestoes,
			timer: timer,
		};

		if (timer) this.store.dispatch(fillAvaliacaoTimer({ payload: this.timer }));
		this.store.dispatch(
			fillAvaliacaoQuestoes({ payload: this.state.avaliacaoQuestoes })
		);

		if (!this.state.avaliacaoTentativa) return false;

		this.dataService
			.responderAvaliacao(
				this.state.avaliacaoTentativa.id_avaliacao_estudante,
				this.state.avaliacaoTentativa.id_avaliacao_estudante_tentativa,
				obj,
				this.state.avaliacaoTentativa.sessao
			)
			.toPromise()
			.then(
				(data: any) => {
					if (!data.mensagem) {
						this.treatError();
						return false;
					}
					this.quantidadeErros = 0;
					this.online = true;
					if (
						finalizar ||
            data.mensagem.toLowerCase() === 'avaliação finalizada!'
					) {
						this.stopTimer();
						this.store.dispatch(fillAvaliacaoStage({ payload: 'congrats' }));
						this.idAvaliacaoEstudanteTentativaFinalizada =
              this.state.avaliacaoTentativa.id_avaliacao_estudante_tentativa;
						this.resetStateFields();
						if (
							data.tentativa.avaliacaoEstudante.entregaTurma
								.id_tipo_entrega_turma === 5
						)
							this.setEntregaRevisao(
								data.tentativa.avaliacaoEstudante.entregaTurma
							);
						if (!finalizar)
							this.global.toast(
								'Tempo esgotado',
								'Não há mais tempo para realizar esta avaliação'
							);
					}
				},
				(error) => {
					console.log(error);
					if (!finalizar && error.status === 0) {
						this.quantidadeErros++;
						this.online = false;
					}

					if (!finalizar && this.quantidadeErros < 5 && error.status === 0)
						return;

					if (!finalizar && error.status === 0) {
						this.treatError(
							'Ocorreu um problema com a sua conexão! Tente novamente mais tarde!',
							false
						);
						return;
					}

					if (error.error && error.error.message)
						this.treatError(error.error.message);
					else this.treatError();
				}
			);
	}

	treatError(errorString = null, canRetry = true) {
		this.initFinish = false;

		if (errorString) this.displayedError = errorString;
		else
			this.displayedError =
        'Ocorreu um problema durante a sua tentativa, por favor, reinicie sua avaliação!';

		this.canRetry = canRetry;
		this.errorModal = true;

		this.store.dispatch(
			fillAvaliacaoError({
				payload: {
					errorModal: this.errorModal,
					displayedError: this.displayedError,
					canRetry: this.canRetry,
				},
			})
		);

		this.stopTimer();
	}

	resetStateFields() {
		this.store.dispatch(fillAvaliacaoQuestao({ payload: null }));
		this.store.dispatch(fillAvaliacaoTimer({ payload: null }));
		this.store.dispatch(fillAvaliacaoTentativa({ payload: null }));
		this.store.dispatch(fillAvaliacaoQuestoes({ payload: null }));
		this.store.dispatch(fillAvaliacaoQuestao({ payload: null }));
		this.store.dispatch(fillAvaliacaoIndex({ payload: 0 }));
		this.timer = { minutes: 0, seconds: 0 };
	}

	initAutoSaving() {
		if (this.saveTimerInterval) return;
		if (this.state?.selectedQuizDiscursivo.duracao_tentativa) {
			this.saveTimerInterval = setInterval(() => {
				this.saveTimer();
			}, 60000);
		}
	}

	initTimer() {
		if (this.stoppedTimer) return;

		this.clockInterval = setInterval(() => {
			if (
				this.state.selectedQuizDiscursivo.duracao_tentativa < this.timer.minutes
			)
				this.finishTentativa();

			this.timer.seconds += 1;
			if (this.timer.seconds != 60) return;

			this.timer.minutes += 1;
			this.timer.seconds = 0;
		}, 1000);
	}

	getFormattedTimer() {
		let ret = null;
		let min: any = this.timer.minutes.toString();
		let sec: any = this.timer.seconds.toString();
		if (this.timer.minutes > 0 || this.timer.seconds > 0) {
			if (min.length === 1) min = '0' + min;
			if (sec.length === 1) sec = '0' + sec;
			ret = min + ':' + sec;
		}
		return ret;
	}

	saveTimer() {
		this.congratsOrSave(this.getFormattedTimer());
	}

	finishTentativa() {
		this.stopTimer();
		this.global.toast(
			'Tempo esgotado',
			'Não há mais tempo para realizar esta avaliação'
		);
		this.congratsOrSave(this.getFormattedTimer(), true);
	}

	getAtualTentativa() {
		let atualTentativa = 0;
		this.state.selectedQuizDiscursivo.avaliacaoEstudante[0].tentativa.forEach(
			(tentativa) => {
				if (tentativa.data_finalizacao) atualTentativa++;
			}
		);
		return this.global.getStringTentativa(atualTentativa);
	}

	exit() {
		this.store.dispatch(
			fillAvaliacaoError({
				payload: {
					errorModal: false,
					displayedError: null,
					canRetry: true,
				},
			})
		);

		this.store.dispatch(fillAvaliacaoStage({ payload: 'fora_da_prova' }));
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
		this.stopTimer();
		this.router.navigate([
			'projeto/' +
        this.state.projetoAtivo.id_projeto_turma +
        '/fase/' +
        this.state.faseAtual.id_fase_turma,
		]);
	}

	stopTimer() {
		clearInterval(this.saveTimerInterval);
		clearInterval(this.clockInterval);

		this.stoppedTimer = true;
	}

	showResult(): void {
		this.state.avaliacaoStage = 'result';
	}

	setEntregaRevisao(entrega) {
		const today = new Date().toISOString();
		if (entrega.data_revisao_inicio && entrega.data_revisao_final) {
			if (
				today >= entrega.data_revisao_inicio &&
        today <= entrega.data_revisao_final
			)
				this.showRevisao = true;
		} else {
			this.showRevisao = true;
		}
	}
}
