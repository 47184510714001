import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'sortArrayDateNullFirst',
})
export class SortPipeArrayDateNullFirst implements PipeTransform {
	transform(array: any[][]): any[][] {
		if (!array) {
			return [];
		}

		const nullDataArrays = [];
		const sortedDataArrays = [];

		array.forEach((subArray) => {
			if (subArray.some((item) => item.data_leitura === null)) {
				nullDataArrays.push(subArray);
			} else {
				sortedDataArrays.push(subArray);
			}
		});

		sortedDataArrays.sort((a, b) => {
			const dateA = new Date(this.getLatestDate(a));
			const dateB = new Date(this.getLatestDate(b));

			return dateB.getTime() - dateA.getTime();
		});

		return nullDataArrays.concat(sortedDataArrays);
	}

	private getLatestDate(array: any[]): string {
		const dates = array.map((item) => item.data_leitura);
		const validDates = dates
			.filter((date) => date !== null)
			.map((date) => new Date(date).getTime());

		if (validDates.length === 0) {
			return null;
		}

		return Math.max.apply(null, validDates);
	}
}
