import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContainerDetalhesComponent } from './container-detalhes.component';



@NgModule({
  declarations: [ContainerDetalhesComponent],
  imports: [
    CommonModule
  ],
  exports: [ContainerDetalhesComponent]
})
export class ContainerDetalhesModule { }
