import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { DataService } from 'src/app/services/data-service/data.service';
import { avaliacaoState, fillDadosAvaliacao, fillArrayRespostasAluno } from 'src/app/state/state.actions';
import { defaultThrottleConfig } from 'rxjs/internal/operators/throttle';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-questionario-avaliacao',
  templateUrl: './questionario-avaliacao.component.html',
  styleUrls: ['./questionario-avaliacao.component.scss']
})
export class QuestionarioAvaliacaoComponent implements OnInit {
  stateSubscription: any;
  state: any;
  shuffledAnswers = [];
  selectedAnswer: string;
  countRight = 0
  questoes = []
  currentQuestion: number = 0
  avaliacaoEstudante: any;
  avaliacaoEstudanteTentativa: any;
  entregaTurma: any;
  resultado: any;
  timer: any;
  seconds: number = 0
  minutes: number = 0
  hours: number = 0
  updateTimer: any;
  isTimed: boolean;
  isSavingQuiz: boolean;
  sessao: number;
  errorModal: boolean = false;
  errorModalMessage: any;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    public global: GlobalService,
    public dataService: DataService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.getState();
    this.entregaTurma = this.state.selectedQuizAvaliacao;
    this.isTimed = !!this.entregaTurma.duracao_tentativa;
    if (this.state.avaliacaoState === 'question' && this.entregaTurma.duracao_tentativa) this.initTimer()
    this.avaliacaoEstudante = this.entregaTurma.avaliacaoEstudante[0].id_avaliacao_estudante
    this.dataService.getQuestoesAvaliacao(this.avaliacaoEstudante)
      .subscribe((response) => {
        if (!response.tentativa.sessao){
          throw new Error('Sessão não informada');
        }
        this.sessao = response.tentativa.sessao;
        let lastTime = response.tentativa.timer
        this.seconds = lastTime ? Number(lastTime.split(':').pop()) : 0
        this.minutes = lastTime ? Number(lastTime.split(':')[0]) : 0

        this.avaliacaoEstudanteTentativa = response.tentativa
        response.tentativa.questions.map(question => {
          this.questoes.push({
            pergunta: question.text,
            options: question.options,
            option: question.option,
            id: question.id_avaliacao_estudante_tentativa_resposta
          })
        })

      }, (error) => {
        let message = error.error.message || error.message || "Problema ao carregar sua tentativa."
        this.global.toast(this.translate.instant("GENERAL.ERROR"), message);
      })
  }

  ngOnDestroy(){
    this.exit();
  }

  initTimer() {
    this.timer = setInterval(() => {
      let timeEnded = this.checkIfTimeEnded()
      if (timeEnded) return
      this.seconds += 1
      if (this.minutes === 60 && this.seconds === 60) {
        this.minutes = 0
        this.seconds = 0
        return
      }
      if (this.seconds === 60) {
        this.minutes += 1
        this.seconds = 0
        return
      }
    }, 1000)

    // this.updateTimer = setInterval(() => {
    //   this.saveTimer({ seconds: this.seconds, minutes: this.minutes }, true)
    // }, 5000)
  }

  checkIfTimeEnded() {
    let maxMinutes = this.entregaTurma.duracao_tentativa
    let timeEnded = this.minutes >= maxMinutes
    if (timeEnded) this.finishTentativa()
    return timeEnded
  }

  finishTentativa() {
    this.stopTimer()
    this.global.toast("Tempo esgotado", "Não há mais tempo para realizar esta avaliação");
    this.final()
    // this.router.navigate([`/avaliacao/gabarito/${this.avaliacaoEstudante}/tentativa/${this.avaliacaoEstudanteTentativa.id_avaliacao_estudante_tentativa}`])
    this.router.navigate(['projeto/' + this.state.projetoAtivo.id_projeto_turma + '/fase/' + this.state.faseAtual.id_fase_turma]);
  }

  async saveTimer(timerInfo: { seconds: number, minutes: number }, isAuto = true): Promise<any> {
    const obj = {
      finalizar: false,
      timer: this.getFormattedTimer(this.minutes, this.seconds),
      questions: this.questoes.map(question => {
        return { id_avaliacao_estudante_tentativa_resposta: question.id, option: question.option }
      })
    }

    await this.updateAnswers(obj)
      .then((response) => {
        //console.log(response);
      })
      .catch((error) => {
        console.log(error);
        this.stopTimer()
        this.errorModal = true;
        // this.global.toast(error.error.message, 'Sua avaliação foi iniciada em outro navegador ou aplicativo do Liga Online! Você poderá continuar normalmente a sua avaliação através da última janela aberta!')
      })
  }

  stopTimer() {
    // clearInterval(this.updateTimer)
    clearInterval(this.timer)
  }

  getState() {
    this.stateSubscription = this.store.select((state: any) => {
      const { projetoAtivo, faseAtual, avaliacaoState, selectedQuizAvaliacao } = state.newAppState;
      this.state = { faseAtual, avaliacaoState, projetoAtivo, selectedQuizAvaliacao };
    }).subscribe();
  }

  exit() {
    this.stopTimer()
    this.store.dispatch(avaliacaoState({ payload: null }));
    this.router.navigate(['projeto/' + this.state.projetoAtivo.id_projeto_turma + '/fase/' + this.state.faseAtual.id_fase_turma]);
  }

  begin() {
    this.currentQuestion = 0
    this.store.dispatch(avaliacaoState({ payload: 'question' }));
    if (this.entregaTurma.duracao_tentativa) this.initTimer()
  }

  updateAnswers(data): Promise<any> {
    return this.dataService.responderAvaliacao(this.avaliacaoEstudante, this.avaliacaoEstudanteTentativa.id_avaliacao_estudante_tentativa, data, this.sessao)
      .toPromise()
  }

  async next(question) {
    if (this.currentQuestion > this.questoes.length) return
    this.isSavingQuiz = true

    if(this.seconds % 5 == 0)
      this.seconds++;

    const obj = {
      finalizar: false,
      timer: this.isTimed ? this.getFormattedTimer(this.minutes, this.seconds) : null,
      questionsFake: [{ id_avaliacao_estudante_tentativa_resposta: question.id, option: question.option }],
      questions: this.questoes.map(question => {
        return { id_avaliacao_estudante_tentativa_resposta: question.id, option: question.option }
      })
    }

    await this.updateAnswers(obj)
      .then((response) => {
        this.currentQuestion += 1
      })
      .catch((error) => {
        console.log(error);
        let message = error.error.message || error.message || "Tivemos um problema para registrar sua resposta, tente novamente."
        this.global.toast(this.translate.instant("GENERAL.ERROR"), message);
      })
      .finally(() => this.isSavingQuiz = false)
  }

  getFormattedTimer(minutes, seconds) {
    let formattedMinute = ("0" + minutes).slice(-2);
    let formattedSecond = ("0" + seconds).slice(-2);

    return `${formattedMinute}:${formattedSecond}`
  }

  previous() {
    if (this.currentQuestion === 0) return
    this.currentQuestion -= 1
  }

  final() {
    this.isSavingQuiz = true

    const obj = {
      finalizar: true,
      questions: this.questoes.map(question => {
        return { id_avaliacao_estudante_tentativa_resposta: question.id, option: question.option }
      }),
      timer: this.isTimed ? this.getFormattedTimer(this.minutes, this.seconds) : null,
    }

    this.dataService.responderAvaliacao(this.avaliacaoEstudante, this.avaliacaoEstudanteTentativa.id_avaliacao_estudante_tentativa, obj, this.sessao)
      .subscribe(response => {
        this.router.navigate(['projeto/' + this.state.projetoAtivo.id_projeto_turma + '/fase/' + this.state.faseAtual.id_fase_turma]);
        this.global.toast("Parabéns!", "Você finalizou a sua avaliação!");
      }, (error) => {
        console.log(error);
        let message = error.error.message || error.message || "Tivemos um problema para registrar sua resposta, tente novamente."
        this.global.toast(this.translate.instant("GENERAL.ERROR"), message);
      }, () => {
        this.isTimed ? this.stopTimer() : null
        this.isSavingQuiz = false
      })
  }

  correctAvaliacao() {
    let count = 0;
    let percent = 0;
    this.state.respostasAluno.forEach((resposta, index) => {
      if (resposta == this.questoes[index].resposta_correta)
        count++;
    });
    percent = (count * 100) / 5;
    this.store.dispatch(fillDadosAvaliacao({ payload: { count: count, percentage: percent } }))
  }

  updateRespostasAluno(previous = false) {
    let updatedRespostas = this.state.respostasAluno;
    if (previous)
      updatedRespostas.pop();
    else
      updatedRespostas.push(this.selectedAnswer);
    this.store.dispatch(fillArrayRespostasAluno({ payload: updatedRespostas }))
  }

  markAnswer(option) {
    this.questoes[this.currentQuestion].option = option
  }
}
