<!-- <div class="overlay"></div> -->
<div
  class="projeto-box-container"
  [class.grow]="growBox"
  (mouseout)="changeSize($event)"
  (mouseover)="changeSize($event)"
  (click)="goToHome()"
>
  <cls-circle
    [size]="'medium-big'"
    [percentage]="extractPercentage(data.atividades_percentual)"
  ></cls-circle>
  <div class="description">
    <div
      *ngIf="data?.foto_capa_web !== null"
      class="back-img"
      [style.background-image]="global.generateImageURL(data.foto_capa_web)"
    ></div>
    <div class="back-shadow"></div>
    <div class="img-branca">
      <img
        *ngIf="
          projetoPolo?.imagem_logo_branca || data?.empresa?.imagem_logo_branca
        "
        [src]="
          projetoPolo?.imagem_logo_branca || data?.empresa?.imagem_logo_branca
        "
      />
    </div>
    <div class="title-section">
      <div
        *ngIf="data.nome?.length > 90"
        class="index-3 nome"
        [innerHtml]="data.nome.slice(0, 90) + '...'"
      ></div>
      <div
        *ngIf="data.nome?.length <= 90"
        [innerHtml]="data.nome"
        class="index-3 nome"
      ></div>
      <div class="divisor-row">
        <div class="divisor"></div>
        <div class="divisor"></div>
        <div class="divisor"></div>
      </div>
      <div
        class="index-3 disciplina"
        *ngIf="data?.nome_disciplina?.length < 30"
      >
        {{ data?.nome_disciplina }}
      </div>
      <div
        class="index-3 disciplina"
        *ngIf="data?.nome_disciplina?.length >= 30"
      >
        {{ data?.nome_disciplina | slice : 0 : 30 }}...
      </div>
    </div>
  </div>
  <div class="tutor">
    <img
      *ngIf="
        data?.projetoTurmaTutor?.length == 0 ||
        (data?.projetoTurmaTutor?.length > 0 &&
          !data?.projetoTurmaTutor[0]?.foto)
      "
      src="../../../assets/imgs/profile-img.svg"
    />
    <div
      class="img-tutor"
      *ngIf="
        data?.projetoTurmaTutor?.length > 0 && data?.projetoTurmaTutor[0]?.foto
      "
      [style.background-image]="
        global.generateImageURL(data?.projetoTurmaTutor[0]?.foto)
      "
    ></div>
    <div
      *ngIf="data?.projetoTurmaTutor[0]?.nome_social?.length <= 25"
      class="nome-tutor"
    >
      {{ data?.projetoTurmaTutor[0]?.nome_social?.toLowerCase() }}
    </div>
    <div
      *ngIf="data?.projetoTurmaTutor[0]?.nome_social?.length > 25"
      class="nome-tutor"
    >
      {{
        data?.projetoTurmaTutor[0]?.nome_social?.toLowerCase() | slice : 0 : 25
      }}...
    </div>
  </div>
  <div class="bars">
    <div class="first-line">
      <div class="nivel">
        {{ data.turma_nivel_ensino }}
      </div>
      <div class="duracao">{{ data.duracao }}h</div>
    </div>
    <div class="second-line">
      <div class="competencia">
        <img src="../../../assets/icons/award-blue.svg" />
        <span class="nome" *ngIf="data?.projetoTurmaCompetencia[0]"
          >{{ data?.projetoTurmaCompetencia[0]?.nome | slice : 0 : 25 }}
        </span>
      </div>
    </div>
  </div>
</div>
