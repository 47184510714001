import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import {
	fillCatCompetencia,
	fillCatExperiencia,
	fillCatFormacao,
	fillCatIdioma,
	fillCatPerfil,
	fillCurriculoStateTemp,
	fillLastRoute,
	fillUserData,
	toggleCurriculoEdit,
} from 'src/app/state/state.actions';
import { DataService } from 'src/app/services/data-service/data.service';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { TranslateService } from '@ngx-translate/core';
import { catchError, finalize, tap } from 'rxjs/operators';
import { EMPTY, Subscription, throwError } from 'rxjs';

@Component({
	selector: 'cls-curriculo',
	templateUrl: './curriculo.component.html',
	styleUrls: ['./curriculo.component.scss'],
})
export class CurriculoComponent implements OnInit, OnDestroy {
	subscriptions: Subscription[] = [];
	state: any;
	limitComp = 5;
	showAllCompetencias = false;
	idiomasArray = [
		'Português',
		'Inglês',
		'Espanhol',
		'Francês',
		'Alemão',
		'Italiano',
		'Chinês',
		'Japonês',
	];
	showDados = false;
	moreSobremim = false;
	moreInteresses = false;
	loading = false;
	sistema_configs: any = {
		sistema_certificados: false,
	};

	constructor(
    private store: Store<AppState>,
    private dataService: DataService,
    public global: GlobalService,
    private translate: TranslateService,
    private router: Router
	) {}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}

	ngOnInit() {
		this.getSystemaCertificadosConfig();
		this.getState();
		this.setCurriculoCategorias();
		this.setCurrentRoute();
		this.scrollTo();
	}

	getSystemaCertificadosConfig() {
		const subscription = this.dataService
			.getSistemaConfigs(41)
			.pipe(
				tap(
					(res) =>
						(this.sistema_configs.sistema_certificados = res.valor_booleano)
				)
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	scrollTo() {
		if (Object.prototype.hasOwnProperty.call(localStorage, 'scrollTo')) {
			const scrollData = JSON.parse(localStorage.getItem('scrollTo'));
			if (scrollData.page == 'curriculo' && scrollData.to == 'bottom') {
				setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 200);
				localStorage.removeItem('scrollTo');
			}
		}
	}

	getState() {
		const subscription = this.store
			.select((state: any) => {
				const {
					dadosEmpresa,
					usuario,
					projetosAluno,
					showingMenu,
					catTestePerfil,
					catFormacao,
					catCompetencia,
					catIdioma,
					catExperiencia,
					catPerfil,
					curriculoEdit,
					curriculoStateTemp,
				} = state.newAppState;
				this.state = {
					dadosEmpresa,
					usuario,
					projetosAluno,
					showingMenu,
					catTestePerfil,
					catFormacao,
					catCompetencia,
					catIdioma,
					catExperiencia,
					catPerfil,
					curriculoEdit,
					curriculoStateTemp,
				};
			})
			.subscribe();
		this.subscriptions.push(subscription);
	}

	setCurrentRoute() {
		sessionStorage.setItem('url', '/curriculo');
		this.store.dispatch(fillLastRoute({ payload: '/curriculo' }));
	}

	setCurriculoCategorias() {
		this.state.usuario.curriculoUsuario[0].categorias.forEach((categoria) => {
			if (categoria.titulo == 'Formação')
				this.store.dispatch(fillCatFormacao({ payload: categoria.entradas }));
			if (categoria.titulo == 'Competência')
				this.store.dispatch(
					fillCatCompetencia({ payload: categoria.entradas })
				);
			if (categoria.titulo == 'Idioma')
				this.store.dispatch(fillCatIdioma({ payload: categoria.entradas }));
			if (categoria.titulo == 'Experiência')
				this.store.dispatch(
					fillCatExperiencia({ payload: categoria.entradas })
				);
			if (categoria.titulo == 'Perfil')
				this.store.dispatch(fillCatPerfil({ payload: categoria.entradas }));
		});
	}

	saveData() {
		if (
			this.state.projetosAluno?.length > 0 &&
      this.state.projetosAluno[0].estudante
      	.id_projeto_turma_estudante_status === 8
		)
			return throwError('No permission');
		this.clearIds();
		const sendData = {
			usuario: {
				id_usuario: this.state.usuario.id_usuario,
				nome_social: this.state.usuario.nome_social,
				celular: this.state.usuario.celular,
				email_pessoal: this.state.usuario.email_pessoal,
				rede_linkedin: this.state.usuario.rede_linkedin,
				rede_twitter: this.state.usuario.rede_twitter,
				rede_facebook: this.state.usuario.rede_facebook,
				rede_instagram: this.state.usuario.rede_instagram,
				rede_skype: this.state.usuario.rede_skype,
				curriculo: {
					id_curriculo_usuario:
            this.state.usuario.curriculoUsuario[0].id_curriculo_usuario,
					id_usuario: this.state.usuario.id_usuario,
					sobremim: this.state.usuario.curriculoUsuario[0].sobremim,
					interesses: this.state.usuario.curriculoUsuario[0].interesses,
					formacao: this.state.catFormacao,
					idioma: this.state.catIdioma,
					experiencia: this.state.catExperiencia,
					competencia: this.state.catCompetencia,
					perfil: this.state.catPerfil,
				},
			},
		};
		this.store.dispatch(fillCurriculoStateTemp({ payload: null }));
		this.setCurriculoEdition(false);
		this.dataService.updateCurriculo(sendData).then(
			() => {
				this.global.toast(
					this.translate.instant('GENERAL.SUCCESS'),
					this.translate.instant('CURRICULO.UPDATED')
				);
				this.dataService.refreshUserData();
			},
			(error) => {
				this.dataService.refreshUserData();
				this.global.toast(
					this.translate.instant('GENERAL.ERROR'),
					error.error.message
				);
			}
		);
	}

	clearIds() {
		this.state.catFormacao.forEach((formacao) => {
			delete formacao.id_nivel_ensino;
		});
		this.state.catIdioma.forEach((idioma) => {
			delete idioma.id_idioma_fluencia;
			delete idioma.id_idioma;
		});
	}

	toggleFormacao(index = null) {
		if (index == null) {
			this.state.catFormacao.push({
				curso: '',
				data_inclusao: null,
				instituicao: '',
				nivel_ensino: '',
				valor_progresso: 100,
				status_progresso: 'Finalizado',
			});
		} else this.state.catFormacao.splice(index, 1);
	}

	toggleIdioma(index = null) {
		if (index == null) {
			this.state.catIdioma.push({
				fluencia: 'Básico',
				idioma: '',
			});
		} else this.state.catIdioma.splice(index, 1);
	}

	toggleExperiencia(index = null) {
		if (index == null) {
			this.state.catExperiencia.push({
				cargo: '',
				corporacao: '',
				data_final: '',
				data_inicio: '',
			});
		} else this.state.catExperiencia.splice(index, 1);
	}

	goToPerfil() {
		this.setCurriculoEdition(false);
		this.router.navigate(['perfil']);
	}

	doToProfileTest() {
		this.router.navigate([
			'questionario-perfil/' + this.state.catTestePerfil.id_perfil_teste,
		]);
	}

	goToProfile(perfil) {
		this.router.navigate(['perfil-usuario/' + perfil.id_perfil_catalogo]);
	}

	setCurriculoEdition(bln) {
		if (bln) {
			const tmp = {
				usuario: this.state.usuario,
				catFormacao: this.state.catFormacao,
				catCompetencia: this.state.catCompetencia,
				catIdioma: this.state.catIdioma,
				catExperiencia: this.state.catExperiencia,
				catPerfil: this.state.catPerfil,
			};
			this.store.dispatch(fillCurriculoStateTemp({ payload: this.deep(tmp) }));
		} else if (this.state.curriculoStateTemp) {
			this.store.dispatch(
				fillUserData({ payload: this.state.curriculoStateTemp.usuario })
			);
			this.store.dispatch(
				fillCatFormacao({ payload: this.state.curriculoStateTemp.catFormacao })
			);
			this.store.dispatch(
				fillCatCompetencia({
					payload: this.state.curriculoStateTemp.catCompetencia,
				})
			);
			this.store.dispatch(
				fillCatIdioma({ payload: this.state.curriculoStateTemp.catIdioma })
			);
			this.store.dispatch(
				fillCatExperiencia({
					payload: this.state.curriculoStateTemp.catExperiencia,
				})
			);
			this.store.dispatch(
				fillCatPerfil({ payload: this.state.curriculoStateTemp.catPerfil })
			);
		}
		this.store.dispatch(toggleCurriculoEdit({ payload: bln }));
	}

	deep(value) {
		if (typeof value !== 'object' || value === null) return value;
		if (Array.isArray(value)) return this.deepArray(value);
		return this.deepObject(value);
	}

	deepArray<T extends any[]>(collection: T) {
		return collection.map((value) => {
			return this.deep(value);
		});
	}

	deepObject<T>(source: T) {
		const result = {};
		Object.keys(source).forEach((key) => {
			const value = source[key];
			result[key] = this.deep(value);
		}, {});
		return result as T;
	}

	toggleShowAllCompetencias() {
		this.showAllCompetencias = !this.showAllCompetencias;
		if (this.showAllCompetencias) this.limitComp = 10000000;
		else this.limitComp = 5;
	}

	toggleFavorita(competencia) {
		const tempArr = [];
		this.state.catCompetencia.forEach((comp) => {
			tempArr.push(Object.assign({}, comp));
		});

		tempArr.forEach((temp) => {
			if (temp.id_usuario_competencia === competencia.id_usuario_competencia)
				temp.favorita = !temp.favorita;
		});

		this.store.dispatch(fillCatCompetencia({ payload: tempArr }));
	}

	goToProjeto(competencia) {
		let projeto: any;
		this.dataService
			.getDadosProjeto(competencia.id_projeto_turma)
			.subscribe((data: any) => {
				projeto = data.projeto;
				projeto.estudante = data.projetoTurmaEstudante;
				this.dataService.setInitialDataAndNavigate(projeto);
			});
	}

	checkIfAprovado(competencia) {
		const aprovado =
      competencia?.projetoTurmaEstudante?.id_projeto_turma_estudante_status ===
        5 && competencia?.valor === 100;

		return aprovado;
	}

	checkIfHasSignature(competencia) {
		if (!competencia) return false;

		if (
			competencia.projeto?.empresa?.imagem_assinatura &&
      competencia.projeto?.empresa?.nome_assinatura &&
      competencia.projeto?.empresa?.imagem_logo_certificado
		)
			return true;

		return false;
	}

	goToSocialMedia(urlSocialMedia: string, socialMedia: string) {
		if (urlSocialMedia && urlSocialMedia.includes('https'))
			window.open(urlSocialMedia, socialMedia);
	}

	emitCertified(competencia: any) {
		this.loading = true;
		const subscription = this.dataService
			.getCertificadoGenerate(competencia.id_competencia)
			.pipe(
				tap((response) => {
					const newCatCompetencia = this.state.catCompetencia.map(
						(catCompetencia) => ({ ...catCompetencia })
					);
					const competenciaSelecionada = newCatCompetencia.find(
						(c) =>
							c.id_usuario_competencia === competencia.id_usuario_competencia
					);
					competenciaSelecionada.certificado_url = response.certificado_url;

					this.store.dispatch(
						fillCatCompetencia({ payload: newCatCompetencia })
					);
					this.global.toast(
						this.translate.instant('GENERAL.SUCCESS'),
						response.message
					);
				}),
				tap((response) => {
					window.open(response.certificado_url, '_blank');
				}),
				catchError((error) => {
					console.log(error);
					this.global.toast(
						this.translate.instant('GENERAL.ERROR'),
						error.error.message
					);
					return EMPTY;
				}),
				finalize(() => (this.loading = false))
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	downloadCertified(competencia: any) {
		window.open(competencia.certificado_url, '_blank');
	}
}
