<div class="main">
  <div class="main-card">
    <div
      *ngIf="loading"
      class="spinner-border spinner-border-md loading"
      role="status"
    >
      <span class="sr-only">Loading...</span>
    </div>
    <div *ngIf="!loading" class="bars">
      <div class="bar marked"></div>
    </div>
    <div *ngIf="!loading" class="header">
      <div class="title">{{ "GABARITO.RESULT" | translate }}</div>
      <div
        class="grade"
        *ngIf="resultado?.avaliacaoEstudante?.entregaTurma?.nota_maxima"
      >
        {{ "GABARITO.GRADE" | translate
        }}{{ resultado?.tentativa?.nota | number : "1.0-2"
        }}{{ "GABARITO.HITS_OF" | translate
        }}{{
          resultado?.avaliacaoEstudante?.entregaTurma?.nota_maxima
            | number : "1.0-2"
        }}
      </div>
    </div>
    <div *ngIf="!loading" class="got-right">
      {{ "GABARITO.ACERTOS" | translate }}{{ resultado?.tentativa?.acertos
      }}{{ "GABARITO.HITS_OF" | translate
      }}{{ resultado?.avaliacaoEstudante?.entregaTurma?.questoes_tentativa }}
    </div>
    <app-gabarito-quiz-novo
      (resultadoEmitter)="receiveResultado($event)"
      [idAvaliacaoEstudante]="idAvaliacaoEstudante"
      [idAvaliacaoEstudanteTentativa]="idAvaliacaoEstudanteTentativa"
      [useInternalLoading]="false"
    >
    </app-gabarito-quiz-novo>
    <ng-content *ngIf="!loading"></ng-content>
  </div>
  <div class="detail-1"></div>
  <div class="detail-2"></div>
</div>
