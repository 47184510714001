<app-agenda
  *ngIf="fullState?.showingAgenda == 'open'"
  (closeMenu)="closeMenu()"
></app-agenda>
<div [@Menu]="transition" class="menu-container">
  <span class="pointer d-flex ml-auto" (click)="closeMenu()">
    <!-- <img class="lo-close" src="../../../assets/icons/icone_fechar.svg" alt="" srcset="" data-cy="MenuImgCloseMenu"> -->
    <div class="close-pattern" data-cy="MenuImgCloseMenu">
      <!-- <i class="fas fa-times pointer"></i> -->
      <img src="assets/icons/fas-fa-times-pointer.svg" />
    </div>
  </span>
  <ul class="menu-list col-12">
    <li
      (click)="goToSolicitacoes()"
      data-cy="MenuLiAtendimento"
      *ngIf="fullState?.projetoAtivo"
      class="pointer d-flex align-items-center justify-content-start menu-item border-bottom pb-4 pt-4"
    >
      <div class="icon mr-2 opacity">
        <img src="../../../assets/icons/icone_solicitacoes.svg" alt="" />
      </div>
      <span class="text text-uppercase">{{
        "MENU.ATTENDANCE" | translate
      }}</span>
    </li>
    <li
      (click)="goToPortal()"
      data-cy="MenuLiPortalGraduacao"
      *ngIf="fullState?.projetoAtivo?.empresa?.login_sia"
      class="pointer d-flex align-items-center justify-content-start menu-item border-bottom pb-4 pt-4"
    >
      <div class="icon mr-2 opacity">
        <img src="../../../assets/icons/icone_portalgraduacao.svg" alt="" />
      </div>
      <span class="text text-uppercase">{{
        "MENU.GRADUATION_PORTAL" | translate
      }}</span>
    </li>
    <li
      (click)="goToAgenda()"
      data-cy="MenuLiCalendario"
      class="pointer d-flex align-items-center justify-content-start menu-item border-bottom pb-4 pt-4"
    >
      <div class="icon mr-2">
        <img src="../../../assets/icons/calendar.svg" alt="" />
      </div>
      <span class="text text-uppercase">{{ "MENU.CALENDAR" | translate }}</span>
    </li>
    <li
      (click)="showModalConfiguracoes()"
      data-cy="MenuLiConfiguracoes"
      class="pointer d-flex align-items-center justify-content-start menu-item border-bottom pb-4 pt-4"
    >
      <div class="icon mr-2">
        <img src="../../../assets/icons/icone_configuracoes.svg" alt="" />
      </div>
      <span class="text text-uppercase">{{
        "MENU.CONFIGURATIONS" | translate
      }}</span>
    </li>
    <li
      (click)="goToCentralAjuda()"
      data-cy="MenuLiAjuda"
      class="pointer d-flex align-items-center justify-content-start menu-item border-bottom pb-4 pt-4"
    >
      <div class="icon ajuda">
        <img class="question" src="../../../assets/icons/question-menu.svg" />
      </div>
      <span class="text text-uppercase">{{ "MENU.HELP" | translate }}</span>
    </li>
    <li
      (click)="logout()"
      data-cy="MenuLiLogout"
      class="pointer d-flex align-items-center justify-content-start menu-item pb-4 pt-4"
    >
      <div class="icon sair">
        <img src="../../../assets/icons/icone_sair.svg" alt="" />
      </div>
      <span class="text text-uppercase">{{ "MENU.EXIT" | translate }}</span>
    </li>
  </ul>
  <div class="footer">
    <div>
      <span>{{ fullState?.usuario?.nome_social }}</span>
    </div>
    <div *ngIf="fullState?.projetoAtivo">
      <span class="text-capitalize">{{ "MENU.REGISTRATION" | translate }}</span>
      {{ fullState?.estudante?.matricula }}
    </div>
    <div>
      <span class="upper">{{ "MENU.CODE" | translate }}</span>
      {{ fullState?.usuario?.cpf }}
    </div>
    <div class="d-flex pt-4 pb-4">
      <img
        *ngIf="fullState?.projetoAtivo"
        [src]="fullState?.projetoAtivo?.empresa?.imagem_rodape"
      />
    </div>
  </div>
  <cls-modal-configuracoes
    *ngIf="fullState?.showModalConfiguracoes"
  ></cls-modal-configuracoes>
</div>
