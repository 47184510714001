import { throwError } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { DataService } from 'src/app/services/data-service/data.service';
import { showModalConfiguracoes, photoLoading } from 'src/app/state/state.actions';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'cls-modal-configuracoes',
  templateUrl: './modal-configuracoes.component.html',
  styleUrls: ['./modal-configuracoes.component.scss']
})
export class ModalConfiguracoesComponent implements OnInit {

  uploadPhoto: FormData;
  photoName: string;
  changePass = { oldpassword: '', newpassword: '', againpassword: '' };
  state: any;
  stateSubscription$: any;
  status: number;

  constructor(
    private store: Store<AppState>,
    private dataService: DataService,
    public global: GlobalService,
    private translate: TranslateService
  ) { }

  ngOnDestroy(): void {
    this.stateSubscription$.unsubscribe()
  }

  ngOnInit() {
    this.getState();
  }

  callInput() {
    if (this.status === 8) return throwError('No permission')
    let input: HTMLElement;
    input = document.querySelector('.upload-picture');
    input.click();
  }

  prepareUpload(event) {
    this.uploadPhoto = new FormData();
    this.uploadPhoto.append('foto', event.target.files[0], event.target.files[0].name);
    this.photoName = event.target.files[0].name;
  }

  uploadPicture() {
    if (this.status === 8) return throwError('No permission')
    this.store.dispatch(photoLoading({ payload: true }));
    this.dataService.changePicture(this.uploadPhoto);
  }

  tryChangePassoword() {
    if (this.status === 8) return throwError('No permission')
    if (this.changePass.newpassword != this.changePass.againpassword)
      this.global.toast(this.translate.instant("GENERAL.ERROR"), this.translate.instant("GENERAL.PASSWORD_NO_MATCH"));
    else if (this.changePass.newpassword == '' || this.changePass.againpassword == '' || this.changePass.oldpassword == '')
      this.global.toast(this.translate.instant("GENERAL.ERROR"), this.translate.instant("GENERAL.FIELDS_PASSWORD"));
    else
      this.dataService.changePassword(this.changePass);
  }

  getState() {
    this.stateSubscription$ = this.store.select((state: any) => {
      const { usuario, photoLoading, projetosAluno } = state.newAppState;
      this.state = { usuario, photoLoading, projetosAluno }
      this.status = this.state.projetosAluno[0].estudante.id_projeto_turma_estudante_status
    }).subscribe();
  }

  closeModal() {
    this.store.dispatch(showModalConfiguracoes({ payload: false }))
  }

}
