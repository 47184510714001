<div class="content">
  <ng-container *ngFor="let filter of objectData.model" [ngSwitch]="true">
    <div
      *ngSwitchCase="filter.type == 'buttons' || filter.type == 'date-buttons'"
      class="filter-section"
    >
      <div class="filter-section__title">
        {{ filter.title | translate }}
      </div>
      <div class="filter-section__buttons">
        <button
          *ngFor="let item of filter.options"
          (click)="
            filter.type == 'buttons'
              ? toggleValue(
                  item,
                  filter.fieldValue,
                  filter.type,
                  filter.multiple
                )
              : setDateRange(item, filter.fieldValue, filter.type)
          "
          [ngClass]="{
            selected: filterSelected(item, filter.fieldValue)
          }"
        >
          {{ item[filter.fieldText] | translate }}
        </button>
      </div>
    </div>
    <div *ngSwitchCase="filter.type == 'date-range'" class="filter-section">
      <div class="filter-section__title">
        {{ filter.title | translate }}
      </div>
      <div class="filter-section__dates">
        <ng-container *ngFor="let item of filter.options; let first = first">
          <input
            type="date"
            [name]="item.field"
            [placeholder]="item.placeholder | translate"
            [(ngModel)]="item[filter.fieldValue]"
            (input)="setDateRange(item, filter.fieldValue, filter.type)"
          />
          <span class="date-to" *ngIf="first">{{
            "EVENT.TO" | translate
          }}</span>
        </ng-container>
      </div>
    </div>
    <div *ngSwitchCase="filter.type == 'range'" class="filter-section">
      <div class="filter-section__title">
        {{ filter.title | translate }}
      </div>
      <div class="filter-section__range">
        <ng-container *ngFor="let item of filter.options; let first = first">
          <mat-slider
            [thumbLabel]="true"
            [displayWith]="displayLabel"
            min="0"
            max="180"
            step="1"
            [(ngModel)]="item[filter.fieldValue]"
            [name]="filter.fieldValue"
            (ngModelChange)="
              toggleValue(item, filter.fieldValue, filter.type, filter.multiple)
            "
          ></mat-slider>
          <div class="filter-section__range__labels">
            <span class="range-value">0h</span>
            <span class="range-value">180h</span>
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngSwitchCase="filter.type == 'tag'" class="filter-section">
      <div class="filter-section__title">
        {{ filter.title | translate }}
      </div>
      <div class="filter-section__tag">
        <div class="filter-section__search" *ngIf="!filter.hideSearchBox">
          <input
            type="text"
            placeholder="{{ 'EVENT.SEARCH' | translate }}"
            [(ngModel)]="filter.filterValue"
          />
          <img src="assets/icons/magnifying-glass-gray.svg" />
        </div>
        <section class="section-scrooler">
          <mat-checkbox
            *ngIf="!filter.hideSearchBox"
            class="example-margin"
            [checked]="allComplete"
            [color]="'primary'"
            [indeterminate]="someComplete(filter.options)"
            (change)="setAll($event.checked, filter.options)"
          >
            {{ "EVENT.SELECT_ALL" | translate }}
          </mat-checkbox>
          <mat-checkbox
            *ngFor="let item of getOptionsFiltered(filter)"
            [(ngModel)]="item.completed"
            [color]="'primary'"
            (ngModelChange)="updateAllComplete(item, filter)"
          >
            {{ item[filter.fieldText] | translate }}
          </mat-checkbox>
        </section>
      </div>
    </div>
    <div *ngSwitchCase="filter.type == 'image'" class="filter-section">
      <div class="filter-section__title">
        {{ filter.title | translate }}
      </div>
      <div class="filter-section__image">
        <div class="filter-section__search">
          <input
            type="text"
            placeholder="{{ 'EVENT.SEARCH' | translate }}"
            [(ngModel)]="filter.filterValue"
          />
          <img src="assets/icons/magnifying-glass-gray.svg" />
        </div>
        <cls-carousel [size]="'small'" *ngIf="filter.options.length">
          <section class="section-carrossel">
            <div
              class="image"
              *ngFor="let item of getOptionsFiltered(filter)"
              (click)="
                toggleValue(
                  item,
                  filter.fieldValue,
                  filter.type,
                  filter.multiple
                )
              "
              [ngClass]="{
                selected: filterSelected(item, filter.fieldValue)
              }"
            >
              <img
                *ngIf="!item.foto"
                class="profile-image"
                src="assets/imgs/profile-img.svg"
              />
              <div
                *ngIf="item.foto"
                class="profile-image"
                [style.background-image]="'url(' + item.foto + ')'"
              ></div>
              <span>{{ item[filter.fieldText] }}</span>
            </div>
          </section>
        </cls-carousel>
      </div>
    </div>
  </ng-container>

  <button class="btn btn-clear" (click)="limparFiltros()">
    {{ "EVENT.CLEAR_FILTERS" | translate }}
  </button>
  <button class="btn btn-apply" (click)="aplicarFiltros()">
    {{ "EVENT.APPLY_FILTERS" | translate }}
  </button>
</div>
