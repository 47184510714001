<div class="content flex-col" [ngClass]="isMobile ? 'content__mobile' : ''">
  <div *ngIf="!isLoading" class="content__validate-container flex-col">

    <div class="content__validate-container__header flex-col w-100 align-items-center">
        <img *ngIf="!requestError" class="content__validate-container__header__logo" [src]="data?.empresa?.imagem_logo_certificado" alt="">
        <div *ngIf="requestError" class="content__validate-container__header__logo"></div>
        <div class="content__validate-container__header__dot-group flex-row justify-content-center">
          <span class="dot bg-yellow"></span>
          <span class="dot bg-yellow"></span>
          <span class="dot bg-yellow"></span>
        </div>
    </div>

    <p class="content__validate-container__certificado-status">
      {{ message | translate }}
    </p>

    <div class="content__validate-container__card">
      <div class="flex-row justify-content-center">
        <img *ngIf="valido" src="assets/icons/check-white.svg" alt="" class="content__validate-container__card__circle bg-green">
        <img *ngIf="!valido"src="assets/icons/x.svg" alt="" class="content__validate-container__card__circle bg-red">
      </div>
      <div class="content__validate-container__card__body">
        <p *ngIf="requestError" class="text-center"><span class="content__validate-container__card__body__title">{{ 'CERTIFICATION.DATA_CHANGED' | translate }}</span></p>
        <div *ngIf="!requestError" class="group">
          <p class="content__validate-container__card__body__username">{{ data?.usuario?.nome_completo }}</p>
          <p><span class="content__validate-container__card__body__title">{{ 'CERTIFICATION.CPF' | translate }}</span> {{ data?.usuario?.cpf }}</p>
          <p><span class="content__validate-container__card__body__title">{{ 'CERTIFICATION.STATUS' | translate }}</span> {{ data?.status?.nome }}</p>
          <p><span class="content__validate-container__card__body__title">{{ 'CERTIFICATION.ACTIVITIES_PROGRESS' | translate }}</span> {{ data?.projetoTurmaEstudante?.perc_progresso_atv || 0 }}%</p>
          <p><span class="content__validate-container__card__body__title">{{ 'CERTIFICATION.COMPETENCE' | translate }}</span> {{ data?.competencia?.nome }}</p>
          <p><span class="content__validate-container__card__body__title">{{ 'CERTIFICATION.WORKLOAD' | translate }}</span> {{ data?.projetoTurma?.duracao }}</p>
          <p><span class="content__validate-container__card__body__title">{{ 'CERTIFICATION.CONCLUSION_DATE' | translate }}</span> {{ data?.usuarioCompetencia?.data_finalizacao | date: 'dd/MM/yyyy' }}</p>
        </div>
      </div>
    </div>

  </div>
  <img *ngIf="!isLoading && !requestError" class="content__logo-liga" [src]="data?.liga?.imagem_logo_certificado" alt="">

  <div class="content__loading-container" *ngIf="isLoading">
    <button class="btn btn-loading mb-1">{{ 'CERTIFICATION.LOADING' | translate }}</button>
  </div>
</div>
