import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GlobalService } from 'src/app/services/global-service/global.service';

@Component({
  selector: 'cls-hub-scrollable-chat',
  templateUrl: './hub-scrollable-chat.component.html',
  styleUrls: ['./hub-scrollable-chat.component.scss']
})
export class HubScrollableChatComponent implements OnInit {

  @Input() messages;
  @Output() reply = new EventEmitter;
  
  @ViewChild('attach') attach: ElementRef;

  fileName = null;
  file: FileList;
  uploadForm: FormGroup;

  newMessage = { mensagem: null, arquivo: null , tipo_arquivo: 'imagem' };

  constructor(private formBuilder: FormBuilder, private global: GlobalService) { }

  ngOnInit(): void {
    this.uploadForm = this.formBuilder.group({ arquivo: null });
  }

  attachFile(event){
    this.fileName = event.srcElement.files[0].name;
    this.file = event.target.files[0];
  }

  clickInputUpload() {
    let input: HTMLElement;
    input = document.querySelector('.ipt-file');
    input.click();
  }

  sendReply(){
    if(!this.file && !this.newMessage.mensagem){
      this.global.toast("Atenção!", "Você precisa enviar uma mensagem ou arquivo!");
      return;
    }

    let formData = new FormData();
    this.uploadForm.get('arquivo').setValue(this.file);

    if (this.uploadForm.get('arquivo').value) 
      formData.append('arquivo', this.uploadForm.get('arquivo').value, this.fileName);
    
    formData.append('mensagem', this.newMessage.mensagem)
    this.reply.emit(formData);
    this.clearInputFields();
  }

  clearInput(){
    this.file = null;
    this.fileName = null;
    if (this.attach)
      this.attach.nativeElement.value = "";
  }

  clearInputFields(){
    this.newMessage = { mensagem: null, arquivo: null , tipo_arquivo: 'imagem' };
    this.file = null;
    this.fileName = null;
    if (this.attach)
      this.attach.nativeElement.value = "";
  }

}
