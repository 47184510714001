import { Component, OnInit } from '@angular/core';
import { AppState } from 'src/app/state';
import { Store } from '@ngrx/store';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { ActivatedRoute, Data } from '@angular/router';
import { DataService } from 'src/app/services/data-service/data.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  state: any;
  stateSubscription: any;
  credentials = { password: "", passwordConfirmation: "", token: "" };
  alert = null;

  constructor(
    private store: Store<AppState>, 
    private route: ActivatedRoute,
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.getState();
    this.getParamsViaRoute();
  }

  validatePasswordWithRegex() {
    let ret = true;
    if (!this.credentials.password || this.credentials.password == '') 
      ret = false;
    else if (this.credentials.password && this.credentials.password.length < 6) 
      ret = false;
    else if (this.credentials.password !== this.credentials.passwordConfirmation)
      ret = false;
    return ret;
  }

  getParamsViaRoute() {
    this.route.paramMap.subscribe((params: any) => {
      this.credentials.token = params.params.token;
    });
  }

  getState() {
    this.stateSubscription = this.store.select((state: any) => {
      const { dadosEmpresa, loginLoading } = state.newAppState;
      this.state = { dadosEmpresa, loginLoading }
    }).subscribe();
  }

  setNewPassword() {
    if(!this.validatePasswordWithRegex())
      this.alert = true;
    else{
      this.alert = false;
      this.dataService.setNewPassword(this.credentials);
    }
  }

}
