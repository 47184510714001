<div class="avaliacao-discursiva-container">
  <!-- <div class='wificonnection'>
        <i *ngIf="online" class="fas fa-wifi good"></i>
        <i *ngIf="!online" class="fas fa-wifi bad"></i>
    </div> -->
  <cls-card-avaliacao
    (exit)="exit()"
    *ngIf="
      getAtualTentativa() === 'primeira' &&
      state?.avaliacaoStage === 'initial' &&
      !errorModal
    "
  >
    <div class="title mt-2 capitalize">
      {{ "AVALIACAO_DISCURSIVA.HOW_WORKS" | translate }}
    </div>
    <p class="capitalize">
      {{ "AVALIACAO_DISCURSIVA.HOW_WORKS_TEXT1" | translate }}
    </p>
    <p class="capitalize">
      {{ "AVALIACAO_DISCURSIVA.HOW_WORKS_TEXT2" | translate }}
    </p>
    <p class="capitalize">
      {{ "AVALIACAO_DISCURSIVA.HOW_WORKS_TEXT3" | translate }}
    </p>
    <p
      class="capitalize"
      *ngIf="state?.selectedQuizDiscursivo?.duracao_tentativa"
    >
      {{ "AVALIACAO_DISCURSIVA.HOW_WORKS_TEXT4" | translate
      }}{{ state?.selectedQuizDiscursivo?.duracao_tentativa }}
      {{ "AVALIACAO_DISCURSIVA.HOW_WORKS_TEXT4_1" | translate }}
      {{ state?.selectedQuizDiscursivo?.questoes_tentativa }}
      {{ "AVALIACAO_DISCURSIVA.HOW_WORKS_TEXT4_2" | translate }}
    </p>
    <p
      class="capitalize"
      *ngIf="!state?.selectedQuizDiscursivo?.duracao_tentativa"
    >
      {{ "AVALIACAO_DISCURSIVA.HOW_WORKS_TEXT4_3" | translate }}
      {{ state?.selectedQuizDiscursivo?.questoes_tentativa }}
      {{ "AVALIACAO_DISCURSIVA.HOW_WORKS_TEXT4_2" | translate }}
    </p>
    <p class="capitalize">
      <b>{{ "AVALIACAO_DISCURSIVA.HOW_WORKS_TEXT5" | translate }}</b>
    </p>
    <div class="btns">
      <button class="btn red mr-2" (click)="exit()">
        {{ "AVALIACAO_DISCURSIVA.NEED_TIME" | translate }}
      </button>
      <button
        *ngIf="state?.selectedQuizDiscursivo?.questoes_tentativa"
        class="btn ml-2"
        (click)="begin()"
      >
        {{ "AVALIACAO_DISCURSIVA.START" | translate }}
      </button>
    </div>
  </cls-card-avaliacao>
  <cls-card-avaliacao
    (exit)="exit()"
    *ngIf="
      getAtualTentativa() !== 'primeira' &&
      state?.avaliacaoStage === 'initial' &&
      !errorModal
    "
  >
    <div class="title mt-2 capitalize">
      {{ "AVALIACAO_DISCURSIVA.ANOTHER" | translate }}
      {{ getAtualTentativa() }}
      {{ "AVALIACAO_DISCURSIVA.MAKE_ATTEMPT2" | translate }}
    </div>
    <p class="capitalize">
      {{ "AVALIACAO_DISCURSIVA.HOW_WORKS_TEXT1" | translate }}
    </p>
    <p class="capitalize">
      {{ "AVALIACAO_DISCURSIVA.HOW_WORKS_TEXT3" | translate }}
    </p>
    <p class="capitalize">
      {{ "AVALIACAO_DISCURSIVA.HOW_WORKS_TEXT4" | translate }}
      {{ state?.selectedQuizDiscursivo?.duracao_tentativa }}
      {{ "AVALIACAO_DISCURSIVA.HOW_WORKS_TEXT4_1" | translate }}
      {{ state?.selectedQuizDiscursivo?.questoes_tentativa }}
      {{ "AVALIACAO_DISCURSIVA.HOW_WORKS_TEXT4_2" | translate }}
    </p>
    <div class="btns">
      <button class="btn red mr-2 upper" (click)="exit()">
        {{ "AVALIACAO_DISCURSIVA.NEED_TIME" | translate }}
      </button>

      <button
        *ngIf="state?.selectedQuizDiscursivo?.questoes_tentativa && !loading"
        class="btn ml-2 upper"
        (click)="begin()"
      >
        {{ "AVALIACAO_DISCURSIVA.MAKE_ATTEMPT1" | translate }}
        {{ getAtualTentativa() }}
        {{ "AVALIACAO_DISCURSIVA.MAKE_ATTEMPT2" | translate }}
      </button>
      <button *ngIf="loading" ng-disabled="loading" class="btn ml-2 upper">
        <div class="spinner-border spinner-border-md" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </button>
    </div>
  </cls-card-avaliacao>
  <cls-resposta-avaliacao
    *ngIf="
      state?.avaliacaoQuestao &&
      state?.avaliacaoStage === 'resposta' &&
      !errorModal &&
      state?.avaliacaoType === 'discursiva'
    "
    [questao]="state?.avaliacaoQuestao"
    [index]="state.avaliacaoIndex + 1"
    [timer]="timer"
    [total]="state?.selectedQuizDiscursivo?.questoes_tentativa"
    (next)="next()"
    (before)="before()"
    (exit)="exit()"
    (changedAnswer)="changeAnswer($event)"
    [savedAnswer]="state?.avaliacaoQuestao?.response"
    (revision)="congratsOrSave(getFormattedTimer(), true)"
  >
  </cls-resposta-avaliacao>
  <cls-resposta-avaliacao-quiz
    *ngIf="
      state?.avaliacaoQuestao &&
      state?.avaliacaoStage === 'resposta' &&
      !errorModal &&
      state?.avaliacaoType === 'quiz legado'
    "
    [questao]="state?.avaliacaoQuestao"
    [index]="state.avaliacaoIndex + 1"
    [timer]="timer"
    [total]="state?.selectedQuizDiscursivo?.questoes_tentativa"
    (next)="next()"
    (before)="before()"
    (exit)="exit()"
    (changedAnswer)="changeAnswer($event)"
    [savedAnswer]="state?.avaliacaoQuestao?.option"
    (revision)="congratsOrSave(getFormattedTimer(), true)"
  >
  </cls-resposta-avaliacao-quiz>
  <cls-resposta-avaliacao-quiz-novo
    *ngIf="
      state?.avaliacaoQuestao &&
      state?.avaliacaoStage === 'resposta' &&
      !errorModal &&
      state?.avaliacaoType === 'quiz novo'
    "
    [questao]="state?.avaliacaoQuestao"
    [index]="state.avaliacaoIndex + 1"
    [timer]="timer"
    [total]="state?.selectedQuizDiscursivo?.questoes_tentativa"
    (next)="next()"
    (before)="before()"
    (exit)="exit()"
    (changedAnswer)="changeAnswer($event)"
    [savedAnswer]="state?.avaliacaoQuestao?.option"
    (revision)="congratsOrSave(getFormattedTimer(), true)"
  >
  </cls-resposta-avaliacao-quiz-novo>
  <cls-card-avaliacao
    (exit)="exit()"
    *ngIf="state?.avaliacaoStage === 'congrats' && !errorModal"
    [hasBack]="false"
    [imageURL]="'assets/imgs/popup4.jpg'"
  >
    <div class="congrats-title capitalize">
      {{ "AVALIACAO_DISCURSIVA.CONGRATS" | translate }}
    </div>
    <div
      class="congrats-description capitalize"
      *ngIf="
        state?.selectedQuizDiscursivo.data_revisao_inicio ||
        state.selectedQuizDiscursivo.data_revisao_final
      "
    >
      {{ "AVALIACAO_DISCURSIVA.REVIEW1" | translate }}
      <div *ngIf="state?.selectedQuizDiscursivo.data_revisao_inicio">
        <span>
          {{
            state.selectedQuizDiscursivo.data_revisao_inicio
              | date : "dd/MM/yyyy"
          }}
          {{ prepDate }}
          {{
            state.selectedQuizDiscursivo.data_revisao_inicio | date : "HH:mm"
          }}
        </span>
      </div>
      <div>
        {{ "AVALIACAO_DISCURSIVA.REVIEW2" | translate }}
        <span *ngIf="state?.selectedQuizDiscursivo.data_revisao_final">
          {{
            state.selectedQuizDiscursivo.data_revisao_final
              | date : "dd/MM/yyyy"
          }}
          {{ prepDate }}
          {{ state.selectedQuizDiscursivo.data_revisao_final | date : "HH:mm" }}
        </span>
      </div>
    </div>
    <div class="flex-row">
      <button class="btn close-congrats upper" (click)="exit()">
        {{ "AVALIACAO_DISCURSIVA.CLOSE" | translate }}
      </button>
      <button
        *ngIf="showRevisao"
        class="btn close-congrats upper"
        (click)="showResult()"
      >
        {{ "AVALIACAO_DISCURSIVA.SHOW_GRADE" | translate }}
      </button>
    </div>
  </cls-card-avaliacao>
  <app-revisao-quiz-novo
    (exit)="exit()"
    *ngIf="state?.avaliacaoStage === 'result'"
    [idAvaliacaoEstudante]="
      state?.selectedQuizDiscursivo?.avaliacaoEstudante[0]
        ?.id_avaliacao_estudante
    "
    [idAvaliacaoEstudanteTentativa]="idAvaliacaoEstudanteTentativaFinalizada"
  >
    <button class="btn close-congrats upper" (click)="exit()">
      {{ "AVALIACAO_DISCURSIVA.CLOSE" | translate }}
    </button>
  </app-revisao-quiz-novo>
  <cls-card-avaliacao (exit)="exit()" *ngIf="errorModal">
    <div class="title mt-2 capitalize">{{ "GENERAL.ERROR" | translate }}</div>
    <p>{{ displayedError }}</p>
    <div class="btns">
      <button class="btn red mr-2 upper" (click)="exit()">
        {{ "AVALIACAO_DISCURSIVA.EXIT" | translate }}
      </button>
      <button class="btn ml-2 upper" *ngIf="canRetry" (click)="restart()">
        {{ "AVALIACAO_DISCURSIVA.RESTART" | translate }}
      </button>
    </div>
  </cls-card-avaliacao>
</div>
