import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PerguntaPesquisaNps } from 'src/app/models/pesquisa-nps.model';

@Component({
  selector: 'cls-pergunta-discursiva',
  templateUrl: './pergunta-discursiva.component.html',
  styleUrls: ['./pergunta-discursiva.component.scss']
})
export class PerguntaDiscursivaComponent implements OnInit {
  @Input() pergunta: PerguntaPesquisaNps;
  @Input() naoRespondida: boolean
  @Output() respostaEvent = new EventEmitter<string> ();

  constructor() { }

  ngOnInit(): void {
  }

  handleInput(event) {
    this.respostaEvent.emit(event.target.value);
  }

}
