<ng-container *ngIf="openModalNotificacao">
  <div class="overlay" (click)="closeModel(); $event.stopPropagation()">
    <div class="box-notify" (click)="$event.stopPropagation()">
      <div
        class="close-pattern"
        (click)="closeModel(); $event.stopPropagation()">
        <i class="fas fa-times pointer"></i>
      </div>
      <div>
        <div>
          <img *ngIf="data.banner" [src]="data.banner" class="capa" />
          <div class="head-mensagem">
            <span class="titulo">
              {{ data.titulo | translate }}
            </span>
            <div [ngSwitch]="locale">
              <span *ngSwitchCase="'pt-br'" class="date_time">{{
                data.notificacao_usuario_data_envio | date : "dd/MM/yyyy | HH:mm"
              }}h</span>
              <span *ngSwitchCase="'en-us'" class="date_time">{{
                data.notificacao_usuario_data_envio | date : "MM/dd/YYYY | h:mm a"
              }}h</span>
            </div>
            <div class="message-text">
              <span
                *ngIf="data?.texto_rico"
                [innerHTML]="data.texto_rico | sanitizeHtml"
              ></span>
              <span *ngIf="!data?.texto_rico">
                <p>{{ data?.mensagem }}</p>
              </span>
            </div>
            <div class="btn-notify" *ngIf="data?.link || event">
              <button
                *ngIf="data?.link"
                class="btn-blue"
                (click)="goToLink(data.link)"
                data-cy="NotificationBtnOpen">
                {{ "NOTIFICATIONS.OPEN" | translate }}
              </button>
              <button
                *ngIf="event"
                class="btn-blue"
                (click)="openEvento()"
                data-cy="NotificationBtnOpen">
                {{ "EVENTO" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="openModalEvent">
  <div class="overlay" (click)="closeModel(); $event.stopPropagation()">
    <div class="box-event" (click)="$event.stopPropagation()">
      <div
        class="close-pattern"
        (click)="closeModel(); $event.stopPropagation()">
          <i class="fas fa-times pointer"></i>
      </div>
      <div>
        <div *ngIf="event.capa_evento">
          <img [src]="event.capa_evento" class="capa" />
        </div>
        <div class="content">
          <span class="content__nome">{{ event.nome }}</span>
          <div class="content__data">
            <div class="content__data__box">
              <span class="content__data__box__dia">{{ dateFormated.dia }}</span>
              <span class="content__data__box__mes">{{ dateFormated.mes }}</span>
            </div>
            <div class="content__data__hora">
              <span>{{ dateFormated.diaSemana }}</span>
              <span>{{ dateFormated.hora }}</span>
            </div>
          </div>
          <div class="tag-tipo">
            <div
              [ngClass]="{
                'tag-tipo__online': event.canal_tipo != 1,
                'tag-tipo__presencial': event.canal_tipo == 1
              }"
            >
              <span>{{ tipoEvento | translate }}</span>
            </div>
            <div class="tag-tipo__atividade">
              <span
                >{{ atividade | translate }} |
                {{ event.atividade_complementar_hora }}h</span
              >
            </div>
          </div>
          <div class="address">
            <ng-container *ngIf="event.canal_tipo != 1">
              <span class="local">{{ event.canal_nome }}</span>
            </ng-container>
            <ng-container *ngIf="event.canal_tipo == 1">
              <span class="local">{{ event.local }}</span>
              <span class="address__street">{{ event.endereco }}</span>
            </ng-container>
          </div>
          <div class="about">
            <span class="about__title">Sobre o evento</span>
            <p class="about__description">{{ event.descricao }}</p>
          </div>
          <div *ngIf="event.palestrantes" class="speaker">
            <div *ngFor="let palestrante of event.palestrantes" class="speaker__box">
              <div>
                <img
                  *ngIf="!palestrante.foto"
                  src="../../../assets/imgs/profile-img.jpg"
                  class="speaker__box__foto"
                />
                <img
                  *ngIf="palestrante.foto"
                  [src]="palestrante.foto"
                  class="speaker__box__foto"
                />
              </div>
              <div class="speaker__box__perfil">
                <div class="speaker__box__perfil__nome">{{ palestrante.nome }}</div>
                <div class="speaker__box__perfil__cargo">
                  {{ palestrante.titulacao_cargo }}
                </div>
                <div class="speaker__box__perfil__minibio">
                  {{ palestrante.minibio }}
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="event.assuntos_abordados" class="subject">
            <span class="subject__title">Assuntos abordados</span>
            <div
              class="subject__body"
              [innerHtml]="event.assuntos_abordados | sanitizeHtml"
            ></div>
          </div>
          <div class="btn-event">
            <ng-container *ngIf="event.agenda == false">
              <button class="btn-blue" (click)="agendarEvento()">
                {{ btnInscrever | translate }}
              </button>
            </ng-container>
            <ng-container
              *ngIf="event.agenda == true && event.canal_tipo != 1 && !releaseLink"
            >
              <button class="btn-blue" id="watch" disabled (click)="goToEvent()">
                {{ btnAssistir | translate }}
              </button>
            </ng-container>
            <ng-container
              *ngIf="event.agenda == true && event.canal_tipo != 1 && releaseLink"
            >
              <button class="btn-blue" (click)="goToEvent()">
                {{ btnAssistir | translate }}
              </button>
            </ng-container>
            <ng-container
              *ngIf="event.agenda == true && event.canal_tipo == 1"
            >
              <button class="btn-blue" disabled>
                {{ btnPresencial | translate }}
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
