import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './state';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private store: Store<AppState>) { }

  state: any;
  stateSubscription: any;

  ngOnInit() {
    this.getState();
  }

  getState() {
    this.stateSubscription = this.store.select((state: any) => {
      const { usuario, toast, documentViewerUrl, imageViewerUrl } = state.newAppState;
      this.state = { usuario, toast, documentViewerUrl, imageViewerUrl }
    }).subscribe();
  }
}
