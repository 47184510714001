import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { GlobalService } from "src/app/services/global-service/global.service";
@Component({
  selector: "cls-resposta-avaliacao",
  templateUrl: "./resposta-avaliacao.component.html",
  styleUrls: ["./resposta-avaliacao.component.scss"],
})
export class RespostaAvaliacaoComponent implements OnInit {
  @Input() index;
  @Input() questao;
  @Input() total;
  @Input() timer;
  @Input() savedAnswer;
  @Input() hasBack = true;

  @Output() next = new EventEmitter();
  @Output() before = new EventEmitter();
  @Output() revision = new EventEmitter();
  @Output() changedAnswer = new EventEmitter();
  @Output() exit = new EventEmitter();

  answer = [];
  bars = [];
  isOpenModal: boolean = false;

  constructor(
    public global: GlobalService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.mountBars();
    this.questao = Object.assign({}, this.questao);
    if (this.savedAnswer) this.answer[this.index - 1] = this.savedAnswer;
  }

  mountBars() {
    this.bars = [];
    for (let i = 0; i < this.total; i++) {
      if (i < this.index) this.bars.push(true);
      else this.bars.push(false);
    }
  }

  emitNext() {
    this.next.emit(true);
  }

  emitBefore() {
    this.before.emit(true);
  }

  emitRevision() {
    this.revision.emit(true);
  }

  emitAnswer(event: any) {
    this.changedAnswer.emit({ questao: this.questao, answer: this.answer[this.index-1]});
  }

  emitExit() {
    this.exit.emit(true);
  }

  getTinyMCEConfigs() {
    let defaultConfig = this.global.getTinyMCEDefaultConfigs();
    return {
      ...defaultConfig,
      placeholder: this.translate.instant("QUESTIONARIO_AVALIACAO.WRITE"),
    };
  }

  openModal(): void {
    if (!this.answer[this.index-1]) this.isOpenModal = true;
    else this.confirmModal();
  }

  closeModal(): void {
    this.isOpenModal = false;
  }

  confirmModal(): void {
    if (this.index === this.total) this.emitRevision();
    else this.emitNext();
  }
}
