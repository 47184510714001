<div class="projeto-component">
  <div
    class="description font-weight-bold mt-3"
    *ngIf="state?.projetoAtivo?.nome?.length <= 130"
    [innerHtml]="state?.projetoAtivo?.nome"
  ></div>
  <div
    class="description font-weight-bold mt-3"
    *ngIf="state?.projetoAtivo?.nome?.length > 130"
    [innerHtml]="state?.projetoAtivo?.nome?.slice(0, 130) + '...'"
  ></div>
  <div class="nome-disciplina font-italic">
    <div class="marcador"></div>
    {{ this.state.projetoAtivo.nome_disciplina }}
  </div>
  <!-- <div class="nome-tutor mt-4" *ngIf="state?.projetoAtivo?.projetoTurmaTutor[0]?.nome">
        <div class="perfil-box">
            <img *ngIf="!state?.projetoAtivo?.projetoTurmaTutor[0]?.foto" src="assets/imgs/profile-img.svg">
            <img *ngIf="state?.projetoAtivo?.projetoTurmaTutor[0]?.foto" [src]=state?.projetoAtivo?.projetoTurmaTutor[0]?.foto>
            <div class="ml-2">{{state?.projetoAtivo?.projetoTurmaTutor[0]?.nome_social?.toLowerCase()}}</div> 
        </div> 
    </div> -->
  <button
    class="btn-more mt-4 no-border pointer upper"
    (click)="
      state && state.projetoAtivo
        ? launchSaibaMais($event)
        : $event.stopPropagation()
    "
  >
    <ng-container *ngIf="state && state.projetoAtivo">
      {{ "PROJECT.UNDERSTAND_PROJECT" | translate }}
      <span class="icon-container float-right cadeado-container">
        <img
          *ngIf="
            today < state?.projetoAtivo?.data_inicio &&
            state.estudante.id_projeto_turma_estudante_status !== 8
          "
          src="../../../assets/icons/icone_cadeado_branco.svg"
          alt=""
          srcset=""
        />
        <!-- <i *ngIf="today >= state?.projetoAtivo?.data_inicio || state.estudante.id_projeto_turma_estudante_status === 8" class="fas fa-plus"></i> -->
        <img
          *ngIf="
            today >= state?.projetoAtivo?.data_inicio ||
            state.estudante.id_projeto_turma_estudante_status === 8
          "
          class="width-30"
          src="assets/icons/fas-fa-plus.svg"
          alt=""
        />
      </span>
    </ng-container>
    <div *ngIf="!state || !state.projetoAtivo" class="text-center loader">
      <div
        class="spinner-border spinner-border-md text-secondary"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </button>
</div>
<cls-saiba-mais
  *ngIf="
    today >= state?.projetoAtivo?.data_inicio ||
    state.estudante.id_projeto_turma_estudante_status === 8
      ? state?.showSaibaMais
      : ''
  "
></cls-saiba-mais>
