<div *ngIf="isLoading" class="text-center">
  <div class="spinner-border spinner-border-md" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<div *ngIf="!isLoading">
  <div class="entrega-container" *ngIf="avaliacaoFinalizada">
    <div class="delivered">
      <img src="assets/imgs/upload-entrega-blue.png">
      {{tentativaRespondida.mensagem}}
    </div>
  </div>
  <div *ngFor="let arquivo of tentativaRespondida?.tentativa.arquivos">
    <app-download-arquivo
      [urlArquivo]="arquivo.url"
      [nomeArquivo]="arquivo.nome_arquivo"
      [maximoDeCaracteres]="35"
    ></app-download-arquivo>
  </div>
  <div *ngIf="tentativaRespondida?.tentativa?.feedback?.length > 0">
    <hr>
    <h1>Feedback do professor</h1>
    <div class="feedback" *ngFor="let feedback of tentativaRespondida.tentativa.feedback; index as i;">
      <h2>Feedback {{i + 1}}</h2>
      <p *ngIf="feedback?.mensagem">{{feedback?.mensagem}}</p>
      <div class="div-btn" *ngIf="feedback.url_anexo">
        <button class="btn liga-btn upper">
          <a [href]="feedback.url_anexo">{{ 'BUTTON.DOWNLOAD_FILE' | translate }}</a>
        </button>
      </div>
    </div>
  </div>
</div>
