import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { GlobalService } from "src/app/services/global-service/global.service";

@Component({
  selector: "cls-resposta-avaliacao-quiz",
  templateUrl: "./resposta-avaliacao-quiz.component.html",
  styleUrls: ["./resposta-avaliacao-quiz.component.scss"],
})
export class RespostaAvaliacaoQuizComponent implements OnInit {
  @Input() index;
  @Input() questao;
  @Input() total;
  @Input() timer;
  @Input() savedAnswer;
  @Input() hasBack = true;

  @Output() next = new EventEmitter();
  @Output() before = new EventEmitter();
  @Output() revision = new EventEmitter();
  @Output() changedAnswer = new EventEmitter();
  @Output() exit = new EventEmitter();

  answer = [];
  bars = [];
  selectedOption: string = null;
  isOpenModal: boolean = false;

  constructor(public global: GlobalService) {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.selectedOption = null;
    this.mountBars();
    this.questao = Object.assign({}, this.questao);
    if (this.savedAnswer) this.selectedOption = this.savedAnswer;
  }

  markAnswer(option) {
    this.selectedOption = option;
    this.emitAnswer();
  }

  mountBars() {
    this.bars = [];
    for (let i = 0; i < this.total; i++) {
      if (i < this.index) this.bars.push(true);
      else this.bars.push(false);
    }
  }

  emitNext() {
    this.next.emit(true);
  }

  emitBefore() {
    this.before.emit(true);
  }

  emitRevision() {
    this.revision.emit(true);
  }

  emitAnswer() {
    this.changedAnswer.emit({
      questao: this.questao,
      answer: this.selectedOption,
    });
  }

  emitExit() {
    this.exit.emit(true);
  }

  openModal(): void {
    if (!this.savedAnswer) this.isOpenModal = true;
    else this.confirmModal();
  }

  closeModal(): void {
    this.isOpenModal = false;
  }

  confirmModal(): void {
    if (this.index === this.total) this.emitRevision();
    else this.emitNext();
  }
}
