import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { DataService } from 'src/app/services/data-service/data.service';
import { AppState } from 'src/app/state';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Subscription } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
	selector: 'cls-modal-evento',
	templateUrl: './modal-evento.component.html',
	styleUrls: ['./modal-evento.component.scss'],
})
export class ModalEventoComponent implements OnInit {
	constructor(
    public global: GlobalService,
    private translate: TranslateService,
    private dataService: DataService,
    private store: Store<AppState>
	) {}

  @Input() data: any;
  @Output() close = new EventEmitter();
  tipoEvento: string;
  atividade = 'EVENT.ACTIVITY';
  btnInscrever = 'EVENT.SUBSCRIBE';
  btnAssistir = 'EVENT.WATCH';
  btnPresencial = 'EVENT.IN_PERSON';
  subscriptions: Subscription[] = [];
  state: any;
  today = moment();
  releaseLink = false;
  dateFormated = {
  	dia: null,
  	mes: null,
  	diaSemana: null,
  	hora: null,
  };
  showPalestrantesGroup = false;

  ngOnInit() {
  	document.querySelector('html')?.setAttribute('style', 'overflow:hidden');
  	this.getTipoEvento();
  	this.getState();
  	this.checkDateToWatch();
  	this.getFormatDate();
  	this.setShowDataPalestrantes();
  }

  ngOnDestroy() {
  	this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  	document.querySelector('html')?.setAttribute('style', 'overflow:auto');
  }

  getState() {
  	const subscription = this.store
  		.select((state: any) => {
  			const { usuario } = state.newAppState;
  			this.state = {
  				usuario,
  			};
  		})
  		.subscribe();
  	this.subscriptions.push(subscription);
  }

  openEvent(url) {
  	window.open(url, 'event');
  }

  closeModel() {
  	this.close.emit(true);
  }

  getTipoEvento() {
  	const canal = this.data.canal_tipo;
  	switch (canal) {
  	case 1:
  		this.tipoEvento = 'EVENT.IN_PERSON';
  		break;
  	default:
  		this.tipoEvento = 'EVENT.ONLINE';
  	}
  }

  setShowDataPalestrantes(): void {
  	console.log(this.data);
  	const hasPalestrantes =
      this.data.palestrantes && this.data.palestrantes.length;
  	const someHasName = this.data.palestrantes.some(
  		(palestrante) => palestrante.nome
  	);

  	this.showPalestrantesGroup = hasPalestrantes && someHasName;
  }

  agendarEvento() {
  	const type = 1; // type 1 indica criar agenda
  	const subscription = this.dataService
  		.agendarOuAcessarEvento(
  			this.data.id_evento,
  			this.state.usuario.id_usuario,
  			type
  		)
  		.pipe(
  			tap((data) => {
  				if (data.jaAgendado) {
  					this.global.toast(
  						this.translate.instant('EVENT.ALREADY_SCHEDULED'),
  						''
  					);
  					this.data.agenda = true;
  				} else if (data.inscricoesEsgotadas) {
  					this.global.toast(this.translate.instant('EVENT.SOLD_OUT'), '');
  					this.data.inscritos = data.item.inscritos;
  				} else {
  					this.global.toast(
  						this.translate.instant('EVENT.TOAST_SCHEDULE'),
  						''
  					);
  					this.data.agenda = true;
  				}
  			}),
  			finalize(() => this.checkDateToWatch()),
  			catchError((error) => {
  				this.global.toast(
  					this.translate.instant('GENERAL.ERROR'),
  					error.error.message
  				);
  				return EMPTY;
  			})
  		)
  		.subscribe();
  	this.subscriptions.push(subscription);
  }

  goToEvent() {
  	const type = 2; //type 2 indica registro primeiro acesso ao evento
  	if (this.data && !this.data.agenda_data_acesso) {
  		const subscription = this.dataService
  			.agendarOuAcessarEvento(
  				this.data.id_evento,
  				this.state.usuario.id_usuario,
  				type
  			)
  			.subscribe();
  		this.subscriptions.push(subscription);
  	}
  	window.open(this.data.link);
  }

  checkDateToWatch() {
  	const inicio = moment(this.data.data_inicio).subtract(15, 'minutes');
  	if (this.today.isAfter(inicio)) this.releaseLink = true;

  	this.data.data_fim = moment(this.data.data_fim).format();
  }

  getFormatDate() {
  	const dataInicio = new Date(this.data.data_inicio);
  	const dataFim = new Date(this.data.data_fim);
  	const meses = [
  		'Jan',
  		'Fev',
  		'Mar',
  		'Abr',
  		'Mai',
  		'Jun',
  		'Jul',
  		'Ago',
  		'Set',
  		'Out',
  		'Nov',
  		'Dez',
  	];
  	const horaInit = dataInicio.getHours();
  	const minutesInit = this.formatDate(dataInicio.getMinutes());
  	const horaEnd = dataFim.getHours();
  	const minutesEnd = this.formatDate(dataFim.getMinutes());
  	const diaNome = dataInicio.toLocaleDateString('pt-BR', { weekday: 'long' });

  	const diaI = dataInicio.getDate();
  	const diaF = dataFim.getDate();
  	const mesF = meses[dataFim.getMonth()];

  	this.dateFormated.mes = meses[dataInicio.getMonth()];
  	this.dateFormated.dia =
      dataInicio.getDate() < 10
      	? `0${dataInicio.getDate()}`
      	: dataInicio.getDate();
  	this.dateFormated.diaSemana =
      diaNome.charAt(0).toUpperCase() + diaNome.slice(1);

  	if (diaI === diaF)
  		this.dateFormated.hora = `${horaInit}:${minutesInit}h às ${horaEnd}:${minutesEnd}h`;
  	if (diaI !== diaF) {
  		this.dateFormated.hora = `${horaInit}:${minutesInit}h até ${diaF} de ${mesF} às ${horaEnd}:${minutesEnd}h`;
  	}
  }
  formatDate(minutes) {
  	return minutes < 10 ? `0${minutes}` : minutes.toString();
  }
}
