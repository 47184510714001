<div class="modal fade show" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header-pesquisa">
                <div class="flex-end">
                    <img src="assets/icons/close-pesquisa.svg" alt="" class="close" (click)="closeModal();$event.stopPropagation()">
                </div>
                <div class="check-bg-blue">
                    <img src="assets/icons/check-pesquisa-white.svg">
                </div>
            </div>
            <div class="modal-body">
                <span class="span-body font-black">{{ 'NPS_SURVEY.OPINION' | translate }}</span>
                <span class="span-body bold-blue">{{ 'NPS_SURVEY.THANK_YOU' | translate }}</span>
            </div>
        </div>
    </div>
</div>