
export class DataUtil {
  // converte o numero em nome do mês
  pegarMesEmPt(mes: number) {
    switch (mes) {
      case 0:
        return 'Janeiro';
        break;
      case 1:
        return 'Fevereiro';
        break;
      case 2:
        return 'Março';
        break;
      case 3:
        return 'Abril';
        break;
      case 4:
        return 'Maio';
        break;
      case 5:
        return 'Junho';
        break;
      case 6:
        return 'Julho';
        break;
      case 7:
        return 'Agosto';
        break;
      case 8:
        return 'Setembro';
        break;
      case 9:
        return 'Outubro';
        break;
      case 10:
        return 'Novembro';
        break;
      case 11:
        return 'Dezembro';
        break;
    }
  }

  pegarMesEmEn(mes: number) {
    switch (mes) {
      case 0:
        return 'January';
        break;
      case 1:
        return 'February';
        break;
      case 2:
        return 'March';
        break;
      case 3:
        return 'April';
        break;
      case 4:
        return 'May';
        break;
      case 5:
        return 'June';
        break;
      case 6:
        return 'July';
        break;
      case 7:
        return 'August';
        break;
      case 8:
        return 'September';
        break;
      case 9:
        return 'October';
        break;
      case 10:
        return 'November';
        break;
      case 11:
        return 'December';
        break;
    }
  }

  urlToAnchors(message: string): string {
    let regexUrl = /((?:http(s)?:\/\/)?(www\.)?([-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b)([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)?)/gm
    const result = message.replace(regexUrl, '<a href="http$2://$3$4$5" rel="noopener noreferrer" target="_blank">$1</a>');
    return result
  }
}
