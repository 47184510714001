import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cls-circle',
  templateUrl: './circle.component.html',
  styleUrls: ['./circle.component.scss']
})
export class CircleComponent implements OnInit {

  @Input() value: any;
  @Input() percentage: any;
  @Input() size: any;
  @Input() textColor: any;
  @Input() strokeFill: any;

  constructor() {}

  ngOnInit() {
    if(!this.value && this.percentage)
      this.value = this.percentage;
  }

  calculateStroke(val){
    return val - (val * this.percentage)/100;
  }

}
