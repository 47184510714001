<div
  class="modal fade show"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="d-flex justify-content-between">
          <h5 class="modal-title capitalize" id="exampleModalLabel">
            {{ "CONFIGURATIONS_EDIT.IMG" | translate }}
          </h5>
          <!-- <i
            class="fas fa-times pointer"
            (click)="closeModal(); $event.stopPropagation()"
          ></i> -->
          <img
            class="close-pattern"
            src="assets/icons/fas-fa-times-pointer.svg"
            (click)="closeModal(); $event.stopPropagation()"
          />
        </div>
        <div class="d-flex align-items-center col-10 mt-3 mb-4">
          <div
            class="user"
            *ngIf="state?.usuario?.foto"
            [style.background-image]="
              global.generateImageURL(state?.usuario?.foto)
            "
          ></div>
          <div
            class="user"
            *ngIf="!state?.usuario?.foto"
            [style.background-image]="
              global.generateImageURL('assets/imgs/profile-img.svg')
            "
          ></div>
          <div
            class="input-label d-flex align-items-center pointer ml-2"
            (click)="callInput()"
          >
            <span *ngIf="!photoName" class="capitalize">{{
              "CONFIGURATIONS_EDIT.IMG_TEXT" | translate
            }}</span>
            <span *ngIf="photoName && photoName.length < 15">{{
              photoName
            }}</span>
            <span *ngIf="photoName && photoName.length >= 15"
              >{{ photoName | slice : 0 : 15 }}...</span
            >
            <img src="../../../assets/icons/icone_imagem.svg" />
          </div>
          <input
            type="file"
            class="d-none upload-picture"
            (change)="prepareUpload($event)"
          />
        </div>
        <button
          type="button"
          (click)="uploadPicture(); $event.stopPropagation()"
          class="btn btn-primary float-right"
        >
          <span *ngIf="!state?.photoLoading" class="upper">{{
            "CONFIGURATIONS_EDIT.BUTTON" | translate
          }}</span>
          <div *ngIf="state?.photoLoading" class="text-center">
            <div class="spinner-border spinner-border-md" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </button>
        <div class="divisor"></div>
        <h5 class="modal-title mb-3" id="exampleModalLabel" class="capitalize">
          {{ "CONFIGURATIONS_EDIT.PASS" | translate }}
        </h5>
        <input
          type="password"
          [(ngModel)]="changePass.oldpassword"
          class="input-label capitalize mb-2"
          placeholder="{{ 'CONFIGURATIONS_EDIT.PASS_TEXT1' | translate }}"
        />
        <input
          type="password"
          [(ngModel)]="changePass.newpassword"
          class="input-label capitalize mb-2"
          placeholder="{{ 'CONFIGURATIONS_EDIT.PASS_TEXT2' | translate }}"
        />
        <input
          type="password"
          [(ngModel)]="changePass.againpassword"
          class="input-label capitalize mb-2"
          placeholder="{{ 'CONFIGURATIONS_EDIT.PASS_TEXT3' | translate }}"
        />
        <div>
          <button
            type="button"
            (click)="tryChangePassoword()"
            class="btn btn-primary float-right mt-3 upper"
          >
            {{ "CONFIGURATIONS_EDIT.BUTTON" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
