import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { AppState } from 'src/app/state';
import { avaliacaoState } from 'src/app/state/state.actions';

@Component({
  selector: 'cls-gabarito-questao',
  templateUrl: './gabarito-questao.component.html',
  styleUrls: ['./gabarito-questao.component.scss']
})
export class GabaritoQuestaoComponent implements OnInit {

  @Input() questao: any;
  @Input() index: any;
  @Input() avaliacao: any;
  resultado: any;
  state: any;
  entregaTurma: any;
  isLoading: boolean;
  isOpen = false;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    public global: GlobalService
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.getState();
  }

  exit() {
    this.store.dispatch(avaliacaoState({ payload: null }));
    this.router.navigate(['projeto/' + this.state.projetoAtivo.id_projeto_turma + '/fase/' + this.state.faseAtual.id_fase_turma]);
  }
  getState() {
    this.store.select((state: any) => {
      const { projetoAtivo, faseAtual, selectedQuizDiscursivo } = state.newAppState;
      this.state = { faseAtual, projetoAtivo, selectedQuizDiscursivo };
      if (this.state.faseAtual && this.state.faseAtual.entregaTurma[0]){
        let arrayTemp = [];
        arrayTemp = this.state.faseAtual.entregaTurma.filter(entrega => entrega.id_entrega_turma == this.avaliacao.id_entrega_turma )
        this.entregaTurma = arrayTemp[0]
      }
    }).subscribe();
  }
}
