<div class="atendimento-box-container">
    <div class="main-title">
        <span class="upper">{{ 'ATENDIMENTO.ATTENDANCE' | translate }}</span>
    </div>
    <div class="form-row form-aluno d-flex justify-content-between mt-4">
        <div class="form-group col-5">
            <label class="upper">{{ 'ATENDIMENTO.SERVICE' |  translate }}</label>
            <select class="form-control" [(ngModel)]="atendimento.id_suporte_categoria">
                <option selected disabled value="undefined">{{ 'ATENDIMENTO.SERVICE' |  translate }}</option>
                <option value="4">{{ 'ATENDIMENTO.FINANCIAL' |  translate }}</option>
                <option value="3">{{ 'ATENDIMENTO.OTHERS' |  translate }}</option>
            </select>
        </div>
        <div class="form-group col-5">
            <label class="upper">{{ 'ATENDIMENTO.TITLE' |  translate }}</label>
            <input type="text" [(ngModel)]="atendimento.assunto" class="form-control" placeholder="{{ ATENDIMENTO.TITLE_WRITE' | translate }}">
        </div>
    </div>
    <div class="form-row form-aluno mt-3">
        <div class="form-group col-12">
            <label class=" upper">{{ 'ATENDIMENTO.MESSAGE' |  translate }}</label>
            <textarea class="form-control" [(ngModel)]="atendimento.mensagem" placeholder="{{ 'ATENDIMENTO.MESSAGE' |  translate }}"></textarea>
        </div>
    </div>
    <div class="file" (click)="clickInput()">
        <img *ngIf="!file" src="assets/icons/paperclip.svg">
        <img class="uploaded" *ngIf="file" src="assets/icons/uploaded.png" (click)="clearFile();$event.stopPropagation()">
        <input type="file" #fileAtendimento class="d-none file-atendimento" (change)="sendFileToDOM($event)">
        <span *ngIf="!fileName upper">{{ 'ATENDIMENTO.ATTACH' |  translate }}</span>
        <span *ngIf="fileName && fileName.length >= 50">{{fileName | slice:0:50}}...</span>
        <span *ngIf="fileName && fileName.length < 50">{{fileName}}</span>
    </div>
    <button class="btn btn-send upper" (click)="emitForm()">{{ 'BUTTON.SEND' | translate }}</button>
</div>