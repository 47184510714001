import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { fillToast } from 'src/app/state/state.actions';

@Component({
  selector: 'cls-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {

  @Input() data;

  state: any;
  stateSubscription: any;
  popups = [
    'assets/imgs/popup1.jpg',
    'assets/imgs/popup2.jpg',
    'assets/imgs/popup3.jpg',
    'assets/imgs/popup4.jpg',
    'assets/imgs/popup5.jpg',
    'assets/imgs/popup6.jpg',
    'assets/imgs/popup7.jpg',
    'assets/imgs/popup8.jpg',
    'assets/imgs/popup9.jpg'
  ];
  randomImg = '';

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    this.getState();
    this.getRandomImage();
    this.automaticClose();
  }

  getState() {
    this.stateSubscription = this.store.select((state: any) => {
      const { usuario, toast } = state.newAppState;
      this.state = { usuario, toast }
    }).subscribe();
  }

  getRandomImage(){
    this.randomImg = this.popups[Math.floor(Math.random() * this.popups.length)];
  }

  automaticClose(){
    setTimeout(() => {
      this.close();
    }, 35000);
  }

  close(){
    this.store.dispatch(fillToast({ payload: null}));
  }

}
