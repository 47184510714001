import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { fillConfirmModal } from 'src/app/state/state.actions';

@Component({
  selector: 'cls-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  stateSubscription: any;
  state: any;

  @Output() confirmed = new EventEmitter();

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    this.getState();
  }

  getState() {
    this.stateSubscription = this.store.select((state: any) => {
      const { confirmModal } = state.newAppState;
      this.state = { confirmModal }
    }).subscribe();
  }

  emitConfirm(){
    this.confirmed.emit(true);
  }

  close(){
    this.store.dispatch(fillConfirmModal({ payload: null }))
  }
}
