import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data-service/data.service';
import { GlobalService } from 'src/app/services/global-service/global.service';

@Component({
	selector: 'cls-projeto-card',
	templateUrl: './projeto-card.component.html',
	styleUrls: ['./projeto-card.component.scss'],
})
export class ProjetoCardComponent implements OnInit {
  @Input() data;
  @Input() polos;
  growBox = false;
  projetoPolo = null;

  constructor(
    private router: Router,
    private dataService: DataService,
    public global: GlobalService
  ) {}

  ngOnInit() {
  	this.getProjetoPolo();
  }

  getProjetoPolo() {
  	if (!this.data?.estudante?.id_polo) return;
  	if (this.polos && this.polos.length > 0)
  		this.projetoPolo = this.polos.find(
  			(polo) => polo.id_polo === this.data.estudante.id_polo
  		);
  }

  goToHome() {
  	this.dataService.setInitialDataAndNavigate(this.data);
  	this.router.navigate(['projeto/' + this.data.id_projeto_turma]);
  	this.dataService.interacoesTurmaRoutine();
  }

  changeSize(event) {
  	if (event.type == 'mouseover') this.growBox = true;
  	else this.growBox = false;
  }

  extractPercentage(percentage = null) {
  	if (percentage == null) return '0';
  	else return percentage.toString();
  }

  addString(html, slice) {
  	return html.slice(0, slice) + '...';
  }
}
