import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { fillImageViewerUrl } from 'src/app/state/state.actions';
@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {
  state: any;
  stateSubscription$: any;

  constructor(private store: Store<AppState>) { }

  ngOnDestroy(): void {
    this.stateSubscription$.unsubscribe()
  }

  ngOnInit(): void {
    this.getState();
  }

  getState(): void {
    this.stateSubscription$ = this.store.select((state: any) => {
      this.state = state.newAppState;
    }).subscribe();
  }


  closeSaibaMais(): void {
    setTimeout(() => {
      this.store.dispatch(fillImageViewerUrl({ payload: '' }))
      document.querySelector('body').removeAttribute('class');
    }, 100);
  }

  handleClickOutside(event): void {
    // setTimeout(() => {
    //   this.closeSaibaMais();
    // }, 300);
  }
}
