<!--ATIVIDADES NORMAIS-->
<div class="atividade-container" *ngIf="state?.atividadeAtual">
  <!--CONTEUDO-->
  <div
    class="content-fase-container"
    [class.closed-menu]="!state?.showMenuFase"
    *ngIf="state?.atividadeAtual.tipo == 'texto'"
  >
    <div class="title-container">
      <div class="main-data">
        <label>{{ state.atividadeAtual.titulo }}</label>
      </div>
    </div>
    <div
      class="conteudo"
      [innerHtml]="state.atividadeAtual.descricao | sanitizeHtml"
    ></div>
  </div>
  <!--ATIVIDADES LTI-->
  <div
    class="content-fase-container"
    [class.closed-menu]="!state?.showMenuFase"
    *ngIf="state?.atividadeAtual.tipo == 'lti'"
  >
    <div class="title-container">
      <div class="main-data">
        <label>{{ state.atividadeAtual.titulo }}</label>
      </div>
    </div>
    <div
      class="conteudo"
      [innerHtml]="state.atividadeAtual.descricao | sanitizeHtml"
    ></div>
    <button
      type="button"
      class="btn btn-avaliacao"
      (click)="openAtividadeLti(state?.atividadeAtual)"
    >
      {{ "CONTENT_FASE.OPEN_LTI" | translate }}
    </button>
  </div>
  <!--CHAT-->
  <div
    class="content-fase-container"
    [class.closed-menu]="!state?.showMenuFase"
    *ngIf="state?.atividadeAtual && state?.atividadeAtual.tipo == 'mesa'"
  >
    <div class="title-container">
      <div class="main-data">
        <label>{{ state.atividadeAtual.titulo }}</label>
      </div>
    </div>
    <div
      class="conteudo"
      [innerHtml]="state.atividadeAtual.descricao | sanitizeHtml"
    ></div>
    <button
      type="button"
      class="btn btn-avaliacao"
      (click)="goToChat(state?.atividadeAtual)"
    >
      {{ "CONTENT_FASE.OPEN_CHAT" | translate }}
    </button>
  </div>
  <!--QUIZ-->
  <div
    class="content-fase-container"
    [class.closed-menu]="!state?.showMenuFase"
    *ngIf="state?.atividadeAtual && state?.atividadeAtual.tipo == 'quiz'"
  >
    <div class="title-container">
      <div class="main-data">
        <label>{{ state.atividadeAtual.titulo }}</label>
      </div>
    </div>
    <div class="conteudo">
      <cls-quiz [quizArray]="state?.atividadeAtual.quizTurma"></cls-quiz>
    </div>
  </div>
  <!--CONCLUIR ATIVIDADE-->
  <div class="centered">
    <div class="borda-curtir">
      <div class="concluir-atividade">
        <div class="header">
          <img src="assets/icons/check-white.svg" alt="" class="bg-blue" />
          <h5 class="title">
            {{ "CONTENT_FASE.CONGRATULATIONS" | translate }}
          </h5>
        </div>
        <div class="button-row">
          <button
            class="btn-concluir-atividade"
            *ngIf="
              !state?.atividadeAtual?.atividadeEstudanteTurma[0]?.concluido
            "
            (click)="
              loading
                ? $event.stopPropagation()
                : concluirAtividade(
                    state?.atividadeAtual,
                    state?.atividadeAtual.id_objetivo_aprendizagem
                  )
            "
          >
            <span *ngIf="!loading">{{
              "CONTENT_FASE.COMPLETE_ACTIVITY" | translate
            }}</span>
            <div *ngIf="loading" class="text-center">
              <div class="spinner-border spinner-border-md" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </button>
        </div>
        <div
          class="button-row"
          *ngIf="
            objetivoAprendizagemAtual &&
            (objetivoAprendizagemAtual.previousAtividade ||
              objetivoAprendizagemAtual.nextAtividade)
          "
        >
          <div
            *ngIf="
              !objetivoAprendizagemAtual ||
              !objetivoAprendizagemAtual.previousAtividade
            "
            class="btn-proxima-atividade-empty"
          ></div>
          <button
            *ngIf="
              objetivoAprendizagemAtual &&
              objetivoAprendizagemAtual.previousAtividade
            "
            class="btn-proxima-atividade"
            (click)="
              loading
                ? $event.stopPropagation()
                : setAtividadeAtual(objetivoAprendizagemAtual.previousAtividade)
            "
          >
            <img
              class="chevron"
              style="transform: rotate(90deg)"
              src="assets\icons\fas-fa-chevron-down-white.svg"
            />
            {{ "CONTENT_FASE.PREVIOUS" | translate }}
          </button>
          <div
            *ngIf="
              !objetivoAprendizagemAtual ||
              !objetivoAprendizagemAtual.nextAtividade
            "
            class="btn-proxima-atividade-empty"
          ></div>
          <button
            *ngIf="
              objetivoAprendizagemAtual &&
              objetivoAprendizagemAtual.nextAtividade
            "
            class="btn-proxima-atividade"
            (click)="
              loading
                ? $event.stopPropagation()
                : setAtividadeAtual(objetivoAprendizagemAtual.nextAtividade)
            "
          >
            {{ "CONTENT_FASE.NEXT" | translate }}
            <img
              class="chevron"
              style="transform: rotate(-90deg)"
              src="assets\icons\fas-fa-chevron-down-white.svg"
            />
          </button>
        </div>
        <span class="btn-duvida" (click)="toggleInteracoesVisibility()">
          {{ "CONTENT_FASE.DOUBTS" | translate }}
        </span>
      </div>
    </div>
  </div>
  <!--FEEDBACK-->
  <!-- <div class="feedback">
    <div [ngSwitch]="state?.atividadeAtual.tipo">
      <label *ngSwitchCase="'texto'">{{ 'CONTENT_FASE.FEEDBACK_TEXT'| translate }}</label>
      <label *ngSwitchCase="'quiz'">{{ 'CONTENT_FASE.FEEDBACK_QUIZ'| translate }}</label>
      <label *ngSwitchDefault>{{ 'CONTENT_FASE.FEEDBACK_DEFAULT'| translate }}</label>
    </div>
    <div class="button-row">
      <button class="btn btn-like"
        (click)="loading ? $event.stopPropagation() : likeAtividade(state?.atividadeAtual, state?.atividadeAtual.id_objetivo_aprendizagem)"
      >
        <img class="thumbs" [src]="(state?.atividadeAtual.atividadeEstudanteTurma.length > 0 && state?.atividadeAtual.atividadeEstudanteTurma[0].curti) ? '/assets/icons/thumbs-up-solid-white.svg' : '/assets/icons/thumbs-up-white.svg'">
        <div class="divider"></div>
        <span>{{ 'CONTENT_FASE.LIKE'| translate }}</span>
      </button>
      <button class="btn btn-dislike"
      (click)="loading ? $event.stopPropagation() : deslikeAtividade(state?.atividadeAtual, state?.atividadeAtual.id_objetivo_aprendizagem)"
      >
        <img class="thumbs" [src]="(state?.atividadeAtual.atividadeEstudanteTurma.length > 0 && state?.atividadeAtual.atividadeEstudanteTurma[0].nao_curti) ? '/assets/icons/thumbs-down-solid-white.svg' : '/assets/icons/thumbs-down-white.svg'">
        <div class="divider"></div>
        <span>{{ 'CONTENT_FASE.DISLIKE'| translate }}</span>
      </button>
    </div>
  </div> -->
</div>
<!-- FASE TURMA -->
<div
  class="content-fase-container"
  [class.closed-menu]="!state?.showMenuFase"
  *ngIf="
    !state?.atividadeAtual && state?.faseAtual && !state?.showEntregaArquivo
  "
>
  <div class="title-container">
    <div class="main-data">
      <label>{{ state.faseAtual.nome }}</label>
    </div>
  </div>
  <!-- <div class="conteudo" [innerHtml]="state?.faseAtual.descricao | sanitizeHtml"></div> -->
</div>
<!-- FASE TURMA -->
<!-- ENTREGA ARQUIVO -->
<div
  class="content-fase-container"
  [class.closed-menu]="!state?.showMenuFase"
  *ngIf="state?.showEntregaArquivo && !state?.atividadeAtual"
>
  <div class="title-container">
    <div class="main-data">
      <label class="capitalize">{{ "CONTENT_FASE.TITLE" | translate }}</label>
    </div>
  </div>
  <div
    *ngFor="
      let entrega of state?.faseAtual?.entregaTurma | orderBy : 'ordenacao'
    "
  >
    <div [ngSwitch]="entrega?.tipoEntregaTurma?.nome?.toLowerCase()">
      <div class="conteudo">
        <div *ngSwitchCase="'arquivo'">
          <ui-avaliacao
            [nota]="entrega?.avaliacaoEstudante[0]?.nota"
            [notaMaxima]="entrega?.nota_maxima"
            [tipoAvaliacao]="entrega?.tipoEntregaTurma.nome"
            [numeroTentativas]="entrega?.tentativas"
            [tentativasUtilizadas]="
              entrega?.avaliacaoEstudante[0]?.tentativa.length
            "
            [titulo]="entrega?.titulo"
            [descricaoAvaliacao]="entrega?.item_entrega_turma"
            [dataRevisaoInicio]="entrega?.data_revisao_inicio"
            [dataRevisaoFinal]="entrega?.data_revisao_final"
            [dataEntregaInicio]="entrega?.data_entrega_inicio"
            [dataEntregaFim]="entrega?.data_entrega"
          >
            <form [formGroup]="formArquivos">
              <app-upload-lista-arquivos
                (changeForm)="changedForm(entrega, $event)"
                (tusURL)="updateURLs($event)"
                (removeURL)="removeURLs($event)"
                (click)="$event.stopPropagation()"
                *ngIf="!entrega?.avaliacaoEstudante[0]?.finalizada"
                [limiteDeArquivos]="entrega?.numero_arquivos"
                [index]="entrega?.id_entrega_turma"
                [clearFiles]="clearFiles"
              ></app-upload-lista-arquivos>
              <button
                *ngIf="
                  !entrega?.avaliacaoEstudante[0]?.finalizada &&
                  entrega?.arquivos?.length > 0
                "
                [disabled]="loading || avaliacaoBotao"
                [ngClass]="{ 'btn-avaliacao-notallow': avaliacaoBotao }"
                (click)="enviarArquivos(entrega)"
                class="btn btn-anexar"
              >
                <span *ngIf="!loading" class="upper">{{
                  "CONTENT_FASE.SEND" | translate
                }}</span>
                <div *ngIf="loading" class="text-center">
                  <div class="spinner-border spinner-border-md" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </button>
            </form>
            <app-accordion-tentativa
              *ngFor="
                let tentativa of entrega?.avaliacaoEstudante[0]?.tentativa;
                let i = index
              "
              [tentativa]="tentativa"
              [numeroTentativa]="i + 1"
              [showNota]="tentativa.nota ? true : false"
              [showRevisao]="entrega?.showRevisao"
            >
              <app-arquivo-avaliacao-aluno
                [avaliacaoEstudante]="tentativa.id_avaliacao_estudante"
                [idAvaliacaoEstudanteTentativa]="
                  tentativa.id_avaliacao_estudante_tentativa
                "
                [showNota]="showNota"
                [avaliacaoFinalizada]="tentativa?.finalizada"
                [avaliacaoEstudanteObj]="tentativa"
              >
              </app-arquivo-avaliacao-aluno>
            </app-accordion-tentativa>
          </ui-avaliacao>
        </div>
        <!-- FIM ARQUIVO -->
        <!-- <div class="conteudo">
          <div [ngSwitchCase]="'sem entrega'">
            <ui-avaliacao [nota]="entrega?.avaliacaoEstudante[0]?.entregaTurma?.nota_maxima"
            [tipoAvaliacao]="entrega?.tipoEntregaTurma.nome" [numeroTentativas]="entrega?.tentativas"
            [tentativasUtilizadas]="entrega?.avaliacaoEstudante[0]?.tentativa.length" [titulo]="entrega?.titulo"
            [dataRevisaoInicio]="entrega?.data_revisao_inicio" [dataRevisaoFinal]="entrega?.data_revisao_final">
            {{entrega?.item_entrega_turma}}
          </ui-avaliacao>
          </div>
        </div> -->
        <!-- INICIO QUIZ -->
        <div *ngSwitchCase="'quiz legado'">
          <!-- AVALIACOES -->
          <ui-avaliacao
            *ngFor="let avaliacao of entrega?.avaliacaoEstudante; let j = index"
            [nota]="avaliacao?.nota"
            [notaMaxima]="entrega?.nota_maxima"
            [tipoAvaliacao]="'Quiz'"
            [numeroTentativas]="entrega?.tentativas"
            [tentativasUtilizadas]="avaliacao?.tentativa?.length"
            [titulo]="entrega?.titulo"
            [descricaoAvaliacao]="entrega?.item_entrega_turma"
            [dataRevisaoInicio]="entrega?.data_revisao_inicio"
            [dataRevisaoFinal]="entrega?.data_revisao_final"
            [dataEntregaInicio]="entrega?.data_entrega_inicio"
            [dataEntregaFim]="entrega?.data_entrega"
          >
            <div
              class="tentativa-card mt-4 mb-4"
              *ngFor="let tentativa of avaliacao?.tentativa; let i = index"
            >
              <div
                class="tentativa-header d-flex flex-row justify-content-between align-items-center align-items-baseline capitalize"
              >
                <p
                  [class.tentativa-title]="isGreaterGrade(avaliacao, tentativa)"
                  class="capitalize"
                >
                  {{ "CONTENT_FASE.ATTEMPT" | translate }}
                  {{ i + 1 | number : "2.0-0" }}
                </p>
                <hr />
                <span
                  *ngIf="tentativa?.data_finalizacao"
                  class="capitalize"
                  [class.grade]="isGreaterGrade(avaliacao, tentativa)"
                >
                  {{ "CONTENT_FASE.NOTE" | translate }}
                  {{ tentativa?.nota | number : "1.0-2" }}
                </span>
                <div
                  class="btn-expand ml-4"
                  *ngIf="entrega?.showRevisao"
                  [class.rotate-180]="tentativa.showResult"
                  (click)="
                    showResult(
                      tentativa,
                      entrega?.avaliacaoEstudante[0].id_avaliacao_estudante
                    )
                  "
                >
                  <!-- <i class="fas fa-chevron-down"></i> -->
                  <img
                    class="chevron"
                    src="assets\icons\fas-fa-chevron-down-white.svg"
                  />
                </div>
              </div>
              <span *ngIf="tentativa?.data_finalizacao" class="capitalize"
                >{{ "CONTENT_FASE.DELIVERED" | translate }}
                {{ tentativa?.data_finalizacao | date : "dd/MM/yy" }} às
                {{ tentativa?.data_finalizacao | date : "HH:mm" }}</span
              >
              <span *ngIf="!tentativa?.data_finalizacao" class="capitalize">{{
                "CONTENT_FASE.ATTEMPT_PROGRESS" | translate
              }}</span>

              <div *ngIf="tentativa.showResult">
                <app-gabarito-avaliacao
                  [avaliacaoEstudante]="
                    entrega?.avaliacaoEstudante[0].id_avaliacao_estudante
                  "
                  [idAvaliacaoEstudanteTentativa]="
                    tentativa.id_avaliacao_estudante_tentativa
                  "
                >
                </app-gabarito-avaliacao>
              </div>
            </div>
            <button
              class="btn btn-avaliacao"
              *ngIf="!avaliacao?.finalizada"
              [ngClass]="{ 'btn-avaliacao-notallow': avaliacaoBotao }"
              [disabled]="avaliacaoBotao"
              (click)="goToQuizDiscursivo(entrega, avaliacao)"
            >
              <span class="capitalize">{{
                "CONTENT_FASE.TEST" | translate
              }}</span>
            </button>
          </ui-avaliacao>
        </div>
        <div *ngSwitchCase="'discursiva'">
          <ui-avaliacao
            *ngFor="let avaliacao of entrega?.avaliacaoEstudante; let j = index"
            [nota]="avaliacao?.nota"
            [notaMaxima]="entrega?.nota_maxima"
            [tipoAvaliacao]="entrega?.tipoEntregaTurma.nome"
            [numeroTentativas]="entrega?.tentativas"
            [tentativasUtilizadas]="avaliacao?.tentativa?.length"
            [titulo]="entrega?.titulo"
            [descricaoAvaliacao]="entrega?.item_entrega_turma"
            [dataRevisaoInicio]="entrega?.data_revisao_inicio"
            [dataRevisaoFinal]="entrega?.data_revisao_final"
            [dataEntregaInicio]="entrega?.data_entrega_inicio"
            [dataEntregaFim]="entrega?.data_entrega"
          >
            <div
              class="tentativa-card discursiva mt-4 mb-4"
              *ngFor="let tentativa of avaliacao?.tentativa; let i = index"
            >
              <div
                class="tentativa-header d-flex flex-row justify-content-between align-items-center align-items-baseline"
              >
                <p class="tentativa-title capitalize">
                  {{ "CONTENT_FASE.REVIEW" | translate }}
                </p>
                <hr />
                <span
                  *ngIf="tentativa?.data_finalizacao"
                  class="capitalize"
                  [class.grade]="isGreaterGrade(avaliacao, tentativa)"
                >
                  {{ "CONTENT_FASE.NOTE" | translate }}
                  {{ tentativa?.nota | number : "1.0-2" }}
                </span>
                <div
                  class="btn-expand ml-4"
                  [class.rotate-180]="tentativa.showResult"
                  (click)="
                    getTentativasDiscursiva(
                      tentativa,
                      entrega?.avaliacaoEstudante[0].id_avaliacao_estudante
                    )
                  "
                >
                  <!-- <i class="fas fa-chevron-down"></i> -->
                  <img
                    class="chevron"
                    src="assets\icons\fas-fa-chevron-down-white.svg"
                  />
                </div>
              </div>
              <span *ngIf="tentativa?.data_finalizacao" class="capitalize"
                >{{ "CONTENT_FASE.DELIVERED" | translate }}
                {{ tentativa?.data_finalizacao | date : "dd/MM/yy" }} às
                {{ tentativa?.data_finalizacao | date : "HH:mm" }}</span
              >
              <span *ngIf="!tentativa?.data_finalizacao" class="capitalize">{{
                "CONTENT_FASE.ATTEMPT_PROGRESS" | translate
              }}</span>

              <div *ngIf="tentativa.showResult">
                <cls-gabarito-questao
                  *ngFor="let questao of tentativasDiscursiva; let i = index"
                  [avaliacao]="avaliacao"
                  [questao]="questao"
                  [index]="i + 1"
                >
                </cls-gabarito-questao>
              </div>
            </div>
            <button
              class="btn btn-avaliacao"
              *ngIf="!avaliacao?.finalizada"
              [ngClass]="{ 'btn-avaliacao-notallow': avaliacaoBotao }"
              [disabled]="avaliacaoBotao"
              (click)="goToQuizDiscursivo(entrega, avaliacao)"
            >
              <span class="capitalize">{{
                "CONTENT_FASE.TEST" | translate
              }}</span>
            </button>
          </ui-avaliacao>
        </div>
        <!-- FIM QUIZ -->
        <!-- NOVO - SEM ENTREGA -->
        <div *ngSwitchCase="'sem entrega'">
          <ui-avaliacao
            [tipoAvaliacao]="entrega?.tipoEntregaTurma.nome"
            [titulo]="entrega?.titulo"
            [descricaoAvaliacao]="entrega?.item_entrega_turma"
            [dataEntregaInicio]="entrega?.data_entrega_inicio"
            [dataEntregaFim]="entrega?.data_entrega"
          >
            <app-accordion-tentativa
              *ngFor="
                let tentativa of entrega?.avaliacaoEstudante[0]?.tentativa;
                let i = index
              "
              [tentativa]="tentativa"
              [showNota]="tentativa.nota ? true : false"
              [showRevisao]="entrega?.showRevisao"
            >
              <app-arquivo-avaliacao-aluno
                [avaliacaoEstudante]="tentativa.id_avaliacao_estudante"
                [idAvaliacaoEstudanteTentativa]="
                  tentativa.id_avaliacao_estudante_tentativa
                "
                [showNota]="showNota"
                [avaliacaoFinalizada]="tentativa?.finalizada"
                [avaliacaoEstudanteObj]="tentativa"
              >
              </app-arquivo-avaliacao-aluno>
            </app-accordion-tentativa>
          </ui-avaliacao>
        </div>
        <!-- ANTIGO - SEM ENTREGA -->
        <!-- <div class="avaliacao-card position-relative">
            <div class="quiz-icon position-absolute">
              <i class="far far-2x fa-check-square"></i>
              <img src="assets/icons/fa-check-square.svg" alt="">
            </div> -->
        <!-- <div class="avaliacao-header d-flex flex-row justify-content-between align-items-baseline"> -->
        <!-- <p class="avaliacao-heading capitalize"> {{ 'CONTENT_FASE.TITLE' | translate }}</p>
              <hr> -->
        <!-- </div> -->
        <!-- <p class="text-center capitalize">{{ 'CONTENT_FASE.NULL' | translate }}</p> -->
        <div *ngSwitchCase="'quiz novo'">
          <!-- AVALIACOES -->
          <ui-avaliacao
            *ngFor="let avaliacao of entrega?.avaliacaoEstudante; let j = index"
            [nota]="avaliacao?.nota"
            [notaMaxima]="entrega?.nota_maxima"
            [tipoAvaliacao]="'Quiz'"
            [numeroTentativas]="entrega?.tentativas"
            [tentativasUtilizadas]="avaliacao?.tentativa?.length"
            [titulo]="entrega?.titulo"
            [descricaoAvaliacao]="entrega?.item_entrega_turma"
            [dataRevisaoInicio]="entrega?.data_revisao_inicio"
            [dataRevisaoFinal]="entrega?.data_revisao_final"
            [dataEntregaInicio]="entrega?.data_entrega_inicio"
            [dataEntregaFim]="entrega?.data_entrega"
          >
            <div
              class="tentativa-card mt-4 mb-4"
              *ngFor="let tentativa of avaliacao?.tentativa; let i = index"
            >
              <div
                class="tentativa-header d-flex flex-row justify-content-between align-items-center align-items-baseline capitalize"
              >
                <p
                  [class.tentativa-title]="isGreaterGrade(avaliacao, tentativa)"
                  class="capitalize"
                >
                  {{ "CONTENT_FASE.ATTEMPT" | translate }}
                  {{ i + 1 | number : "2.0-0" }}
                </p>
                <hr />
                <span
                  *ngIf="tentativa?.data_finalizacao && entrega?.nota_maxima"
                  class="capitalize"
                  [class.grade]="isGreaterGrade(avaliacao, tentativa)"
                >
                  {{ "CONTENT_FASE.NOTE" | translate }}
                  {{ tentativa?.nota | number : "1.0-2" }}
                </span>
                <div
                  class="btn-expand ml-4"
                  *ngIf="entrega?.showRevisao"
                  [class.rotate-180]="tentativa.showResult"
                  (click)="
                    showResult(
                      tentativa,
                      entrega?.avaliacaoEstudante[0].id_avaliacao_estudante
                    )
                  "
                >
                  <!-- <i class="fas fa-chevron-down"></i> -->
                  <img
                    class="chevron"
                    src="assets\icons\fas-fa-chevron-down-white.svg"
                  />
                </div>
              </div>
              <span *ngIf="tentativa?.data_finalizacao" class="capitalize"
                >{{ "CONTENT_FASE.DELIVERED" | translate }}
                {{ tentativa?.data_finalizacao | date : "dd/MM/yy" }} às
                {{ tentativa?.data_finalizacao | date : "HH:mm" }}</span
              >
              <span *ngIf="!tentativa?.data_finalizacao" class="capitalize">{{
                "CONTENT_FASE.ATTEMPT_PROGRESS" | translate
              }}</span>

              <div *ngIf="tentativa.showResult">
                <app-gabarito-quiz-novo
                  [idAvaliacaoEstudante]="
                    entrega?.avaliacaoEstudante[0].id_avaliacao_estudante
                  "
                  [idAvaliacaoEstudanteTentativa]="
                    tentativa.id_avaliacao_estudante_tentativa
                  "
                >
                </app-gabarito-quiz-novo>
              </div>
            </div>
            <button
              class="btn btn-avaliacao"
              *ngIf="!avaliacao?.finalizada"
              [ngClass]="{ 'btn-avaliacao-notallow': avaliacaoBotao }"
              [disabled]="avaliacaoBotao"
              (click)="goToQuizDiscursivo(entrega, avaliacao)"
            >
              <span class="capitalize">{{
                "CONTENT_FASE.TEST" | translate
              }}</span>
            </button>
          </ui-avaliacao>
        </div>
      </div>
    </div>
  </div>
  <cls-modal-pesquisa
    *ngIf="state?.showModalPesquisa"
    [pesquisa]="pesquisaNps"
    [id_projeto_turma_estudante]="state.estudante.id_projeto_turma_estudante"
    (closeModal)="closeModalPesquisa()"
  ></cls-modal-pesquisa>
  <cls-modal-pesquisa-sucesso
    *ngIf="state?.showModalPesquisaSucesso"
  ></cls-modal-pesquisa-sucesso>
</div>
