import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppState } from 'src/app/state';
import { Store } from '@ngrx/store';
import { showSaibaMais } from 'src/app/state/state.actions';

@Component({
	selector: 'cls-projeto',
	templateUrl: './projeto.component.html',
	styleUrls: ['./projeto.component.scss'],
})
export class ProjetoComponent implements OnInit, OnDestroy {
	state: any;
	stateSubscription$: any;
	today: any;

	constructor(private store: Store<AppState>) {}
	ngOnDestroy(): void {
		this.stateSubscription$.unsubscribe();
	}

	ngOnInit() {
		this.getState();
	}

	getState() {
		this.stateSubscription$ = this.store
			.select((state: any) => {
				this.state = state.newAppState;
				this.today = new Date().toISOString().slice(0, 19);
			})
			.subscribe();
	}

	launchSaibaMais(event) {
		event.stopPropagation();
		this.store.dispatch(showSaibaMais({ payload: true }));
		document.querySelector('body').setAttribute('class', 'no-scrollbar');
	}
}
