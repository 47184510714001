<div class="menu-container">
  <!-- <div class="title-box">
        <div class="title upper" [class.light]="!state?.atividadeAtual && !state?.showEntregaArquivo" (click)="clearAtividadeAtual()">
            {{ 'MENU_FASE.WHAT_LEARN' | translate }}
            <i class="fas fa-chevron-right small-chevron"></i>
            <img src="assets/icons/fas-fa-chevron-right.svg" class="small-chevron">
        </div>
    </div> -->
  <div class="big-box upper">
    {{ "MENU_FASE.ACTIVITIES" | translate }}
  </div>
  <div
    class="sub-title"
    [class.no-border]="i == state?.faseAtual.objetivosAprendizagem.length - 1"
    *ngFor="
      let objetivo of state?.faseAtual.objetivosAprendizagem
        | orderBy : 'id_objetivo_aprendizagem';
      let i = index
    "
    (click)="showAtividades(objetivo)"
  >
    <div class="title-container">
      <cls-circle
        class="pt-2"
        [size]="'small'"
        [value]="i + 1"
        [percentage]="calculatePercentage(objetivo)"
      ></cls-circle>
      <div class="main">
        <div class="break-word" [innerHtml]="objetivo.nome"></div>
      </div>
      <!-- <i class="fas big-chevron" [class.fa-chevron-down]="!objetivo?.show_atividades"
        [class.fa-chevron-up]="objetivo?.show_atividades"></i> -->
      <img
        [src]="
          !objetivo?.show_atividades
            ? 'assets/icons/fas-big-chevron-fa-chevron-down.svg'
            : 'assets/icons/fas-big-chevron-fa-chevron-up.svg'
        "
      />
    </div>
    <div
      class="atividades"
      [class.trans-height]="objetivo?.show_atividades"
      (click)="$event.stopPropagation()"
    >
      <div
        class="atividade"
        [class.p-trans]="i == 0"
        [class.marked]="isMarked(atividade)"
        *ngFor="let atividade of objetivo.atividadeRoteiroTurma; let i = index"
        (click)="setAtividadeAtual(atividade); $event.stopPropagation()"
      >
        <div
          class="check"
          (click)="
            toggleAtividade(atividade, objetivo); $event.stopPropagation()
          "
          [class.checked]="isChecked(atividade)"
        >
          <i class="fas fa-check" *ngIf="objetivo.show_icon"></i>
        </div>
        <span class="break-word">{{ atividade.titulo }}</span>
        <i class="fas fa-chevron-right small-chevron"></i>
        <!-- <img src="assets/icons/fas-fa-chevron-right.svg" class="small-chevron"> -->
      </div>
    </div>
  </div>
  <div class="divisor" *ngIf="state?.showEntregaArquivo"></div>
  <!-- TODO: VERIFICAR EXISTENCIA DESSES [0] -->
  <div
    class="big-box pointer upper"
    [class.light]="state?.showEntregaArquivo"
    (click)="
      showAvaliacao(
        state?.faseAtual?.entregaTurma[0]?.tipoEntregaTurma?.nome === 'Quiz'
      )
    "
  >
    {{ "MENU_FASE.AVALIATION" | translate }}
    <!-- <i class="fas fa-chevron-right small-chevron"></i> -->
    <img src="assets/icons/fas-fa-chevron-right.svg" class="small-chevron" />
  </div>
  <div class="side-detail" (click)="toggleMenu()">
    <div class="shadow-detail"></div>
    <div class="close-menu">
      <img
        [class.rotate]="!state?.showMenuFase"
        src="../../../assets/icons/chevrons-left.svg"
      />
    </div>
    <div class="shadow-detail"></div>
  </div>
</div>
