import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data-service/data.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { entregaLoading, showModalFeedback } from 'src/app/state/state.actions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cls-fase-details',
  templateUrl: './fase-details.component.html',
  styleUrls: ['./fase-details.component.scss']
})
export class FaseDetailsComponent implements OnInit, OnDestroy {

  objetivosArray: any;
  entregasArray: any;
  state: any;
  idFase: string;
  fileName = null;
  file: FileList;
  uploadForm: FormGroup;
  loadingEntrega = false;

  @ViewChild('uploadEntrega') uploadEntrega: ElementRef;
  stateSubscription$: any;

  constructor(
    private globalService: GlobalService,
    private store: Store<AppState>,
    private activedRoute: ActivatedRoute,
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private global: GlobalService,
    private translate: TranslateService
  ) { }
  ngOnDestroy(): void {
    this.stateSubscription$.unsubscribe()
  }

  ngOnInit() {
    this.activedRoute.url.subscribe(url => {
      this.getState();
      this.globalService.vimeo();
    });
    /// Removido devido à não necessidade desta requisição ser feita. Ao selecionar o projeto essa chamada já é feita e o resultado armazenado no state
    // this.dataService.getAndSetEntregasAluno();
    this.uploadForm = this.formBuilder.group({
      arquivo: null
    });
    this.globalService.getMessage().subscribe(message => {
      this.clearInputFile();
    });
  }

  goToFeedback() {
    if (!this.state.faseAtual.feedbackEntrega || this.state.faseAtual.feedbackEntrega.length == 0)
      this.global.toast("Atenção!", "Seu tutor ainda não enviou nenhuma resposta para a sua entrega!");
    else if (this.state.faseAtual.feedbackEntrega && this.state.faseAtual.feedbackEntrega.length > 0)
      this.showFeedbackModal();
  }

  showFeedbackModal() {
    this.store.dispatch(showModalFeedback({ payload: true }));
  }

  callUpload() {
    if (this.state.faseAtual.faseAtividadesAlunoComcluidas)
      this.clickInputUpload();
    //else 
      //.onTap.subscribe(data => this.clickInputUpload())
  }

  clickInputUpload() {
    let input: HTMLElement;
    input = document.querySelector('.input-upload');
    input.click();
  }

  uploadFile(event) {
    this.fileName = event.srcElement.files[0].name;
    this.file = event.target.files[0];
  }

  clearInputFile() {
    if (this.uploadEntrega) {
      this.uploadEntrega.nativeElement.value = '';
      this.fileName = null;
      this.file = null;
    }
  }

  prepareEntrega() {
    this.uploadForm.get('arquivo').setValue(this.file);
    let formData = new FormData();
    if (this.uploadForm.get('arquivo').value) {
      this.store.dispatch(entregaLoading({ payload: true }));
      formData.append('arquivo', this.uploadForm.get('arquivo').value, this.fileName);
      this.dataService.sendEntrega(formData);
    } else if (this.state.faseAtual.arquivoEstudanteFaseTurma)
      this.global.toast(this.translate.instant("GENERAL.ERROR"), this.translate.instant("GENERAL.DELIVERED"));
    else
      this.global.toast(this.translate.instant("GENERAL.ERROR"),this.translate.instant("GENERAL.NEED_FILE"));
  }

  getState() {
    this.stateSubscription$ = this.store.select((state: any) => {
      this.state = state.newAppState;
      sessionStorage.setItem('state', JSON.stringify(state.newAppState));
    }).subscribe();
  }
}