import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-download-arquivo',
  templateUrl: './download-arquivo.component.html',
  styleUrls: ['./download-arquivo.component.scss']
})
export class DownloadArquivoComponent {

  @Input() nomeArquivo: string;
  @Input() urlArquivo: string;
  @Input() maximoDeCaracteres: number;

  constructor() { }

  downloadEntrega(url) {
    window.open(url, 'Download');
  }

  reduzirTamanhoDoNome(nomeArquivo: string) {
    if (nomeArquivo.length >= this.maximoDeCaracteres) {
      return nomeArquivo.slice(0, this.maximoDeCaracteres) + '...';
    }
    return nomeArquivo;
  }

}
