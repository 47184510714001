import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarContentComponent } from './sidebar-content.component';
import {ClickOutsideModule} from 'ng-click-outside';



@NgModule({
  declarations: [SidebarContentComponent],
  imports: [
    CommonModule,
    ClickOutsideModule
  ],
  exports: [SidebarContentComponent]
})
export class SidebarContentModule { }
